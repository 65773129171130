import React, { Component } from 'react';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import lodashGet from 'lodash.get';
import Store from 'store';
import { isHieuTruongOrPho, isLeader } from 'components/admin/user/utils';

export const getTemisConfByUser = (user) => {
  const state = Store.getState();
  const isHT = isHieuTruongOrPho(user);
  const isLD = isLeader(user);
  const temisConfig = lodashGet(state, 'domainInfo.conf.temis') || {};
  const rubricToAssessment = lodashGet(
    temisConfig,
    isHT ? 'tcnn_ht' : 'tcnn_gv',
  );

  return {
    temisConfig,
    isHieuTruong: isHT,
    isLeader: isLD,
    rubricToAssessment,
  };
};

/**
 * Will pass down the following props to component
 *
 * @param WrappedComponent
 * @returns {*}
 */
const withTemisConfig = (WrappedComponent) => {
  class SchoolConfigsHOC extends Component {
    render() {
      const { globalConfigs, ...others } = this.props;

      return <WrappedComponent {...others} {...globalConfigs} />;
    }
  }

  const mapStateToProps = (state) => {
    const user = lodashGet(state, 'user.info');
    const enableAssessmentForMamnon = lodashGet(
      state,
      'domainInfo.conf.enable_assessment_for_mamnon',
    );

    const organizationIidsAllowedToUpdateOrganization = lodashGet(
      state,
      'domainInfo.conf.organization_iids_allowed_to_update_organization_for_user_on_cdsl_when_user_organizations_changed',
    );

    const temisByUser = getTemisConfByUser(user);

    return {
      ...temisByUser,
      enableAssessmentForMamnon,
      organizationIidsAllowedToUpdateOrganization,
      userRoot: user,
    };
  };

  hoistNonReactStatic(SchoolConfigsHOC, WrappedComponent);

  return connect(mapStateToProps)(SchoolConfigsHOC);
};

export default withTemisConfig;
