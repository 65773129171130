import React, { Component } from 'react';
import './style.scss';
import {
  REVIEW_STATUS,
  ASSESSMENT_STATUS,
  BECOME_MASTER_STATUS,
  TCNN_TYPE,
} from '../../constant';
import ApprovedAction from './ApprovedAction';
import RejectedAction from './RejectedAction';
import ResetAction from './ResetAction';

const RenderLabelStatus = ({ status }) => (
  <div className={`review-result review-result-${status.key} `}>
    <div className="review-result__left">
      <span className={status.iconClass}> </span>
    </div>
    <div className="review-result__middle">{status.label}</div>
  </div>
);

class ReviewResult extends Component {
  render() {
    const {
      status,
      item = {},
      hasAction = true,
      tcnn = TCNN_TYPE.GV,
      searchFormId,
      disabledAction = false,
      viewOnly = false,
      scholastic,
    } = this.props;
    const { iid } = item;

    const statusObject = REVIEW_STATUS[status];

    if (!statusObject) {
      return null;
    }

    return (
      <div className="review-result">
        {hasAction ? (
          <>
            {status === BECOME_MASTER_STATUS.SENT && (
              <>
                <div className="review-result__left">
                  <ApprovedAction
                    key={iid}
                    data={{
                      iids: [iid],
                      become_master_status: BECOME_MASTER_STATUS.APPROVED,
                      tcnn,
                      scholastic,
                    }}
                    searchFormId={searchFormId}
                    disabled={disabledAction || viewOnly}
                  />
                </div>

                <div className="review-result__right">
                  <RejectedAction
                    key={iid}
                    data={{
                      iids: [iid],
                      become_master_status: BECOME_MASTER_STATUS.REJECTED,
                      tcnn,
                      scholastic,
                    }}
                    searchFormId={searchFormId}
                    disabled={disabledAction || viewOnly}
                  />
                </div>
              </>
            )}

            {(status === BECOME_MASTER_STATUS.APPROVED ||
              status === BECOME_MASTER_STATUS.REJECTED) && (
              <>
                <div className="review-result__left">
                  <RenderLabelStatus
                    key={`label-${iid}`}
                    status={statusObject}
                  />
                </div>
                <div className="review-result__right">
                  <ResetAction
                    key={iid}
                    data={{
                      iids: [iid],
                      become_master_status: BECOME_MASTER_STATUS.SENT,
                      tcnn,
                      scholastic,
                    }}
                    searchFormId={searchFormId}
                    disabled={viewOnly}
                  />
                </div>
              </>
            )}
          </>
        ) : (
          <RenderLabelStatus key={iid} status={statusObject} />
        )}
      </div>
    );
  }
}

export default ReviewResult;
