import React from 'react';
import get from 'lodash.get';
import Tag from 'antd/lib/tag';
import CommonAntdTable from 'components/common/antd/table';
import { Link } from 'react-router-dom';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import TooltipWithIcon from 'common/tooltip';
import { userGradeToShortText } from 'configs/constants/user';
import ChuongTrinhTag from 'components/bdtx/common/ChuongTrinhTag';
import CancelDeploy from '../cancel-deploy';

const TableResult = ({
  items = [],
  showAction,
  searchFormId,
  formIdsToSubmitOnSuccess,
  trainingPhaseIid,
  isCCModel,
}) => {
  const renderTableColumns = () => {
    return [
      {
        title: 'Chương trình bồi dưỡng',
        width: 200,
        render: (item) => {
          const iid = get(item, 'iid');
          return (
            <Link to={`/admin/enrolment-plan/${iid}`} target="_blank">
              {get(item, 'name')}
            </Link>
          );
        },
      },
      {
        title: 'Đơn vị',
        render: (item) => {
          return <span>{get(item, '__expand.organizations.0.name')}</span>;
        },
      },
      {
        title: 'Chương trình',
        dataIndex: 'chuong_trinh',
        render: (chuongTrinh) => {
          return <ChuongTrinhTag chuongTrinh={chuongTrinh} shortName />;
        },
      },
      {
        title: 'Mô đun',
        render: (item) => {
          return <span>{get(item, '__expand.module.name')}</span>;
        },
      },
      {
        title: 'Cấp',
        type: TYPE_OF_TABLE_DATA.TITLE,
        render: (item) => {
          return (
            <Tag className={'m-5'}>
              {userGradeToShortText(
                get(item, 'taphuan_smart_enrolment_plan_v2.grade'),
              )}
            </Tag>
          );
        },
      },
      {
        title: 'Tổng GV/CBQL',
        type: TYPE_OF_TABLE_DATA.TITLE,
        width: 70,
        dataIndex: 'total_members',
        render: (totalMembers = 0) => <span>{totalMembers}</span>,
      },
      {
        title: 'Tổng licenses',
        type: TYPE_OF_TABLE_DATA.TITLE,
        width: 70,
        dataIndex: 'total_licenses',
        render: (totalLicenses = 0) => <span>{totalLicenses}</span>,
      },
      {
        title: 'Thống kê',
        type: TYPE_OF_TABLE_DATA.TITLE,
        children: [
          {
            title: (
              <>
                Đã đăng ký
                <TooltipWithIcon
                  content={`Tổng GV/CBQL đã đăng ký / Tổng GV/CBQL của đơn vị`}
                />
              </>
            ),
            type: TYPE_OF_TABLE_DATA.TITLE,
            width: 70,
            render: ({ total_members = 0, stats = {} }) => {
              const registered = get(stats, 'registered', 0);

              return (
                <>
                  <span className="text-secondary">{registered}</span>/
                  {total_members}
                </>
              );
            },
          },
          {
            title: (
              <>
                Đã học
                <TooltipWithIcon
                  content={`Tổng GV/CBQL đã đi học / Tổng GV/CBQL đã đăng ký`}
                />
              </>
            ),
            type: TYPE_OF_TABLE_DATA.TITLE,
            width: 70,
            render: ({ stats = {} }) => {
              const registered = get(stats, 'registered', 0);
              const learned = get(stats, 'learned', 0);

              return (
                <>
                  <span className="text-primary">{learned}</span>/{registered}
                </>
              );
            },
          },
          {
            title: (
              <>
                Đã dùng license
                <TooltipWithIcon
                  content={`Tổng licenses đã sử dụng / Tổng licenses của đơn vị`}
                />
              </>
            ),
            type: TYPE_OF_TABLE_DATA.TITLE,
            width: 70,
            render: ({ total_licenses = 0, stats = {} }) => {
              const licenses = get(stats, 'licenses', 0);

              return (
                <>
                  <span className="text-primary">{licenses}</span>/
                  {total_licenses}
                </>
              );
            },
          },
        ],
      },
      ...(showAction
        ? [
            {
              title: 'Thao tác',
              type: TYPE_OF_TABLE_DATA.TITLE,
              render: (item) => {
                const epIid = get(item, 'iid');
                const canCancelDeploy = get(item, 'can_cancel_deploy');
                const module = get(item, '__expand.module');
                const organization = get(item, '__expand.organizations.0');
                const chuongTrinh = get(item, 'chuong_trinh');
                const cap = get(item, 'taphuan_smart_enrolment_plan_v2.grade');
                const targetUser = get(
                  item,
                  'taphuan_smart_enrolment_plan_v2.taphuan_user_type',
                );

                return (
                  <>
                    {canCancelDeploy && (
                      <div className="m-b-15">
                        <CancelDeploy
                          module={module}
                          organization={organization}
                          searchFormId={searchFormId}
                          formIdsToSubmitOnSuccess={formIdsToSubmitOnSuccess}
                          hiddenFields={{
                            cap,
                            chuong_trinh: chuongTrinh,
                            training_phase_iid: trainingPhaseIid,
                          }}
                        />
                      </div>
                    )}
                  </>
                );
              },
            },
          ]
        : []),
    ];
  };

  return (
    <CommonAntdTable
      dataSource={items || []}
      columns={renderTableColumns()}
      pagination={false}
      className="learn-default"
    />
  );
};

export default TableResult;
