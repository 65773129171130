import React from 'react';
import Modal from 'antd/lib/modal';
import get from 'lodash.get';
import { generateTEMISUrl } from 'common/utils/url';
import { editTcnn, temisAssessments } from 'components/temis/routes';
import withUserInfo from 'common/hoc/withUserInfo';
import fetchData from 'components/common/fetchData';
import { connect } from 'react-redux';
import { getConf } from 'utils/selectors';
import { Link } from 'react-router-dom';
import NewButton from 'components/common/mui/NewButton';
import {
  getCBQLApproveSupportPlanResultUrl,
  getCBQLApproveSupportPlanUrl,
  getCBQLMenuItem,
} from 'routes/cbql/utils';
import { teacherMenuItems } from 'routes/teacher/util';
import { getMasterMenuItem } from 'routes/master/utils.js';
import { getPathname } from 'common/selectors/router';
import { isBDTX } from 'utils/Util';
import lodashGet from 'lodash.get';

const ShowModalWarningToFinishMission = ({
  userInfo,
  time_to_show_warning = 0,
  data = {},
  loadingStatus,
  handleRefetch,
  pathname,
}) => {
  const [open, setOpen] = React.useState(false);
  const {
    not_finished_self_assessment,
    insufficient_evidence,
    not_finished_assessment_all_teacher,
    not_reviewed_all_support_plan,
    not_reviewed_all_support_plan_result,
    not_reviewed_all_support_plan_with_role_gvspcc,
    not_reviewed_all_support_plan_result_with_role_gvspcc,
  } = data || {};

  const fetchData = () => {
    setTimeout(() => {
      handleRefetch();
    }, time_to_show_warning * 1000 * 60);
  };

  React.useEffect(
    () => {
      if (!open) {
        fetchData();
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [open],
  );

  React.useEffect(
    () => {
      if (
        [
          temisAssessments('assess_assessment'),
          temisAssessments('assess_assessment/peers'),
          getCBQLMenuItem(teacherMenuItems.APPROVE_SUPPORT_PLAN),
          getCBQLMenuItem(teacherMenuItems.APPROVE_RESULT_PLAN),
          getMasterMenuItem(teacherMenuItems.APPROVE_SUPPORT_PLAN),
          getMasterMenuItem(teacherMenuItems.APPROVE_RESULT_PLAN),
        ].includes(pathname)
      ) {
        return;
      }

      // vì màn hình học cũng đã có logic bắt user phải làm đánh giá, nộp minh chứng, ... rồi
      // hoặc màn hình cập nhật profile
      if (
        pathname.includes('/learn/') ||
        pathname.includes('/admin') ||
        pathname.includes('/profile/update-info')
      ) {
        return;
      }

      if (!time_to_show_warning || loadingStatus !== 'finished') {
        return;
      }
      if (
        not_finished_self_assessment ||
        insufficient_evidence ||
        not_finished_assessment_all_teacher ||
        not_reviewed_all_support_plan ||
        not_reviewed_all_support_plan_result ||
        not_reviewed_all_support_plan_with_role_gvspcc ||
        not_reviewed_all_support_plan_result_with_role_gvspcc
      ) {
        setOpen(true);
      }
    },
    [
      not_finished_self_assessment,
      insufficient_evidence,
      time_to_show_warning,
      loadingStatus,
      not_finished_assessment_all_teacher,
      not_reviewed_all_support_plan,
      not_reviewed_all_support_plan_result,
      not_reviewed_all_support_plan_with_role_gvspcc,
      not_reviewed_all_support_plan_result_with_role_gvspcc,
      pathname,
    ],
  );

  const getMessage = React.useMemo(
    () => {
      if (
        (!not_finished_self_assessment &&
          !insufficient_evidence &&
          !not_finished_assessment_all_teacher &&
          !not_reviewed_all_support_plan &&
          !not_reviewed_all_support_plan_result &&
          !not_reviewed_all_support_plan_with_role_gvspcc &&
          !not_reviewed_all_support_plan_result_with_role_gvspcc) ||
        !time_to_show_warning
      ) {
        return;
      }

      const scholastic2019Id = get(
        window,
        'TEMIS_CONFIG.SCHOLASTIC_20192020_ID',
      );
      let doAssessmentOf2019 = editTcnn;
      if (scholastic2019Id) {
        doAssessmentOf2019 = `${editTcnn}?scholastic=${scholastic2019Id}`;
      }

      const temisUrl = generateTEMISUrl(userInfo, doAssessmentOf2019);

      return (
        <div>
          {!!not_finished_self_assessment && (
            <div>
              <div className={'m-b-5'}>
                - Trên hệ thống TEMIS,{' '}
                <span className="text-danger">
                  năm học 2019-2020 thầy cô chưa ghi kết quả tự đánh giá theo
                  chuẩn, và chưa tải minh chứng tự đánh giá.
                </span>
                <br />
                Quý thầy/cô vui lòng truy cập hệ thống TEMIS tự đánh đánh giá
                theo chuẩn và tải minh chứng.
                <div className="text-center">
                  <a target={'_blank'} href={temisUrl}>
                    <NewButton label={'Vào tự đánh giá'} />
                  </a>
                </div>
              </div>
              <hr />
            </div>
          )}

          {!!insufficient_evidence && (
            <div>
              <div className={'m-b-5'}>
                - Trên hệ thống TEMIS,{' '}
                <span className="text-danger">
                  năm học 2019-2020 thầy cô đã ghi kết quả tự đánh giá theo
                  chuẩn, nhưng chưa tải minh chứng tự đánh giá.
                </span>
                <br />
                Quý thầy/cô vui lòng truy cập hệ thống TEMIS để cập nhật và bổ
                sung minh chứng.
                <div className="text-center">
                  <a target={'_blank'} href={temisUrl}>
                    <NewButton label={'Vào cập nhật minh chứng'} />
                  </a>
                </div>
              </div>
              <hr />
            </div>
          )}

          {!!not_finished_assessment_all_teacher && (
            <div>
              <div className={'m-b-5'}>
                - Trên hệ thống TEMIS,{' '}
                <span className="text-danger">
                  năm học 2019-2020 thầy cô chưa hoàn thành đánh giá giáo viên
                  theo chuẩn của trường mình.
                </span>
                <br />
                Xin mời thầy/cô vào hệ thống TEMIS để hoàn thành đánh giá giáo
                viên theo chuẩn của trường mình.
                <div className="text-center">
                  <a
                    target={'_blank'}
                    href={generateTEMISUrl(
                      userInfo,
                      temisAssessments('assess-assessment/peers'),
                    )}
                  >
                    <NewButton label={'Vào đánh giá giáo viên theo chuẩn'} />
                  </a>
                </div>
              </div>
              <hr />
            </div>
          )}

          {!!not_reviewed_all_support_plan && (
            <div>
              <div className={'m-b-5'}>
                - Quý thầy/cô&nbsp;
                <span className="text-danger">
                  chưa hoàn thành phê duyệt kế hoạch hỗ trợ đồng nghiệp của Giáo
                  viên cốt cán
                </span>{' '}
                trong trường mình.
                <br />- Quý thầy/cô vui lòng vào phê duyệt kế hoạch hỗ trợ đồng
                nghiệp của Giáo viên cốt cán trong trường mình.
                <div className="text-center">
                  <Link
                    onClick={() => setOpen(false)}
                    to={getCBQLApproveSupportPlanUrl({
                      module: lodashGet(
                        not_reviewed_all_support_plan,
                        'notReviewedCotcanTpCategory.0',
                      ),
                      statuses: ['organization_management_have_not_review_yet'],
                    })}
                  >
                    <NewButton label={'Vào phê duyệt kế hoạch hỗ trợ'} />
                  </Link>
                </div>
              </div>
              <hr />
            </div>
          )}

          {!!not_reviewed_all_support_plan_result && (
            <div>
              <div className={'m-b-5'}>
                - Quý thầy/cô&nbsp;
                <span className="text-danger">
                  chưa hoàn thành phê duyệt báo cáo kết quả hỗ trợ đồng nghiệp
                  của Giáo viên cốt cán
                </span>{' '}
                trong trường mình.
                <br />- Quý thầy/cô vui lòng vào phê duyệt báo cáo kết quả hỗ
                trợ đồng nghiệp của Giáo viên cốt cán trong trường mình.
                <div className="text-center">
                  <Link
                    onClick={() => setOpen(false)}
                    to={getCBQLApproveSupportPlanResultUrl({
                      school_year: lodashGet(
                        not_reviewed_all_support_plan_result,
                        'notReviewedSchoolYears.0',
                      ),
                      statuses: ['organization_management_have_not_review_yet'],
                    })}
                  >
                    <NewButton label={'Vào phê duyệt báo cáo kết quả'} />
                  </Link>
                </div>
              </div>
              <hr />
            </div>
          )}

          {!!not_reviewed_all_support_plan_with_role_gvspcc && (
            <div>
              <div className={'m-b-5'}>
                - Quý thầy/cô{' '}
                <span className="text-danger">
                  chưa hoàn thành phê duyệt kế hoạch hỗ trợ đồng nghiệp của
                  GVCC/CBQLCC trong bồi dưỡng cốt cán
                </span>
                <br />- Quý thầy/cô vui lòng vào phê duyệt kế hoạch hỗ trợ đồng
                nghiệp của GVCC/CBQLCC.
                <div className="text-center">
                  <Link
                    onClick={() => setOpen(false)}
                    to={getMasterMenuItem(
                      teacherMenuItems.APPROVE_SUPPORT_PLAN,
                    )}
                  >
                    <NewButton label={'Vào duyệt KHHT đồng nghiệp'} />
                  </Link>
                </div>
              </div>
              <hr />
            </div>
          )}

          {!!not_reviewed_all_support_plan_result_with_role_gvspcc && (
            <div>
              <div className={'m-b-5'}>
                - Quý thầy/cô{' '}
                <span className="text-danger">
                  chưa hoàn thành phê duyệt báo cáo kết quả hỗ trợ đồng nghiệp
                  của GVCC/CBQLCC trong bồi dưỡng cốt cán
                </span>
                <br />- Quý thầy/cô vui lòng vào phê duyệt báo cáo kết quả hỗ
                trợ đồng nghiệp của GVCC/CBQLCC.
                <div className="text-center">
                  <Link
                    onClick={() => setOpen(false)}
                    to={getMasterMenuItem(teacherMenuItems.APPROVE_RESULT_PLAN)}
                  >
                    <NewButton
                      label={'Vào duyệt báo cáo kết quả hỗ trợ đồng nghiệp'}
                    />
                  </Link>
                </div>
              </div>
              <hr />
            </div>
          )}
        </div>
      );
    },
    [
      insufficient_evidence,
      not_finished_assessment_all_teacher,
      not_finished_self_assessment,
      not_reviewed_all_support_plan,
      not_reviewed_all_support_plan_result,
      not_reviewed_all_support_plan_with_role_gvspcc,
      not_reviewed_all_support_plan_result_with_role_gvspcc,
      time_to_show_warning,
      userInfo,
    ],
  );

  if (!open || !getMessage || isBDTX()) {
    return null;
  }

  return (
    <Modal
      title={`Kính gửi Thầy/Cô: ${userInfo.name}`}
      visible={open && getMessage}
      footer={null}
      onCancel={() => setOpen(false)}
    >
      <div>{getMessage}</div>
    </Modal>
  );
};

const fetchOverallFinishMission = (props) => ({
  baseUrl: '/taphuan/api/get-overall-to-finish-mission',
  fetchCondition:
    !!get(props, 'userInfo.iid') && get(props, 'time_to_show_warning') > 0,
  propKey: 'data',
  refetchCondition: (prevProps) =>
    !!get(props, 'userInfo.iid') &&
    get(props, 'userInfo.iid') !== get(prevProps, 'userInfo.iid'),
});

const mapStateToProps = (state) => {
  const time_to_show_warning = (getConf(state) || {})
    .time_to_show_warning_update_self_assessment;

  const pathname = getPathname(state);

  return {
    pathname,
    time_to_show_warning,
  };
};

export default connect(mapStateToProps)(
  withUserInfo(
    fetchData(fetchOverallFinishMission)(ShowModalWarningToFinishMission),
  ),
);
