import React from 'react';
import { Tabs } from 'antd';
import { t1 } from 'translate';
import OverviewNcbdtxByModule from './overview';
import DetailNcbdtxByModule from './details';

const NcbdtxByModule = () => {
  return (
    <>
      <h3>
        Tổng hợp Nhu cầu BDTX theo module đào tạo của Giáo Viên, CBQL CSGDPT
      </h3>

      <hr />

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={t1('overview_ncbdtx_by_module')} key="1">
          <OverviewNcbdtxByModule />
        </Tabs.TabPane>

        <Tabs.TabPane tab={t1('details_ncbdtx_by_module')} key="2">
          <DetailNcbdtxByModule />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default NcbdtxByModule;
