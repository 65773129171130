export const adminMenuItems = {
  HOME: 'home',
  ABOUT_ETEP: 'about_etep',
  PAYMENT_SUPPORT: 'payment_support',
  FAQ: 'faq',
  CREDIT: 'credit',
  SYLLABUS: 'syllabus',
  PROGRAM: 'program',
  ACADEMIC_CATEGORY: 'academic-category',
  SKILL: 'skill',
  RUBRIC: 'rubric',
  BANK: 'bank',
  MEDIA_V1: 'media-v1',
  MEDIA: 'media',
  IMPORT_RUBRICS: 'import_rubrics',
  COURSE: 'course',
  MY_SYLLABUS: 'my_syllabus',
  OTHER_CERTIFICATES: 'other-certificates',
  // OFFLINE_EXAM: 'offline-exam',
  STUDENTS_TAKING_THE_OFFLINE_EXAM: 'students_taking_the_offline_exam',
  PATH: 'path',
  COURSE_ENROLMENT: 'course_enrolment',
  REPORT: 'report',
  STUDENT_FEEDBACK: 'student_feedback',
  TEACHER_FEEDBACK: 'teacher_feedback',
  TEACHER_QA: 'admin_q_&_a',
  ORGANIZATION_LEARNING: 'organization_learning',
  TIME_SHEET: 'time_sheet',
  // MAJOR: 'major',
  SEMESTER: 'semester',
  ICO: 'ico',
  // GOAL: 'goal',
  PLAN: 'plan',
  CLASSGROUP: 'classgroup',
  TIMETABLE: 'timetable',
  SCHEDULE_OVERVIEW: 'schedule-overview',
  PROCESSING_REQUESTS: 'processing_requests',
  REQUEST_TYPE_MANAGE: 'request_type_manage',
  TEACHER: 'teacher',
  PARENT: 'parent',
  ACCOUNT: 'account',
  USER_MANAGE: 'user_manage',
  // USER_MAJORS: 'user_majors',
  GROUP_MANAGE: 'group_manage',
  USER_GROUP_CATEGORY: 'user_group_category',
  ADMISSION: 'admission',
  STUDENT_RECOGNITION: 'student-recognition',
  EXPULSION_GROUP: 'expulsion-group',
  FINISHING_SENIOR: 'finishing-senior',
  GRADUATING_SENIOR: 'graduating-senior',
  ORGANIZATIONS: 'organizations',
  ENABLED_FEATURES_BY_ORGANIZATION: 'enabled_features_by_organization',
  JOB_POSITION: 'job_position',
  TOP_EQUIVALENT_POSITION: 'top-equivalent-position',
  SCHOOL_BUS: 'school-bus',
  TRANSACTION: 'transaction',
  DEGREE: 'degree',
  VENUE: 'venue',
  SCHOOL_IN_VIETNAM: 'school_in_vietnam',
  ASSET: 'asset',
  ASSET_CATEGORIES: 'asset-categories',
  FINANCIAL: 'financial',
  CONTEST: 'contest',
  EXAM_TEMPLATE: 'exam-template',
  TEMPLATE: 'template',
  QUESTION_BANK: 'question-bank',
  PAGE: 'page',
  BLOG_CATEGORIES: 'blog_categories',
  EVENT: 'event',
  ABSTRACT_ROLE: 'abstract-role',
  SCHOOL_ROLE: 'school-role',
  LOG: 'log',
  TRAINING_PLAN: 'training_plan',
  ENROLMENT_PLAN: 'enrolment_plan',
  SURVEY: 'survey',
  MESSAGE_TEMPLATES: 'message-templates',
  SCHOOL_MESSAGE_TEMPLATES: 'school-message-templates',
  BUDGETARY: 'budgetary',
  ABAC_ROLE_ACTION: 'abac-role-action',
  ABAC_ROLE_MODULE: 'abac-role-module',
  JOBS: 'jobs',
  SUPPORT: 'support',
  MENU_ITEM_IT_SYNCDAT_FOR_CSDLN: 'syncdata_for_csdln',
  TRAINING_HOMEWORK: 'training-homework',
  TEMIS: 'temis',
  ASSESSMENT_EVIDENCE_TEMPLATE: 'assessment_evidence_template',
  TEACHER_DASHBOARD: 'teacher_dashboard',
  MASTER_TEACHER: 'master_teacher',
  DASHBOARD: 'dashboard',
  ASSIGNED_COURSE: 'assigned_course',
  TRAINING_PLAN_QUOTA: 'training_plan_quota',
  TRAINING_PLAN_QUOTA_MULTIPLE_MODULES: 'training_plan_quota_multiple_modules',
  SCHOOL_GROUP: 'school-group', // Taphuan smart ep for PGD
  LICENSE_LEARNING: 'license',
  LICENSING_LEARNING: 'licensing',
  // bdtx
  BDTX_MODULE: 'module',
  BDTX_CHOOSE_MODULES_FOR_YEAR: 'choose-modules-for-year',
  BDTX_OVERVIEW_MODULE_MATERIALS: 'overview-module-materials',
  REVIEW_PROGRAM: 'review-program',
  REVIEW_EXAM_STORE: 'review-exam-store',
  BDTX_BUY_MATERIALS: 'buy-materials',
  BDTX_ASSIGN_MODULES: 'bdtx-assign-modules',
  BDTX_PLAN: 'bdtx-plan',
  BDTX_KPI: 'bdtx-kpi',
  BDTX_MIGRATE_ETEP_TO_BDTX: 'bdtx-migrate-etep-to-bdtx',
};
