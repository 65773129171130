import React from 'react';
import NodeNew from '../../../admin/node/new';
import endPoints from '../../assessment/endpoints';

const BaseAction = ({
  alternativeApi,
  labelButton,
  classButton,
  data = {},
  formid = 'review-form',
  searchFormId,
  disabled,
}) => (
  <NodeNew
    schema={{}}
    formid={formid}
    searchFormId={searchFormId}
    hiddenFields={data}
    alternativeApi={alternativeApi || endPoints.chooseUsersToBecomeMaster}
    submitButton={() => {
      return (
        <div className="d-flex">
          <button
            className={`m-b-10 btn-action btn btn-outline ${classButton}`}
            disabled={disabled}
          >
            {labelButton}
          </button>
        </div>
      );
    }}
  />
);

export default BaseAction;
