import withTemisConfig from 'common/hoc/withTemisConfig';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { isAdminTruongOrHieuTruongOrPho } from 'components/admin/user/utils';
import CommonAntdTable from 'components/common/antd/table';
import DisplayHtml from 'components/common/html';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import { userGradeToText } from 'configs/constants/user';
import get from 'lodash.get';
import React from 'react';
import { t1 } from 'translate';
import { isEnableTemis } from 'components/temis/utils';
import { getNumberFormat } from '../../utils';
import { convertNumberToRomanNumber } from 'common/utils/Number';
import { getSchoolYearByTrainingPlanCategory } from 'components/admin/training-plan/common';

const BDTX_TYPE = {
  totalMembers: 'totalMembers',
  onlineMembers: 'onlineMembers',
  finishedSurvey: 'finishedSurvey',
  finishedModule: 'finishedModule',
  numberAndRateResponses: 'numberAndRateResponses',
};

const getTitleColumn = (resultType, isResultForCBQL, trainingPlanCategory) => {
  let title = '';
  switch (resultType) {
    case BDTX_TYPE.totalMembers:
      title = isResultForCBQL ? 'Tổng số CBQL</i>' : 'Tổng số GV</i>';
      break;
    case BDTX_TYPE.finishedSurvey:
      title = isResultForCBQL
        ? 'CBQL đã trả lời phiếu khảo sát</i>'
        : 'Giáo viên đã trả lời phiếu khảo sát</i>';
      break;
    case BDTX_TYPE.finishedModule:
      const schoolYear = getSchoolYearByTrainingPlanCategory(
        trainingPlanCategory,
      );
      let mdbd = 'các MĐBD';
      switch (schoolYear) {
        case 2020: {
          mdbd = 'MĐBD 1,2';
          break;
        }
        case 2021: {
          mdbd = 'MĐBD 3,4';
          break;
        }
        default: {
          break;
        }
      }

      title = isResultForCBQL
        ? `Tổng số CBQL CSGDPT hoàn thành ${mdbd} năm ${schoolYear}`
        : `Tổng số GVPT CSGDPT hoàn thành ${mdbd} năm ${schoolYear}` +
          ' <br><i>(% = SL/Tổng)</i>';

      break;
    case BDTX_TYPE.onlineMembers:
      title = isResultForCBQL
        ? 'Tổng số CBQL tham gia học tập trực tuyến'
        : 'Tổng số GVPT tham gia học tập trực tuyến';
      break;
    case BDTX_TYPE.numberAndRateResponses:
      title =
        'Số lượng và tỷ lệ % HV hài lòng <br><i>(có điểm TB ở 4/5 mục từ 3.0 trở lên (không có mục nào điểm TB dưới 2.5))</i>';
      break;
    default:
      title = isResultForCBQL
        ? 'CBQL đã trả lời phiếu khảo sát'
        : 'Giáo viên đã trả lời phiếu khảo sát';
  }

  return title;
};

const getDataRenderByColumn = (detail, total, skipFormatNumber, reportType) => {
  let text = detail;

  if (reportType === 'percentage' && detail > 0 && total) {
    text = parseFloat(((text / total) * 100).toFixed(2));
  }

  return {
    children: total > 0 ? getNumberFormat(text, skipFormatNumber) : '',
    props: {
      className: 'text-right',
      width: 90,
    },
  };
};

const getColumns = (
  questionGroups,
  userRoot,
  numberOfDataSource = 0,
  isResultForCBQL,
  skipFormatNumber,
  trainingPlanCategory,
) =>
  [
    {
      title: isAdminTruongOrHieuTruongOrPho(userRoot) ? '' : t1('school_level'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: ({ school_level, name, isChildren }) => {
        return {
          children: isChildren ? (
            name
          ) : (
            <span style={{ fontWeight: 600 }}>
              {userGradeToText(school_level)}
            </span>
          ),
          props: {
            style: { width: 180 },
          },
        };
      },
    },
    isEnableTemis() && {
      title: (
        <DisplayHtml
          content={getTitleColumn(
            BDTX_TYPE.totalMembers,
            isResultForCBQL,
            trainingPlanCategory,
          )}
        />
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      render: ({ total_members }) => {
        return {
          children: getNumberFormat(total_members, skipFormatNumber) || 0,
          props: {
            style: { width: 100 },
            className: 'text-right',
          },
        };
      },
    },
    {
      title: (
        <DisplayHtml
          content={getTitleColumn(
            BDTX_TYPE.onlineMembers,
            isResultForCBQL,
            trainingPlanCategory,
          )}
        />
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      render: ({ total_members_learned }) => {
        return {
          children:
            getNumberFormat(total_members_learned, skipFormatNumber) || 0,
          props: {
            style: { width: 100 },
            className: 'text-right',
          },
        };
      },
    },
    {
      title: (
        <DisplayHtml
          content={getTitleColumn(
            BDTX_TYPE.finishedModule,
            isResultForCBQL,
            trainingPlanCategory,
          )}
        />
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: `SL`,
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 100,
          render: ({ total_members_passed_learning, total_members_learned }) =>
            getDataRenderByColumn(
              total_members_passed_learning,
              total_members_learned,
              skipFormatNumber,
            ),
        },
        {
          title: `%`,
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 100,
          render: ({ total_members_passed_learning, total_members_learned }) =>
            getDataRenderByColumn(
              total_members_passed_learning,
              total_members_learned,
              skipFormatNumber,
              'percentage',
            ),
        },
      ],
    },
    {
      title: (
        <DisplayHtml
          content={getTitleColumn(
            BDTX_TYPE.finishedSurvey,
            isResultForCBQL,
            trainingPlanCategory,
          )}
        />
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: `SL`,
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 100,
          render: ({
            total_members_finished_survey,
            total_members_passed_learning,
            total_members_learned,
          }) =>
            getDataRenderByColumn(
              total_members_passed_learning,
              total_members_learned,
              skipFormatNumber,
            ),
        },
        {
          title: `%`,
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 100,
          render: ({
            total_members_finished_survey,
            total_members_passed_learning,
            total_members,
            total_members_learned,
          }) =>
            getDataRenderByColumn(
              total_members_passed_learning,
              total_members_learned,
              skipFormatNumber,
              'percentage',
            ),
        },
      ],
    },
    {
      title: (
        <DisplayHtml
          content={getTitleColumn(
            BDTX_TYPE.numberAndRateResponses,
            isResultForCBQL,
            trainingPlanCategory,
          )}
        />
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: `SL`,
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 100,
          render: ({
            total_members_happy_survey,
            total_members_finished_survey,
            total_members_passed_learning,
          }) =>
            getDataRenderByColumn(
              total_members_happy_survey,
              total_members_passed_learning,
              skipFormatNumber,
            ),
        },
        {
          title: `%`,
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 100,
          render: ({
            total_members_happy_survey,
            total_members_finished_survey,
            total_members_passed_learning,
          }) =>
            getDataRenderByColumn(
              total_members_happy_survey,
              total_members_passed_learning,
              skipFormatNumber,
              'percentage',
            ),
        },
      ],
    },
    {
      title: (
        <>
          Thông tin điểm TB của tất cả các item
          <br />
          (có điểm TB của tổng các item từ 3.0 (không có mục nào dưới 2.5))
        </>
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: `Điểm TB`,
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 100,
          render: ({
            average_survey_score,
            average_passed_survey_score,
            total_members_finished_survey,
            total_members_passed_survey,
          }) =>
            total_members_passed_survey
              ? average_passed_survey_score
                ? average_passed_survey_score.toFixed(2)
                : average_passed_survey_score
              : '',
        },
        {
          title: `SL`,
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 100,
          render: ({
            total_members_passed_survey,
            total_members_finished_survey,
            total_members_passed_learning,
          }) =>
            getDataRenderByColumn(
              total_members_passed_survey,
              total_members_passed_learning,
              skipFormatNumber,
            ),
        },
        {
          title: `%`,
          type: TYPE_OF_TABLE_DATA.TITLE,
          width: 100,
          render: ({
            total_members_passed_survey,
            total_members_finished_survey,
            total_members_passed_learning,
          }) =>
            getDataRenderByColumn(
              total_members_passed_survey,
              total_members_passed_learning,
              skipFormatNumber,
              'percentage',
            ),
        },
      ],
    },
  ]
    .concat(
      Array.isArray(questionGroups) && questionGroups.length > 0
        ? questionGroups.map((questionGroup, index) => {
            return {
              title: (
                <>
                  {get(questionGroup, 'content')}
                  <br />
                  (có điểm TB của tổng các item từ 3.0)
                </>
              ),
              type: TYPE_OF_TABLE_DATA.TITLE,
              children: [
                {
                  title: 'Điểm TB',
                  type: TYPE_OF_TABLE_DATA.TITLE,
                  width: 100,
                  render: ({ detail, total_members_finished_survey }) => {
                    let score = get(
                      detail,
                      `${get(
                        questionGroup,
                        'question_id',
                      )}.average_passed_score`,
                    );
                    if (score) {
                      score = score.toFixed(2);
                    }

                    return getDataRenderByColumn(
                      score,
                      get(
                        detail,
                        `${get(questionGroup, 'question_id')}.passed_item`,
                        0,
                      ),
                      skipFormatNumber,
                    );
                  },
                },
                {
                  title: `SL`,
                  type: TYPE_OF_TABLE_DATA.TITLE,
                  width: 100,
                  render: ({
                    detail,
                    total_members_finished_survey,
                    total_members_passed_learning,
                  }) =>
                    getDataRenderByColumn(
                      get(
                        detail,
                        `${get(questionGroup, 'question_id')}.passed_item`,
                      ),
                      total_members_passed_learning,
                      skipFormatNumber,
                    ),
                },
                {
                  title: `%`,
                  type: TYPE_OF_TABLE_DATA.TITLE,
                  width: 100,
                  render: ({
                    detail,
                    total_members_finished_survey,
                    total_members_passed_learning,
                  }) =>
                    getDataRenderByColumn(
                      get(
                        detail,
                        `${get(questionGroup, 'question_id')}.passed_item`,
                      ),
                      total_members_passed_learning,
                      skipFormatNumber,
                      'percentage',
                    ),
                },
              ],
            };
          })
        : [],
    )
    .filter(Boolean);

const getPropsToRenderTable = (dataTable) => {
  if (!Array.isArray(dataTable) || !dataTable.length) {
    return { dataSource: [], expandedRowKeys: [] };
  }

  const expandedRowKeys = [];

  let dataSource = dataTable
    .map((data) => {
      expandedRowKeys.push(data.id);
      return {
        ...data,
        children: (data.children || []).map((childrenData) => {
          return {
            ...childrenData,
            name: t1(childrenData.id),
            isChildren: true,
          };
        }),
      };
    })
    .filter(Boolean);

  return {
    dataSource,
    expandedRowKeys,
  };
};

const TableResult = ({
  dataResult,
  userRoot,
  isResultForCBQL,
  defaultExpandAllRows,
  skipFormatNumber,
  trainingPlanCategory,
}) => {
  const { dataSource, expandedRowKeys } = getPropsToRenderTable(
    get(dataResult, 'data', []),
  );
  const questionGroups = get(dataResult, 'question_groups', []);

  if (!Array.isArray(dataSource) || !Array.isArray(questionGroups)) {
    return <SimpleNoResult />;
  }

  return (
    <CommonAntdTable
      bordered
      rowKey="id"
      pagination={false}
      className="white-background learn-default"
      columns={getColumns(
        questionGroups,
        userRoot,
        dataSource.length,
        isResultForCBQL,
        skipFormatNumber,
        trainingPlanCategory,
      )}
      dataSource={dataSource}
      scroll={{ x: 'max-content' }}
      defaultExpandedRowKeys={expandedRowKeys}
      defaultExpandAllRows={defaultExpandAllRows}
    />
  );
};

export default withTemisConfig(TableResult);
