/**
 * Created by hungvo on 26/06/17.
 */
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import Store from 'store';
import { replaceStaticToStatic2 } from './url';

const getExtensionFromUrlFile = (url) => {
  return (url = url.substr(1 + url.lastIndexOf('/')).split('?')[0])
    .split('#')[0]
    .substr(url.lastIndexOf('.'));
};

export const downloadFileFromUrl = ({ downloadUrl, fileName = '' }) => {
  const urlReplaced = replaceStaticToStatic2(downloadUrl);
  const fallbackToRedirect = () => {
    window.location.assign(urlReplaced);
  };

  if (!fileName) {
    fallbackToRedirect();

    return;
  }

  try {
    fetch(urlReplaced)
      .then((resp) => {
        // Forbidden
        if (resp.status == 403) {
          throw resp.statusText;
        }

        return resp.blob();
      })
      .then((blob) => {
        let extension = getExtensionFromUrlFile(downloadUrl);
        extension = extension.replace('.', '');

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${fileName}.${extension}`;

        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(() => {
        fallbackToRedirect();
      });
  } catch (e) {
    fallbackToRedirect();
  }
};

export const convertFromBase64ToFile = (dataURI, fileName) => {
  let byteString;

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ia], { type: mimeString });

  return new File([blob], fileName);
};

/**
 * @param typesOrType
 * @return {Array|undefined}
 *  if undefined => allow everything
 *  else => an array of accept file types
 */
export const allowedFileTypes = (typesOrType) => {
  const filesTypes = [
    'video/*',
    'image/*',
    'audio/*',
    'application/pdf',
    '.doc,.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // ppt
    '.ppt,.pptx',
    'application/vnd.sealed-ppt',
    'application/vnd.ms-powerpoint',
    // document
    '.txt',
    // excel
    '.excel,.csv',
    '.xls,.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    // zip file type
    '.rar,.gz,application/zip,application/x-compressed',
    // font
    '.font,.ttf',
    // media_type
    'application/vnd.adobe.flash.movie',
    '.h5p',
  ];

  let types = typesOrType;
  if (!Array.isArray(types)) {
    types = [types];
  }

  if (types.includes('word')) {
    types.push('.doc');
  }

  if (types.includes('ppt')) {
    types.push('.ppt');
    types.push('powerpoint');
  }

  if (types.includes('excel')) {
    types.push('.xls');
    types.push('.csv');
    types.push('spreadsheetml');
  }

  if (types.includes('h5p')) {
    types.push('.h5p');
  }

  // filter out empty value
  types = types.filter(Boolean);

  if (!types.length) {
    return [];
  }

  if (types.some((type) => type === 'anything')) {
    return undefined;
  }

  return filesTypes.filter((fType) =>
    types.some((type) => {
      if (fType.includes(type)) {
        return true;
      }

      if (type === 'swf') {
        return fType === 'application/vnd.adobe.flash.movie';
      }

      // some special cases
      // very fucking hackish, type == 'text' is when we're handling the avatar of the text
      // TODO: fix this later
      if (type === 'img' || type === 'text') {
        return fType === 'image/*';
      }
    }),
  );
};

export const getDisplayFileSize = (sizeInByte) => {
  const allUnits = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let sizeInUnit = sizeInByte;
  let unitIndex = 0;
  while (sizeInUnit > 1024) {
    unitIndex += 1;
    sizeInUnit /= 1024;
  }
  return `${Math.round(sizeInUnit * 10) / 10} ${allUnits[unitIndex]}`;
};

export const getFileFullName = (item) => {
  let name = t1('unknown');
  if (item) {
    if (item.name) {
      name = item.name;
    }
    if (item.ext) {
      name += `.${item.ext}`;
    }
  }
  return name;
};

export const getFileKeyForElement = (item) =>
  item.id || item.code || item.iid || getFileFullName(item);

export const createDownloadLink = (file) => {
  const path = file['path'];

  if (path.startsWith('2020')) {
    return 'https://taphuan-statics2.csdl.edu.vn/ufiles/' + path;
  } else {
    return 'https://taphuan-admin.csdl.edu.vn/ufiles/' + path;
  }
};

export const getAcceptFileExtensionFromAcceptFileType = (
  acceptFileTypes = [],
) => {
  if (
    !acceptFileTypes ||
    !acceptFileTypes.length ||
    acceptFileTypes.includes('anything')
  ) {
    return;
  }

  const domainInfo = Store.getState().domainInfo || {};
  const { accept_files_mapping = {} } = domainInfo;

  let acceptExtensions = [];
  acceptFileTypes.forEach((fileType) => {
    const extensionsMapping = accept_files_mapping[fileType];
    if (extensionsMapping) {
      acceptExtensions = acceptExtensions.concat(extensionsMapping);
    }
  });

  return acceptExtensions;
};

export const isVideoOrAudioFile = (file) => {
  const type = lodashGet(file, 'type') || '';

  return type.startsWith('audio/') || type.startsWith('video/');
};

export const getDurationAsync = (file) => {
  const url = URL.createObjectURL(file);
  const type = lodashGet(file, 'type') || '';

  let fileType = '';
  if (type.startsWith('audio/')) {
    fileType = 'audio';
  } else if (type.startsWith('video/')) {
    fileType = 'video';
  }

  return new Promise((resolve, reject) => {
    if (!fileType) {
      reject('file is not video or audio');

      return;
    }

    const videoEl = document.createElement(fileType);
    videoEl.muted = true;

    const source = document.createElement('source');
    source.src = url; //--> blob URL

    videoEl.preload = 'metadata';
    videoEl.appendChild(source);

    videoEl.onloadedmetadata = function() {
      resolve(videoEl.duration);

      videoEl.remove();
    };
  });
};
