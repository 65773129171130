import React from 'react';
import fetchData from 'components/common/fetchData';
import { formatDataSourceFromDataServerToRenderTableAssess } from 'components/temis/assessment//util';
import { t1 } from 'translate';
import Tooltip from 'antd/lib/tooltip';
import get from 'lodash.get';
import {
  generateClassByStatusAssessment,
  isApprovedAssessment,
} from 'components/temis/utils';
import Title from 'components/temis/common/Title';
import { assessmentResultShortText } from 'components/temis/common/constant';
import { leaderPositionToText } from 'configs/constants/user';
import CommonAntdTable from 'components/common/antd/table';
import endPoints from '../../endpoints';
import { SCHOOL_TYPE } from 'components/temis/constant';
import withTemisConfig from 'common/hoc/withTemisConfig';
import { isMamNon } from 'components/admin/user/utils';

const labelAnswer = {
  0: assessmentResultShortText.CD,
  1: assessmentResultShortText.D,
  2: assessmentResultShortText.K,
  3: assessmentResultShortText.T,
};

class AssessmentInOrg extends React.Component {
  render() {
    const {
      items,
      rubrics,
      score_scale,
      loadingStatus,
      searchValues,
      isResultOfHP,
      isResultOfHT,
      isSo,
      isPhong,
    } = this.props;

    if (loadingStatus !== 'finished') {
      return `${t1('loading')} ...`;
    }
    if (
      !Array.isArray(rubrics) ||
      !rubrics.length ||
      !score_scale ||
      !Array.isArray(score_scale.parts) ||
      !score_scale.parts.length
    ) {
      return <h3>{t1('no_content_to_assessment')}</h3>;
    }

    const columns = [
      {
        title: t1('full_name'),
        key: 'name',
        width: '10%',
        dataIndex: 'name',
      },
      ...(isSo || isPhong
        ? [
            {
              title: t1('organizations'),
              key: 'organizations',
              render: (row) => {
                return (
                  <div className="organizations">
                    <div className="organizations-name">
                      {get(row, 'organization_name')}
                    </div>
                  </div>
                );
              },
            },
          ]
        : []),
      {
        title: 'Chức vụ',
        className: 'text-center',
        key: 'position',
        width: '8%',
        render: ({ ...user }) => {
          return {
            children: leaderPositionToText(get(user, 'leader_position')),
          };
        },
      },
      {
        title: (
          <div className="text-center">
            <Title />
          </div>
        ),
        key: 'score',
        className: 'text-center',
        children: (Array.isArray(rubrics) ? rubrics : [])
          .filter(({ isHeader }) => !isHeader)
          .map(({ name, iid }, index) => ({
            title: <Tooltip title={name}>{index + 1}</Tooltip>,
            className: 'text-center',
            render: (row) => {
              const temisData = get(row, `__expand.temis`, {});
              const { tcnn_gv, tcnn_ht } = temisData;
              const status = get(tcnn_gv || tcnn_ht, 'status');
              const tasks = get(tcnn_gv || tcnn_ht, 'task');
              if (
                !isApprovedAssessment(status) ||
                !Array.isArray(tasks) ||
                !tasks.length
              ) {
                return '';
              }

              const taskByRubric = tasks.find(
                ({ rubric_iid }) => String(rubric_iid) === String(iid),
              );
              const answer = get(taskByRubric, 'answer') || '__';
              const result = get(labelAnswer, answer);
              return (
                <div
                  className={`color-status-${generateClassByStatusAssessment(
                    result,
                  )}`}
                >
                  {result}
                </div>
              );
            },
          })),
      },
      {
        title: 'Xếp loại',
        width: '8%',
        className: 'text-center',
        render: ({ assessment }) => {
          const take = get(assessment, 'task');
          const parts = get(assessment, 'score_scale');

          const result = get(assessment, 'result', {});
          if (typeof result.final === 'undefined') {
            return null;
          }

          const part = parts.find(
            ({ id }) => String(id) === String(result.final),
          );
          return (
            <div
              className={`${generateClassByStatusAssessment(
                get(part, 'name'),
              )}`}
            >
              {get(part, 'name')}
            </div>
          );
        },
      },
    ];

    const isResultOfCBQL = isResultOfHP || isResultOfHT;
    return (
      <CommonAntdTable
        dataSource={Array.isArray(items) ? items : []}
        columns={columns}
        bordered
        className="white-background learn-default custom-p-column"
        rowKey="id"
        pagination={false}
        searchValues={searchValues}
        stt={1}
        sttProps={{
          title: 'STT',
          width: 55,
        }}
        scroll={{
          x: isResultOfCBQL && true,
        }}
      />
    );
  }
}

const fetDataAssessment = fetchData(
  ({
    rubricIid,
    scholastic,
    items,
    assessment_of_organization_type,
    tcnn,
    userRoot,
  }) => ({
    baseUrl: endPoints.dataToAssessment,
    params: {
      assessment_of_organization_type:
        assessment_of_organization_type ||
        (isMamNon(userRoot) ? SCHOOL_TYPE.MN : SCHOOL_TYPE.GVPT),
      tcnn,
      rubric_iid: rubricIid,
      scholastic,
    },
    propKey: 'rubric',
    formatDataResult: ({ rubrics, score_scale, assessment_name } = {}) => {
      return {
        assessment_name,
        rubrics: formatDataSourceFromDataServerToRenderTableAssess(rubrics),
        score_scale,
      };
    },
    fetchCondition:
      scholastic &&
      Array.isArray(items) &&
      items.length &&
      (!!rubricIid || (tcnn && assessment_of_organization_type)),
    refetchCondition: (prevProps) =>
      Array.isArray(items) &&
      items.length &&
      (rubricIid !== prevProps.rubricIid ||
        scholastic !== prevProps.scholastic ||
        assessment_of_organization_type !==
          prevProps.assessment_of_organization_type ||
        tcnn !== prevProps.tcnn),
  }),
);

export default withTemisConfig(fetDataAssessment(AssessmentInOrg));
