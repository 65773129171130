import React, { useState, Fragment } from 'react';
import { compose } from 'redux';
import HeaderAssessment from './headerAssessment';
import {
  isHieuPho,
  isHieuTruong,
  isToTruong,
} from 'components/admin/user/utils';
import Form from '../../edit/Form';
import withUserInfo from 'common/hoc/withUserInfo';
import withOutOfDeadlineAssessment from '../../../components/scholastic/withOutOfDeadlineAssessment';
const ViewAssessMyPeersContent = ({
  userForAssessment,
  dialogKey,
  searchFormId,
  closeDialog,
  handleReload,
  userInfo,
  viewOnly,
  scholastic,
  isOutOfDeadlineAssessment,
  currentStatusOfParentToDoAssessment,
  finalAggregateAssessment,
  assessorIid,
}) => {
  const [useTeamLeaderResult, setUseTeamLeaderResult] = useState(null);
  const [useTeacherResult, setUseTeacherResult] = useState(null);

  return (
    <Fragment>
      <HeaderAssessment
        name={userForAssessment.name}
        viewPeersAssessment={!isHieuPho(userForAssessment)}
        viewSelfAssessment
        userIid={userForAssessment.iid}
        rubricIid={userForAssessment.rubric_iid}
        dialogKey={`self_${dialogKey}`}
        userForAssessment={userForAssessment}
        scholastic={scholastic}
        setUseTeamLeaderResult={setUseTeamLeaderResult}
        showButtonUseTeamLeaderResult={
          isHieuTruong(userInfo) &&
          !isHieuPho(userForAssessment) &&
          !isToTruong(userForAssessment) &&
          !!finalAggregateAssessment
        }
        showDisabledUseTeamLeaderResultButton={isOutOfDeadlineAssessment}
        setUseTeacherResult={setUseTeacherResult}
        showButtonUseTeacherResult={
          !finalAggregateAssessment && isToTruong(userInfo)
        }
        showDisabledUseTeacherResultButton={isOutOfDeadlineAssessment}
        showButtonSummaryOpinion={
          isHieuTruong(userInfo) && isHieuPho(userForAssessment)
        }
        finalAggregateAssessment={finalAggregateAssessment}
      />
      <div className={`${viewOnly ? 'mui-paper-no-box-shadow' : ''}`}>
        <Form
          peerAssess
          hiddenFields={{
            target: {
              iid: userForAssessment.iid,
              ntype: 'user',
            },
            peer_assess: 1,
          }}
          userIid={userForAssessment.iid}
          searchFormId={searchFormId}
          assessorIid={assessorIid}
          requestSuccessful={() => {
            closeDialog();
            handleReload();
          }}
          dialogKey={dialogKey}
          hideBecomeMaster
          ignoreUserIidParamToEvidenceAPI
          useTeamLeaderResult={useTeamLeaderResult}
          useTeacherResult={useTeacherResult}
          readOnly={viewOnly}
          scholastic={scholastic}
          currentStatusOfParentToDoAssessment={
            currentStatusOfParentToDoAssessment
          }
          finalAggregateAssessment={finalAggregateAssessment}
          userForAssessment={userForAssessment}
        />
      </div>
    </Fragment>
  );
};

export default compose(
  withUserInfo,
  withOutOfDeadlineAssessment,
)(ViewAssessMyPeersContent);
