import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getFormValues } from 'redux-form';
import { SCHOLASTIC_FORM_ID } from './index';
import { SCHOLASTIC_SELECT_FIELD_NAME } from './schema';

const withOutOfDeadlineAssessment = (WrappedComponent) => {
  class OutOfDeadlineAssessment extends Component {
    checkOutOfDeadlineAssessment = () => {
      const { scholastic = '', config = {} } = this.props;
      const scholasticConfigId = get(config, 'id');
      if (scholastic !== scholasticConfigId) {
        return true;
      }

      const currentDate = parseInt(moment().format('X'));
      const deadlineValid = get(config, 'deadline_valid_to_assessment', 0);
      const startDate = get(config, 'duration.start_date', 0);
      const endDate = get(config, 'duration.end_date', 0);

      const scholasticInvalid =
        currentDate > endDate || currentDate < startDate;
      const datelineInvalid = currentDate > deadlineValid;

      if (deadlineValid) {
        return datelineInvalid || scholasticInvalid;
      }

      return scholasticInvalid;
    };

    render() {
      const isOutOfDeadlineAssessment = this.checkOutOfDeadlineAssessment();

      return (
        <WrappedComponent
          {...this.props}
          isOutOfDeadlineAssessment={isOutOfDeadlineAssessment}
        />
      );
    }
  }

  const mapStateToProps = (state) => {
    const formValues = getFormValues(SCHOLASTIC_FORM_ID)(state);
    const scholasticConfig = get(state, 'scholasticConfig.config', {});
    return {
      scholastic: get(formValues, SCHOLASTIC_SELECT_FIELD_NAME, ''),
      config: scholasticConfig,
    };
  };

  return connect(mapStateToProps)(OutOfDeadlineAssessment);
};

export default withOutOfDeadlineAssessment;
