import React from 'react';

const LayoutFreeStyle = ({ groups, submitButton }) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-10">{groups.default.fieldNames.text}</div>
        <div className="col-md-2">
          <div className="m-t-10">{submitButton}</div>
        </div>
      </div>
      <div className="d-flex">
        <div>{groups.default.fieldNames.sub_type}</div>
        <div className="m-l-50">
          {groups.default.fieldNames.organization_inactive}
        </div>
      </div>
    </div>
  );
};

export default LayoutFreeStyle;
