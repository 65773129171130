// String.prototype.replaceAll = function (str, replace) {
//     reg = new RegExp(RegExp.quote(str), 'g');
//     return this.replace(reg, replace);
// };

// for weird characters
export const uppercaseVietnamese = (str) => {
  const trans = {
    á: 'Á',
    à: 'À',
    ả: 'Ả',
    ã: 'Ã',
    ạ: 'Ạ',
    ă: 'Ă',
    ắ: 'Ắ',
    ằ: 'Ằ',
    ẳ: 'Ẳ',
    ẵ: 'Ẵ',
    ặ: 'Ặ',
    â: 'Â',
    ấ: 'Ấ',
    ầ: 'Ầ',
    ẩ: 'Ẩ',
    ẫ: 'Ẫ',
    ậ: 'Ậ',

    đ: 'Đ',

    é: 'É',
    è: 'È',
    ẻ: 'Ẻ',
    ẽ: 'Ẽ',
    ẹ: 'Ẹ',
    ê: 'Ê',
    ế: 'Ế',
    ề: 'Ề',
    ể: 'Ể',
    ễ: 'Ễ',
    ệ: 'Ệ',

    í: 'I',
    ì: 'Ì',
    ỉ: 'Ỉ',
    ĩ: 'Ĩ',
    ị: 'Ị',

    ó: 'Ó',
    ò: 'Ò',
    ỏ: 'Ỏ',
    õ: 'Õ',
    ọ: 'Ọ',
    ô: 'Ô',
    ố: 'Ố',
    ồ: 'Ồ',
    ổ: 'Ổ',
    ỗ: 'Ỗ',
    ộ: 'Ộ',
    ơ: 'Ơ',
    ớ: 'Ớ',
    ờ: 'Ờ',
    ở: 'Ở',
    ỡ: 'Ỡ',
    ợ: 'Ợ',

    ú: 'Ú',
    ù: 'Ù',
    ủ: 'Ủ',
    ũ: 'Ũ',
    ụ: 'Ụ',
    ư: 'Ư',
    ứ: 'Ứ',
    ừ: 'Ừ',
    ử: 'Ử',
    ữ: 'Ữ',
    ự: 'Ự',

    ý: 'Y',
    ỳ: 'Ỳ',
    ỷ: 'Ỷ',
    ỹ: 'Ỹ',
    ỵ: 'Ỵ',
  };

  let i;
  for (i in trans) str = str.replace(new RegExp(i, 'g'), trans[i]);

  return str.toUpperCase();
};

export const unaccentVietnamese = (str) => {
  const trans = {
    á: 'a',
    à: 'a',
    ả: 'a',
    ã: 'a',
    ạ: 'a',
    ă: 'a',
    ắ: 'a',
    ằ: 'a',
    ẳ: 'a',
    ẵ: 'a',
    ặ: 'a',
    â: 'a',
    ấ: 'a',
    ầ: 'a',
    ẩ: 'a',
    ẫ: 'a',
    ậ: 'a',
    Á: 'A',
    À: 'A',
    Ả: 'A',
    Ã: 'A',
    Ạ: 'A',
    Ă: 'A',
    Ắ: 'A',
    Ằ: 'A',
    Ẳ: 'A',
    Ẵ: 'A',
    Ặ: 'A',
    Â: 'A',
    Ấ: 'A',
    Ầ: 'A',
    Ẩ: 'A',
    Ẫ: 'A',
    Ậ: 'A',
    đ: 'd',
    Đ: 'D',
    é: 'e',
    è: 'e',
    ẻ: 'e',
    ẽ: 'e',
    ẹ: 'e',
    ê: 'e',
    ế: 'e',
    ề: 'e',
    ể: 'e',
    ễ: 'e',
    ệ: 'e',
    É: 'E',
    È: 'E',
    Ẻ: 'E',
    Ẽ: 'E',
    Ẹ: 'E',
    Ê: 'E',
    Ế: 'E',
    Ề: 'E',
    Ể: 'E',
    Ễ: 'E',
    Ệ: 'E',
    í: 'i',
    ì: 'i',
    ỉ: 'i',
    ĩ: 'i',
    ị: 'i',
    Í: 'I',
    Ì: 'I',
    Ỉ: 'I',
    Ĩ: 'I',
    Ị: 'I',
    ó: 'o',
    ò: 'o',
    ỏ: 'o',
    õ: 'o',
    ọ: 'o',
    ô: 'o',
    ố: 'o',
    ồ: 'o',
    ổ: 'o',
    ỗ: 'o',
    ộ: 'o',
    ơ: 'o',
    ớ: 'o',
    ờ: 'o',
    ở: 'o',
    ỡ: 'o',
    ợ: 'o',
    Ó: 'O',
    Ò: 'O',
    Ỏ: 'O',
    Õ: 'O',
    Ọ: 'O',
    Ô: 'O',
    Ố: 'O',
    Ồ: 'O',
    Ổ: 'O',
    Ỗ: 'O',
    Ộ: 'O',
    Ơ: 'O',
    Ớ: 'O',
    Ờ: 'O',
    Ở: 'O',
    Ỡ: 'O',
    Ợ: 'O',
    ú: 'u',
    ù: 'u',
    ủ: 'u',
    ũ: 'u',
    ụ: 'u',
    ủ: 'u',
    ư: 'u',
    ứ: 'u',
    ừ: 'u',
    ử: 'u',
    ữ: 'u',
    ự: 'u',
    Ú: 'U',
    Ù: 'U',
    Ủ: 'U',
    Ũ: 'U',
    Ụ: 'U',
    Ư: 'U',
    Ứ: 'U',
    Ừ: 'U',
    Ử: 'U',
    Ữ: 'U',
    Ự: 'U',
    ý: 'y',
    ỳ: 'y',
    ỷ: 'y',
    ỹ: 'y',
    ỵ: 'y',
    Ý: 'Y',
    Ỳ: 'Y',
    Ỷ: 'Y',
    Ỹ: 'Y',
    Ỵ: 'Y',
  };

  let i;
  for (i in trans) str = str.replace(new RegExp(i, 'g'), trans[i]);
  return str;
};

export const nonAccentVietnamese = (str = '') => {
  if (!str) {
    return '';
  }

  str = str.toLowerCase();
  str = str.replace(
    /\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g,
    'a',
  );
  str = str.replace(
    /\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g,
    'e',
  );
  str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, 'i');
  str = str.replace(
    /\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g,
    'o',
  );
  str = str.replace(
    /\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g,
    'u',
  );
  str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, 'y');
  str = str.replace(/\u0111/g, 'd');

  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư

  return str;
};
