import React, { Component } from 'react';
import AntdTable from 'antd/lib/table';
import { t1 } from 'translate/index';
import PreviewUserInDialog from 'components/admin/user/account/common/PreviewInDialog';
import Avatar from 'antd/lib/avatar';
import { timestampToDateTimeString } from 'common/utils/Date';
import withTemisConfig from 'common/hoc/withTemisConfig';
import AssignPeersToAssess from '../assign-peers-to-assessment';
import { connect } from 'react-redux';
import endPoints from '../../endpoints';
import sagaActions from 'actions/node/saga-creators';

class ProfileResults extends Component {
  divStyle = {
    marginTop: '20px',
    textAlign: 'center',
  };
  raisedButtonStyle = {
    color: '#ffffff',
  };

  render() {
    const { items, assignPeersToAssess } = this.props;

    const columns = [
      {
        title: 'Thông tin giáo viên',
        width: '18%',
        key: 'name',
        render: (name, item) => {
          return (
            <div>
              {item.avatar && <Avatar src={item.avatar} />}
              <PreviewUserInDialog
                user={item}
                showFullDetailButton={false}
                hiddenItem={['positions']}
                field="name"
              />
              <br />
              <span className="text-faded">{item.mail}</span>
            </div>
          );
        },
      },
      {
        title: t1('birthday'),
        width: '12%',
        className: 'text-center',
        key: 'birthday',
        render: (birthday, item) => {
          return (
            <div>
              {item.birthday > 0 &&
                timestampToDateTimeString(item.birthday, {
                  showTime: false,
                  splitter: '/',
                })}
            </div>
          );
        },
      },
      {
        title: t1('assign_peers_to_assessment'),
        width: '25%',
        render: (row) => {
          return (
            <AssignPeersToAssess
              user={row}
              assignPeersToAssess={assignPeersToAssess}
            />
          );
        },
      },
    ].filter(Boolean);

    return (
      <AntdTable
        columns={columns}
        dataSource={items}
        rowKey="id"
        bordered
        pagination={false}
        childrenColumnName={null}
        className={'learn-default'}
        locale={{ emptyText: 'Không có dữ liệu' }}
      />
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch, props) => {
  const { searchFormId } = props;
  return {
    assignPeersToAssess: (
      action = 'assign',
      target,
      peers_assigned,
      rubric_iid,
    ) => {
      dispatch(
        sagaActions.submitFormRequest(null, {
          extraParams: {
            action,
            target,
            rubric_iid,
            peers_assigned,
          },
          url: endPoints.assignToAssess,
          formidToSubmitOnSuccess: searchFormId,
        }),
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTemisConfig(ProfileResults));
