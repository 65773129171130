import get from 'lodash.get';
import { required } from 'common/validators';
import { t1 } from 'translate';
import { organizations } from 'components/admin/organization/schema/elements';
import { input, number, rte } from 'components/common/forms/schema-fields';
import subTypes from 'configs/constants/org-sub-types';
import { listSchoolYear, targetUser } from 'components/common/elements';
import { slugifier } from 'common/normalizers';
import { academicAndSkillByCap } from 'components/admin/academic-category/schema/elements/academic-and-skill-by-cap';

const schema = (formid, values, step, xpath, props) => {
  const targetUsers = get(values, 'target_users', []);

  return {
    organizations: organizations({
      formid,
      multiple: false,
      label: `${t1('managing_organizations')} (*)`,
      defaultValue: props.orgIids,
      subTypes: [
        subTypes.TAPHUAN_SUBTYPE_BO_GD,
        subTypes.TAPHUAN_SUBTYPE_SO_GD,
        subTypes.TAPHUAN_SUBTYPE_PHONG_GD,
        subTypes.TAPHUAN_SUBTYPE_LTTU,
      ],
      validate: [required()],
    }),
    name: input('Tên module (*)', true),
    short_name: input('Tên ngắn'),
    code: input('Mã (*)', true, {
      normalize: slugifier,
    }),
    content: rte('Mô tả'),
    outcome: rte('Điều kiện đầu ra'),
    credit: number('Số tiết (*)', true, { min: 0, max: 60 }),
    credit_theory: number('Số tiết lý thuyết tương đương', false, {
      min: 0,
      max: 60,
    }),
    credit_practice: number('Số tiết thực hành tương đương', false, {
      min: 0,
      max: 60,
    }),
    academic_categories_by_cap: academicAndSkillByCap({
      targetUsers,
    }),
    target_users: targetUser({
      floatingLabelText: 'Đối tượng học (*)',
      validate: [required()],
    }),
    school_years: listSchoolYear({
      floatingLabelText: 'Năm học (*)',
      validate: [required()],
    }),
    stt: number('STT trong chương trình'),
  };
};

const ui = () => {
  let fields = [
    'name',
    'short_name',
    'code',
    'content',
    'outcome',
    'credit',
    'credit_theory',
    'credit_practice',
    'organizations',
    'school_years',
    'target_users',
    'academic_categories_by_cap',
    'stt',
  ];

  return [
    {
      id: 'default',
      fields,
    },
  ];
};

export default { schema, ui };
