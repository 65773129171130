import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t3 } from 'translate';
import UpdateAvatarForm from './Form';

class UserAvatar extends Component {
  render() {
    const { user, actionsToDoOnSuccess, requestSuccessful } = this.props;
    if (!user) return null;

    return (
      <div className="text-center update-avatar">
        <div>
          <h1 className="uppercase update-avatar-title">
            {t3('update_avatar')}
          </h1>
        </div>
        <UpdateAvatarForm
          key="avatar"
          user={user}
          actionsToDoOnSuccess={actionsToDoOnSuccess}
          requestSuccessful={requestSuccessful}
        />
      </div>
    );
  }
}

export default connect()(UserAvatar);
