import React from 'react';
import get from 'lodash.get';

class FormLayout extends React.PureComponent {
  render() {
    const { groups, readOnly } = this.props;
    const { attach_to_assessments, content } = groups;
    let { submitButton } = this.props;

    if (readOnly) {
      submitButton = null;
    }

    const isDuplicateAssessments = get(
      this.props,
      'layoutOptionsProperties.isDuplicateAssessments',
    );

    return (
      <div className="popup-evidence">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-5">
                {groups.default.fieldNames.non_unique_code}
              </div>

              <div className="col-md-7">{groups.default.fieldNames.name}</div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {groups.default.fieldNames.description}
              </div>
            </div>
          </div>
          <div className="col-md-6">{content.fieldNames.content}</div>
          <div className="col-md-12 m-t-15 m-b-15 popup-evidence__attach-file">
            <span className="popup-evidence__title">
              {attach_to_assessments.title}
            </span>

            <div
              className={`popup-evidence__content ${
                readOnly ? 'popup-evidence__content--readonly' : ''
              }`}
            >
              {attach_to_assessments.fieldNames.attach_to_assessments}
            </div>
          </div>
        </div>

        {isDuplicateAssessments && (
          <div className="text-danger text-center">
            Đã có bản ghi bị trùng, vui lòng kiểm tra lại loại đánh giá, tiêu
            chuẩn, tiêu chí
          </div>
        )}
        <div
          className={`popup-evidence__button ${
            isDuplicateAssessments ? 'disable-button' : ''
          }`}
        >
          {submitButton}
        </div>
      </div>
    );
  }
}

export default FormLayout;
