import React from 'react';
import {
  isAdminTruongOrHieuTruongOrPho,
  isSo,
  isPhong,
  isAmTinh,
} from 'components/admin/user/utils';
import withTemisConfig from 'common/hoc/withTemisConfig';
import TCNNTruong from './TCNNTruong';
import TCNNPhong from './TCNNPhong';
import TCNNSo from './TCNNSo';
import TabList from '../../components/tabs';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import { TCNN_TYPE } from 'components/temis/constant';

const TemisReport = ({ userRoot, scholastic }) => {
  const tabs = [
    isAdminTruongOrHieuTruongOrPho(userRoot) && {
      name: TCNN_TYPE.GV,
      label: 'TTNN trường',
      component: <TCNNTruong scholastic={scholastic} />,
    },
    isPhong(userRoot) && {
      name: 'tcnn_p',
      label: 'TTNN Phòng',
      component: <TCNNPhong scholastic={scholastic} />,
    },
    (isSo(userRoot) || isAmTinh(userRoot)) && {
      name: 'tcnn_so',
      label: 'TTNN Sở',
      component: <TCNNSo scholastic={scholastic} />,
    },
  ].filter(Boolean);

  return <TabList tabList={tabs} key={scholastic} />;
};

export default withScholastic(withTemisConfig(TemisReport));
