import SearchFormLayout from './SearchFormLayout';
import {
  organizations,
  phongbanInOrganization,
} from 'components/admin/organization/schema/elements';
import get from 'lodash.get';
import { leaderPositions } from 'configs/constants/user';
import { t1 } from 'translate';
import {
  isAdminSo,
  isAdminPhong,
  isAdminTruong,
  isAmTinh,
} from 'components/admin/user/utils';

const schema = (formid, values, step, xpath, props, domainInfo) => {
  const orgTypes = get(domainInfo, 'school.org_types');
  const { orgIids = [] } = props;

  return {
    text: {
      type: 'text',
      floatingLabelText: 'Tìm kiếm theo tên học viên',
    },
    sex: {
      type: 'multiCheckbox',
      inline: true,
      floatingLabelText: t1('gender'),
      options: [
        {
          value: 1,
          label: 'Nam',
        },
        {
          value: 0,
          label: 'Nữ',
        },
      ],
    },
    temis_account_status: {
      type: 'multiCheckbox',
      inline: true,
      floatingLabelText: 'Trạng thái',
      options: [
        {
          value: 'activated',
          label: 'Hoạt động',
        },
        {
          value: 'inactivate',
          label: 'Không hoạt động',
        },
      ],
    },
    phongban_id: phongbanInOrganization({
      orgTypes,
      ancestor_iids: values.user_organizations || orgIids,
      lastOption: {
        value: '_null_',
        label: 'Chưa có tổ chuyên môn',
      },
    }),

    user_organizations: organizations({
      formid,
      label: `${t1('content_organizations')}`,
      defaultValue: orgIids,
      fullWidth: true,
      rootIids: values.organizationRootIids,
      includeRoot: values.includeRootOrganizations,
      getOnlyOrganizationWhereUserHasPermission:
        values.getOnlyOrganizationWhereUserHasPermission,
      defaultOrganizations:
        Array.isArray(props.orgIids) && props.orgIids.length > 0
          ? props.orgIids
          : undefined,
      includeSubOrganizations: 0,
      multiple: false,
      includeSubOrganizationsLabel: t1('include_users_in_sub_organizations'),
    }),

    leader_position: {
      type: 'select',
      floatingLabelText: 'Chức vụ',
      fullWidth: true,
      options: [
        {
          value: '',
          label: t1('all'),
        },
        {
          value: leaderPositions.LEADER,
          label: 'Hiệu trưởng',
        },
        {
          value: leaderPositions.VICE_LEADER,
          label: 'Phó hiệu trưởng',
        },
        {
          value: leaderPositions.TO_TRUONG,
          label: 'Tổ trưởng',
        },
        {
          value: leaderPositions.TEACHER,
          label: 'Giáo viên',
        },
      ],
    },
  };
};

const ui = (step, values, themeConfig, xpath, formid, { userRoot } = {}) => {
  return [
    {
      id: 'id',
      fields: [
        'text',
        'sex',
        'temis_account_status',
        isAdminTruong(userRoot) && 'phongban_id',
        (isAmTinh(userRoot) || isAdminSo(userRoot) || isAdminPhong(userRoot)) &&
          'user_organizations',
        'leader_position',
      ].filter(Boolean),
    },
  ];
};

const layout = {
  component: SearchFormLayout,
  freestyle: 1,
};

export default { schema, ui, layout };
