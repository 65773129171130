import lodashGet from 'lodash.get';
import { leaderPositions } from 'configs/constants/user';
import subTypes from 'configs/constants/org-sub-types';
import lodashIntersection from 'lodash.intersection';
import { timestampToDateString } from 'common/utils/Date';
import { capitalize, t1 } from 'translate';
import {
  BGD_ORG_IID,
  dhspIids,
  HVQL_IID,
  SGD_HCM_ORG_IID,
} from '../organization/utils';
import Store from 'store';
import Perm from 'common/utils/Perm';
import { ETEP_CONFIG } from 'components/taphuan/configs';

export const userPreviewLink = (user, mode, action) => {
  let tmp;
  if (mode === 'account') tmp = 'user';
  else tmp = mode || 'student';

  const a = action ? action : 'dashboard';
  return `/admin/${tmp}/${user.iid}/${a}`;
};

export const mustUserChangePassBeforeUsingSystem = (userInfo) => {
  const enabledInConfig = lodashGet(
    Store.getState(),
    'domainInfo.conf.enable_must_change_pass_before_using_system_feature',
  );

  if (localStorage.loginAsManagerAdmin) {
    return false;
  }

  return (
    enabledInConfig &&
    [1, '1'].includes(
      lodashGet(userInfo, 'must_change_pass_before_using_system'),
    )
  );
};

export const mustEnableTwoFactorAuthentication = (userInfo) =>
  ![1, '1'].includes(lodashGet(userInfo, 'ga_enabled')) &&
  lodashGet(userInfo, 'must_enable_two_factor_authentication');

export const isHieuTruongOrPho = (user) =>
  [leaderPositions.LEADER, leaderPositions.VICE_LEADER].includes(
    lodashGet(user, 'leader_position'),
  ) &&
  !isPhong(user) &&
  !isSo(user) &&
  !isLTTUStaff(user);

export const isHieuTruong = (userInfo) => {
  let user = userInfo;
  if (!user) {
    user = Store.getState().user.info || {};
  }

  const positionIsThePrincipal = [leaderPositions.LEADER].includes(
    lodashGet(user, 'leader_position'),
  );
  const subPositionIsHieuTruong =
    lodashGet(user, 'sub_leader_position') === leaderPositions.LEADER;

  return (
    (positionIsThePrincipal || subPositionIsHieuTruong) &&
    !isPhong(user) &&
    !isSo(user) &&
    !isLTTUStaff(user)
  );
};

export const isHieuPho = (user) => {
  const positionIsTheVicePrincipal = [leaderPositions.VICE_LEADER].includes(
    lodashGet(user, 'leader_position'),
  );
  const subPositionIsHieuPho =
    lodashGet(user, 'sub_leader_position') === leaderPositions.VICE_LEADER;

  return (
    (positionIsTheVicePrincipal || subPositionIsHieuPho) &&
    !isPhong(user) &&
    !isSo(user)
  );
};

export const isLeader = (user) => {
  return [
    leaderPositions.LEADER,
    leaderPositions.VICE_LEADER,
    leaderPositions.GIAM_DOC_SO,
    leaderPositions.PHO_GIAM_DOC_SO,
    leaderPositions.TRUONG_PHONG_GIAO_DUC,
    leaderPositions.PHO_PHONG_GIAO_DUC,
  ].includes(lodashGet(user, 'leader_position'));
};

export const isSupport = (userInfo) => {
  let user = userInfo;
  if (!user) {
    user = Store.getState().user.info || {};
  }

  const csCode = lodashGet(window, 'LIST_CS_CODE', []);
  // populate default
  csCode.push('cs');

  const userCode = lodashGet(user, 'code');

  return csCode.includes(userCode);
};

export const isRoot = () => {
  return Perm.hasPerm('root');
};
export const isReviewApprover = (user) => {
  const leaderPosition = lodashGet(user, 'leader_position');

  return (
    [
      leaderPositions.GIAM_DOC_SO,
      leaderPositions.TRUONG_PHONG_GIAO_DUC,
      leaderPositions.LEADER,
      leaderPositions.VICE_LEADER,
    ].includes(leaderPosition) ||
    (leaderPosition === leaderPositions.TEACHER &&
      lodashGet(user, 'current_position') === leaderPositions.LEADER)
  );
};

export const isToTruong = (user) => {
  const leaderPosition = lodashGet(user, 'leader_position');
  const subLeaderPosition = lodashGet(user, 'sub_leader_position');
  const phongBanId = lodashGet(user, 'phongban_id');

  const teacherPositionLeader =
    leaderPosition === leaderPositions.TEACHER &&
    lodashGet(user, 'current_position') === leaderPositions.LEADER;

  const subPositionIsToTruong = subLeaderPosition === leaderPositions.TO_TRUONG;

  return (
    !!phongBanId &&
    (teacherPositionLeader || subPositionIsToTruong) &&
    !isPhong(user) &&
    !isSo(user) &&
    !isLTTUStaff(user)
  );
};

export const isPhong = (user) =>
  [
    leaderPositions.ADMIN_SSO_PHONG,
    leaderPositions.CHUYEN_VIEN_PHONG,
    leaderPositions.TRUONG_PHONG_GIAO_DUC,
    leaderPositions.PHO_PHONG_GIAO_DUC,
    leaderPositions.CAN_BO_PHONG,
  ].includes(lodashGet(user, 'leader_position')) &&
  !isSo(user) &&
  !isBo(user) &&
  !isLTTUStaff(user);

export const isSo = (user) =>
  [
    leaderPositions.ADMIN_SSO_SO,
    leaderPositions.CHUYEN_VIEN_SO,
    leaderPositions.TRUONG_PHONG_SO,
    leaderPositions.PHO_GIAM_DOC_SO,
    leaderPositions.GIAM_DOC_SO,
    leaderPositions.CAN_BO_SO,
  ].includes(lodashGet(user, 'leader_position')) &&
  !isBo(user) &&
  !isLTTUStaff(user);

export const isBo = (user = {}) => {
  const organizations = lodashGet(user, 'organizations[0]', {});
  const subType = lodashGet(organizations, 'sub_type');

  return subType === subTypes.TAPHUAN_SUBTYPE_BO_GD;
};

export const isHCMUser = (user = {}) => {
  const orgIids = (lodashGet(user, 'user_organizations') || []).concat(
    lodashGet(user, 'ancestor_organizations') || [],
  );

  return orgIids.map((i) => Number.parseInt(i, 10)).includes(SGD_HCM_ORG_IID);
};

export const isMamNon = (user = {}) => {
  const organizations = lodashGet(user, 'organizations', []);

  const orgMamNon = organizations.find((org) => {
    return lodashGet(org, 'sub_type') === subTypes.TAPHUAN_SUBTYPE_MAM_NON;
  });

  return !!orgMamNon;
};

export const isBoOrAdminSP = (user = {}) => {
  return isBo(user) || isLTTUStaff(user);
};

export const isTeacher = (user) =>
  [leaderPositions.TEACHER].includes(lodashGet(user, 'leader_position')) &&
  !isLTTUStaff(user);

export const isAdminTruong = (user) => {
  return [leaderPositions.ADMIN_SSO_TRUONG].includes(
    lodashGet(user, 'leader_position'),
  );
};

export const isAmTinh = (user) => {
  const code = lodashGet(user, 'code');
  const organization =
    lodashGet(user, 'organizations[0]') ||
    lodashGet(user, '__expand.user_organizations[0]');
  const orgCode = lodashGet(organization, 'code');

  if (!code || !orgCode) {
    return false;
  }

  const isSo =
    lodashGet(organization, 'sub_type') === subTypes.TAPHUAN_SUBTYPE_SO_GD;
  let isAm =
    code.toLowerCase() === `am-${orgCode.toLowerCase().replace('_sgd', '')}`;

  /**
   * special cases
   */
  if (
    [
      'huynv37@viettel.com.vn', // BTN
      'lehoa1103@gmail.com', // HCM
      'hoangna1@viettel.com.vn', // STG
    ].includes(code)
  ) {
    isAm = true;
  }

  return isSo && isAm;
};

export const isAdminSo = (user) => {
  return (
    [leaderPositions.ADMIN_SSO_SO].includes(
      lodashGet(user, 'leader_position'),
    ) && isSo(user)
  );
};

export const isAdminPhong = (user) => {
  return (
    [leaderPositions.ADMIN_SSO_PHONG].includes(
      lodashGet(user, 'leader_position'),
    ) && isPhong(user)
  );
};

export const isAdmin = (user) => {
  return (
    isAdminPhong(user) ||
    isAdminSo(user) ||
    isAmTinh(user) ||
    isAdminTruong(user)
  );
};

export const isSoOrPhong = (userInfo) => {
  let user = userInfo;
  if (!user) {
    user = Store.getState().user.info || {};
  }

  return isSo(user) || isPhong(user);
};

export const isCotCan = (
  userInfo,
  userType = null,
  trainingPlanCategory = null,
) => {
  let cotCanData = lodashGet(userInfo, 'cot_can_data') || [];

  if (trainingPlanCategory) {
    cotCanData = cotCanData.filter((d) => {
      return lodashGet(d, 'training_plan_category') == trainingPlanCategory;
    });
  }

  if (!userType) {
    return lodashGet(cotCanData, 'length') > 0;
  }

  return (cotCanData || []).some((d) => {
    return lodashGet(d, 'taphuan_user_type') === userType;
  });
};

export const canUseSecondaryCodeFeature = (userInfo) => {
  return lodashGet(userInfo, 'can_use_secondary_code_feature');
};

export const doesUserHaveMission = (userInfo) => {
  let user = userInfo;
  if (!user) {
    user = Store.getState().user.info || {};
  }

  return lodashGet(user, 'have_mission');
};

export const isGVSPCC = (userInfo) =>
  isLTTUStaff(userInfo) || isHVQLStaff(userInfo);

export const isLTTUStaff = (userInfo) => {
  const orgs = lodashGet(userInfo, 'user_organizations');
  const prefixedOrg = lodashGet(ETEP_CONFIG, 'GVDHSP_organization_iids');

  if (lodashIntersection(orgs, prefixedOrg).length > 0) return true;
};

export const isAdminLTTU = (userInfo) => {
  return (
    isLTTUStaff(userInfo) &&
    (window.adminAdminGiangVienSuPhamIid || []).includes(
      userInfo && userInfo.iid,
    )
  );
};

export const isDHSPStaff = (userInfo) => {
  const orgs = lodashGet(userInfo, 'user_organizations') || [];

  return lodashIntersection(orgs, dhspIids).length > 0;
};

export const isHVQLStaff = (userInfo) => {
  const orgs = lodashGet(userInfo, 'user_organizations') || [];
  return orgs.includes(HVQL_IID);
};

export const isVGDStaff = (userInfo) => {
  const userCode = lodashGet(userInfo, 'code', '');

  return isBo(userInfo) && userCode.startsWith('vgd');
};

export const isVGDTieuHoc = (userInfo) => {
  const userCode = lodashGet(userInfo, 'code', '');

  return isVGDStaff(userInfo) && ['vgd-tieuhoc'].includes(userCode);
};

export const isVGDTrungHoc = (userInfo) => {
  const userCode = lodashGet(userInfo, 'code', '');

  return isVGDStaff(userInfo) && ['vgd-trunghoc'].includes(userCode);
};

export const isSGDStaff = (userInfo) => {
  const leaderPosition = lodashGet(userInfo, 'leader_position');

  return !![
    leaderPositions.ADMIN_SSO_SO,
    leaderPositions.CHUYEN_VIEN_SO,
    leaderPositions.TRUONG_PHONG_SO,
    leaderPositions.PHO_GIAM_DOC_SO,
    leaderPositions.GIAM_DOC_SO,
  ].includes(leaderPosition);
};

export const isPGDStaff = (userInfo) => {
  const leaderPosition = lodashGet(userInfo, 'leader_position');

  return [
    leaderPositions.ADMIN_SSO_PHONG,
    leaderPositions.CHUYEN_VIEN_PHONG,
    leaderPositions.PHO_PHONG_GIAO_DUC,
  ].includes(leaderPosition);
};

export const isAdminTruongOrHieuTruongOrPho = (user) =>
  isHieuTruongOrPho(user) || isAdminTruong(user);

export const isRestrictionUser = (user) => {
  const restrictionCode = lodashGet(window, 'LIST_RESTRICTION_CODE', []);

  const userCode = lodashGet(user, 'code');

  return restrictionCode.includes(userCode);
};

export const isUserPassedCotCanDot1 = (user) => {
  if (!user) {
    return false;
  }

  const cotCanDot1Data = [];

  if (user.gv_cot_can_dot_1_data) {
    cotCanDot1Data.push(...user.gv_cot_can_dot_1_data);
  }

  if (user.quan_ly_cot_can_dot_1_data) {
    cotCanDot1Data.push(...user.quan_ly_cot_can_dot_1_data);
  }

  if (cotCanDot1Data.length) {
    return cotCanDot1Data.find((item) => item.enrolment_plan_passed == 1);
  }

  return false;
};

const isSubLeaderPosition = (user, role) => {
  return lodashGet(user, 'sub_leader_position') === role;
};

export const isSubLeaderPositionToTruong = (user) => {
  return isSubLeaderPosition(user, leaderPositions.TO_TRUONG);
};

export const isSubLeaderPositionHieuTruong = (user) => {
  return isSubLeaderPosition(user, leaderPositions.LEADER);
};

export const isSubLeaderPositionHieuPho = (user) => {
  return isSubLeaderPosition(user, leaderPositions.VICE_LEADER);
};

export const isChuyenVienSo = (userInfo) => {
  const leaderPosition = lodashGet(userInfo, 'leader_position');
  return leaderPosition === leaderPositions.CHUYEN_VIEN_SO;
};

export const isChuyenVienPhong = (userInfo) => {
  const leaderPosition = lodashGet(userInfo, 'leader_position');
  return leaderPosition === leaderPositions.CHUYEN_VIEN_PHONG;
};

export const canAssessCBQL = (user) =>
  [
    leaderPositions.ADMIN_SSO_SO,
    leaderPositions.TRUONG_PHONG_SO,
    leaderPositions.GIAM_DOC_SO,
    leaderPositions.ADMIN_SSO_PHONG,
    leaderPositions.TRUONG_PHONG_GIAO_DUC,
  ].includes(lodashGet(user, 'leader_position')) &&
  !isBo(user) &&
  !isLTTUStaff(user) &&
  !isAmTinh(user);

export const getUserBirthday = (birthday) =>
  timestampToDateString(birthday, {
    showTime: false,
    type: 'long_date',
    unixEpoch: true,
  });

export const getUserGender = (gender) =>
  gender && parseInt(gender) === 0 ? t1('female') : t1('male');

export const getUserEthnicity = (user, capitalized = false) => {
  if (!user) {
    return null;
  }

  const ethnicity = user.other_ethnicity || user.ethnicity;

  if (!ethnicity) {
    return null;
  }

  return capitalized ? capitalize(ethnicity) : ethnicity;
};

export const getUserDiaBanKhoKhan = (diaBanKhoKhan) =>
  diaBanKhoKhan ? t1('yes') : t1('no');

export const isOrganizationBelongToBGD = (organization) => {
  let orgs = organization;
  if (typeof organization !== 'object') {
    orgs = [organization];
  }

  return orgs.every((org) => org == BGD_ORG_IID);
};

export const isGiamSatBGD = (userInfo = {}) => {
  return ['giamsat-bgd@viettel.com.vn'].includes(userInfo.code);
};

export const isCucNG = (userInfo = {}) => {
  return ['bgd_cucng'].includes(userInfo.code);
};

export const isBoIVA = (userInfo) => {
  return userInfo && ['bgd-iva'].includes(userInfo.code);
};

export const isCskhViettel = (userInfo) => {
  return userInfo && ['cskh-viettel'].includes(userInfo.code);
};

export const isUserCanFilterCourseBySessionStatus = (userInfo) => {
  let user = userInfo;
  if (!user) {
    user = Store.getState().user.info || {};
  }

  const listUser = lodashGet(
    Store.getState(),
    'domainInfo.conf.list_user_codes_can_filter_course_by_session_status',
    [],
  );

  const userCode = lodashGet(user, 'code');

  return listUser.includes(userCode);
};

export const simpleField = ['name', 'phone'];

export const basicField = ['name', 'mail', 'phone'];

export const basicFieldsForAdmin = [
  'name',
  'mail',
  'phone',
  'ethnicity',
  'roles',
  'status',
  'user_organizations',
];
