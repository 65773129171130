import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import endpoints from 'components/bdtx/training-phase/endpoints';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import schema from './schema';
import TableResult from './TableResult';
import withUserInfo from 'common/hoc/withUserInfo';

const SEARCH_FORM_ID = 'search-form-deployment-review';

const SearchDeploymentReview = ({
  trainingPhaseIid,
  userInfo,
  hiddenFields = {},
  noSearchForm,
  showAction,
  searchFormId,
  isCCModel,
}) => {
  const { ep_organization_iid } = hiddenFields;

  const formId = `${SEARCH_FORM_ID}-${trainingPhaseIid}-${ep_organization_iid}`;

  const renderResultComponent = (items) => {
    return (
      <div className="'white-background'">
        <TableResult
          items={items}
          searchFormId={formId}
          formIdsToSubmitOnSuccess={[searchFormId || SEARCH_FORM_ID]}
          showAction={showAction}
          trainingPhaseIid={trainingPhaseIid}
          isCCModel={isCCModel}
        />
      </div>
    );
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Không có thông tin triển khai" />;
  };

  return (
    <SearchWrapper
      schema={noSearchForm ? {} : schema}
      formid={formId}
      alternativeApi={endpoints.bdtx_preview_tps}
      renderResultsComponent={renderResultComponent}
      renderNoResultComponent={renderNoResultComponent}
      hiddenFields={{
        training_phase_iid: trainingPhaseIid,
        ...hiddenFields,
      }}
      userInfo={userInfo}
      showSearchButton={false}
      autoSearchWhenValuesChange
    />
  );
};

export default withUserInfo(SearchDeploymentReview);
