import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { t1 } from 'translate';
import { connect } from 'react-redux';
import actions from 'actions/node/creators';
import { Prompt } from 'react-router';
import Warning from 'components/common/Warning';
import { withRouter } from 'react-router';

const ContentDialog = ({ message, onOk, onCancel, cancelText, okText }) => {
  return (
    <div className="text-center">
      <Warning>
        <span className="font-size-large">{message}</span>
      </Warning>

      <div className="text-center m-t-25">
        {!!onCancel && (
          <button
            className="m-l-10 btn btn-primary"
            onClick={() => {
              if (typeof onCancel === 'function') {
                onCancel();
              }
            }}
          >
            {cancelText}
          </button>
        )}
        {!!onOk && (
          <button
            className="m-l-10 btn btn-tertiary"
            onClick={() => {
              if (typeof onOk === 'function') {
                onOk();
              }
            }}
          >
            {okText}
          </button>
        )}
      </div>
    </div>
  );
};

const ConfirmToLeave = ({
  dispatch,
  shouldShowConfirm,
  confirmMessage,
  history,
  cancelText,
  okText,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(shouldShowConfirm);

  useEffect(
    () => {
      setShowConfirmation(shouldShowConfirm);
    },
    [shouldShowConfirm],
  );

  const closeConfirmToLeaveDialog = () => dispatch(actions.closeAllDialogs());

  const goToPath = (gotoPathName) => {
    setShowConfirmation(false);

    setTimeout(() => history.push(gotoPathName));

    closeConfirmToLeaveDialog();
  };

  const showConfirmationDialog = (contentDialog, options) => {
    dispatch(
      actions.handleOpenDialog(
        { contentDialog, optionsProperties: options },
        'confirm-to-leave',
      ),
    );
  };

  const showLeavingRouteBeforeSaveDialog = (nextLocation = {}) => {
    const contentDialog = (
      <ContentDialog
        message={confirmMessage}
        onOk={() => goToPath(nextLocation.pathname)}
        onCancel={closeConfirmToLeaveDialog}
        cancelText={cancelText}
        okText={okText}
      />
    );

    showConfirmationDialog(contentDialog, {
      width: '600px',
      handleClose: true,
      modal: true,
      title: t1('notice'),
    });

    return false;
  };

  return (
    <Prompt
      when={showConfirmation}
      message={showLeavingRouteBeforeSaveDialog}
    />
  );
};

export default compose(
  connect(),
  withRouter,
)(ConfirmToLeave);
