import React, { Component } from 'react';
import './style.scss';

class SendAssessmentResult extends Component {
  render() {
    const { status, date } = this.props;
    return (
      <div className="send-assessment-result text-center">
        <div className="send-assessment-result__status">
          <div
            className={`send-assessment-result__status-detail ${
              status ? 'done' : 'fail'
            }`}
          >
            <span
              className={`send-assessment-result__status-icon ${
                status ? 've-check' : 've-close-outline'
              }`}
            />
            <span className="send-assessment-result__status-text">
              {status ? 'Đã gửi bản tự đánh giá' : 'Chưa có bản tự đánh giá'}
            </span>
          </div>
          {status && date && (
            <div className="send-assessment-result__date">Gửi lúc: {date}</div>
          )}
        </div>
      </div>
    );
  }
}

export default SendAssessmentResult;
