import React from 'react';
import get from 'lodash.get';
import { max } from 'common/utils/Array';
import RadarChart from 'components/common/charts/radar/RadarChart';
import { t1 } from 'translate';
import Store from 'store/index';
import {
  generateClassByStatusAssessment,
  isFromQualifiedAssessment,
} from '../utils';
import { TCNN_TYPE } from '../constant';

// list index of rubrics
const indexOfImportantTieuChi = {
  tcnn_gv: [2, 3, 4, 5, 6], // đánh giá giáo viên
  tcnn_ht: [0, 1, 3, 4, 5, 7, 9, 11, 12, 13], //đánh giá hiệu trưởng và phó hiệu trường
};

// list index of rubrics for mầm non
const indexOfImportantTieuChiMN = {
  tcnn_gv: [0, 2, 3, 4, 5, 6, 7, 8], // đánh giá giáo viên
  tcnn_ht: [0, 3, 4, 5, 6, 8, 10, 12, 13, 14], //đánh giá hiệu trưởng và phó hiệu trường
};

export const getTypeOfTCNN = (rubricIid) => {
  const state = Store.getState();
  if (
    String(rubricIid) === String(get(state, 'domainInfo.conf.temis.tcnn_ht')) ||
    String(rubricIid) === String(get(state, 'domainInfo.conf.temis.tcnn_ht_mn'))
  ) {
    return TCNN_TYPE.HT;
  }

  return TCNN_TYPE.GV;
};

export const checkAssessmentForMamNonByRubriciid = (rubricIid) => {
  const state = Store.getState();
  if (
    String(rubricIid) === String(get(state, 'domainInfo.conf.temis.tcnn_ht')) ||
    String(rubricIid) === String(get(state, 'domainInfo.conf.temis.tcnn_gv'))
  ) {
    return false;
  }

  return true;
};

export const getOverallResultsOfTheAssessment = (
  task,
  scoreBasedOnSelectedTask = false,
  rubricIid,
) => {
  if (!Array.isArray(task) || !task.length) {
    return {
      final: 0,
      summary: [],
      number_of_question: 0,
    };
  }

  const forMN = checkAssessmentForMamNonByRubriciid(rubricIid);
  const tcnnType = getTypeOfTCNN(rubricIid);

  let maxScoreReached = 0;
  let finalScore = null;
  let hasTieuChiIsNotAssessment = false;
  const summaryById = {};

  const isAnswerEmpty = (answer) =>
    answer === null || typeof answer === 'undefined';
  const taskToCalculate = scoreBasedOnSelectedTask
    ? task.filter(({ answer }) => !isAnswerEmpty(answer))
    : task;
  const numberOfQuestion = taskToCalculate.length;

  taskToCalculate.forEach(({ answer, rubric_iid }) => {
    if (isAnswerEmpty(answer)) {
      hasTieuChiIsNotAssessment = true;
    }

    const id = Number(answer) || 0;
    const answerScore = id; // Chưa đạt = 0, Đạt = 1, Khá = 2, Tốt = 3;

    if (!summaryById[id]) {
      summaryById[id] = [rubric_iid];
    } else {
      summaryById[id].push(rubric_iid);
    }

    if (maxScoreReached < answerScore) {
      maxScoreReached = answerScore;
    }

    if ((tcnnType === TCNN_TYPE.GV || forMN) && !answerScore) {
      finalScore = answerScore;
    } else if (finalScore === null || finalScore - 1 > answerScore) {
      finalScore = answerScore + 1;
    }

    const index = task.findIndex((tc) => tc.rubric_iid == rubric_iid);
    const importantQuestion = forMN
      ? indexOfImportantTieuChiMN
      : indexOfImportantTieuChi;
    if (
      finalScore > answerScore &&
      importantQuestion[tcnnType].includes(index)
    ) {
      finalScore = answerScore;
    }
  });

  const summary = [];
  Object.keys(summaryById).forEach((id) => {
    summary.push({
      id,
      rubric_iids: summaryById[id],
    });
  });

  if (finalScore) {
    const count = Array.isArray(summaryById[finalScore - 1])
      ? summaryById[finalScore - 1].length
      : 0;

    if (count / numberOfQuestion > 1 / 3) {
      finalScore = finalScore - 1;
    }

    if (finalScore > maxScoreReached) {
      finalScore = maxScoreReached;
    }
  }

  return {
    final: finalScore && !hasTieuChiIsNotAssessment ? finalScore : 0,
    summary,
    number_of_question: numberOfQuestion,
  };
};

export const formatDataSourceFromDataServerToRenderTableAssess = (
  rubrics,
  assessmentRubricIid,
  {
    depth = 0,
    ancestor_iids = assessmentRubricIid ? [assessmentRubricIid] : [],
  } = {},
) => {
  if (!Array.isArray(rubrics) || !rubrics.length) {
    return [];
  }

  return rubrics.reduce((result, { iid, children, ...rubric }) => {
    const isHeader = Array.isArray(children) && children.length > 0;
    rubric = {
      ...rubric,
      iid: Number.parseInt(iid, 10),
      depth,
      isHeader,
      ancestor_iids: ancestor_iids.map((a) => Number.parseInt(a, 10)),
    };

    return result.concat(
      [rubric],
      formatDataSourceFromDataServerToRenderTableAssess(
        children,
        assessmentRubricIid,
        {
          depth: depth + 1,
          ancestor_iids: [get(rubric, 'iid')].concat(ancestor_iids),
        },
      ),
    );
  }, []);
};

export const radarChartResultsAssess = (
  rubrics,
  value,
  score_scale,
  tcnnType = TCNN_TYPE.GV,
  scoreBasedOnSelectedTask = false,
  rubricIid,
) => {
  const parts = get(score_scale, 'parts');
  const upperBound = get(max(parts, (p) => get(p, 'id') || 0), 'max.id');
  const valueDomain = [0, upperBound];

  const data = (Array.isArray(rubrics) ? rubrics : [])
    .filter((rubric) => rubric && !get(rubric, 'isHeader'))
    .map((rubric) => {
      const answer = get(
        (value || []).find((a) => get(a, 'rubric_iid') == get(rubric, 'iid')),
        'answer',
        0,
      );
      const valueAsText = get(
        (parts || []).find((p) => get(p, 'id') == answer),
        'name',
        'Chưa đạt',
      );

      return {
        ...rubric,
        name: get(rubric, 'name'),
        short_name: get(rubric, 'short_name') || get(rubric, 'name'),
        value: answer,
        valueAsText: valueAsText,
      };
    });

  const result = getOverallResultsOfTheAssessment(
    value,
    scoreBasedOnSelectedTask,
    rubricIid,
  );
  if (
    typeof result.final === 'undefined' ||
    !Array.isArray(parts) ||
    !parts.length
  ) {
    return null;
  }

  const part = parts.find(({ id }) => id === result.final);
  return (
    <React.Fragment>
      <RadarChart valueDomain={valueDomain} data={data} />
      <span
        className="d-flex justify-content-center align-items-center"
        style={{
          fontWeight: 500,
        }}
      >
        {t1('rate_the_overall_assessment_results')}:{' '}
        <span
          style={{
            fontWeight: 600,
          }}
          className={`p-l-5 status-text ${generateClassByStatusAssessment(
            get(part, 'name'),
          )}`}
        >
          {get(part, 'name') || ''}
        </span>
      </span>
    </React.Fragment>
  );
};

export const RESULT_LEVELS = [
  {
    id: 0,
    name: 'Chưa đạt',
  },
  {
    id: 1,
    name: 'Đạt',
  },
  {
    id: 2,
    name: 'Khá',
  },
  {
    id: 3,
    name: 'Tốt',
  },
];

export const noDataToAssessment = (dataSource, scoreScale) =>
  !Array.isArray(dataSource) ||
  !dataSource.length ||
  !scoreScale ||
  !Array.isArray(scoreScale.parts) ||
  !scoreScale.parts.length;

export const isRequiredMC =
  window.TEMIS_CONFIG && !!get(window, 'TEMIS_CONFIG.REQUIRED_MC', 0);

export const isValidTaskAnswer = (
  tasks = [],
  isPeerAssessment,
  doNotFillAllTasks,
  hasAssessment,
) => {
  if (!tasks || !tasks.length) {
    return false;
  }

  let answerIsEnough = tasks.every(({ answer }) => parseInt(answer) >= 0);
  if (doNotFillAllTasks) {
    const answers = tasks.filter(({ answer }) => parseInt(answer) >= 0);
    answerIsEnough = !!answers.length;
  }

  const invalidEvidence = tasks.find((task) => {
    const qualified = isFromQualifiedAssessment(task);
    const haveNotEvidence = !task.evidence_iids || !task.evidence_iids.length;

    return qualified && haveNotEvidence;
  });

  if (!isPeerAssessment && isRequiredMC && !hasAssessment) {
    return answerIsEnough && !invalidEvidence;
  }

  return answerIsEnough;
};

export const getFinalResultTextOfAssessment = (assessment) => {
  if (!get(assessment, 'result.final')) {
    return '';
  }

  const final = get(assessment, 'result.final');
  const matchedScoreScale = get(assessment, 'score_scale', []).find(
    (scoreScale) => scoreScale.id == final,
  );

  if (matchedScoreScale) {
    return matchedScoreScale.name;
  }

  return '';
};
