import React from 'react';
import get from 'lodash.get';
import withUserInfo from 'common/hoc/withUserInfo';
import { isHieuTruong } from 'components/admin/user/utils';

const LayoutFreeStyle = (props) => {
  const fieldNames = get(props, 'groups.id.fieldNames') || {};
  const userInfo = get(props, 'userInfo');

  return (
    <div>
      <div className="row">
        {fieldNames.phongban_id && (
          <div
            className={`col-md-4 ${
              !isHieuTruong(userInfo) ? 'disabled-select-organizations' : ''
            }`}
          >
            {fieldNames.phongban_id}
          </div>
        )}
        {fieldNames.leader_position && (
          <div className="col-md-4">{fieldNames.leader_position}</div>
        )}
        <div className="col-md-4">{fieldNames.text}</div>
      </div>

      <div>{fieldNames.assessment_status}</div>

      <div className="text-center">{get(props, 'submitButton')}</div>
    </div>
  );
};

export default withUserInfo(LayoutFreeStyle);
