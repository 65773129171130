import React from 'react';
import schema from './schema';
import get from 'lodash.get';
import t1 from 'translate';
import apiEndpoints from '../apiEndpoints';
import NodeNew from '../../admin/node/new';

const UpdateContactInfoForm = ({ userInfo, requestSuccessful }) => {
  const node = {
    secondary_phone: get(userInfo, 'second_phone') || get(userInfo, 'phone'),
    secondary_mail: get(userInfo, 'second_mail') || get(userInfo, 'mail'),
  };

  return (
    <NodeNew
      schema={schema}
      node={node}
      mode={'edit'}
      step={'contact_info'}
      hiddenFields={{}}
      alternativeApi={apiEndpoints.update}
      requestSuccessful={requestSuccessful}
      submitLabels={{
        edit: t1('update'),
      }}
    />
  );
};

export default UpdateContactInfoForm;
