import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Form from './Form';

const Edit = ({ searchFormId, scholastic, viewOnly, item, organizations }) => {
  const dialogKey = `edit-category-${item.iid}`;

  const renderPreview = ({ showFull }) => {
    return (
      <span className="ve-edit m-r-10 cursor-pointer" onClick={showFull}>
        {' '}
      </span>
    );
  };

  const renderFull = () => {
    return (
      <Form
        dialogKey={dialogKey}
        searchFormId={searchFormId}
        formid={dialogKey}
        hiddenFields={{
          scholastic,
          sub_type: 21, //Tổ chuyên môn,
          pid: item.pid,
          p_code: item.p_code,
        }}
        node={item}
        organizations={organizations}
      />
    );
  };

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogKey={dialogKey}
      dialogOptionsProperties={{
        title: 'Sửa tổ chuyên môn',
        width: '60%',
        handleClose: true,
        modal: true,
      }}
    />
  );
};

export default Edit;
