import React from 'react';
import get from 'lodash.get';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Icon from 'components/common/Icon';
import DeploymentPreview from 'components/bdtx/training-phase/mainstage/deployment-preview';
import { t1 } from 'translate';
import endpoints from 'components/bdtx/training-phase/endpoints';
import DeleteItem from 'components/common/action-button/DeleteBtnWithConfirmDialog';
import { Alert } from 'antd';

const ConfirmContent = ({ trainingPhase, searchFormId, closeDialog }) => {
  const iid = get(trainingPhase, 'iid');
  const id = get(trainingPhase, 'id');
  const name = get(trainingPhase, 'name');

  return (
    <>
      <Alert
        message="Chú ý"
        description={
          <span>
            Bạn đang thực hiển xóa bỏ đợt triển khai: <b>{name}</b>. Hãy xem lại
            thật kỹ thông tin đã triển khai của đợt: {name} trước khi "Xóa bỏ"
          </span>
        }
        showIcon
        type="warning"
        className="m-b-15"
      />

      <DeploymentPreview
        trainingPhaseIid={iid}
        noSearchForm
        showAction={false}
      />

      <div className="m-t-15 text-center">
        <button className="btn btn-secondary m-r-15" onClick={closeDialog}>
          Không hủy
        </button>

        <DeleteItem
          title={t1(`Xóa đợt triển khai ${name}`)}
          textConfirm={`Bạn có chắc chắn muốn xóa bỏ đợt triển khai ${name}?`}
          formid={searchFormId}
          ntype={'training-phase'}
          itemId={id}
          iconButton={false}
          alternativeApi={endpoints.bdtx_delete_training_phase}
          className="btn-tertiary"
          label="Tôi hiểu, xóa bỏ đợt triên khai"
          onRequestSuccessful={closeDialog}
        />
      </div>
    </>
  );
};

const ConfirmDelete = ({ trainingPhase, searchFormId }) => {
  const name = get(trainingPhase, 'name');

  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => {
        return (
          <Icon icon={'delete'} className="text-danger" onClick={showFull} />
        );
      }}
      renderFull={({ closeDialog }) => {
        return (
          <ConfirmContent
            trainingPhase={trainingPhase}
            searchFormId={searchFormId}
            closeDialog={closeDialog}
          />
        );
      }}
      dialogOptionsProperties={{
        title: (
          <span>
            Thông tin triển khai của đợt: <b>{name}</b>
          </span>
        ),
        handleClose: true,
        modal: true,
        width: '90%',
      }}
    />
  );
};

export default ConfirmDelete;
