import React from 'react';
import contentSchema from './content';
import { t1 } from 'translate';
import uniqWith from 'lodash.uniqwith';
import isEqual from 'lodash.isequal';
import { required } from 'common/validators';
import { slugifier } from 'common/normalizers';
import FormLayout from './formLayout';
import attachToAssessmentsSchema from 'components/temis/evidence/schema/attachToAssessment';

export const checkDuplicateAssessments = (assessments = []) => {
  if (!assessments || assessments.length < 2) {
    return false;
  }

  const mainFieldOfAssessments = assessments.map((item) => {
    const { rubric_iid = '', tieu_chuan_iid = '', tieu_chi_iid } = item;
    return {
      rubric_iid,
      tieu_chuan_iid,
      tieu_chi_iid,
    };
  });

  const assessmentToCheckDuplicate = mainFieldOfAssessments.filter(
    (assessment) => assessment.tieu_chuan_iid && assessment.tieu_chi_iid,
  );
  const assessmentsUniqWith = uniqWith(assessmentToCheckDuplicate, isEqual);

  return !(
    assessmentsUniqWith &&
    Array.isArray(assessmentsUniqWith) &&
    assessmentsUniqWith.length === assessmentToCheckDuplicate.length
  );
};

const schema = (
  formid,
  values,
  step,
  { readOnly },
  { getOnlyAssessmentThatUserCanDo },
) => {
  const { attach_to_assessments = [] } = values;
  const isDuplicateAssessments = checkDuplicateAssessments(
    attach_to_assessments,
  );

  return {
    non_unique_code: {
      type: 'text',
      floatingLabelText: t1('assessment_evidence_code'),
      validate: [required(t1('code_cannot_be_empty'))],
      fullWidth: true,
      normalize: slugifier,
      readOnly: true,
    },
    name: {
      type: 'text',
      floatingLabelText: `${t1('assessment_evidence_name')} *`,
      validate: [required(t1('name_cannot_be_empty'))],
      defaultValue: '',
      fullWidth: true,
      disabled: readOnly,
    },
    description: {
      type: 'text',
      multiLine: true,
      floatingLabelText: t1('assessment_evidence_description'),
      defaultValue: '',
      errorText: '',
      fullWidth: true,
      disabled: readOnly,
    },
    content: {
      type: 'section',
      schema: contentSchema,
      disabled: readOnly,
    },
    attach_to_assessments: {
      type: 'array',
      schema: attachToAssessmentsSchema({
        getOnlyAssessmentThatUserCanDo,
      }),
      defaultValue:
        step === 'new_do_assessment'
          ? [
              {
                rubric_iid: values.assessmentRubricIid,
                tieu_chuan_iid: values.tieuChuanIid,
                tieu_chi_iid: values.tieuChiIid,
              },
            ]
          : [{}],
      readOnly: step === 'new_do_assessment' || readOnly,
      disabled: readOnly,
      hiddenRemoveButton: attach_to_assessments.length === 1,
      hiddenAddButton: isDuplicateAssessments,
      isDuplicateAssessments,
    },
  };
};

const ui = (step) => {
  switch (step) {
    case 'new':
    case 'edit': {
      return [
        {
          id: 'attach_to_assessments',
          title: t1('apply_to'),
          fields: ['attach_to_assessments'],
        },
        {
          id: 'default',
          title: t1('basic_information'),
          fields: ['non_unique_code', 'name', 'description'],
        },
        {
          id: 'content',
          title: t1('content'),
          fields: ['content'],
        },
      ];
    }
    case 'new_with_template':
    case 'edit_with_template': {
      return [{ id: 'default', fields: ['content'] }];
    }
    case 'new_do_assessment': {
      return [
        {
          id: 'attach_to_assessments',
          title: t1('apply_to'),
          fields: ['attach_to_assessments'],
        },
        {
          id: 'default',
          title: t1('basic_information'),
          fields: ['non_unique_code', 'name', 'description'],
        },
        {
          id: 'content',
          title: t1('content'),
          fields: ['content'],
        },
      ];
    }
  }
};

const layout = (step, values, xpath, props, domainInfo) => {
  const { attach_to_assessments = [] } = values;
  const isDuplicateAssessments = checkDuplicateAssessments(
    attach_to_assessments,
  );

  return {
    component: FormLayout,
    freestyle: 1,
    optionsProperties: {
      isDuplicateAssessments,
    },
  };
};

export default {
  schema,
  ui,
  layout,
};
