import get from 'lodash.get';
import Dashboard from 'components/bdtx/training-phase/mainstage/dashboard';
import EditTrainingPhase from 'components/bdtx/training-phase/mainstage/edit';
import AssignModule from 'components/bdtx/training-phase/mainstage/assign-module';
import DeploymentPreview from 'components/bdtx/training-phase/mainstage/deployment-preview';
import DeploymentOrganization from 'components/bdtx/training-phase/mainstage/deployment-organization';
import TrainingModelCotCan from 'components/bdtx/training-phase/mainstage/training-model-cot-can';

import { TRAINING_MODEL } from 'components/taphuan/configs';

export const MAIN_STAGE_VIEW_TYPE = {
  DASHBOARD: 'dashboard',
  ASSIGN: 'assign',
  REPORT: 'report',
  EDIT: 'edit',
  DEPLOYMENT_PREVIEW: 'deployment-review',
  TRAINING_MODEL_COT_CAN: 'training-model-cot-can',
  DEPLOYMENT_ORG: 'deployment-organization',
};

export const trainingPhaseRootUrl = '/admin/bdtx/training-phase';

export const generateTrainingPhaseUrl = (action) => {
  if (action) {
    return `${trainingPhaseRootUrl}/:iid/${action}`;
  }

  return trainingPhaseRootUrl;
};

export const getUrlByTrainingPhaseIid = (trainingPhaseIid, action) => {
  return `${trainingPhaseRootUrl}/${trainingPhaseIid}/${action ||
    MAIN_STAGE_VIEW_TYPE.DASHBOARD}`;
};

export const trainingPhaseUrl = {
  rootUrl: trainingPhaseRootUrl,
  dashboard: generateTrainingPhaseUrl(MAIN_STAGE_VIEW_TYPE.DASHBOARD),
  edit: generateTrainingPhaseUrl(MAIN_STAGE_VIEW_TYPE.EDIT),
  assign_modules: generateTrainingPhaseUrl(MAIN_STAGE_VIEW_TYPE.ASSIGN),
  report: generateTrainingPhaseUrl(MAIN_STAGE_VIEW_TYPE.REPORT),
  deployment_preview: generateTrainingPhaseUrl(
    MAIN_STAGE_VIEW_TYPE.DEPLOYMENT_PREVIEW,
  ),
};

export const menuItems = (node, iid) => {
  const trainingPhaseIid = iid || get(node, 'iid');
  const trainingModel = get(node, 'training_model');
  const status = get(node, 'status');
  const deployed = get(status, 'deployed');

  const cotcanMenu = [
    {
      id: 'training-phase-organization',
      url: getUrlByTrainingPhaseIid(
        trainingPhaseIid,
        MAIN_STAGE_VIEW_TYPE.DEPLOYMENT_ORG,
      ),
      title: 'Cây đơn vị',
    },
    ...(deployed
      ? [
          {
            id: 'training-phase-cot-can',
            url: getUrlByTrainingPhaseIid(
              trainingPhaseIid,
              MAIN_STAGE_VIEW_TYPE.TRAINING_MODEL_COT_CAN,
            ),
            title: 'Tổ chức Cốt Cán',
          },
        ]
      : []),
  ];

  return [
    {
      id: 'training-phase-dashboard',
      url: getUrlByTrainingPhaseIid(
        trainingPhaseIid,
        MAIN_STAGE_VIEW_TYPE.DASHBOARD,
      ),
      title: 'Tổng quan',
    },
    {
      id: 'training-phase-edit',
      url: getUrlByTrainingPhaseIid(
        trainingPhaseIid,
        MAIN_STAGE_VIEW_TYPE.EDIT,
      ),
      title: 'Thông tin',
    },
    {
      id: 'training-phase-assign-module',
      url: getUrlByTrainingPhaseIid(
        trainingPhaseIid,
        MAIN_STAGE_VIEW_TYPE.ASSIGN,
      ),
      title: 'Triển khai',
    },
    {
      id: 'training-phase-report',
      url: getUrlByTrainingPhaseIid(
        trainingPhaseIid,
        MAIN_STAGE_VIEW_TYPE.REPORT,
      ),
      title: 'Báo cáo',
    },
    ...(trainingModel === TRAINING_MODEL.COT_CAN ? cotcanMenu : []),
  ];
};

export const getContent = (action) => {
  switch (action) {
    case MAIN_STAGE_VIEW_TYPE.DASHBOARD:
      return Dashboard;

    case MAIN_STAGE_VIEW_TYPE.EDIT:
      return EditTrainingPhase;

    case MAIN_STAGE_VIEW_TYPE.ASSIGN:
      return AssignModule;

    case MAIN_STAGE_VIEW_TYPE.REPORT:
      return DeploymentPreview;

    case MAIN_STAGE_VIEW_TYPE.DEPLOYMENT_ORG:
      return DeploymentOrganization;

    case MAIN_STAGE_VIEW_TYPE.TRAINING_MODEL_COT_CAN:
      return TrainingModelCotCan;

    default:
      return Dashboard;
  }
};

export default getContent;
