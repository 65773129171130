import LayoutFreeStyle from './LayoutFreeStyle';
import { t1 } from 'translate';
import orgApiUrls from 'components/admin/organization/endpoints';
import { isNotEmptyArray } from 'common/utils/Array';

const schema = (formId, values) => ({
  text: {
    type: 'text',
    floatingLabelText: 'Mã, Tên đơn vị',
    fullWidth: true,
  },
  sub_type: {
    type: 'multiCheckbox',
    inline: true,
    floatingLabelText: t1('sub_types'),
    options: 'async',
    paramsasync: {
      __url__:
        orgApiUrls.get_organization_children_sub_types_for_select_options,
      key: `organization_sub_types_${values.pIids}_${values.rootIids}_${
        values.include_sub_organizations
      }`,
      value: {
        parent_organization_iids: isNotEmptyArray(values.pIids)
          ? values.pIids
          : values.rootIids,
        get_deeper_level: values.include_sub_organizations,
      },
    },
  },
  organization_inactive: {
    type: 'multiCheckbox',
    floatingLabelText: 'Trạng thái',
    inline: true,
    options: [
      {
        label: 'Hoạt động',
        value: 'activate',
      },
      {
        label: 'Ngưng hoạt động',
        value: 'inactivate',
      },
    ],
  },
});

const ui = () => [
  {
    id: 'default',
    fields: ['text', 'sub_type', 'organization_inactive'],
  },
];

const layout = () => ({
  component: LayoutFreeStyle,
  freestyle: 1,
});

export default {
  schema,
  ui,
  layout,
};
