import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import NodeNew from 'components/admin/node/new';
import schema from './schema';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { t1 } from 'translate';
import Icon from 'antd/lib/icon';

const ChangePassword = ({ user = {} }) => {
  const dialogKey = `change_password_${user.iid}`;
  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <div
          className="cursor-pointer"
          onClick={showFull}
          title={t1('change_password')}
        >
          <Icon type="key" />
        </div>
      )}
      renderFull={() => {
        return (
          <NodeNew
            formid={`edit_password_${user.iid}`}
            schema={schema}
            mode="edit"
            node={user}
            alternativeApi="/user/update"
            hiddenFields={{
              iid: user.iid,
            }}
            dialogKey={dialogKey}
            step="set_pass"
          />
        );
      }}
      dialogOptionsProperties={{
        width: 500,
        title: `Đổi mật khẩu cho: ${user.name}`,
      }}
      dialogKey={dialogKey}
    />
  );
};

const mapStateToProps = (state) => ({
  themeConfig: get(state, 'domainInfo.school.theme'),
});

export default connect(mapStateToProps)(ChangePassword);
