import React from 'react';
import get from 'lodash.get';

const LayoutFreeStyle = (props) => {
  const fieldNames = get(props, 'groups.id.fieldNames') || {};

  return (
    <>
      <div className="row search-form-assessment">
        <div className="col-md-3 disabled-select-organizations">
          {fieldNames.user_organizations}
        </div>
        <div className="col-md-3">{fieldNames.phongban_id}</div>
        <div className="col-md-4">{fieldNames.text}</div>
        <div className="col-md-2 text-center m-t-5">
          {get(props, 'submitButton')}
        </div>
      </div>
      <div className="wrap-radio row">
        <div className="col-md-6 m-t-10 m-b-30">{fieldNames.passed}</div>
        <div className="col-md-6">{fieldNames.passed_rather} </div>
      </div>
    </>
  );
};

export default LayoutFreeStyle;
