import React from 'react';
import get from 'lodash.get';
import Widget from 'components/common/Widget';
import { radarChartResultsAssess } from '../util';
import withTemisConfig from 'common/hoc/withTemisConfig';

const LayoutWhenEdit = (props) => {
  const submitButton = get(props, 'submitButton');
  const fieldNames = get(props, 'groups.default.fieldNames', {});
  const task = get(props, 'formValues.task', []);
  const {
    tcnnType,
    dataSource,
    score_scale,
    doNotFillAllTasks = false,
    rubricIid,
  } = get(props, 'layoutOptionsProperties', {});

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          {fieldNames.task}
          <div className="text-center">{submitButton}</div>
        </div>

        <div className="col-md-4 widget-right-wrap mui-page-border">
          {submitButton && (
            <div className="m-t-5 chart">
              <Widget title="Kết quả" className="title-default">
                {radarChartResultsAssess(
                  dataSource,
                  task,
                  score_scale,
                  tcnnType,
                  doNotFillAllTasks,
                  rubricIid,
                )}
              </Widget>
            </div>
          )}

          {!!fieldNames.overall_comment && (
            <div className={`${!submitButton && 'm-t-35'}`}>
              <Widget title="Nhận xét (ghi rõ)" className="title-default">
                <div className="row">{fieldNames.overall_comment}</div>
              </Widget>
            </div>
          )}
          {!!fieldNames.personal_orientation && (
            <Widget
              title="Kế hoạch học tập, bồi dưỡng phát triển năng lực nghề nghiệp trong năm tiếp theo"
              className="title-default"
            >
              {fieldNames.personal_orientation}
            </Widget>
          )}

          {!!fieldNames.attachments && <div>{fieldNames.attachments}</div>}
        </div>
      </div>
    </>
  );
};

export default withTemisConfig(LayoutWhenEdit);
