import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t1 } from 'translate';
import OrganizationsOrPhongBan from 'components/admin/group/common/OrganizationsOrPhongBanInResultTable';
import endpoints from 'components/temis/endpoints';
import { displayOrganizationTypeLabel } from 'utils/Util';
import lodashGet from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import ApiRequestBtnWithConfirmDialog from 'components/common/action-button/ApiRequestBtnWithConfirmDialog';
import Button from 'components/common/primary-button';
import Warning from 'components/common/Warning';

class Results extends Component {
  render() {
    const { formId, orgTypes, items = [] } = this.props;

    const columns = [
      {
        title: 'Mã đơn vị',
        key: 'code',
        dataIndex: 'code',
        width: 250,
        render: (code, item) => (
          <div>
            {code}
            <span className="text-muted m-l-5">
              {!!item.org_id && `(Mã: #${item.org_id})`}
            </span>
          </div>
        ),
      },
      {
        title: 'Tên đơn vị',
        key: 'name',
        dataIndex: 'name',
        render: (name) => <div>{name}</div>,
      },
      {
        title: t1('organization_type'),
        key: 'type',
        dataIndex: 'type',
        render: (type, item) =>
          type === 'organization' && item.sub_type
            ? displayOrganizationTypeLabel(orgTypes, item.sub_type)
            : type
            ? t1(type)
            : '',
      },
      {
        title: 'Trực tiếp dưới đơn vị',
        key: 'org',
        render: (item) => (
          <div>
            {item && item.__expand && item.__expand.ancestor_iids && (
              <OrganizationsOrPhongBan item={item} attr={'ancestor_iids'} />
            )}
          </div>
        ),
      },
      {
        title: 'Trạng thái hoạt động',
        key: 'org',
        type: TYPE_OF_TABLE_DATA.ACTION,
        render: (item) => {
          const { iid, id, organization_inactive } = item;

          return (
            <div className="text-center">
              {organization_inactive ? (
                <>
                  <div className="text-danger m-b-5">Không hoạt động</div>
                  <ApiRequestBtnWithConfirmDialog
                    renderComponent={({ onClick }) => {
                      return (
                        <button
                          className="btn btn-outline btn-primary"
                          onClick={onClick}
                        >
                          Hoạt động
                        </button>
                      );
                    }}
                    url={endpoints.organization_update}
                    params={{
                      id,
                      iid,
                      _sand_step: 'organization_inactive',
                    }}
                    formidToSubmitOnSuccess={formId}
                    textConfirm="Hoạt động"
                    closeModal
                    modalKey="account-active"
                  />
                </>
              ) : (
                <>
                  <div className="text-primary">Đang hoạt động</div>
                  <ApiRequestBtnWithConfirmDialog
                    renderComponent={({ onClick }) => {
                      return (
                        <Button
                          className="btn btn-outline btn-tertiary p-10"
                          onClick={onClick}
                        >
                          Dừng hoạt động
                        </Button>
                      );
                    }}
                    url={endpoints.organization_update}
                    params={{
                      id,
                      iid,
                      organization_inactive: 1,
                      _sand_step: 'organization_inactive',
                    }}
                    formidToSubmitOnSuccess={formId}
                    textConfirm={
                      <Warning>
                        Dừng hoạt động.
                        <br />
                      </Warning>
                    }
                    closeModal
                    modalKey="account-inactive"
                  />
                </>
              )}
            </div>
          );
        },
      },
    ];

    return (
      <CommonAntdTable
        columns={columns}
        dataSource={items}
        className="learn-default"
        childrenColumnName={null}
        pagination={false}
        rowKey="iid"
      />
    );
  }
}

const mapStateToProps = (state) => ({
  orgTypes: lodashGet(state, 'domainInfo.school.org_types'),
});

export default connect(mapStateToProps)(Results);
