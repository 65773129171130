import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import defaultSchema from './schema';
import NormalTableResults from './NormalTableResults';
import OrganizationTreeTableResults from './OrganizationTreeTableResults';
import apiEndpoints from '../../../endpoints';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import ExportData from 'components/common/export-data';
import ReportProgressSyncerButton from '../../syncer';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import lastTimeFinishedSyncing from '../../syncer/lastTimeFinishedSyncing';
import Checkbox from 'antd/lib/checkbox';
import { getSubTypesToDisplayFilterData } from 'components/temis/report/utils';
import { createSelector } from 'reselect';
import lodashGet from 'lodash.get';
import connect from 'react-redux/lib/connect/connect';

const CheckboxGroup = Checkbox.Group;

const RenderResultsComponent = ({
  org_types,
  resultData = {},
  isResultForCBQL,
  isResultForMN,
  exportProps,
  tcnn,
  formid = '',
  isOutOfDeadlineAssessment,
  apiSync,
  object,
  searchValues = {},
  subTypeOptions = [],
  subTypeData = [],
}) => {
  const [
    subTypesOfChildrenDisplay,
    setSubTypesOfChildrenDisplay,
  ] = React.useState([]);

  const { data, last_time_finished_syncing } = resultData;
  const { organizations = [], scholastic, view_mode, category } = searchValues;

  const hasData = Array.isArray(data) && !!data.length;
  const isGradeView = view_mode === 'grade';

  const TableResults = ['tree', 'grade'].includes(view_mode)
    ? OrganizationTreeTableResults
    : NormalTableResults;

  return (
    <>
      {hasData &&
        view_mode === 'tree' &&
        Array.isArray(subTypeOptions) &&
        !!subTypeOptions.length &&
        Array.isArray(org_types) && (
          <CheckboxGroup
            className="m-b-30"
            options={subTypeOptions.map((subType) => {
              const orgType =
                org_types.find(({ type }) => type == subType) || {};

              return {
                label: orgType.name || subType,
                value: parseInt(subType),
              };
            })}
            value={subTypesOfChildrenDisplay}
            onChange={(values) => {
              let newValue = values;
              values.forEach((value) => {
                newValue = newValue.concat(subTypeData[value]);
              });
              setSubTypesOfChildrenDisplay(
                newValue.filter((val, index, self) => {
                  return self.indexOf(val) === index;
                }),
              );
            }}
          />
        )}

      <div className="text-right">
        <div className="d-flex justify-content-end">
          {hasData && (
            <div className="m-t-12 m-r-10">
              {lastTimeFinishedSyncing(last_time_finished_syncing)}
            </div>
          )}

          <div className="report-result__syncer m-r-10">
            <ReportProgressSyncerButton
              formId={formid}
              params={{
                type: 'bdtx_survey',
                scholastic,
                organizations,
                ...searchValues,
              }}
              apiSync={apiSync}
              disabled={isOutOfDeadlineAssessment}
            />
          </div>

          {hasData && (
            <div className="report-result__export-excel">
              <ExportData
                updateTableBody={subTypesOfChildrenDisplay}
                buttonClass={'m-b-15'}
                apiExport={apiEndpoints.export_summary_report_bdtx_survey}
                buttonText="Xuất excel"
                params={{
                  ...searchValues,
                  reportSyncTime: last_time_finished_syncing,
                }}
                tableExport={
                  <TableResults
                    skipFormatNumber={true}
                    dataResult={resultData}
                    defaultExpandAllRows
                    isResultForCBQL={isResultForCBQL}
                    isResultForMN={isResultForMN}
                    tcnn={tcnn}
                    subTypesOfChildrenDisplay={subTypesOfChildrenDisplay}
                    isGradeView={isGradeView}
                    trainingPlanCategory={category}
                  />
                }
                {...exportProps}
              />
            </div>
          )}
        </div>

        {hasData ? (
          <TableResults
            dataResult={resultData}
            isResultForCBQL={isResultForCBQL}
            isResultForMN={isResultForMN}
            tcnn={tcnn}
            subTypesOfChildrenDisplay={subTypesOfChildrenDisplay}
            isGradeView={isGradeView}
            trainingPlanCategory={category}
          />
        ) : (
          <SimpleNoResult text="Không có dữ liệu" />
        )}
      </div>
    </>
  );
};

const BDTXReportSearch = ({
  tcnn,
  scholastic,
  isResultForCBQL,
  isResultForMN,
  showSearchForm,
  hideSubmitButton = false,
  autoSearchWhenStart = false,
  autoSearchWhenValuesChange = false,
  userInfo,
  exportProps,
  hiddenFields = {},
  skipScholastic = false,
  alternativeApi,
  apiSync,
  org_types = [],
  schema,
}) => {
  if (!scholastic && !skipScholastic) {
    return null;
  }

  const formId = `temis-report-${tcnn}-${scholastic}`;
  return (
    <SearchWrapper
      key={formId}
      formid={formId}
      apiSync={apiSync}
      schema={showSearchForm ? schema || defaultSchema : undefined}
      hiddenFields={{
        ...hiddenFields,
        scholastic,
        tcnn,
      }}
      alternativeApi={alternativeApi || apiEndpoints.summary_report_bdtx_survey}
      renderResultsComponent={(
        resultData = {},
        propsResult,
        object,
        searchValues = {},
      ) => {
        const { subTypeData, subTypeOptions } =
          (Array.isArray(lodashGet(resultData, 'data')) &&
            lodashGet(resultData, 'data').length &&
            getSubTypesToDisplayFilterData(lodashGet(resultData, 'data'))) ||
          {};
        return (
          <RenderResultsComponent
            {...propsResult}
            resultData={resultData}
            object={object}
            subTypeData={subTypeData}
            subTypeOptions={subTypeOptions}
            searchValues={searchValues}
            org_types={org_types}
          />
        );
      }}
      isResultForCBQL={isResultForCBQL}
      isResultForMN={isResultForMN}
      hideSubmitButton={hideSubmitButton || !showSearchForm}
      showResult
      showResultsWhenSubmitSucceeded
      autoSearchWhenStart={autoSearchWhenStart || !autoSearchWhenValuesChange}
      autoSearchWhenValuesChange={autoSearchWhenValuesChange}
      userInfo={userInfo}
      exportProps={exportProps}
      tcnn={tcnn}
    />
  );
};

const mapStateToProps = createSelector(
  (state) => lodashGet(state, 'domainInfo.school.org_types'),
  (org_types) => ({ org_types }),
);

export default connect(mapStateToProps)(
  withOutOfDeadlineAssessment(BDTXReportSearch),
);
