import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Form from './Form';
import { t1 } from 'translate';
import DetailButton from 'components/common/detail-button/DetailButton';

const Edit = ({ evidence, searchFormId, preview, scholastic }) => {
  const dialogKey = 'view_assessment_evidence_content';

  const renderPreview = React.useCallback(
    ({ showFull }) => {
      return preview ? (
        <DetailButton onClick={showFull} />
      ) : (
        <span className="ve-edit m-t-3 cursor-pointer" onClick={showFull} />
      );
    },
    [preview],
  );

  const renderFull = React.useCallback(
    () => {
      return (
        <Form
          readOnly={preview}
          evidence={evidence}
          dialogKey={dialogKey}
          searchFormId={searchFormId}
          getOnlyAssessmentThatUserCanDo
          scholastic={scholastic}
        />
      );
    },
    [evidence, searchFormId, scholastic, preview],
  );

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogKey={dialogKey}
      dialogOptionsProperties={{
        title: preview
          ? t1('Tài liệu minh chứng')
          : t1('Thêm/Sửa tài liệu minh chứng'),
        width: '80%',
      }}
    />
  );
};

export default Edit;
