import get from 'lodash.get';
import { input } from 'components/common/forms/schema-fields';
import DatePicker from 'schema-form/elements/date-picker';
import { dateGreaterThan, dateLessThan, required } from 'common/validators';
import { t1 } from 'translate';
import {
  caphoc,
  listSchoolYear,
  targetUser,
  trainingModel,
} from 'components/common/elements';
import { userGradeOptions } from 'configs/constants/user';
import { TRAINING_MODEL } from 'components/taphuan/configs';

const schema = (formid, values, localStep, xpath, props) => {
  const deployed = get(props, 'deployed');

  return {
    name: input('Tên đợt đào tạo (*)', true, {
      classWrapper: 'col-md-12',
    }),
    start_date: {
      type: DatePicker,
      getStartDate: true,
      validate: [
        required(t1('start_time_cannot_be_empty')),
        dateLessThan(values.end_date, t1('start_time_must_be_before_end_time')),
      ],
      hintText: t1('start_time'),
      floatingLabelText: 'Thời gian bắt đầu (*)',
      classWrapper: 'col-md-6',
      fullWidth: true,
      // disabled: deployed,
    },
    end_date: {
      type: DatePicker,
      getEndDate: true,
      validate: [
        required(t1('end_time_cannot_be_empty')),
        dateGreaterThan(
          values.start_date,
          t1('end_time_must_be_after_start_time'),
        ),
      ],
      hintText: t1('end_time'),
      floatingLabelText: 'Thời gian kết thúc (*)',
      classWrapper: 'col-md-6',
      // disabled: deployed,
      fullWidth: true,
    },
    target_user: targetUser({
      type: 'radio',
      floatingLabelText: 'Đối tượng (*)',
      disabled: deployed,
      classWrapper: 'col-md-3',
    }),
    caps: caphoc({
      floatingLabelText: 'Cấp học *',
      defaultValue: userGradeOptions().map((option) => option.value),
      disabled: deployed,
      classWrapper: 'col-md-6',
    }),
    training_model: trainingModel({
      floatingLabelText: 'Mô hình đào tạo (*)',
      disabled: deployed,
      classWrapper: 'col-md-3',
    }),
    school_year: listSchoolYear({
      floatingLabelText: 'Năm học (*)',
      multiple: false,
      validate: [required()],
      // disabled: deployed,
      classWrapper: 'col-md-12',
    }),
    use_cot_can_as_teacher: {
      type: 'checkbox',
      label: t1('use_cot_can_as_teacher'),
      classWrapper: 'col-md-12',
      disabled: deployed,
    },
    allow_other_target_user_to_learn: {
      type: 'checkbox',
      label: t1('allow_other_target_user_to_learn'),
      classWrapper: 'col-md-12',
      disabled: deployed,
    },
  };
};

const ui = (step, values) => {
  return [
    {
      id: 'default',
      fields: [
        'name',
        'school_year',
        'start_date',
        'end_date',
        'training_model',
        'target_user',
        'caps',
        ...(values.training_model === TRAINING_MODEL.DAI_TRA
          ? ['use_cot_can_as_teacher']
          : []),
        'allow_other_target_user_to_learn',
      ],
    },
  ];
};

export default {
  schema,
  ui,
};
