import React from 'react';
import lodashGet from 'lodash.get';
import Form, { formId } from './Form';
import withUserInfo from 'common/hoc/withUserInfo';
import { connect } from 'react-redux';
import store from 'store';
import { updateUserInfo } from 'actions/auth';
import { compose } from 'redux';
import { ACCOUNT_ROUTER } from '../../constant';
import { withRouter } from 'react-router';
import { getValueOfBooleanString } from 'common/utils/string';

const TemisProfileEditor = (props) => {
  const userLoginId = lodashGet(props, 'userInfo.id');
  const userEditId = lodashGet(props, 'match.params.id');
  const userId = userEditId || userLoginId;

  const handleFormSubmitSuccess = (apiResponse) => {
    localStorage.setItem('isFinishTemisForm', 'true');

    const { result } = apiResponse;
    const { phongbans = [] } = result;

    if (userEditId) {
      const { history } = props;
      history.push(ACCOUNT_ROUTER);
    } else {
      store.dispatch(
        updateUserInfo({
          ...result,
          phongbans: phongbans
            .map((pb) => lodashGet(pb, 'iid'))
            .filter(Boolean),
        }),
      );
    }
  };

  const isFinishProfile = () => {
    return (
      getValueOfBooleanString(
        lodashGet(props, 'userInfo.have_enter_temis_profile_info', false),
      ) &&
      !getValueOfBooleanString(
        lodashGet(props, 'userInfo.force_re_enter_profile_info', false),
      )
    );
  };

  const showAlertMessage = !isFinishProfile() || props.errors;

  return (
    <Form
      userId={userId}
      notShowCardShadow={props.notShowCardShadow}
      onSuccess={handleFormSubmitSuccess}
      showAlertMessage={!!showAlertMessage}
      isEditAccount={!!userEditId}
    />
  );
};

const mapState = (state) => ({
  errors: lodashGet(state, `form.${formId}.syncErrors`),
});

export default compose(
  withRouter,
  connect(mapState),
  withUserInfo,
)(TemisProfileEditor);
