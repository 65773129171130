import Layout from './Layout';
import Attachments from 'schema-form/elements/attachments';
import TableForm from './table-form';
import { VIEWER_FILE_EXTENSIONS } from '../../constant';
import { required } from 'common/validators';
import get from 'lodash.get';

const schema = (formId, values, step, xpath, props) => {
  const { dataSource = [], readOnly } = props;
  return {
    attachments: {
      type: Attachments,
      allowDownload: true,
      fullWidth: true,
      multiple: false,
      noFileManager: true,
      viewerFileExtensions: VIEWER_FILE_EXTENSIONS,
      limit: 1,
      maxFileSize: 25,
      title: 'Đính kèm BM03/PL2 CV4529',
      validate: [required('Vui lòng tải File đính kèm BM03/PL2 CV4529')],
      accept: ['.pdf', '.doc', '.docx', '.xlsx', '.png', '.jpg', 'jpeg'],
    },
    opinions: {
      type: TableForm,
      readOnly,
      dataSource: dataSource,
    },
    good_things: {
      type: 'text',
      floatingLabelText: 'Những điểm tốt trong hoạt động quản lý nhà trường:',
      multiLine: true,
    },
    bad_things: {
      type: 'text',
      floatingLabelText: 'Những điều cần thay đổi:',
      multiLine: true,
    },
  };
};

const ui = (step, values, themeConfig, xpath, formid, { dataSource }) => [
  {
    id: 'default',
    fields: [
      'attachments',
      Array.isArray(dataSource) && !!dataSource.length && 'opinions',
      'good_things',
      'bad_things',
    ].filter(Boolean),
  },
];

const layout = (step, values, xpath, { readOnly }) => {
  const attachments = get(values, 'attachments', []);
  return {
    component: Layout,
    freestyle: 1,
    optionsProperties: {
      readOnly,
      attachments,
    },
  };
};

export default {
  schema,
  ui,
  layout,
};
