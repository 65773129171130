import React, { Component } from 'react';
import SimpleNoResult from '../../../common/search-wrap/simple-no-result/SimpleNoResult';

class NoAssessmentResult extends Component {
  render() {
    const {
      text = 'Bạn chưa tự đánh giá, hãy đánh giá ngay bây giờ!',
    } = this.props;
    return (
      <SimpleNoResult
        text={text}
        imagePath="/media/images/empty/assessment-empty.png"
      />
    );
  }
}

export default NoAssessmentResult;
