import getUser from 'common/auth';
import get from 'lodash.get';
import { isProjectConfigBDTX } from 'utils/Util';
import { shouldShowSwitchProject } from 'components/common/switch-project/utils';

const schoolYearConfigKey = 'SCHOOL_YEAR_CONFIGURATION_KEY';

export const savSchoolYearToLocalStorage = (schoolYear) => {
  const userInfo = getUser();
  const userId = get(userInfo, 'info.iid', '');
  const currentSchoolYear = getSchoolYearOfAllUserFromLocalStorage() || {};
  const newSchoolYear = {
    ...currentSchoolYear,
    [userId]: schoolYear,
  };

  localStorage.setItem(schoolYearConfigKey, JSON.stringify(newSchoolYear));
};

export const getSchoolYearOfAllUserFromLocalStorage = () => {
  const schoolYearString = localStorage.getItem(schoolYearConfigKey);
  if (schoolYearString) {
    return JSON.parse(schoolYearString);
  }

  return null;
};

export const getSchoolYearOfCurrentUser = () => {
  const userInfo = getUser();
  const userId = get(userInfo, 'info.iid', '');
  const schoolYearFromLocalStorage =
    getSchoolYearOfAllUserFromLocalStorage() || {};

  return get(schoolYearFromLocalStorage, userId) || window.DEFAULT_SCHOOL_YEAR;
};

export const shouldShowSchoolYearConfig = () => {
  return isProjectConfigBDTX() && shouldShowSwitchProject();
};

export const onSaveSchoolYear = (schoolYear, callback) => {
  savSchoolYearToLocalStorage(schoolYear);

  if (typeof callback === 'function') {
    callback();
  }

  window.location.reload();
};
