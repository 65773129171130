import React from 'react';
import { t1 } from 'translate';
import LayoutFreeStyle from './LayoutFreeStyle';
import { phongbanInOrganization } from 'components/admin/organization/schema/elements';
import get from 'lodash.get';
import { isHieuTruong } from 'components/admin/user/utils';
import { leaderPositions } from 'configs/constants/user';

const schema = (formid, values, step, xpath, props, domainInfo) => {
  const orgTypes = get(domainInfo, 'school.org_types');
  const { shouldDisabled = false, finalAggregateAssessment } = props;
  return {
    text: {
      type: 'text',
      hintText: 'Nhập Tên, Mã hoặc Email',
      floatingLabelText: finalAggregateAssessment
        ? 'Phó HT, tổ trưởng và giáo viên'
        : 'Giáo viên',
      defaultValue: '',
      errorText: '',
      fullWidth: true,
    },
    phongban_id: phongbanInOrganization({
      orgTypes,
      ancestor_iids: props.orgIids,
      defaultValue: isHieuTruong(get(props, 'userRoot'))
        ? null
        : get(props, 'userRoot.phongban_id'),
      readOnly: shouldDisabled,
      disabled: shouldDisabled,
    }),
    assessment_status: {
      type: 'multiCheckbox',
      inline: true,
      options: [
        {
          value: 'have_sent',
          label: `${
            finalAggregateAssessment ? 'GV/TTCM/PHT' : 'GV'
          } đã gửi tự đánh giá`,
        },
        {
          value: 'have_not_sent_yet',
          label: `${
            finalAggregateAssessment ? 'GV/TTCM/PHT' : 'GV'
          } chưa gửi tự đánh giá`,
        },
        {
          value: 'have_assessed',
          label: `${
            finalAggregateAssessment ? 'Hiệu trưởng' : 'TTCM'
          } đã đánh giá`,
        },
        {
          value: 'have_not_assessed_yet',
          label: `${
            finalAggregateAssessment ? 'Hiệu trưởng' : 'TTCM'
          } chưa đánh giá`,
        },
      ],
    },
    leader_position: {
      type: 'select',
      floatingLabelText: 'Chức vụ',
      fullWidth: true,
      options: [
        {
          value: '',
          label: t1('all'),
        },
        {
          value: leaderPositions.VICE_LEADER,
          label: 'Phó hiệu trưởng',
        },
        {
          value: leaderPositions.TO_TRUONG,
          label: 'Tổ trưởng',
        },
        {
          value: leaderPositions.TEACHER,
          label: 'Giáo viên',
        },
      ],
    },
  };
};

const ui = (step, values, themeConfig, xpath, formid, props) => {
  const { userRoot } = props;
  const finalAggregateAssessment = typeof props.finalAggregateAssessment;

  const fields = [
    'phongban_id',
    'text',
    isHieuTruong(userRoot) && finalAggregateAssessment && 'leader_position',
    'assessment_status',
  ].filter(Boolean);

  return [
    {
      id: 'id',
      fields: fields,
    },
  ];
};

const layout = () => {
  return { component: LayoutFreeStyle, freestyle: 1 };
};

export default {
  schema,
  ui,
  layout,
};
