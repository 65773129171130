import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash.get';
import { editTcnn } from 'components/temis/routes';
import Icon from 'components/common/Icon';
import { timestampToDateTimeString } from 'common/utils/Date';
import Warning from 'components/common/Warning';
import { t1 } from 'translate';
import {
  generateClassByStatusAssessment,
  isApprovedAssessment,
} from 'components/temis/utils';
import StatusAssessment from 'components/temis/assessment/do-assessment/statusAssessment';
import endPoints from 'components/temis/assessment/endpoints';
import store from 'store';
import sagaActions from 'actions/node/saga-creators';
import './stylesheet.scss';
import { ASSESSMENT_STATUS, TCNN_TYPE } from 'components/temis/constant';
import { getOverallResultsOfTheAssessment } from '../util';

const generateIcon = (status) =>
  `ve-${generateClassByStatusAssessment(status)}`;

const lastUpdated = (ts) => {
  return ts ? (
    <span>
      {'Bạn đã đánh giá lúc'} {timestampToDateTimeString(ts)}
    </span>
  ) : (
    <span>
      <Warning inline>{t1('never_updated')}</Warning> <Icon icon="edit" />
    </span>
  );
};

const iconStyle = {
  fontSize: '500%',
};

const InfoSelfAssessment = ({
  updatedTs,
  value,
  score_scale,
  tcnnType = TCNN_TYPE.GV,
  status,
  node = {},
  viewOnly = false,
  onSetApprovedAssessment,
  scholastic,
  isDisabled,
  shouldBeShowMessageLackEvidence,
  scoreBasedOnSelectedTask,
  rubricIid,
}) => {
  const [isApproved, setApprovedAssessment] = useState(
    isApprovedAssessment(status),
  );

  const parts = get(score_scale, 'parts', []);
  const result = getOverallResultsOfTheAssessment(
    value,
    scoreBasedOnSelectedTask,
    rubricIid,
  );

  if (typeof result.final === 'undefined') {
    return null;
  }

  const part = parts.find(({ id }) => id === result.final);

  const sendAssessment = () => {
    const payload = {
      apiUrl: endPoints.doAssessment,
      data: {
        ...node,
        status: ASSESSMENT_STATUS.APPROVED,
      },
      requestSuccessful: () => {
        setApprovedAssessment(true);

        if (typeof onSetApprovedAssessment === 'function') {
          onSetApprovedAssessment(true);
        }
      },
    };

    store.dispatch(sagaActions.upsertNodeRequest(payload));
  };
  const name = get(part, 'name', '');

  return (
    <div className="info-self-assessment">
      <div className="m-b-20 m-t-25 text-center">
        <span style={iconStyle} className={`${generateIcon(name)}`} />
        <div className="m-t-10 status-text text-center">{name}</div>
      </div>
      <StatusAssessment
        className="m-b-30"
        isApprovedStatus={isApproved}
        key={scholastic}
      />
      <div className="d-flex assessment-action">
        <Link to={editTcnn}>
          <button
            type="button"
            className="m-b-20 btn btn-secondary btn-outline"
          >
            {'Đánh giá lại'}
          </button>
        </Link>
        {!isApproved && (
          <button
            disabled={viewOnly || isDisabled}
            type="button"
            className="m-b-20 btn btn-secondary"
            onClick={sendAssessment}
          >
            {'Gửi đánh giá'}
          </button>
        )}
      </div>
      {shouldBeShowMessageLackEvidence && (
        <div className="text-danger">
          Thiếu tài liệu MC nên không thế gửi đánh giá.
        </div>
      )}
      <br />
      {lastUpdated(updatedTs)}
      {isDisabled && (
        <div className="text-danger">
          (Hoàn thành đánh giá trước khi gửi đi)
        </div>
      )}
    </div>
  );
};

export default InfoSelfAssessment;
