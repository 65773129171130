import React from 'react';
import {
  isAdminTruongOrHieuTruongOrPho,
  isTeacher,
  isToTruong,
  isMamNon,
  isHVQLStaff,
} from 'components/admin/user/utils';
import { REPORT_GROUP_ROUTER } from '../constant';
import { Avatar, List } from 'antd';
import ok from '../../../common/images/ok.png';
import { Link } from 'react-router-dom';
import DisplayHtml from 'components/common/html';
import { formatNumber } from 'common/utils/Number';

export const reportMNTitle = {
  mn: 'Danh sách báo cáo Mầm Non',
  mn1: 'Kết quả đánh giá và xếp loại GV Mầm non theo chuẩn nghề nghiệp',
  mn2: 'Kết quả đánh giá và xếp loại CBQL CSGD Mầm non theo chuẩn hiệu trưởng',
  mn3:
    'Nhu cầu bồi dưỡng của GV Mầm non (Ghi tên 5 tiêu chí; số lượng và tỷ lệ % ở mức xếp loại thấp nhất của từng tiêu chí theo tổng chung, phân tách theo các nhóm Nữ, DTTS, Nữ DTTS và công tác vùng khó khăn)',
  mn4:
    'Nhu cầu bồi dưỡng của CBQLCSGD Mầm non (Ghi tên 5 tiêu chí; số lượng và tỷ lệ % ở mức xếp loại thấp nhất của từng tiêu chí theo tổng chung, phân tách theo các nhóm Nữ, DTTS, Nữ DTTS và công tác vùng khó khăn)',
  mn5:
    'Nhu cầu bồi dưỡng theo mong muốn của GV Mầm non (Tập hợp từ câu hỏi 2 trong phiếu Tự đánh giá xếp loại giáo viên)',
  mn6:
    'Nhu cầu bồi dưỡng theo mong muốn của CBQL CSGD Mầm non (Tập hợp từ câu hỏi 2 trong phiếu Tự đánh giá xếp loại CBQL CSGD Mầm non',
  mn7:
    'Báo cáo tổng hợp theo tiêu chí kết quả đánh giá và xếp loại của GV Mầm non theo chuẩn nghề nghiệp',
  mn8:
    'Báo cáo tổng hợp theo tiêu chí kết quả đánh giá và xếp loại của CBQL CSGD Mầm non theo chuẩn nghề nghiệp',
};

export const reportMN = (user) => {
  if (isTeacher(user) || isToTruong(user)) {
    return [];
  }

  const isHVQL = isHVQLStaff(user);

  const allReports = [
    !isHVQL && {
      title: reportMNTitle.mn1,
      path: REPORT_GROUP_ROUTER.cbql_gvmn,
      forGV: true,
    },
    {
      title: reportMNTitle.mn2,
      path: REPORT_GROUP_ROUTER.cbql_cbmn,
    },
    !isHVQL && {
      title: reportMNTitle.mn3,
      path: REPORT_GROUP_ROUTER.ncbdtx_gvmn,
      forGV: true,
    },
    {
      title: reportMNTitle.mn4,
      path: REPORT_GROUP_ROUTER.ncbdtx_cbmn,
    },
    !isHVQL && {
      title: reportMNTitle.mn5,
      path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_gvmn,
      forGV: true,
    },
    {
      title: reportMNTitle.mn6,
      path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_cbmn,
    },
    !isHVQL && {
      title: reportMNTitle.mn7,
      path: REPORT_GROUP_ROUTER.cbql_gvmn_by_rubric,
      forGV: true,
    },
    {
      title: reportMNTitle.mn8,
      path: REPORT_GROUP_ROUTER.cbql_cbmn_by_rubric,
    },
  ].filter(Boolean);

  if (isAdminTruongOrHieuTruongOrPho(user)) {
    return allReports.filter((report) => report.forGV);
  }

  return allReports;
};

export const reportATitle = {
  a:
    'PHẦN A: KẾT QUẢ ĐÁNH GIÁ VÀ XẾP LOẠI GIÁO VIÊN PHỔ THÔNG VÀ CÁN BỘ QUẢN LÝ CƠ SỞ GIÁO DỤC PHỔ THÔNG THEO CHUẨN NGHỀ NGHIỆP GIÁO VIÊN VÀ CHUẨN HIỆU TRƯỞNG CƠ SỞ GIÁO DỤC PHỔ THÔNG',
  a1:
    'A.1. Kết quả đánh giá và xếp loại giáo viên phổ thông theo chuẩn nghề nghiệp',
  a2:
    'A.2. Kết quả đánh giá và xếp loại cán bộ quản lí cơ sở giáo dục phổ thông theo chuẩn hiệu trưởng',
};

export const reportA = (user) => {
  if (isTeacher(user) || isToTruong(user)) {
    return [];
  }

  const forGV = isHVQLStaff(user)
    ? []
    : [
        {
          title: reportATitle.a1,
          path: REPORT_GROUP_ROUTER.cbql_gvpt,
        },
      ];
  if (isAdminTruongOrHieuTruongOrPho(user)) {
    return forGV;
  }

  return [
    ...forGV,
    {
      title: reportATitle.a2,
      path: REPORT_GROUP_ROUTER.cbql_cbpt,
    },
  ];
};

export const getReportATitle = (
  isResultForMN,
  isResultByRubric,
  isResultForCBQL,
) => {
  let reportTitle;
  if (isResultByRubric) {
    if (isResultForMN) {
      reportTitle = isResultForCBQL ? reportMNTitle.mn8 : reportMNTitle.mn7;
    } else {
      reportTitle = isResultForCBQL
        ? reportBCThongKeTitle.cnnCBQL
        : reportBCThongKeTitle.cnnGVPT;
    }
  } else if (isResultForMN) {
    reportTitle = isResultForCBQL ? reportMNTitle.mn2 : reportMNTitle.mn1;
  } else {
    reportTitle = isResultForCBQL ? reportATitle.a2 : reportATitle.a1;
  }

  return reportTitle;
};

export const reportBTitle = {
  b:
    'PHẦN B: KẾT QUẢ ĐÁNH GIÁ VỀ CÁC CHƯƠNG TRÌNH BỒI DƯỠNG THƯỜNG XUYÊN (Dựa trên khảo sát trực tuyến GVPT và CBQLCSGDPT hoàn thành các mô đun bồi dưỡng vào cuối năm)',
  b1:
    'B.1. Kết quả trả lời phiếu khảo sát trực tuyến của GIÁO VIÊN PHỔ THÔNG về chương trình bồi dưỡng thường xuyên',
  b2:
    'B.2. Kết quả trả lời phiếu khảo sát trực tuyến của CBQLCSGDPT về chương trình bồi dưỡng thường xuyên',
};

export const reportB = (user) => {
  if (isTeacher(user) || isToTruong(user)) {
    return [];
  }

  const forGV = isHVQLStaff(user)
    ? []
    : [
        {
          title: reportBTitle.b1,
          path: REPORT_GROUP_ROUTER.ctbdtx_gvpt,
        },
      ];

  if (isAdminTruongOrHieuTruongOrPho(user)) {
    return forGV;
  }

  return [
    ...forGV,
    {
      title: reportBTitle.b2,
      path: REPORT_GROUP_ROUTER.ctbdtx_cbql,
    },
  ];
};

export const getReportBTitle = (isResultForCBQL) => {
  return isResultForCBQL ? reportBTitle.b2 : reportBTitle.b1;
};

export const reportCTitle = {
  c:
    'PHẦN C: BÁO CÁO KẾT QUẢ KHẢO SÁT NHU CẦU BỒI DƯỠNG THƯỜNG XUYÊN CỦA GIÁO VIÊN VÀ CBQLCSGDPT THEO CÁC TIÊU CHÍ TRONG CHUẨN NGHỀ NGHIỆP GIÁO VIÊN VÀ CHUẨN HIỆU TRƯỞNG CƠ SỞ GIÁO DỤC PHỔ THÔNG',
  c1: 'C.1. Nhu cầu bồi dưỡng dựa trên đánh giá theo chuẩn',
  c11:
    'C.1.1. Nhu cầu bồi dưỡng của GIÁO VIÊN (Ghi tên 5 tiêu chí; số lượng và tỷ lệ % ở mức xếp loại thấp nhất của từng tiêu chí theo tổng chung, phân tách theo các nhóm Nữ, DTTS, Nữ DTTS và công tác vùng khó khăn)',
  c12:
    'C.1.2. Nhu cầu bồi dưỡng của CBQLCSGDPT (Ghi tên 5 tiêu chí; số lượng và tỷ lệ % ở mức xếp loại thấp nhất của từng tiêu chí theo tổng chung, phân tách theo các nhóm Nữ, DTTS, Nữ DTTS và công tác vùng khó khăn)',
  c2:
    'C.2. Nhu cầu bồi dưỡng theo mong muốn của giáo viên (Tập hợp từ câu hỏi 2 trong phiếu Tự đánh giá xếp loại giáo viên - biểu mẫu 1 – phụ lục II theo CV 4530)',
  c3:
    'C.3. Nhu cầu bồi dưỡng theo mong muốn của CBQL CSGDPT (Tập hợp từ câu hỏi 2 trong phiếu Tự đánh giá xếp loại CBQL CSGDPT - biểu mẫu 1 – phụ lục II theo CV 4529)',
};

export const reportC = (user) => {
  if (isTeacher(user) || isToTruong(user)) {
    return [];
  }

  if (isAdminTruongOrHieuTruongOrPho(user)) {
    return [
      {
        title: reportCTitle.c1,
        description: [
          {
            title: reportCTitle.c11,
            path: REPORT_GROUP_ROUTER.ncbdtx_gvpt,
          },
        ],
      },
      {
        title: reportCTitle.c2,
        path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_gvpt,
      },
    ];
  }

  const isHVQL = isHVQLStaff(user);
  return [
    {
      title: reportCTitle.c1,
      description: [
        !isHVQL && {
          title: reportCTitle.c11,
          path: REPORT_GROUP_ROUTER.ncbdtx_gvpt,
        },
        {
          title: reportCTitle.c12,
          path: REPORT_GROUP_ROUTER.ncbdtx_cbql,
        },
      ].filter(Boolean),
    },
    !isHVQL && {
      title: reportCTitle.c2,
      path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_gvpt,
    },
    {
      title: reportCTitle.c3,
      path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_cbpt,
    },
  ].filter(Boolean);
};

export const getReportCTitle = (
  isResultForMN,
  isThongKeReport,
  isResultForCBQL,
  isPlanDevelopment,
) => {
  let reportTitle;
  if (isThongKeReport) {
    reportTitle = reportBCThongKeTitle.cnnCBQLOrg;
  } else if (isPlanDevelopment) {
    if (isResultForMN) {
      reportTitle = isResultForCBQL ? reportMNTitle.mn6 : reportMNTitle.mn5;
    } else {
      reportTitle = isResultForCBQL ? reportCTitle.c3 : reportCTitle.c2;
    }
  } else {
    if (isResultForMN) {
      reportTitle = isResultForCBQL ? reportMNTitle.mn4 : reportMNTitle.mn3;
    } else {
      reportTitle = isResultForCBQL ? reportCTitle.c12 : reportCTitle.c11;
    }
  }

  return reportTitle;
};

export const reportBCThongKeTitle = {
  tk: 'DANH SÁCH BÁO CÁO THỐNG KÊ',
  cnnGVPT:
    'Báo cáo tổng hợp theo tiêu chí kết quả đánh giá và xếp loại của GV CSGDPT theo chuẩn nghề nghiệp',
  cnnCBQL:
    'Báo cáo tổng hợp theo tiêu chí kết quả đánh giá và xếp loại của CBQL CSGDPT theo chuẩn nghề nghiệp',
  cnnGVPTOrg:
    'Báo cáo tổng hợp đánh giá và xếp loại chuẩn nghề nghiệp GVPT theo đơn vị',
  cnnCBQLOrg:
    'Báo cáo tổng hợp đánh giá và xếp loại chuẩn nghề nghiệp CBQL theo đơn vị',
};

export const reportBCThongKe = (user) => {
  if (isTeacher(user) || isToTruong(user)) {
    return [];
  }

  const forGV = isHVQLStaff(user)
    ? []
    : [
        {
          title: reportBCThongKeTitle.cnnGVPT,
          path: REPORT_GROUP_ROUTER.cbql_gvpt_by_rubric,
        },
      ];

  if (isAdminTruongOrHieuTruongOrPho(user)) {
    return forGV;
  }

  return [
    ...forGV,
    {
      title: reportBCThongKeTitle.cnnCBQL,
      path: REPORT_GROUP_ROUTER.cbql_cbpt_by_rubric,
    },
  ];
};

export const getReportBCThongKeTitle = () => {};

export const convertStringToElement = (item, className = '') => {
  const title = item.title || item;
  const titleConvert = title.replace(
    /(\(.*\))/g,
    `<span class="${className || 'sub-title-report'}">$1</span>`,
  );

  return <DisplayHtml content={titleConvert} />;
};

export const renderReportList = (items) => {
  const renderDescription = (description) => {
    if (Array.isArray(description)) {
      return renderReportList(description);
    }

    return description;
  };

  const renderTitle = (item) => {
    if (!item) {
      return;
    }

    const title = (
      <span style={{ color: 'black' }}>{convertStringToElement(item)}</span>
    );

    const path = item.link || item.path;
    return path ? <Link to={path}>{title}</Link> : title;
  };

  return (
    <List
      itemLayout="horizontal"
      dataSource={items}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={ok} />}
            title={renderTitle(item)}
            description={renderDescription(item.description)}
          />
        </List.Item>
      )}
    />
  );
};

export const checkPermissionByPathname = (pathname, search, listItem = []) => {
  if (!listItem || !listItem.length) {
    return;
  }

  const matchedItem = listItem.find((item) => {
    if (Array.isArray(item.description)) {
      return checkPermissionByPathname(pathname, search, item.description);
    }

    return (
      pathname === item.path ||
      `${pathname}${search}` === item.path ||
      pathname === REPORT_GROUP_ROUTER.detail
    );
  });

  return !!matchedItem;
};

export const getNumberFormat = (data, skipFormatNumber) => {
  return skipFormatNumber ? data : formatNumber(data);
};

export const getSubTypesToDisplayFilterData = (
  organizations,
  subtypes = {},
  subTypeOptions = [],
  parentSubType = null,
) => {
  if (!Array.isArray(organizations) || !organizations.length) {
    return {
      subTypeData: subtypes,
      subTypeOptions: subTypeOptions,
    };
  }

  let newSubtypes = subtypes;
  let newSubTypeOptions = Array.isArray(subTypeOptions) ? subTypeOptions : [];

  organizations.forEach(({ sub_type, children }) => {
    let tmp = newSubtypes[sub_type] || [];
    if (parentSubType && !tmp.includes(parentSubType)) {
      tmp.push(parentSubType);
      if (newSubtypes[parentSubType]) {
        tmp = tmp.concat(newSubtypes[parentSubType]);
      }
      newSubtypes[sub_type] = tmp;
    }

    if (parentSubType && !newSubTypeOptions.includes(sub_type)) {
      newSubTypeOptions.push(sub_type);
    }

    tmp = getSubTypesToDisplayFilterData(
      children,
      newSubtypes,
      newSubTypeOptions,
      sub_type,
    );

    newSubtypes = tmp.subTypeData;
    newSubTypeOptions = tmp.subTypeOptions;
  });

  return {
    subTypeData: newSubtypes,
    subTypeOptions: newSubTypeOptions,
  };
};
