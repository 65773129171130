import React, { Component } from 'react';
import AssessMyPeers from '../../assessments-in-organization/overview';

class PopupDetail extends Component {
  render() {
    const { currentOrganizations, scholastic, userIid } = this.props;
    return (
      <AssessMyPeers
        currentOrganizations={currentOrganizations}
        scholastic={scholastic}
        userIid={userIid}
      />
    );
  }
}

export default PopupDetail;
