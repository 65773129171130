import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import endpoints from 'components/temis/endpoints';
import Result from './Result';
import withUserOrganizations from 'common/hoc/withUserOrganizations';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import subTypes from '../../../configs/constants/org-sub-types';

const searchFormId = 'sub-organization';

const SubOrganization = ({ orgIids }) => {
  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Không tìm thấy đơn vị" />;
  };

  const renderResultComponent = (items) => {
    return <Result items={items} formId={searchFormId} />;
  };

  const hiddenFields = {
    _sand_expand: ['ancestor_iids'],
    include_sub_organizations: 1,
    rootIids: orgIids,
    pIids: orgIids,
    allow_organization_inactive: 1,
    sub_types_discarded: [subTypes.TAPHUAN_SUBTYPE_TO_CHUYEN_MOM],
    depth: -1,
    view: 'grid',
  };

  return (
    <div className="sub-organization">
      <SearchWrapper
        formid={searchFormId}
        schema={schema}
        hiddenFields={hiddenFields}
        renderResultsComponent={renderResultComponent}
        renderNoResultComponent={renderNoResultComponent}
        alternativeApi={endpoints.organization_search}
      />
    </div>
  );
};

export default withUserOrganizations(SubOrganization);
