import React from 'react';
import get from 'lodash.get';
import {
  generateClassByStatusAssessment,
  generateStatusResultAssessment,
} from '../../utils';
import Form from '../../assessment/edit/Form';

const ViewAssessmentByResult = ({
  item,
  scholastic,
  assessmentSelfResult,
  peerAssess,
  assessorIid,
}) => {
  const rubricToAssessment = get(item, '__expand.temis.rubric_iid');
  const userIidToAssessment = get(item, 'iid');
  const name = get(item, 'name');

  return (
    <>
      <div className="assessment-result d-flex">
        <div className="title">{`Đánh giá CNN: ${name}`}</div>
        <div className="m-l-35 assessment-result__result">
          Xếp loại đánh giá:{' '}
          <span
            className={` status-text ${generateClassByStatusAssessment(
              generateStatusResultAssessment(assessmentSelfResult) || '',
            )}`}
          >
            {generateStatusResultAssessment(assessmentSelfResult)}
          </span>
        </div>
      </div>
      <div className="temis-manager">
        <div className="mui-paper-no-padding mui-paper-no-box-shadow">
          <Form
            formId="self-assessment"
            userIid={userIidToAssessment}
            readOnly
            scholastic={scholastic}
            peerAssess={peerAssess}
            assessorIid={assessorIid}
            userForAssessment={item}
          />
        </div>
      </div>
    </>
  );
};

export default ViewAssessmentByResult;
