import React, { Component } from 'react';
import Widget from 'components/common/Widget';
import TCNNReportSearch from '../components/SearchForm';
import withUserInfo from 'common/hoc/withUserInfo';
import ExportData from 'components/common/export-data';
import { EXPORT_FILENAME, TCNN_TYPE } from 'components/temis/constant';
import ReportProgressSyncerButton from '../syncer';
import lastTimeFinishedSyncing from '../syncer/lastTimeFinishedSyncing';

const TCNNSo = ({ userInfo = {}, scholastic, isOutOfDeadlineAssessment }) => {
  const [
    lastTimeFinishedSyncingDataTCNN_GV,
    setLastTimeFinishedSyncingDataTCNN_GV,
  ] = React.useState(null);
  const [
    lastTimeFinishedSyncingDataTCNN_CBQL,
    setLastTimeFinishedSyncingDataTCNN_CBQL,
  ] = React.useState(null);

  const { organizations = [] } = userInfo;
  const so = organizations[0];

  const formId_TCNNGV = `temis-report-tcnn_gv-self_assessment-${scholastic}`;
  const formId_TCNNGV_CSGD = `temis-report-tcnn_gv-approve_reviews-${scholastic}`;
  const formId_TCNNHT = `temis-report-tcnn_ht-self_assessment-${scholastic}`;
  const formId_TCNNHT_CSQL = `temis-report-tcnn_ht-approve_reviews-${scholastic}`;

  return (
    <div className="report-result">
      <div className="report-result__organization m-b-10">{`Sở giáo dục: ${!!so &&
        so.name}`}</div>
      <div>
        <div className="report-result__school-export-excel">
          <div className="report-result__tnnn-type">
            Kết quả đánh giá giáo viên
          </div>
          <div className="d-flex">
            <div className="m-t-12 m-r-10">
              {lastTimeFinishedSyncing(lastTimeFinishedSyncingDataTCNN_GV)}
            </div>

            <div className="report-result__syncer m-r-10">
              <ReportProgressSyncerButton
                formIds={[formId_TCNNGV, formId_TCNNGV_CSGD]}
                params={{
                  scholastic,
                }}
                disabled={isOutOfDeadlineAssessment}
              />
            </div>

            <div className="report-result__export-excel">
              <ExportData
                apiExport="/temis/export/export-overall-result-assessment-report-in-organization"
                buttonText="Xuất excel BM3/PL3"
                formId="search-score-by-rubric"
                params={{
                  scholastic,
                }}
                fileName={EXPORT_FILENAME.admin.BM3_PL3}
              />
            </div>
          </div>
        </div>

        <hr />

        <div>
          <div className="report-result__container">
            <Widget
              title="1. Kết quả tự đánh giá của giáo viên"
              className="title-default"
            >
              <TCNNReportSearch
                tcnn={TCNN_TYPE.GV}
                hideChart
                type="self_assessment"
                scholastic={scholastic}
                formIdDefault={formId_TCNNGV}
                setLastTimeFinishedSyncingData={
                  setLastTimeFinishedSyncingDataTCNN_GV
                }
              />
            </Widget>
          </div>
          <div className="report-result__container">
            <Widget
              title="2. Kết quả đánh giá của cơ sở giáo dục phổ thông"
              className="title-default"
            >
              <TCNNReportSearch
                tcnn={TCNN_TYPE.GV}
                hideChart
                type="approve_reviews"
                scholastic={scholastic}
                formIdDefault={formId_TCNNGV_CSGD}
              />
            </Widget>
          </div>
        </div>
      </div>
      <div>
        <div className="report-result__school-export-excel">
          <div className="report-result__tnnn-type">
            Kết quả đánh giá hiệu trưởng/hiệu phó
          </div>
          <div className="d-flex">
            <div className="m-t-12 m-r-10">
              {lastTimeFinishedSyncing(lastTimeFinishedSyncingDataTCNN_CBQL)}
            </div>

            <div className="report-result__syncer m-r-10">
              <ReportProgressSyncerButton
                formIds={[formId_TCNNHT, formId_TCNNHT_CSQL]}
                params={{
                  scholastic,
                }}
                disabled={isOutOfDeadlineAssessment}
              />
            </div>

            <div className="report-result__export-excel">
              <ExportData
                apiExport="/temis/export/export-overall-result-assessment-report-in-organization"
                buttonText="Xuất excel BM1/PL3.4529"
                formId="search-score-by-rubric"
                params={{
                  tcnn: TCNN_TYPE.HT,
                  scholastic,
                }}
                fileName={EXPORT_FILENAME.admin.BM1_PL3_4529}
              />
            </div>
          </div>
        </div>

        <hr />

        <div>
          <div className="report-result__container">
            <Widget
              title="1. Kết quả tự đánh giá của hiệu trưởng/hiệu phó"
              className="title-default"
            >
              <TCNNReportSearch
                tcnn="tcnn_ht"
                hideChart
                type="self_assessment"
                scholastic={scholastic}
                formIdDefault={formId_TCNNHT}
                setLastTimeFinishedSyncingData={
                  setLastTimeFinishedSyncingDataTCNN_CBQL
                }
              />
            </Widget>
          </div>
          <div className="report-result__container">
            <Widget
              title="2. Kết quả đánh giá của cơ quan quản lý cấp trên trực tiếp"
              className="title-default"
            >
              <TCNNReportSearch
                tcnn="tcnn_ht"
                hideChart
                type="approve_reviews"
                scholastic={scholastic}
                formIdDefault={formId_TCNNHT_CSQL}
              />
            </Widget>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUserInfo(TCNNSo);
