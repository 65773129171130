import React, { Component } from 'react';
import { t1 } from 'translate';
import get from 'lodash.get';
import {
  timestampToDateString,
  timestampToDateTimeString,
} from 'common/utils/Date';
import DetailOnDialog from 'components/common/detail-on-dialog';
import SendAssessmentResult from '../../../components/send-assessment-result';
import {
  getResultFromTheAssessment,
  isApprovedAssessment,
} from '../../../utils';
import PeerResult from 'components/temis/components/peer-result';
import CommonAntdTable from 'components/common/antd/table';
import PopupDetail from './PopupDetail';
import Communicate from '../../../components/temis-communication';
import { ASSESSMENT_STATUS } from '../../../constant';
import { withRouter } from 'react-router';
import withUserInfo from 'common/hoc/withUserInfo';
import PrincipalAssessment from './principal-assessment';

const sendAssessmentResult = 'sendAssessmentResult';

class TableResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }
  setSelectedRowKeys = (value) => {
    this.setState({
      selectedRowKeys: value,
    });
  };
  getColumns = () => {
    const { scholastic, viewOnly, searchFormId } = this.props;
    return [
      {
        title: t1('organizations'),
        key: 'organizations',
        render: (organizations, item) => {
          const parents = get(item, 'p_code');
          return (
            <div className="organizations">
              <div className="organizations-name">{item.name}</div>
            </div>
          );
        },
      },
      {
        title: 'Số lượng GV',
        key: 'teachers',
        className: 'text-center',
        width: 130,
        render: (teachers, item) => get(item, 'number_of_teachers'),
      },
      {
        title: 'Thông tin đánh giá của trường',
        key: 'sendInformation',
        width: 160,
        render: (sendInformation, item) => {
          if (!get(item, 'leader')) {
            return null;
          }

          const orgIds = get(item, 'leader.user_organizations', []);
          const userIid = get(item, 'leaderId');
          return (
            <DetailOnDialog
              renderPreview={({ showFull }) => (
                <div className="text-center cursor-pointer" onClick={showFull}>
                  Xem chi tiết
                </div>
              )}
              renderFull={() => {
                return (
                  <PopupDetail
                    currentOrganizations={orgIds}
                    scholastic={scholastic}
                    userIid={userIid}
                  />
                );
              }}
              dialogKey={sendAssessmentResult}
              dialogOptionsProperties={{
                autoDetectWindowHeight: false,
                handleClose: true,
                width: '100%',
                className: 'modal-full-height assessment-in-school',
                title: `Kết quả đánh giá ${item.name}`,
              }}
            />
          );
        },
      },
      {
        title: 'Hiệu trưởng',
        key: 'principal',
        width: 160,
        render: (principal, item) => {
          const principalName = get(item, 'leader.name');
          const principalEmail = get(item, 'leader.mail');
          const principalBirthday = get(item, 'leader.birthday');
          return (
            <div className="principal">
              <div className="principal-name">{principalName}</div>
              <div className="principal-email text-faded">{principalEmail}</div>
              {principalBirthday > 0 && (
                <div className="principal-birthday text-faded">
                  {timestampToDateString(principalBirthday, {
                    showTime: false,
                    splitter: '/',
                  })}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: 'Đánh giá',
        className: 'text-center',
        key: 'assessment_job_standard',
        width: 300,
        render: (assessmentJobStandard, item) => {
          const target = get(item, 'leader');

          if (!target) {
            return (
              <div className="text-danger text-center">
                <span className="ve-close-outline m-r-5 icon-small-text" />
                <span>Chưa có Hiệu trưởng</span>
              </div>
            );
          }

          const temisItem = get(item, 'leader.__expand.temis.tcnn_ht');

          const isApproved =
            get(item, 'leader.__expand.temis.tcnn_ht.status') ===
            ASSESSMENT_STATUS.APPROVED;
          const resultFromPeer = getResultFromTheAssessment(temisItem);
          const date = timestampToDateTimeString(get(temisItem, 'updated_ts'));
          const selfAssessment = get(item, 'leader.__expand.temis.tudanhgia');
          const selfAssessmentStatus = get(
            item,
            'leader.__expand.temis.tudanhgia.status',
            null,
          );
          const hasSelfAssessment =
            selfAssessment !== null &&
            isApprovedAssessment(selfAssessmentStatus);

          const principalAssessmentDialogKey = `principal-assessment-${get(
            target,
            'iid',
          )}`;

          const selfAssessmentTimeSend =
            selfAssessment !== null &&
            get(item, 'leader.__expand.temis.tudanhgia.updated_ts');
          const showSelfAssessmentTimeSend =
            isApprovedAssessment(selfAssessmentStatus) &&
            !get(item, 'leader.__expand.temis.tcnn_ht.status') &&
            selfAssessmentTimeSend;

          return (
            <DetailOnDialog
              renderPreview={({ showFull }) => {
                return (
                  <>
                    <PeerResult
                      result={resultFromPeer}
                      date={date}
                      showPopup={showFull}
                      isApproved={isApproved}
                      viewOnly={viewOnly}
                      hasSelfAssessment={hasSelfAssessment}
                      hasPeerAssessment={false}
                      userInfo
                    />
                    {showSelfAssessmentTimeSend && (
                      <div className="m-t-5">
                        <SendAssessmentResult
                          status
                          date={timestampToDateTimeString(
                            selfAssessmentTimeSend,
                          )}
                        />
                      </div>
                    )}
                  </>
                );
              }}
              renderFull={() => {
                return (
                  <PrincipalAssessment
                    target={target}
                    principalAssessmentDialogKey={principalAssessmentDialogKey}
                    searchFormId={searchFormId}
                  />
                );
              }}
              dialogKey={principalAssessmentDialogKey}
              dialogOptionsProperties={{
                title: 'Đánh giá hiệu trưởng',
                width: '100%',
                className: 'modal-full-height',
                handleClose: true,
              }}
            />
          );
        },
      },
    ];
  };
  render() {
    const {
      totalItems,
      paramsSearchForm,
      apiResponseData,
      viewOnly,
    } = this.props;
    const { selectedRowKeys } = this.state;
    const dataSource =
      apiResponseData && apiResponseData.length
        ? apiResponseData.map((item) => ({
            ...item,
            leaderId: get(item, 'leader.iid'),
          }))
        : [];
    const leaderIds = apiResponseData.map((item) => get(item, 'leader.iid'));
    let buttonText = 'Gửi cảnh báo';
    if (selectedRowKeys.length) {
      buttonText = `${buttonText} đến ${selectedRowKeys.length} người đã chọn`;
    }

    return (
      <div className="table-action">
        <CommonAntdTable
          columns={this.getColumns()}
          dataSource={dataSource}
          pagination={false}
          bordered
          size="middle"
          className="learn-default"
          rowKey="leaderId"
          rowSelection={{
            selectedRowKeys,
            hideDefaultSelections: true,
            onChange: this.setSelectedRowKeys,
            selections: [
              {
                key: 'select_current_page',
                text: t1('select_data_in_current_page'),
                onSelect: () => {
                  const currentSelectedRowKeys = Array.isArray(selectedRowKeys)
                    ? selectedRowKeys
                    : [];
                  this.setSelectedRowKeys(
                    currentSelectedRowKeys.concat(
                      leaderIds
                        .map(
                          (iid) => !currentSelectedRowKeys.includes(iid) && iid,
                        )
                        .filter(Boolean),
                    ),
                  );
                },
              },
              {
                key: 'invert_current_page',
                text: t1('invert_data_in_current_page'),
                onSelect: () => {
                  this.setSelectedRowKeys(
                    (Array.isArray(leaderIds) ? leaderIds : []).filter(
                      (iid) => !selectedRowKeys.includes(iid),
                    ),
                  );
                },
              },
              Array.isArray(selectedRowKeys) &&
                !!selectedRowKeys.length &&
                !selectedRowKeys.every((id) => leaderIds.includes(id)) && {
                  key: 'remove_all',
                  text: t1('remove_all_data_selected'),
                  onSelect: () => {
                    this.setSelectedRowKeys([]);
                  },
                },
            ].filter(Boolean),
            getCheckboxProps: (record) => {
              return {
                disabled: !record.leaderId,
              };
            },
          }}
        />

        <Communicate
          renderPreview={({ showFull }) => (
            <button
              className="btn btn-secondary m-t-10"
              disabled={viewOnly || (!totalItems && !selectedRowKeys.length)}
              onClick={showFull}
            >
              {buttonText}
            </button>
          )}
          optionsProperties={{
            title: 'Gửi thông báo cảnh báo đến hiệu trưởng',
          }}
          objectTitle={'Hiệu Trưởng'}
          totalWhenSelectAll={totalItems}
          paramsFilterWhenSelectAll={paramsSearchForm}
          iidSelected={selectedRowKeys}
        />
      </div>
    );
  }
}

export default withRouter(withUserInfo(TableResult));
