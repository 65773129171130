import React from 'react';
import get from 'lodash.get';
import { isPhong, isSo } from 'components/admin/user/utils';
import withTemisConfig from 'common/hoc/withTemisConfig';
import './style.scss';
import ExportData from 'components/common/export-data';
import { EXPORT_FILENAME, TCNN_TYPE } from 'components/temis/constant';
import { leaderPositions } from 'configs/constants/user';

const getTitle = (isResultOfHP, isResultOfHT) => {
  let content =
    'Kết quả xếp loại đánh giá giáo viên của cơ sở giáo dục phổ thông';
  if (isResultOfHP) {
    content = 'Kết quả xếp loại đánh giá Hiệu phó của cơ sở giáo dục phổ thông';
  } else if (isResultOfHT) {
    content =
      'Kết quả xếp loại đánh giá Hiệu Trưởng của cơ sở giáo dục phổ thông';
  }
  return content;
};

const getFileName = (isResultOfHP, isResultOfHT) => {
  let fileName = EXPORT_FILENAME.principal.GV;
  if (isResultOfHP) {
    fileName = EXPORT_FILENAME.principal.HP;
  } else if (isResultOfHT) {
    fileName = EXPORT_FILENAME.principal.HT;
  }
  return fileName;
};

const OverviewLayoutFreeStyle = (props) => {
  const fieldNames = get(props, 'groups.id.fieldNames') || {};
  const userRoot = get(props, 'userRoot');
  const scholastic = get(props, 'formValues.scholastic');
  const userIid = get(props, 'formValues.userIid');
  const phongbanId = get(props, 'formValues.phongban_id');
  const organizations = get(props, 'formValues.organizations');
  const isResultOfHP = get(props, 'layoutOptionsProperties.isResultOfHP');
  const isResultOfHT = get(props, 'layoutOptionsProperties.isResultOfHT');
  const isResultCBQL = isResultOfHP || isResultOfHT;
  return (
    <div>
      {!isResultOfHP && (
        <div className="row search-form-assessment">
          {(isPhong(userRoot) || isSo(userRoot)) &&
          fieldNames.assessment_of_organization_type ? (
            <div className="col-md-5">
              {fieldNames.assessment_of_organization_type}
            </div>
          ) : null}
          <div className="col-md-5">
            {isResultOfHT ? fieldNames.organizations : fieldNames.phongban_id}
          </div>
          <div className="col-md-2 text-center m-t-5">
            {' '}
            {get(props, 'submitButton')}
          </div>
        </div>
      )}

      <div
        className={`search-professional-groups d-flex justify-content-between align-items-center ${
          isPhong(userRoot) || isSo(userRoot) ? 'display-none' : ''
        }`}
      >
        <div>{`1. ${getTitle(isResultOfHP, isResultOfHT)}`}</div>
        <div className="text-right">
          <ExportData
            apiExport="/temis/export/export-result-assessment-of-teacher-in-organization"
            buttonText="Xuất excel"
            params={{
              scholastic,
              user_iid: userIid,
              leader_position: isResultOfHT
                ? leaderPositions.LEADER
                : isResultOfHP
                ? leaderPositions.VICE_LEADER
                : leaderPositions.TEACHER,
              ...(!isResultCBQL ? { phongban_id: phongbanId } : {}),
              ...(isResultCBQL ? { organizations: organizations } : {}),
            }}
            fileName={getFileName(isResultOfHP, isResultOfHT)}
          />
        </div>
      </div>
    </div>
  );
};

export default withTemisConfig(OverviewLayoutFreeStyle);
