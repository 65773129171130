import React from 'react';
import fetchData from 'components/common/fetchData';
import get from 'lodash.get';
import AttachmentViewer from 'components/common/forms/questions/open-ended/attachment-viewer';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import DetailOnDialog from 'components/common/detail-on-dialog';
import endPoints from '../../endpoints';

const TeamLeaderAssessOffline = ({
  assessOfflineResult,
  userForAssessment,
  buttonText = '',
}) => {
  const attachments = assessOfflineResult
    ? get(assessOfflineResult[0], 'attachments', [])
    : [];
  const ext = get(attachments, '[0].ext');
  const isPdf = ext === 'pdf' || ext === 'PDF';

  const userForAssessmentName = get(userForAssessment, 'name');

  return (
    <DetailOnDialog
      renderPreview={({ showFull }) => (
        <button
          type="button"
          className="text-center btn btn-secondary btn-outline m-r-15"
          onClick={() => showFull()}
        >
          {buttonText}
        </button>
      )}
      renderFull={() => {
        if (attachments.length) {
          return (
            <AttachmentViewer attachments={attachments} showHeader={false} />
          );
        }

        return (
          <SimpleNoResult text="Chưa có kết quả đánh giá của tổ chuyên môn." />
        );
      }}
      dialogKey="team_leader_assess_offline"
      dialogOptionsProperties={{
        title: `Kết quả đánh giá của tổ trưởng: ${userForAssessmentName}`,
        width: '100%',
        className: `team-leader-assess-offline-model ${
          isPdf ? 'custom-for-pdf' : ''
        }`,
      }}
    />
  );
};

const fetchTeamLeaderAssessOffline = fetchData((props) => {
  const userIid = get(props, 'userForAssessment.iid');
  return {
    baseUrl: endPoints.assessOfPeers,
    params: {
      rubric_iid: props.rubricToAssessment,
      useSuperiorAssessment: 1,
      user_iid: userIid,
    },
    propKey: 'assessOfflineResult',
    fetchCondition: true,
    refetchCondition: () => false,
  };
});

export default fetchTeamLeaderAssessOffline(TeamLeaderAssessOffline);
