import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getFormValues } from 'redux-form';
import { SCHOLASTIC_FORM_ID } from './index';
import { SCHOLASTIC_SELECT_FIELD_NAME } from './schema';

const withScholastic = (WrappedComponent) => {
  class ScholasticHOC extends Component {
    render() {
      const { scholastic } = this.props;

      return <WrappedComponent {...this.props} scholastic={scholastic} />;
    }
  }

  const mapStateToProps = (state) => {
    const formValues = getFormValues(SCHOLASTIC_FORM_ID)(state);
    return {
      scholastic: get(formValues, SCHOLASTIC_SELECT_FIELD_NAME, ''),
      deadlineAssessment: get(
        state,
        'scholasticConfig.config.deadline_assessment',
        '',
      ),
    };
  };

  return connect(mapStateToProps)(ScholasticHOC);
};

export default withScholastic;
