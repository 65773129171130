import './stylesheet.scss';
import React from 'react';
import { t1 } from 'translate';
import VideoPlayer from 'components/common/media-player/video';
import MicroSoftEmbedLink from 'components/common/MicroSoftEmbedLink';
import PdfViewer from './PdfViewer';
import ListAttachmentsToDownloadAsTable from 'components/common/attachment/ListAttachmentsToDownloadAsTable';

const cssClass = 'attachment-viewer';

export const isPicture = (fileExt) =>
  ['png', 'jpg', 'jpeg', 'gif'].includes(fileExt);

export const isPdf = (fileExt) => ['pdf'].includes(fileExt);

export const isVideo = (ext) =>
  ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'm3u8'].includes(ext);

export const isVideoCanViewOnline = (ext) => ['mp4', 'm3u8'].includes(ext);

export const isNotAllowedToDownload = (ext) => ['m3u8'].includes(ext);

export const isAudio = (ext) => ['mp3'].includes(ext);

export const isMicrosoftEmbedFile = (ext) =>
  ['docx', 'doc', 'pptx', 'ppt', 'xlsx', 'xls'].includes(ext);

export const fileCanPreview = (file) => {
  const ext = file.ext ? file.ext.toLowerCase() : '';
  return (
    isPicture(ext) ||
    isPdf(ext) ||
    isVideoCanViewOnline(ext) ||
    isMicrosoftEmbedFile(ext)
  );
};

export const getItemAttachment = (file) => {
  const ext = file.ext ? file.ext.toLowerCase() : '';
  let contentItem = null;
  let contentType = '';

  if (isPicture(ext)) {
    contentType = 'img';
    contentItem = (
      <img src={file.link} style={{ width: '100%', height: 'auto' }} alt="" />
    );
  } else if (isPdf(ext)) {
    contentType = 'pdf';
    contentItem = <PdfViewer link={file.link} />;
  } else if (isVideo(ext)) {
    contentType = 'video';
    contentItem = (
      <VideoPlayer controls url={file.link} autoPlay="true" width="100%" />
    );
  } else if (isMicrosoftEmbedFile(ext)) {
    contentType = ext;
    contentItem = (
      <>
        <div className={'m-t-25 m-b-10 '}>{file.name}</div>
        <MicroSoftEmbedLink link={file.link} />
      </>
    );
  }

  contentItem = contentItem && (
    <div className={`${cssClass}__item ${cssClass}__item--${contentType}`}>
      {contentItem}
    </div>
  );

  return { contentItem, contentType };
};

const AttachmentViewer = ({ attachments, showHeader, alwaysShowPreview }) => {
  let listFileCanDisplay = [];

  if (attachments && attachments.length) {
    listFileCanDisplay = attachments.filter((file) => {
      return fileCanPreview(file);
    });
  }

  return (
    <div className={cssClass}>
      {listFileCanDisplay.length === 1 || alwaysShowPreview
        ? listFileCanDisplay.map((file) => {
            return <>{getItemAttachment(file).contentItem}</>;
          })
        : null}
      {attachments && attachments.length ? (
        <div className={`${cssClass}__item`}>
          {showHeader ? (
            <h4 className="m-b-0 m-t-10">{t1('list_of_files_submitted')}</h4>
          ) : null}

          <ListAttachmentsToDownloadAsTable
            attachments={attachments}
            className={`${cssClass}__attachments`}
          />
        </div>
      ) : null}
    </div>
  );
};

export default AttachmentViewer;
