import React from 'react';
import SurveyReportQuestion from './Question';
import { types } from 'components/admin/question/schema/question-types';
import { USER_GROUP_ALL } from 'configs/constants/user-demographics';

const ReportAsChart = ({
  questions,
  surveyIid,
  trainingPlan,
  paramsToFilter,
}) => {
  if (!Array.isArray(questions) || !questions.length) {
    return null;
  }

  let stt = 0;
  return questions.map((question, index) => {
    if (question.type != types.TYPE_INTRODUCTION) {
      stt = stt + 1;
    }

    return (
      <SurveyReportQuestion
        stt={stt}
        question={question}
        surveyIid={surveyIid}
        key={`srq-${index}_${question.id}`}
        trainingPlan={trainingPlan}
        paramsToFilter={paramsToFilter}
        userDemographic={USER_GROUP_ALL}
      />
    );
  });
};

export default ReportAsChart;
