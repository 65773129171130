import React, { Component } from 'react';
import withUserInfo from 'common/hoc/withUserInfo';
import PeersAssessment from '../../../report/PeersAssessment';
import withScholastic from 'components/temis/components/scholastic/withScholastic';

class AssessmentsInOrganizationAssessmentMyPeers extends Component {
  render() {
    const { userInfo = {}, scholastic } = this.props;
    return (
      <PeersAssessment
        user={userInfo}
        scholastic={scholastic}
        useSuperiorAssessment
      />
    );
  }
}

export default withScholastic(
  withUserInfo(AssessmentsInOrganizationAssessmentMyPeers),
);
