import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Form from './Form';
import { t1 } from 'translate';
import RaisedButton from '../../../../common/mui/RaisedButton';

const NewEvidence = ({
  tieuChuanIid,
  tieuChiIid,
  searchFormId,
  onNewEvidence,
  assessmentEvidence,
  assessmentRubricIid,
}) => {
  const dialogKey = 'new_evidence_temis';

  const renderPreview = React.useCallback(({ showFull }) => {
    return (
      <div className="d-flex justify-content-end">
        <RaisedButton
          className="m-b-10 btn btn-secondary btn-outline"
          label="Thêm minh chứng mới"
          onClick={showFull}
        />
      </div>
    );
  }, []);
  const renderFull = React.useCallback(
    () => {
      return (
        <Form
          dialogKey={dialogKey}
          tieuChuanIid={tieuChuanIid}
          tieuChiIid={tieuChiIid}
          searchFormId={searchFormId}
          onNewEvidence={onNewEvidence}
          assessmentRubricIid={assessmentRubricIid}
        />
      );
    },
    [
      tieuChuanIid,
      tieuChiIid,
      searchFormId,
      onNewEvidence,
      assessmentRubricIid,
    ],
  );

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogKey={dialogKey}
      dialogOptionsProperties={{
        title: t1('new_assessment_evidence'),
        width: '80%',
        handleClose: true,
      }}
    />
  );
};

export default NewEvidence;
