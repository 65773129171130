import React, { Component } from 'react';
import { t1 } from 'translate';
import get from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import JobStandardResult from '../../../components/job-standard-result';
import ReviewResult from '../../../components/review-result';
import endPoints from '../../endpoints';
import NodeNew from 'components/admin/node/new';
import {
  leaderPositionToText,
  qualificationOptions,
} from 'configs/constants/user';
import { getResultFromTheAssessment } from '../../../utils';
import PreviewUserInDialog from 'components/admin/user/account/common/PreviewInDialog';
import { BECOME_MASTER_STATUS } from '../../../constant';
import AspirationMaster from 'components/temis/components/aspiration-master';

class TableResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }
  setSelectedRowKeys = (value) => {
    this.setState({
      selectedRowKeys: value,
    });
  };
  getColumns = (searchFormId, viewOnly) => {
    const { scholastic } = this.props;
    return [
      {
        title: 'Thông tin giáo viên',
        width: 240,
        key: 'name',
        render: (name, item) => {
          return (
            <div>
              <PreviewUserInDialog
                user={item}
                showFullDetailButton={false}
                hiddenItem={['positions']}
                field="name"
              />
              <br />
              <span className="text-faded">{item.mail}</span>
            </div>
          );
        },
      },
      {
        title: 'Bộ môn',
        key: 'subject',
        render: (subject, item) => {
          return get(item, '__expand.phongbans[0].name');
        },
      },
      {
        title: t1('organizations'),
        key: 'organizations',
        width: 140,
        render: (organizations, item) => {
          return (
            <div className="organizations">
              <div className="organizations-name">{item.organization_name}</div>
            </div>
          );
        },
      },
      {
        title: 'Cấp trên duyệt',
        width: 200,
        className: 'text-center',
        key: 'become_master_status',
        render: (status, item) => (
          <ReviewResult
            searchFormId={searchFormId}
            status={get(
              item,
              'become_master_status',
              BECOME_MASTER_STATUS.SENT,
            )}
            item={item}
            viewOnly={viewOnly}
            scholastic={scholastic}
          />
        ),
      },
      {
        title: 'Chức vụ',
        key: 'position',
        className: 'text-center',
        render: (position, item) => {
          return leaderPositionToText(get(item, 'leader_position'));
        },
      },
      {
        title: 'Trình độ',
        className: 'text-center',
        key: 'level',
        render: (level, item) => {
          const qualification = qualificationOptions().find(
            ({ value }) => value === get(item, 'qualifications'),
          );
          return get(qualification, 'label');
        },
      },
      {
        title: 'Năm KN',
        key: 'position',
        className: 'text-center',
        render: (experience, item) => get(item, 'teaching_exp_years'),
      },
      {
        title: 'TCNN',
        key: 'tcnn',
        render: (tcnn, item) => {
          const assessment = get(item, '__expand.temis.tcnn_gv');

          const result =
            get(assessment, 'status') === 'approved' &&
            getResultFromTheAssessment(assessment);

          if (!result) {
            return null;
          }

          return JobStandardResult(
            get(result, 'id', 0),
            get(result, 'name', 'Chưa đạt'),
          );
        },
      },
      {
        title: 'Nguyện vọng GVCC/CBQLCC',
        key: 'have_desire_to_become_master',
        width: 130,
        render: (item) => {
          return <AspirationMaster item={item} />;
        },
      },
    ];
  };
  render() {
    const { apiResponseData, searchFormId, viewOnly, scholastic } = this.props;
    const { selectedRowKeys } = this.state;
    const principalIds = apiResponseData.map(({ iid = '' }) => iid);
    let buttonText = 'Duyệt GVCC/CBQLCC';
    let buttonCancel = 'Từ chối GVCC/CBQLCC';

    if (selectedRowKeys.length) {
      buttonText = `Duyệt ${selectedRowKeys.length} GVCC/CBQLCC`;
      buttonCancel = `Từ chối ${selectedRowKeys.length} GVCC/CBQLCC`;
    }

    return (
      <div className="table-action">
        <CommonAntdTable
          columns={this.getColumns(searchFormId, viewOnly)}
          dataSource={apiResponseData}
          pagination={false}
          bordered
          size="middle"
          className="learn-default"
          rowKey="iid"
          rowSelection={{
            selectedRowKeys,
            hideDefaultSelections: true,
            onChange: this.setSelectedRowKeys,
            selections: [
              {
                key: 'select_current_page',
                text: t1('select_data_in_current_page'),
                onSelect: () => {
                  const currentSelectedRowKeys = Array.isArray(selectedRowKeys)
                    ? selectedRowKeys
                    : [];
                  this.setSelectedRowKeys(
                    currentSelectedRowKeys.concat(
                      principalIds
                        .map(
                          (iid) => !currentSelectedRowKeys.includes(iid) && iid,
                        )
                        .filter(Boolean),
                    ),
                  );
                },
              },
              {
                key: 'invert_current_page',
                text: t1('invert_data_in_current_page'),
                onSelect: () => {
                  this.setSelectedRowKeys(
                    (Array.isArray(principalIds) ? principalIds : []).filter(
                      (iid) => !selectedRowKeys.includes(iid),
                    ),
                  );
                },
              },
              Array.isArray(selectedRowKeys) &&
                !!selectedRowKeys.length &&
                !selectedRowKeys.every((id) => principalIds.includes(id)) && {
                  key: 'remove_all',
                  text: t1('remove_all_data_selected'),
                  onSelect: () => {
                    this.setSelectedRowKeys([]);
                  },
                },
            ].filter(Boolean),
          }}
        />
        <div className="d-flex">
          <NodeNew
            schema={{}}
            formid="approve-teacher"
            searchFormId={searchFormId}
            hiddenFields={{
              iids: selectedRowKeys,
              become_master_status: BECOME_MASTER_STATUS.APPROVED,
              scholastic,
            }}
            alternativeApi={endPoints.chooseUsersToBecomeMaster}
            submitButton={() => {
              return (
                <div className="d-flex">
                  <button
                    className="btn btn-secondary m-t-10"
                    disabled={!selectedRowKeys.length || viewOnly}
                  >
                    {buttonText}
                  </button>
                </div>
              );
            }}
          />

          <NodeNew
            schema={{}}
            formid="reject-teacher"
            searchFormId={searchFormId}
            hiddenFields={{
              iids: selectedRowKeys,
              become_master_status: BECOME_MASTER_STATUS.REJECTED,
              scholastic,
            }}
            alternativeApi={endPoints.chooseUsersToBecomeMaster}
            submitButton={() => {
              return (
                <div className="d-flex">
                  <button
                    className="btn btn-tertiary m-t-10 m-l-10"
                    disabled={!selectedRowKeys.length || viewOnly}
                  >
                    {buttonCancel}
                  </button>
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default TableResult;
