import React from 'react';
import DateTimePicker from 'schema-form/elements/date-time-picker';
import { UiLibs } from 'configs/constants';
import { simpleOrganization } from 'components/common/elements';
import subTypes from 'configs/constants/org-sub-types';
import { isBo } from 'components/admin/user/utils';
import { t1 } from 'translate';

const schema = (formid, values, step, xpath, props) => {
  return {
    name: {
      type: 'text',
      floatingLabelText: 'Tìm theo tên',
      classWrapper: 'col-md-6',
    },
    sgd: simpleOrganization(
      {
        floatingLabelText: 'SGD đã mua',
        classWrapper: 'col-md-6',
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_SO_GD],
        },
      },
      false,
      {
        value: '',
        label: 'Tất cả các đơn vị',
      },
    ),
    pgd: simpleOrganization(
      {
        floatingLabelText: 'PGD đã mua',
        classWrapper: 'col-md-6',
      },
      {
        value: {
          pIids: [values.sgd],
          sub_type: [subTypes.TAPHUAN_SUBTYPE_PHONG_GD],
        },
        key: `pgd-of-${values.sgd}`,
      },
    ),
    seller_organization_iid: simpleOrganization(
      {
        floatingLabelText: 'Đơn vị bán',
        classWrapper: isBo(props.userInfo) ? 'col-md-12' : 'display-none',
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_LTTU],
        },
      },
      false,
      {
        value: '',
        label: 'Tất cả đơn vị',
      },
    ),
    start_date: {
      type: DateTimePicker,
      uiLib: UiLibs.ANT,
      floatingLabelText: 'Thời gian bắt đầu',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    end_date: {
      type: DateTimePicker,
      uiLib: UiLibs.ANT,
      floatingLabelText: 'Thời gian kết thúc',
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    q_program: {
      type: 'text',
      hintText: t1('input_program_name_or_code'),
      floatingLabelText: t1('input_program_name_or_code'),
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
    q_deal: {
      type: 'text',
      hintText: t1('input_deal_name_or_code'),
      floatingLabelText: t1('input_deal_name_or_code'),
      fullWidth: true,
      classWrapper: 'col-md-6',
    },
  };
};

const ui = () => {
  return [
    {
      id: 'id',
      fields: [
        'seller_organization_iid',
        'sgd',
        'pgd',
        'start_date',
        'end_date',
        'q_deal',
        'q_program',
      ],
    },
  ];
};

export default {
  schema,
  ui,
};
