import React, { useEffect, useState } from 'react';
import Button from 'antd/lib/button';
import fetchData from '../../fetchData';
import Alert from 'antd/lib/alert';
import Loading from 'components/common/loading';
import { t, t1, t3, t4 } from 'translate';
import { downloadFileFromUrl } from 'common/utils/File';
import Tooltip from 'antd/lib/tooltip';
import Checkbox from 'antd/lib/checkbox';
import { timestampToDateString } from 'common/utils/Date';
import lodashGet from 'lodash.get';
import { optionsGetBackgroundableFunctionResult } from 'configs/constants';

const AlertToRetryDownload = ({
  params = {},
  handleRefetch,
  closeDialog,
  message,
  executeRealtime = false,
  fetched = false,
}) => {
  const [realtime, setFetchingRealtime] = useState(
    !fetched && !!executeRealtime,
  );

  return (
    <div>
      <Alert
        message={`${t3('error')} !`}
        description={message || t1('some_downloaded_file_failed')}
        type="error"
      />
      {!!executeRealtime && (
        <div className="m-t-20">
          <Checkbox
            checked={realtime}
            onChange={(e) =>
              setFetchingRealtime(lodashGet(e, 'target.checked'))
            }
          >
            {t1('realtime_start_retry_download')}
          </Checkbox>
        </div>
      )}
      <div className="m-t-20 row m-r-5">
        <div className="pull-right">
          <Button
            type="primary"
            ghost
            onClick={() => {
              handleRefetch({
                ...params,
                option_fetching: realtime
                  ? optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE
                  : optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE_IN_BACKGROUNDABLE,
              });
            }}
          >
            {t1('retry_download')}
          </Button>
          <Button className="m-l-20" onClick={closeDialog}>
            {t1('cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const AlertAwaitingDownload = ({
  closeDialog,
  handleRefetch,
  params,
  info = {},
  executeRealtime = false,
}) => {
  useEffect(
    () => {
      const timer = setTimeout(() => {
        handleRefetch({
          ...params,
          option_fetching:
            optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_GET_CACHE_ONLY,
        });
      }, 10 * 1000);
      return () => clearTimeout(timer);
    },
    [handleRefetch, params],
  );

  const { ts, url, last_time_start_download } = info;

  const tsStartedDownloading = Date.now() - last_time_start_download * 1000;

  return (
    <div>
      <Alert
        message={t1(
          'is_in_the_process_of_rebuilding_file.please_download_after_waiting_a_few_minutes',
        )}
        description={
          <div className="text-center m-t-10">
            <Loading circularLoadingIcon />
            <p> {t1('downloading...')}</p>
          </div>
        }
        type="info"
        showIcon
      />
      <div className="row m-t-20 m-r-5">
        <div className="pull-right">
          {!!last_time_start_download && tsStartedDownloading > 10 * 60 * 1000 && (
            <Button
              type="primary"
              ghost
              onClick={() => {
                handleRefetch({
                  ...params,
                  option_fetching: executeRealtime
                    ? optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE
                    : optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE_IN_BACKGROUNDABLE,
                });
              }}
            >
              {t1('retry_download')}
            </Button>
          )}
          <Button className="m-l-20" onClick={closeDialog}>
            {t1('cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const AlertIsUnderHeavyLoad = ({
  closeDialog,
  handleRefetch,
  params,
  executeRealtime = false,
}) => {
  return (
    <div>
      <Alert
        message={t1('recommendation_download_file')}
        description={t1(
          'the_website_is_under_heavy_load.please_return_to_download_the_file_at_another_time',
        )}
        type="error"
        showIcon
      />

      <div className="m-t-20 row m-r-5">
        <div className="pull-right">
          <Button
            type="primary"
            ghost
            onClick={() => {
              handleRefetch({
                ...params,
                option_fetching:
                  optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE_IN_BACKGROUNDABLE,
              });
            }}
          >
            {t1('retry_download')}
          </Button>
          <Button className="m-l-20" onClick={closeDialog}>
            {t1('cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const AlertFileReadyToDownload = ({
  closeDialog,
  onDownloadFile,
  fileName,
  info,
  handleRefetch,
  params,
  executeRealtime = false,
}) => {
  const [resynchronizeFile, setResynchronizeFile] = useState(null);
  const { ts, url, last_time_start_download } = info;

  const downloadNow = () => {
    closeDialog();

    if (typeof onDownloadFile === 'function') {
      onDownloadFile({
        fileName,
        url,
      });
    }

    downloadFileFromUrl({
      fileName,
      downloadUrl: url,
    });
  };

  useEffect(
    () => {
      if (last_time_start_download && !executeRealtime) {
        const timer = setTimeout(() => {
          handleRefetch({
            ...params,
            option_fetching:
              optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_GET_CACHE_ONLY,
          });
        }, 10 * 1000);
        return () => clearTimeout(timer);
      }
    },
    [handleRefetch, params, last_time_start_download, executeRealtime],
  );

  useEffect(
    () => {
      if (resynchronizeFile && resynchronizeFile != ts) {
        setResynchronizeFile(false);
      }
    },
    [resynchronizeFile, setResynchronizeFile, ts],
  );

  if (last_time_start_download || resynchronizeFile) {
    const tsStartedDownloading = Date.now() - last_time_start_download * 1000;

    return (
      <div>
        <Alert
          message={t1(
            'is_in_the_process_of_rebuilding_file.please_download_after_waiting_a_few_minutes',
          )}
          description={
            <div className="text-center m-t-10">
              <Loading circularLoadingIcon />
              <p> {t4('downloading...')}</p>

              {!resynchronizeFile && tsStartedDownloading > 10 * 60 * 1000 && (
                <div className="text-center">
                  <Button
                    type="primary"
                    ghost
                    onClick={() =>
                      handleRefetch({
                        ...params,
                        option_fetching: executeRealtime
                          ? optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE
                          : optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE_IN_BACKGROUNDABLE,
                      })
                    }
                  >
                    {t1('retry_download')}
                  </Button>
                </div>
              )}
            </div>
          }
          type="warning"
          showIcon
        />

        <div className="m-t-10">
          <p>
            {t1('can_old_file_is_downloaded_while_downloading_from_server')}{' '}
            <span onClick={downloadNow} style={{ cursor: 'pointer' }}>
              <Tooltip
                title={
                  ts
                    ? `${t1(
                        'last_time_finished_rebuilding_file',
                      )}: ${timestampToDateString(ts, { showTime: false })}`
                    : null
                }
              >
                <strong>{t('download_here')}</strong>
              </Tooltip>
            </span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Alert
        message={t1('the_file_are_ready_to_download.')}
        description={
          <div className="text-center m-t-20">
            {!!ts && (
              <p className="m-t-5">
                {`(${t1(
                  'last_time_finished_rebuilding_file',
                )}: ${timestampToDateString(ts, { showTime: false })})`}
              </p>
            )}
          </div>
        }
        type="success"
        showIcon
      />

      <div className="m-t-15 text-center">
        <Button
          type="primary"
          icon="sync"
          className="m-5"
          ghost
          onClick={() => {
            if (executeRealtime) {
              setResynchronizeFile(ts);
            }

            handleRefetch({
              ...params,
              option_fetching: executeRealtime
                ? optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE
                : optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE_IN_BACKGROUNDABLE,
            });
          }}
        >
          {t1('resynchronize_the_latest_file')}
        </Button>

        <Button
          type="primary"
          icon="download"
          danger
          onClick={downloadNow}
          className="m-5"
        >
          {t1('download')}
        </Button>
      </div>
    </div>
  );
};

const alertStartDownload = ({
  params,
  handleRefetch,
  closeDialog,
  executeRealtime,
}) => {
  return (
    <div>
      <Alert
        message={t1('warning')}
        description={t1(
          'when_click_start_downloading,it_takes_time_to_rebuild_the_temporary_file',
        )}
        type="warning"
        showIcon
      />
      <div className="row m-t-20 m-r-5">
        <div className="pull-right">
          <Button
            type="primary"
            onClick={() => {
              handleRefetch({
                ...params,
                option_fetching: executeRealtime
                  ? optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE
                  : optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_REFETCH_CACHE_IN_BACKGROUNDABLE,
              });
            }}
          >
            {t1('start_downloading')}
          </Button>
          <Button className="m-l-20" onClick={closeDialog}>
            {t1('cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const DownloadFileFromCache = ({
  params,
  fileName,
  loadingStatus,
  refetchFileInfoFromCache,
  fetchFileInfo = {},
  closeDialog,
  onDownloadFile,
  executeRealtime = false,
}) => {
  const [fetched, setFetchingStatus] = useState(false);

  const handleRefetch = (newParams) => {
    setFetchingStatus(true);
    refetchFileInfoFromCache(newParams);
  };

  return (
    <div style={{ padding: 15 }}>
      {(() => {
        const { success, result, objects, message } = fetchFileInfo;

        if (loadingStatus !== 'finished' && fetched) {
          return (
            <AlertAwaitingDownload
              closeDialog={closeDialog}
              handleRefetch={handleRefetch}
              params={params}
              executeRealtime={executeRealtime}
            />
          );
        }
        if (loadingStatus !== 'finished' && !(result || objects)) {
          return (
            <div className="text-center">
              <Loading circularLoadingIcon />
              <p> {t1('loading...')}</p>
            </div>
          );
        }

        if (!success || (!result && !objects && fetched)) {
          return (
            <AlertToRetryDownload
              params={params}
              handleRefetch={handleRefetch}
              closeDialog={closeDialog}
              message={message}
              executeRealtime={executeRealtime}
              fetched={fetched}
            />
          );
        }

        if (!result && !objects) {
          return alertStartDownload({
            params,
            handleRefetch,
            closeDialog,
            executeRealtime,
          });
        }

        const info = Object.assign({}, objects, result);

        if (info.url) {
          return (
            <AlertFileReadyToDownload
              closeDialog={closeDialog}
              onDownloadFile={onDownloadFile}
              fileName={fileName}
              info={info}
              handleRefetch={handleRefetch}
              params={params}
              executeRealtime={executeRealtime}
            />
          );
        } else if (info.last_time_start_download) {
          return (
            <AlertAwaitingDownload
              closeDialog={closeDialog}
              handleRefetch={handleRefetch}
              params={params}
              info={info}
              executeRealtime={executeRealtime}
            />
          );
        }

        return (
          <AlertIsUnderHeavyLoad
            closeDialog={closeDialog}
            handleRefetch={handleRefetch}
            params={params}
            executeRealtime={executeRealtime}
          />
        );
      })()}
    </div>
  );
};

const fetchFileInfoFromCache = fetchData(({ url, params = {} }) => {
  return {
    baseUrl: url,
    getFullResponse: true,
    fetchCondition: true,
    params: {
      ...params,
      option_fetching:
        optionsGetBackgroundableFunctionResult.GET_BACKGROUNDABLE_FUNCTION_RESULT_GET_CACHE_ONLY,
    },
    propKey: 'fetchFileInfo',
    renderLoadingComponent: () => false,
    method: 'post',
    fetchFunctionPropKey: 'refetchFileInfoFromCache',
  };
});

export default fetchFileInfoFromCache(DownloadFileFromCache);
