import { cbqlUrl } from '../root-url';
import { stringify } from 'query-string';
import { teacherMenuItems } from 'routes/teacher/util';

export const getCBQLMenuItem = (id) => {
  return `${cbqlUrl}/${id}`;
};

export const getCBQLApproveSupportPlanUrl = ({ module, statuses } = {}) => {
  const queryStr = stringify({
    module,
    statuses,
  });

  return `${getCBQLMenuItem(teacherMenuItems.APPROVE_SUPPORT_PLAN)}${
    queryStr ? `?${queryStr}` : ''
  }`;
};

export const getCBQLApproveSupportPlanResultUrl = ({
  school_year,
  statuses,
} = {}) => {
  const queryStr = stringify({
    school_year,
    statuses,
  });

  return `${getCBQLMenuItem(teacherMenuItems.APPROVE_RESULT_PLAN)}${
    queryStr ? `?${queryStr}` : ''
  }`;
};
