import schoolYear from './school-year';
import semester from './semester';
import {
  reviewStatusOptions,
  TARGET_USER,
  targetUserOptions,
  userGradeOptions,
  userGrades,
  validOptions,
} from 'configs/constants/user';
import { t1 } from 'translate';
import get from 'lodash.get';
import { applicableProjectOptions } from 'configs/constants/project';
import { chuongTrinhOptions } from 'components/bdtx/configs';
import {
  TRAINING_MODEL,
  trainingModelOptions,
} from 'components/taphuan/configs';
import { required } from 'common/validators';
import { BGD_ORG_IID } from 'components/admin/organization/utils';
import { unaccentVietnamese } from 'common/utils/string/vn';
import { getSchoolYearOfCurrentUser } from 'components/common/school-year-config/utils';
import apiUrls from 'components/temis/assessment/endpoints';
import { populateDefaultBdtxParams } from 'common/utils/url';

export const caphoc = (props = {}) => {
  let defaultValue;
  if (props.type === 'radio' || (props.type === 'select' && !props.multiple)) {
    defaultValue = userGrades.PRIMARY;
  } else {
    defaultValue = [userGrades.PRIMARY];
  }

  return {
    type: 'multiCheckbox',
    inline: true,
    options: userGradeOptions(),
    floatingLabelText: t1('user_grade'),
    // defaultValue,
    ...props,
  };
};

export const targetUser = (props = {}) => {
  let defaultValue = props.defaultValue;

  if (typeof defaultValue == 'undefined') {
    if (
      props.type === 'radio' ||
      (props.type === 'select' && !props.multiple)
    ) {
      defaultValue = TARGET_USER.GVPT;
    } else {
      defaultValue = [TARGET_USER.GVPT];
    }
  }

  return {
    type: 'multiCheckbox',
    inline: true,
    options: targetUserOptions(),
    floatingLabelText: t1('target_user'),
    defaultValue,
    ...props,
  };
};

export const tieuChiByCapAndTargetUsers = (props = {}) => {
  const tieuChiByCapAndTargetUserProps = get(
    props,
    'tieuChiByCapAndTargetUserProps',
    {},
  );

  const targetUsers = get(props, 'targetUsers', []);
  const cap = get(props, 'cap');

  return {
    floatingLabelText: 'Nhu cầu BDTX',
    type: 'section',
    schema: {
      schema: () => {
        return {
          gv: tieuChiByCapAndTargetUser({
            floatingLabelText: 'Nhu cầu BDTX dành cho GV',
            target_user: 'gv',
            cap,
            ...tieuChiByCapAndTargetUserProps,
          }),
          cbql: tieuChiByCapAndTargetUser({
            floatingLabelText: 'Nhu cầu BDTX dành cho CBQL',
            target_user: 'cbql',
            cap,
            ...tieuChiByCapAndTargetUserProps,
          }),
        };
      },
      ui: () => {
        const fields = targetUsers.map((targetUser) => targetUser);

        return [
          {
            id: 'default',
            fields: fields || ['gv'],
          },
        ];
      },
    },
  };
};

export const tieuChiByCapAndTargetUser = (props = {}) => {
  const readOnly = get(props, 'readOnly');
  const targetUser = get(props, 'target_user');
  const cap = get(props, 'cap');

  return {
    type: 'select',
    multiple: true,
    options: 'async',
    paramsasync: {
      __url__: apiUrls.get_tieu_chi,
      value: {
        target_user: targetUser,
        cap,
      },
      transformData: (options) => {
        if (!Array.isArray(options) || !options.length) {
          return [];
        }
        return options.map(({ iid, name }) => ({
          value: iid,
          label: name,
          primaryText: name,
        }));
      },
      key: `contents-for-${targetUser}-${cap}`,
    },
    floatingLabelText:
      'Nội dung đăng ký học tập, bồi dưỡng (Các năng lực cần ưu tiên cải thiện)',
    disabled: readOnly,
    ...props,
  };
};

export const modules = (props = {}) => {
  const {
    status,
    schoolYear,
    chuong_trinh,
    target_users,
    isBdtx,
    reportOrgIids,
  } = props;

  let searchParams = {
    __expand: ['organizations'],
    items_per_page: -1,
  };

  if (status) {
    searchParams = {
      ...searchParams,
      status,
    };
  }

  if (schoolYear) {
    searchParams = {
      ...searchParams,
      school_years: Array.isArray(schoolYear) ? schoolYear : [schoolYear],
    };
  }

  if (chuong_trinh) {
    searchParams = {
      ...searchParams,
      chuong_trinh,
    };
  }

  if (target_users) {
    searchParams = {
      ...searchParams,
      target_users: (target_users || []).filter(
        (targetUser) => targetUser === 'gv' || targetUser === 'cbql',
      ),
    };
  }

  if (reportOrgIids) {
    searchParams = {
      ...searchParams,
      report_org_iids: reportOrgIids,
    };
  }

  return {
    type: 'select',
    floatingLabelText: t1('module'),
    options: 'async',
    fullWidth: true,
    multiple: true,
    paramsasync: {
      __url__: '/module/schema-form/search',
      value: isBdtx ? populateDefaultBdtxParams(searchParams) : searchParams,
      transformData: (data) => {
        if (!Array.isArray(data) || !data.length) {
          return [];
        }
        let modules = data.map((item) => ({
          value: get(item, 'iid'),
          label: `(${get(item, 'org_name')}) ${get(item, 'name')}`,
        }));

        return modules;
      },
      key: `modules-for-program-${
        Array.isArray(props.target_users) ? props.target_users.join('-') : ''
      }-${
        Array.isArray(props.chuong_trinh)
          ? props.chuong_trinh.join('-')
          : props.chuong_trinh
      }-${getSchoolYearOfCurrentUser()}-${(
        searchParams.school_years || []
      ).join('-')}-${(reportOrgIids || []).join('-')}`,
    },
    showSearch: true,
    filterOption: (input, option) => {
      const children = get(option, 'props.children', '');
      return (
        unaccentVietnamese(children)
          .toLowerCase()
          .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0
      );
    },
    ...props,
  };
};

export const listSchoolYear = (props = {}) => {
  const { multiple = true, getSchoolYearForRegistration, ...rest } = props;
  let defaultSchoolYear = multiple
    ? [window.DEFAULT_SCHOOL_YEAR]
    : window.DEFAULT_SCHOOL_YEAR;

  let url = '/school-year/api/list';
  if (getSchoolYearForRegistration) {
    url = '/school-year/api/get-school-years-for-registration';

    // always get first school year of list school years registration
    defaultSchoolYear = undefined;
  }

  return {
    type: 'select',
    floatingLabelText: t1('school_year'),
    options: 'async',
    fullWidth: true,
    multiple,
    populateValue: true,
    paramsasync: {
      __url__: url,
      transformData: (data) => {
        if (!Array.isArray(data) || !data.length) {
          return [];
        }

        return data.map((item) => ({
          value: get(item, 'id'),
          primaryText: get(item, 'name'),
        }));
      },
    },
    defaultValue: defaultSchoolYear,
    ...rest,
  };
};

export const applicableProjects = (props = {}) => ({
  type: 'select',
  floatingLabelText: t1('applicable_projects'),
  options: applicableProjectOptions(),
  fullWidth: true,
  multiple: true,
  ...props,
});

export const reviewStatus = (props = {}) => ({
  type: 'multiCheckbox',
  options: reviewStatusOptions(),
  inline: true,
  floatingLabelText: t1('status'),
  ...props,
});

export const valid = (props = {}) => ({
  type: 'multiCheckbox',
  options: validOptions(),
  inline: true,
  floatingLabelText: t1('valid'),
  ...props,
});

export const chuong_trinh = (props = {}, ctConfig = {}) => ({
  type: 'multiCheckbox',
  options: chuongTrinhOptions(ctConfig),
  inline: true,
  floatingLabelText: 'Chương trình',
  ...props,
});

export const simpleOrganization = (
  elementProps = {},
  paramsasyncProps = {},
  isRequired,
  allOrganizationOption,
) => {
  const { value = {}, key, transformData } = paramsasyncProps;

  const defaultTransformData = (organizations) => {
    if (!organizations || !organizations.length) {
      return [];
    }

    const orgFormatted = organizations.map((org) => ({
      value: org.iid,
      label: org.name,
    }));

    if (allOrganizationOption && typeof allOrganizationOption === 'object') {
      return [allOrganizationOption].concat(orgFormatted);
    }

    return orgFormatted;
  };

  return {
    type: 'select',
    floatingLabelText: `Đơn vị ${isRequired ? '(*)' : ''}`,
    options: 'async',
    paramsasync: {
      __url__: '/organization/api/search',
      value: {
        type: 'organization',
        pIids: [BGD_ORG_IID],
        include_sub_organizations: 1,
        view: 'grid',
        items_per_page: -1,
        depth: -1,
        ...value,
      },
      transformData:
        typeof transformData === 'function'
          ? transformData
          : defaultTransformData,
      key,
    },
    validate: isRequired ? [required()] : [],
    showSearch: true,
    filterOption: (input, option) => {
      const children = get(option, 'props.children', '');
      return (
        unaccentVietnamese(children)
          .toLowerCase()
          .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0
      );
    },
    ...elementProps,
  };
};

export const trainingModel = (props = {}) => ({
  type: 'radio',
  inline: true,
  options: trainingModelOptions(),
  floatingLabelText: t1('training_model'),
  defaultValue: TRAINING_MODEL.DAI_TRA,
  ...props,
});

export const trainingPhase = (
  props = {},
  paramsasyncProps = {},
  values = {},
) => ({
  type: 'select',
  floatingLabelText: t1('training_phase'),
  options: 'async',
  fullWidth: true,
  multiple: false,
  populateValue: true,
  paramsasync: {
    __url__: 'bdtx/training-phase/schema-form/list',
    transformData: (data) => {
      if (!Array.isArray(data) || !data.length) {
        return [];
      }

      return data.map((item) => ({
        value: get(item, 'iid'),
        label: get(item, 'name'),
      }));
    },
    key: `training-phase-${get(values, 'organization')}`,
    ...paramsasyncProps,
  },
  ...props,
});

export const capByTrainingPhase = (
  props = {},
  paramsasyncProps = {},
  values = {},
) => {
  return {
    type: 'select',
    floatingLabelText: t1('user_grade'),
    options: 'async',
    fullWidth: true,
    multiple: true,
    populateValue: true,
    paramsasync: {
      __url__: 'bdtx/training-phase/schema-form/get-caps',
      value: {
        training_phase: get(values, 'training_phase'),
      },
      transformData: (data) => {
        if (!Array.isArray(data) || !data.length) {
          return [];
        }

        return data.map((item) => ({
          value: get(item, 'value'),
          label: get(item, 'label'),
        }));
      },
      ...paramsasyncProps,
    },
    ...props,
  };
};

export const targetUserWithPersonalOrientationSkill = (props = {}) => {
  const personalOrientationProps = get(props, 'personalOrientationProps', {});
  const userIid = get(props, 'userInfo.iid');
  const targetUsers = get(props, 'target_users', []);

  return {
    floatingLabelText: 'Nhu cầu BDTX',
    type: 'section',
    schema: {
      schema: () => {
        return {
          gv: personalOrientationContents({
            floatingLabelText: 'Nhu cầu BDTX dành cho GV',
            user_iid: userIid,
            target_user: 'gv',
            ...personalOrientationProps,
          }),
          cbql: personalOrientationContents({
            floatingLabelText: 'Nhu cầu BDTX dành cho CBQL',
            user_iid: userIid,
            target_user: 'cbql',
            ...personalOrientationProps,
          }),
        };
      },
      ui: () => {
        const fields = targetUsers.map((targetUser) => targetUser);

        return [
          {
            id: 'default',
            fields: fields || ['gv'],
          },
        ];
      },
    },
  };
};

export const personalOrientationContents = (props = {}) => {
  const readOnly = get(props, 'readOnly');
  const targetUser = get(props, 'target_user');

  return {
    type: 'select',
    multiple: true,
    options: 'async',
    paramsasync: {
      __url__: '/ncbdtx/schema-form/get-personal-orientation-content',
      value: {
        target_user: targetUser,
      },
      transformData: (options) => {
        if (!Array.isArray(options) || !options.length) {
          return [];
        }
        return options.map(({ iid, name }) => ({
          value: iid,
          label: name,
          primaryText: name,
        }));
      },
      key: `contents-for-${targetUser}`,
    },
    floatingLabelText:
      'Nội dung đăng ký học tập, bồi dưỡng (Các năng lực cần ưu tiên cải thiện)',
    disabled: readOnly,
    ...props,
  };
};

export const courseList = (
  props = {},
  paramsasyncProps = {},
  formValues = {},
  includeAllOption = true,
) => {
  const { value, ...remainParamsasyncProps } = paramsasyncProps;
  const trainingPlanCategory = get(formValues, 'training_plan_category');
  const isActive = get(formValues, 'is_active');

  return {
    type: 'select',
    floatingLabelText: t1('course_name'),
    options: 'async',
    fullWidth: true,
    multiple: false,
    populateValue: true,
    paramsasync: {
      __url__: '/course/my',
      value: {
        status: ['approved'],
        is_active: typeof isActive === 'undefined' ? 1 : isActive,
        training_plan_category: trainingPlanCategory,
        items_per_page: 1000, // just in case -1 will return too much courses
        ...value,
      },
      transformData: (courses) => {
        if (!Array.isArray(courses) || !courses.length) {
          return [];
        }

        let dataTransformed = includeAllOption
          ? [
              {
                value: '',
                label: t1('all_courses'),
              },
            ]
          : [];

        dataTransformed = dataTransformed.concat(
          (courses || []).map((course) => ({
            value: String(course.iid),
            label: course.name,
          })),
        );

        return dataTransformed;
      },
      key: `course-list-by-${trainingPlanCategory}-is-active-${isActive}`,
      ...remainParamsasyncProps,
    },
    ...props,
  };
};

export default { schoolYear, semester };
