import React, { useCallback } from 'react';
import get from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import InputNumber from 'antd/lib/input-number';
import { RESULT_LEVELS } from '../../util';

const TableForm = ({
  dataSource,
  answers = RESULT_LEVELS,
  onBlur,
  value = [],
  floatingLabelText,
  readOnly,
}) => {
  const getItemById = useCallback(
    (id) => {
      if (!Array.isArray(value) || !value.length) {
        return undefined;
      }

      return value.find((v) => v.iid === id);
    },
    [value],
  );

  const getInputDefaultValue = useCallback(
    (id, answerId) => {
      const currentItem = getItemById(id);
      if (currentItem) {
        return get(currentItem, `value.${answerId}`, '');
      }

      return '';
    },
    [getItemById],
  );

  const onInputChange = useCallback(
    (iid, answerId, inputValue) => {
      let newValue = [];
      if (getItemById(iid)) {
        newValue = value.map((v) => {
          if (iid === v.iid) {
            const currentItemValue = get(v, 'value', {});
            return {
              ...v,
              value: {
                ...currentItemValue,
                [answerId]: inputValue,
              },
            };
          }

          return v;
        });
      } else {
        const newItem = {
          iid,
          value: {
            [answerId]: inputValue,
          },
        };
        newValue = [...value, newItem];
      }

      onBlur(newValue);
    },
    [value, getItemById, onBlur],
  );

  const getColumns = useCallback(
    () => {
      return [
        {
          title: 'Nội dung',
          render: (row) => {
            const { name = '' } = row;
            return {
              children: <div>{name}</div>,
            };
          },
        },
        {
          title: 'Mức đồng ý (Ghi số lượng ý kiến vào các ô tương ứng)',
          children: answers.map((answer) => {
            const { id: answerId = '', name = '' } = answer;
            return {
              title: name,
              width: 130,
              className: 'text-center',
              render: (row) => {
                const { iid = '' } = row;
                const defaultValue = getInputDefaultValue(iid, answerId);
                return {
                  children: [
                    <InputNumber
                      key={`${iid}_${answerId}`}
                      min={0}
                      onChange={(value) => onInputChange(iid, answerId, value)}
                      value={defaultValue}
                      readOnly={readOnly}
                    />,
                  ],
                  props: {
                    colSpan: 1,
                  },
                };
              },
            };
          }),
        },
      ];
    },
    [answers, onInputChange, getInputDefaultValue, readOnly],
  );

  return (
    <div className="table-form">
      {floatingLabelText && <div className="m-b-10">{floatingLabelText}</div>}
      <CommonAntdTable
        childrenColumnName={null}
        dataSource={dataSource}
        columns={getColumns()}
        className="learn-default"
        pagination={false}
      />
    </div>
  );
};

export default makeReduxFormCompatible({})(TableForm);
