import React from 'react';
import './stylesheets.scss';
import Icon from 'antd/lib/icon';
import Popover from 'antd/lib/popover';
import DisplayHtml from 'components/common/html';
import { isSmallScreen } from 'common';

const TooltipAssessment = ({ content, showContentAsIcon = false }) => (
  <Popover
    content={<DisplayHtml content={content} />}
    overlayClassName="assessment-content"
    placement={`${isSmallScreen ? 'topRight' : 'topLeft'}`}
  >
    {showContentAsIcon ? content : <Icon type="info-circle-o" />}
  </Popover>
);

export default TooltipAssessment;
