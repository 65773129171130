import React, { useEffect, useMemo, useState } from 'react';
import fetchData from 'components/common/fetchData';
import organizationApiUrls from 'components/admin/organization/endpoints';
import TreeSelect from 'components/common/tree-select';
import Cascader from 'components/common/cascader';
import { findInTree, mapTree } from 'common/utils/object';
import Form from 'antd/lib/form';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import { getOrgTypes, loadingStatuses } from 'configs/constants';
import { arraysConsistOfTheSameValues, groupByKey } from 'common/utils/Array';
import get from 'lodash.get';
import {
  userOrganizationsSelector,
  userOrgIidsSelector,
} from 'common/selectors';
import { elementDisplayModes } from 'schema-form/constants';
import DefaultFormElementRecap from 'components/common/default-form-element-recap';
import Requester from 'common/network/http/Request';
import isEqual from 'lodash.isequal';

import './stylesheet.scss';
import usePrevious from 'components/common/hook/usePrevious';
import { populateDefaultBdtxParams } from 'common/utils/url';
import {
  groupOrganizationTreeDataByOrgType,
  addValueToOrganizationTreeDataForTreeSelect,
  getTreeSelectNodeValueFromOrganization,
  getTreeSelectNodeTitleFromOrganization,
} from 'components/admin/organization/utils';

const getFullDataOfSelectedOrganizationInTreeData = (
  v,
  organizationTreeData,
) => {
  for (let tree of organizationTreeData || []) {
    const tmp = findInTree(tree, (org) => {
      return getTreeSelectNodeValueFromOrganization(org) === v;
    });
    if (tmp) {
      return tmp;
    }
  }
};

const checkIfSelectedOrganizationInTreeData = (v, organizationTreeData) => {
  return (
    getFullDataOfSelectedOrganizationInTreeData(v, organizationTreeData) !==
    undefined
  );
};

const getFullDataOfSelectedOrganizationThatNotInTreeData = (
  v,
  selectedOrganizationsFullInfo,
) => {
  return (selectedOrganizationsFullInfo || []).find((org) => {
    return getTreeSelectNodeValueFromOrganization(org) === v;
  });
};

const getFullDataOfSelectedOrganization = (
  v,
  organizationTreeData,
  selectedOrganizationsFullInfo,
) => {
  return (
    getFullDataOfSelectedOrganizationInTreeData(v, organizationTreeData) ||
    getFullDataOfSelectedOrganizationThatNotInTreeData(
      v,
      selectedOrganizationsFullInfo,
    )
  );
};

const OrganizationsInDefaultMode = ({
  organizationTreeData,
  label,
  hintText,
  value,
  onChange,
  multiple,
  orgTypesToGet,
  selectedOrganizationsFullInfo,
  organizationTreeDataLoadingStatus,
  fullWidth,
  errorText,
  readOnly,
  disabledBySubType,
  modeRender,
  fullOrgTypes,
  subTypesDiscarded,
  depthOfOrganizationTreeToShow,
  orgTypesReached,
  userOrgIids,
  renderKey,
  // (BDTX) các EP, TP có thể có cây đơn vị khác nhau do cây đơn vị phụ thuộc vào module, năm học
  enrolmentPlanIid,
  trainingPlanIid,
  moduleIid,
  schoolYear,
  trainingPlanCategory,
  userTypes,
  isBdtx,
}) => {
  const [treeData, setTreeData] = useState([]);

  useEffect(
    () => {
      const dataModified = groupOrganizationTreeDataByOrgType(
        addValueToOrganizationTreeDataForTreeSelect(
          organizationTreeData,
          disabledBySubType,
          depthOfOrganizationTreeToShow,
          orgTypesReached,
        ),
        fullOrgTypes,
        null,
        modeRender,
      );

      setTreeData(dataModified);
    },
    [
      organizationTreeData,
      fullOrgTypes,
      disabledBySubType,
      modeRender,
      depthOfOrganizationTreeToShow,
      orgTypesReached,
    ],
  );

  const selectedOrganizationsThatNotInTreeData = useMemo(
    () =>
      (value || []).filter((v) => {
        return !checkIfSelectedOrganizationInTreeData(v, treeData);
      }),
    [value, treeData],
  );

  const setTreeDataToNode = (node, data) => {
    const dataModified = groupOrganizationTreeDataByOrgType(
      addValueToOrganizationTreeDataForTreeSelect(
        data,
        disabledBySubType,
        depthOfOrganizationTreeToShow,
        orgTypesReached,
      ),
      fullOrgTypes,
      node,
      disabledBySubType,
    );

    if (!dataModified || !dataModified.length) {
      return;
    }
    setTreeData([...treeData, ...dataModified]);
  };

  const loadAsyncData = (treeNode) => {
    const node = treeNode.props;

    const { id, iid } = node;

    if (!id || !iid) {
      return Promise.resolve();
    }

    const subTypes = Array.isArray(orgTypesToGet)
      ? orgTypesToGet.map((type) => get(type, 'value'))
      : undefined;

    let params = {
      depth: 0,
      pIids: [iid],
      sub_type: subTypes,
      sub_types_discarded: subTypesDiscarded,

      enrolment_plan_iid: enrolmentPlanIid,
      training_plan_iid: trainingPlanIid,
      module_iid: moduleIid,
      school_year: schoolYear,
      training_plan_category: trainingPlanCategory,
      user_types: userTypes,
    };

    if (isBdtx) {
      params = populateDefaultBdtxParams(params);
    }

    return Requester.get(organizationApiUrls.organization_search, params).then(
      (response) => {
        if (response.success && Array.isArray(response.result)) {
          setTreeDataToNode(node, response.result);
        }
      },
    );
  };

  // add hidden nodes to represent selected organizations that are not in original tree data
  const treeDataWithHiddenNodes = (treeData || []).concat(
    selectedOrganizationsThatNotInTreeData.map((v) => {
      const org = getFullDataOfSelectedOrganizationThatNotInTreeData(
        v,
        selectedOrganizationsFullInfo,
      );
      return {
        key: getTreeSelectNodeValueFromOrganization(org),
        id: getTreeSelectNodeValueFromOrganization(org),
        title: getTreeSelectNodeTitleFromOrganization(org),
        value: getTreeSelectNodeValueFromOrganization(org),
        style: {
          display: 'none',
        },
      };
    }),
  );

  return (
    <Form.Item
      label={label}
      colon={false}
      validateStatus={errorText ? 'error' : ''}
      help={errorText || ''}
    >
      {modeRender === 'cascader' ? (
        <Cascader
          allowClear
          readOnly={readOnly}
          className={fullWidth ? 'w-100' : undefined}
          valueAsArrayEvenWhenNotMultiple
          placeholder={hintText}
          value={value}
          onChange={onChange}
          treeData={treeDataWithHiddenNodes}
          multiple={multiple}
          loading={
            organizationTreeDataLoadingStatus === loadingStatuses.LOADING
          }
        />
      ) : (
        <TreeSelect
          readOnly={readOnly}
          className={`${fullWidth ? 'w-100' : ''} tree-select-organizations`}
          valueAsArrayEvenWhenNotMultiple
          placeholder={hintText}
          value={value}
          onChange={onChange}
          treeData={treeDataWithHiddenNodes}
          multiple={multiple}
          loading={
            organizationTreeDataLoadingStatus === loadingStatuses.LOADING
          }
          loadAsyncData={loadAsyncData}
          treeDefaultExpandedKeys={userOrgIids}
        />
      )}
    </Form.Item>
  );
};

const OrganizationsInRecapMode = ({
  organizationTreeData,
  label,
  value,
  selectedOrganizationsFullInfo,
}) => {
  if (!Array.isArray(value) || value.length === 0) {
    return null;
  }

  return (
    <DefaultFormElementRecap
      label={label}
      content={value
        .map((v) =>
          getTreeSelectNodeTitleFromOrganization(
            getFullDataOfSelectedOrganization(
              v,
              organizationTreeData,
              selectedOrganizationsFullInfo,
            ),
          ),
        )
        .join(', ')}
    />
  );
};

const Organizations = ({
  elementDisplayMode,
  organizationTreeData,
  label,
  hintText,
  value,
  onChange,
  multiple,
  orgTypesToGet,
  selectedOrganizationsFullInfo,
  organizationTreeDataLoadingStatus,
  fullWidth,
  errorText,
  readOnly,
  disabledBySubType,
  modeRender,
  fullOrgTypes,
  subTypesDiscarded,
  depthOfOrganizationTreeToShow,
  orgTypesReached,
  userOrgIids,
  renderKey,
  // (BDTX) các EP, TP có thể có cây đơn vị khác nhau do cây đơn vị phụ thuộc vào module, năm học
  enrolmentPlanIid,
  trainingPlanIid,
  moduleIid,
  schoolYear,
  trainingPlanCategory,
  userTypes,
  isBdtx,
}) => {
  const [renderTimes, setRenderTimes] = useState();
  const prevRenderKey = usePrevious(renderKey);

  useEffect(
    () => {
      if (renderKey && renderKey !== prevRenderKey) {
        setRenderTimes(Date.now());
      }
    },
    [prevRenderKey, renderKey],
  );

  let content;
  switch (elementDisplayMode) {
    case elementDisplayModes.RECAP:
      content = (
        <OrganizationsInRecapMode
          label={label}
          selectedOrganizationsFullInfo={selectedOrganizationsFullInfo}
          organizationTreeData={organizationTreeData}
          value={value}
        />
      );
      break;
    default:
      content = (
        <OrganizationsInDefaultMode
          label={label}
          onChange={onChange}
          value={value}
          hintText={hintText}
          multiple={multiple}
          organizationTreeData={organizationTreeData}
          organizationTreeDataLoadingStatus={organizationTreeDataLoadingStatus}
          orgTypesToGet={orgTypesToGet}
          selectedOrganizationsFullInfo={selectedOrganizationsFullInfo}
          fullWidth={fullWidth}
          errorText={errorText}
          readOnly={readOnly}
          disabledBySubType={disabledBySubType}
          modeRender={modeRender}
          fullOrgTypes={fullOrgTypes}
          subTypesDiscarded={subTypesDiscarded}
          depthOfOrganizationTreeToShow={depthOfOrganizationTreeToShow}
          orgTypesReached={orgTypesReached}
          userOrgIids={userOrgIids}
          renderKey={renderTimes}
          enrolmentPlanIid={enrolmentPlanIid}
          trainingPlanIid={trainingPlanIid}
          moduleIid={moduleIid}
          schoolYear={schoolYear}
          trainingPlanCategory={trainingPlanCategory}
          userTypes={userTypes}
          isBdtx={isBdtx}
        />
      );
  }

  return <div key={renderTimes}>{content}</div>;
};

const rerenderIfSomeParamsChanged = (Comp) => {
  const Wrapped = ({ ...organizationsProps }) => {
    const {
      enrolmentPlanIid,
      trainingPlanIid,
      moduleIid,
      schoolYear,
      trainingPlanCategory,
      userTypes,
      isBdtx,
    } = organizationsProps;

    const key = `organizations-${enrolmentPlanIid}-${trainingPlanIid}-${moduleIid}-${schoolYear}-${trainingPlanCategory}-${userTypes}-${isBdtx}`;

    return <Comp {...organizationsProps} key={key} />;
  };

  return Wrapped;
};

const orgTypesToGetSelector = (state, { shouldGetAllSubTypes, subTypes }) => {
  return shouldGetAllSubTypes
    ? // get all organizations
      getOrgTypes(state, '*')
    : subTypes
    ? (getOrgTypes(state, '*') || []).filter((t) =>
        subTypes.find((s) => s == get(t, 'value')),
      )
    : // get only has_perm organizations
      // (at the time of writing, we have only has_perm or phongban organizations, if this logic is wrong when you read it, please update)
      getOrgTypes(state, 'has_perm');
};

const getDataFromState = connect(
  createSelector(
    orgTypesToGetSelector,
    userOrganizationsSelector,
    userOrgIidsSelector,
    (state) => getOrgTypes(state, '*'),
    (orgTypesToGet, userOrganizations, userOrgIids, fullOrgTypes) => ({
      orgTypesToGet,
      userOrganizations,
      userOrgIids,
      fullOrgTypes,
    }),
  ),
);

const formatOrganizationTreeDataBySubTypes = (data, subTypesToDisplay) => {
  if (
    !Array.isArray(data) ||
    !data.length ||
    !Array.isArray(subTypesToDisplay) ||
    !subTypesToDisplay.length
  ) {
    return [];
  }

  let result = [];

  data.forEach(({ children, ...row }) => {
    const chil = formatOrganizationTreeDataBySubTypes(
      children,
      subTypesToDisplay,
    );
    const hasChildren = Array.isArray(chil) && chil.length;
    const includes = subTypesToDisplay.includes(row.sub_type);

    if (includes) {
      if (hasChildren) {
        row.children = chil;
      }
      result = result.concat([row]);
    } else if (hasChildren) {
      result = result.concat(chil);
    }
  });

  return result;
};

const fetchOrganizationTreeData = fetchData(
  ({
    rootIids,
    includeRoot,
    provinceId,
    districtId,
    orgTypesToGet,
    subTypesToDisplay,
    userOrgIidsAsRootIids,
    userOrgIids,
    subTypesDiscarded,
    modeRender,

    enrolmentPlanIid,
    trainingPlanIid,
    moduleIid,
    schoolYear,
    trainingPlanCategory,
    userTypes,
    isBdtx,
  }) => {
    const depth = 0;
    const subTypes = Array.isArray(orgTypesToGet)
      ? orgTypesToGet.map((type) => get(type, 'value'))
      : undefined;

    if (!rootIids && userOrgIidsAsRootIids) {
      rootIids = userOrgIids;
    }

    let params = {
      view: 'tree',
      depth,
      pid: '0',
      includeRoot,
      _sand_expand: ['ancestor_iids'],
      sub_type: subTypes,
      sub_types_discarded: subTypesDiscarded,
      ...(rootIids && modeRender !== 'cascader' ? { pIids: rootIids } : {}),
      ...(provinceId ? { org_province_id: provinceId } : {}),
      ...(districtId ? { org_district_id: districtId } : {}),

      enrolment_plan_iid: enrolmentPlanIid,
      training_plan_iid: trainingPlanIid,
      module_iid: moduleIid,
      school_year: schoolYear,
      training_plan_category: trainingPlanCategory,
      user_types: userTypes,
    };

    if (isBdtx) {
      params = populateDefaultBdtxParams(params);
    }

    return {
      fetchCondition: true,
      baseUrl: organizationApiUrls.organization_search,
      params,
      formatDataResult: (data, originalProps, loadingStatus) => {
        if (
          loadingStatus === 'finished' &&
          Array.isArray(subTypesToDisplay) &&
          subTypesToDisplay.length
        ) {
          const organizationTreeData = formatOrganizationTreeDataBySubTypes(
            data,
            subTypesToDisplay,
          );
          return {
            organizationTreeData,
          };
        }

        return {
          organizationTreeData: data,
        };
      },
      propKey: 'organizationTreeData',
      loadingStatusPropKey: 'organizationTreeDataLoadingStatus',
    };
  },
);

const fetchSelectedOrganizationData = fetchData(
  ({
    value,
    enrolmentPlanIid,
    trainingPlanIid,
    moduleIid,
    schoolYear,
    trainingPlanCategory,
    userTypes,
    isBdtx,
  }) => {
    // => for the organizations that we do not have full info in the client, we need to fetch those data from server

    const selectedOrganizationsThatNeedFullInfo = value;

    let params = {
      iids: selectedOrganizationsThatNeedFullInfo,
      _sand_expand: ['ancestor_iids', 'isGhostOrg'],

      enrolment_plan_iid: enrolmentPlanIid,
      training_plan_iid: trainingPlanIid,
      module_iid: moduleIid,
      school_year: schoolYear,
      training_plan_category: trainingPlanCategory,
      user_types: userTypes,
    };

    if (isBdtx) {
      params = populateDefaultBdtxParams(params);
    }

    return {
      baseUrl: organizationApiUrls.get_multiple_organizations_info,
      fetchCondition: selectedOrganizationsThatNeedFullInfo.length > 0,
      refetchCondition: (prevProps) => {
        const iids = get(prevProps, 'value');

        if (
          isEqual(iids, selectedOrganizationsThatNeedFullInfo) ||
          !selectedOrganizationsThatNeedFullInfo.length
        ) {
          return false;
        }

        return true;
      },
      params,
      propKey: 'selectedOrganizationsFullInfo',
    };
  },
);

const removeInvalidValues = (Comp) => {
  class Wrapped extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
      const {
        value,
        onChange,
        selectedOrganizationsFullInfo,
        organizationTreeData,
      } = this.props;

      if (
        organizationTreeData &&
        selectedOrganizationsFullInfo &&
        selectedOrganizationsFullInfo.length &&
        // make sure selectedOrganizationsFullInfo matched value
        arraysConsistOfTheSameValues(
          selectedOrganizationsFullInfo.map((org) =>
            getTreeSelectNodeValueFromOrganization(org),
          ),
          value,
          (a, b) => a == b,
        )
      ) {
        const validSelectedOrganizations = selectedOrganizationsFullInfo.filter(
          (org) => {
            return (
              get(org, '__expand.isGhostOrg') ||
              [org]
                .concat(get(org, '__expand.ancestor_iids') || [])
                .some((o) =>
                  checkIfSelectedOrganizationInTreeData(
                    getTreeSelectNodeValueFromOrganization(o),
                    organizationTreeData,
                  ),
                )
            );
          },
        );

        let newValue = validSelectedOrganizations.map((org) =>
          getTreeSelectNodeValueFromOrganization(org),
        );

        if (!isEqual(newValue, value)) {
          onChange(newValue);
        }
      }
    }

    render() {
      return <Comp {...this.props} />;
    }
  }

  return Wrapped;
};

export default rerenderIfSomeParamsChanged(
  makeReduxFormCompatible({})(
    getDataFromState(
      fetchOrganizationTreeData(
        fetchSelectedOrganizationData(removeInvalidValues(Organizations)),
      ),
    ),
  ),
);
