import React from 'react';
import { getAttachmentFileNameForDisplay } from './common';
import { isSmallScreen } from '../../../common';
import { t1 } from '../../../translate';
import { getItemAttachment } from '../forms/questions/open-ended/attachment-viewer';
import DetailOnDialog from '../detail-on-dialog';
import Icon from '../Icon';
import DownloadAttachmentWrapper from 'components/common/attachment/DownloadAttachmentWrapper';
import AntdTable from 'antd/lib/table';

const ListAttachmentsToDownloadAsTable = React.memo(
  ({
    attachments,
    showHeader = true,
    compact = false,
    className = '',
    allowDownload = true,
  }) => {
    const [pagination, setPagination] = React.useState({
      page: 1,
      defaultPageSize: 20,
    });

    const onTableChange = React.useCallback(
      (nextPagination) => {
        setPagination({ ...pagination, page: nextPagination.current });
      },
      [setPagination, pagination],
    );

    const getTitle = React.useCallback((item) => {
      return `${
        item.ext ? `${item.ext.toUpperCase()} - ` : ''
      }${getAttachmentFileNameForDisplay(item)}`;
    }, []);

    const columns = React.useMemo(
      () => {
        return [
          !compact &&
            !isSmallScreen && {
              title: t1('stt').toUpperCase(),
              className: 'text-center',
              render: (value, item, index) => {
                return (index + 1) * pagination.page;
              },
              width: 70,
            },
          {
            title: t1('file_name'),
            key: 'name',
            width: isSmallScreen ? 400 : 'auto',
            render: (item) => {
              const name = getAttachmentFileNameForDisplay(item);
              return <div>{name}</div>;
            },
          },
          !compact && {
            title: t1('file_type'),
            key: 'name',
            className: 'text-center',
            render: (item) =>
              item.ext ? item.ext.toUpperCase() : t1('unknown'),
          },
          {
            title: t1('download'),
            key: 'download',
            className: 'text-center',
            width: isSmallScreen ? 60 : 'auto',
            render: (item, row, index) => {
              const itemView = getItemAttachment(item);
              const { contentItem } = itemView;

              return (
                <>
                  {contentItem && (
                    <DetailOnDialog
                      renderPreview={({ showFull }) => (
                        <span onClick={showFull} className="p-5 m-r-10">
                          <Icon icon="preview" />
                        </span>
                      )}
                      dialogKey={`content-item-view-${index}`}
                      renderFull={() => <>{contentItem}</>}
                    />
                  )}
                  {!!allowDownload && (
                    <DownloadAttachmentWrapper
                      attachment={item}
                      renderComponent={({ href, onClick }) => {
                        return (
                          <a
                            href={href}
                            onClick={onClick}
                            title={getTitle(item)}
                          >
                            <span className="ve-download" />
                          </a>
                        );
                      }}
                    />
                  )}
                </>
              );
            },
          },
        ].filter(Boolean);
      },
      [compact, getTitle, pagination.page, allowDownload],
    );

    if (!attachments || !Array.isArray(attachments)) {
      return null;
    }

    return (
      <AntdTable
        showHeader={showHeader}
        columns={columns}
        dataSource={attachments}
        childrenColumnName={null}
        className={`${className} learn-default`}
        rowKey="id"
        pagination={attachments.length > 20 ? pagination : false}
        onChange={onTableChange}
      />
    );
  },
);

export default ListAttachmentsToDownloadAsTable;
