import React from 'react';
import { Link } from 'react-router-dom';
import { editAccount } from 'components/temis/routes';
import './style.scss';

const AccountEdit = ({ id = '' }) => (
  <Link className="m-l-10" to={editAccount(id)}>
    <span className="ve ve-edit account-edit-icon" title="Chỉnh sửa" />
  </Link>
);

export default AccountEdit;
