import React from 'react';
import get from 'lodash.get';
import withTemisConfig from 'common/hoc/withTemisConfig';
import fetchData from 'components/common/fetchData';
import endPoints from '../endpoints';
import { generateClassByStatusAssessment } from 'components/temis/utils';
import CommonAntdTable from 'components/common/antd/table';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import Tooltip from 'antd/lib/tooltip';
import Html from 'components/common/html';
import Icon from 'antd/lib/icon';
import './stylesheet.scss';
import InputText from 'antd/lib/input';
import Widget from 'components/common/Widget';
import TooltipAssessment from 'components/temis/components/tooltip-assessment';
import { compose } from 'redux';
import { radarChartResultsAssess } from '../util';
import { TCNN_TYPE } from '../../constant';
import { isMamNon } from 'components/admin/user/utils';

const getColumns = ({ assessment_name, score_scale, task }) => {
  const parts = get(score_scale, 'parts');
  let labelScoreById = null;
  if (Array.isArray(parts) && parts.length) {
    labelScoreById = {};
    parts.forEach(({ id, name }) => {
      labelScoreById[id] = name;
    });
  }

  return [
    {
      title: assessment_name,
      width: '70%',
      render: ({ isHeader, name, description, depth }) => {
        return {
          children: (
            <div
              style={{
                fontWeight: isHeader ? 'bold' : '',
                marginLeft: 20 * depth,
              }}
            >
              <span className="m-r-5">{name}</span>
              {!isHeader && description && (
                <TooltipAssessment content={description} />
              )}
            </div>
          ),
          props: {
            colSpan: isHeader ? 5 : 1,
          },
        };
      },
    },
    {
      title: <div className="text-center">Tiêu chí</div>,
      className: 'text-center',
      children: (Array.isArray(parts) ? parts : []).map(({ id, name }) => ({
        title: name,
        className: `text-center ${generateClassByStatusAssessment(name)}`,
        render: ({ isHeader, iid, scaled_children, ...row }) => {
          const scaledCell =
            Array.isArray(scaled_children) &&
            scaled_children.find(
              ({ scale_id }) => String(scale_id) === String(id),
            );
          const description =
            get(scaledCell, 'detailed_description') ||
            get(scaledCell, 'description');
          const currentValue =
            (Array.isArray(task) &&
              task.find(
                (val) => String(get(val, 'rubric_iid')) === String(iid),
              )) ||
            {};
          const hasValue = String(get(currentValue, 'answer')) === String(id);

          return {
            children: [
              hasValue && <span className="ve-check icon-check" />,
              description && (
                <span style={{ position: 'absolute', top: 0, right: 0 }}>
                  <Tooltip
                    title={<Html content={description} />}
                    placement="topRight"
                  >
                    <Icon
                      type="question-circle"
                      style={{ color: 'rgba(0,0,0,.45)' }}
                    />
                  </Tooltip>
                </span>
              ),
            ],
            props: {
              colSpan: isHeader ? 0 : 1,
              className: 'text-center',
              style: {
                position: 'relative',
              },
            },
          };
        },
      })),
    },
  ].filter(Boolean);
};

const PeersResultAssessRoleOfTeacher = ({
  selfAssess,
  assessOfPeers = [],
  dataSource,
  assessment_name,
  score_scale,
  user,
  viewPeersAssessment,
  viewSuperiorAssessment,
  isPeersAssess,
}) => {
  if (
    !Array.isArray(dataSource) ||
    !dataSource ||
    !assessOfPeers ||
    !dataSource.length ||
    !Array.isArray(assessOfPeers) ||
    !assessOfPeers.length
  ) {
    return (
      <SimpleNoResult
        text={
          (viewPeersAssessment && !viewSuperiorAssessment) || isPeersAssess
            ? 'Chưa có đánh giá của đồng nghiệp.'
            : 'Chưa có đánh giá của thủ trưởng.'
        }
      />
    );
  }

  const assessments = assessOfPeers.filter(({ updated_ts }) => !!updated_ts);

  const name = get(user, 'name');
  const task = get(assessments[0], 'task', []);
  const rubricIid = get(assessments[0], 'rubric_iid');
  const overall_comment = get(assessments[0], 'overall_comment');
  const strengths = get(overall_comment, 'strengths');
  const weakness = get(overall_comment, 'weakness');
  const proposingDevelopmentDirect = get(
    overall_comment,
    'proposing_development_direct_and_remedies',
  );
  const customClass =
    (viewPeersAssessment && !viewSuperiorAssessment) || isPeersAssess
      ? 'col-md-4'
      : 'col-md-6';

  const scoreBasedOnSelectedTask = false;

  return (
    <>
      <div className="m-b-15 row">
        <p className="col-md-12 peer-assessment__name m-b-0">
          Đánh giá CNN: {name}
        </p>
      </div>

      <div className="row">
        <div className="col-md-offset-3 col-md-6 text-center m-t-10">
          <Widget className="box-chart" style={{ height: '100%' }}>
            {radarChartResultsAssess(
              dataSource,
              task,
              score_scale,
              TCNN_TYPE.GV,
              scoreBasedOnSelectedTask,
              rubricIid,
            )}
          </Widget>
        </div>
      </div>

      <CommonAntdTable
        columns={getColumns({
          assessment_name,
          score_scale,
          assessments,
          selfAssess,
          task,
        })}
        dataSource={assessments.length ? dataSource : []}
        pagination={false}
        bordered
        size="middle"
        className="learn-default"
      />

      <Widget title="1.Nhận xét (ghi rõ)" className="title-default  m-t-20">
        <div className="row m-t-15 title-default__overall-comment">
          <div className={customClass}>
            <label className="title-overall-comment">Điểm mạnh</label>
            <InputText.TextArea
              fullWidth
              value={strengths}
              autosize={{ minRows: 3, maxRows: 5 }}
              disabled={true}
              className="m-t-10"
            />
          </div>

          <div className={customClass}>
            <label className="title-overall-comment">
              Những vấn đề cần cải thiện
            </label>
            <InputText.TextArea
              fullWidth
              value={weakness}
              autosize={{ minRows: 3, maxRows: 5 }}
              disabled={true}
              className="m-t-10"
            />
          </div>
          {((viewPeersAssessment && !viewSuperiorAssessment) ||
            isPeersAssess) && (
            <div className="col-md-4">
              <label className="title-overall-comment">
                Đề xuất hướng phát huy điểm mạnh, khắc phục điểm yếu
              </label>
              <InputText.TextArea
                fullWidth
                value={proposingDevelopmentDirect}
                autosize={{ minRows: 3, maxRows: 5 }}
                disabled={true}
                className="m-t-10"
              />
            </div>
          )}
        </div>
      </Widget>
    </>
  );
};

const fetchAssessOfPeers = fetchData((props) => {
  const rubricIid = get(props, 'rubricToAssessment');
  const rubricIidByUserPassed = get(props, 'user.rubric_iid');
  const { finalAggregateAssessment = 0 } = props;

  return {
    baseUrl: endPoints.assessOfPeers,
    params: {
      ...(props.user && props.user.iid
        ? {
            user_iid: props.user.iid,
          }
        : {}),
      rubric_iid: rubricIidByUserPassed ? rubricIidByUserPassed : rubricIid,
      useSuperiorAssessment: props.useSuperiorAssessment ? 1 : 0,
      scholastic: props.scholastic,
      ...(finalAggregateAssessment ? { final_aggregate_assessment: 0 } : {}), //Hieu truong xem danh gia cua to truong
    },
    keyState: 'assessOfPeers',
    propKey: 'assessOfPeers',
    fetchCondition: true,
    refetchCondition: (prevProps) => props.scholastic !== prevProps.scholastic,
  };
});

export default compose(
  fetchAssessOfPeers,
  withTemisConfig,
)(PeersResultAssessRoleOfTeacher);
