import React from 'react';
import NewEvidenceForm from 'components/temis/evidence/templateless-evidence/new/Form';
import lodashGet from 'lodash.get';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

const getFormId = (props) => {
  const { assessmentRubricIid, tieuChuanIid, tieuChiIid } = props;
  return `new_evidence_when_do_assessment_${assessmentRubricIid}_${tieuChuanIid}_${tieuChiIid}`;
};

const Form = (props = {}) => {
  const {
    readOnly,
    dialogKey,
    searchFormId,
    assessmentRubricIid,
    tieuChuanIid,
    tieuChiIid,
    onNewEvidence,
    formValues,
  } = props;

  const handleNewEvidence = (apiResponse) => {
    const evidence = lodashGet(apiResponse, 'result');

    onNewEvidence({
      ...evidence,
      ...formValues,
    });
  };

  return (
    <NewEvidenceForm
      hiddenFields={{
        assessmentRubricIid,
        tieuChuanIid,
        tieuChiIid,
      }}
      step="do_assessment"
      readOnly={readOnly}
      formid={getFormId(props)}
      dialogKey={dialogKey}
      searchFormId={searchFormId}
      requestSuccessful={(apiResponse) => handleNewEvidence(apiResponse)}
    />
  );
};

const mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues(getFormId(props))(state),
  };
};

export default connect(mapStateToProps)(Form);
