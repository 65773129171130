/**
 * Created by hungvo on 19/04/2017.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t1 } from 'translate/index';
import sagaActions from 'actions/node/saga-creators';
import ActionBtnWithConfirmDialog from 'components/common/action-button/ActionBtnWithConfirmDialog';

const ApiRequestBtnWithConfirmDialog = (props) => {
  const {
    dispatch,
    executeOnFailure,
    title,
    icon,
    textConfirm,
    closeModal,
    modalKey,
    extraParams,
    formidToSubmitOnSuccess,
    formIdsToSubmitOnSuccess,
    successMessage,
    failureMessage,
    params,
    getFullResponse,
    clearFormOnSubmitSuccess,
    callApiMultipleTimesSettings,
    url,
  } = props;

  const [submitting, setSubmitting] = React.useState(false);

  const handleAction = React.useCallback(
    (onRequestSuccessful) => {
      setSubmitting(true);

      dispatch(
        sagaActions.submitFormRequest('', {
          closeModal,
          modalKey,
          extraParams,
          formidToSubmitOnSuccess,
          formIdsToSubmitOnSuccess,
          successMessage,
          failureMessage,
          params,
          getFullResponse,
          clearFormOnSubmitSuccess,
          callApiMultipleTimesSettings,
          url,
          executeOnSuccess: (...params) => {
            setSubmitting(false);
            onRequestSuccessful(...params);
          },
          executeOnFailure: (...params) => {
            setSubmitting(false);
            executeOnFailure(...params);
          },
        }),
      );
    },
    [
      clearFormOnSubmitSuccess,
      closeModal,
      dispatch,
      executeOnFailure,
      extraParams,
      failureMessage,
      formIdsToSubmitOnSuccess,
      formidToSubmitOnSuccess,
      getFullResponse,
      modalKey,
      params,
      callApiMultipleTimesSettings,
      successMessage,
      url,
    ],
  );

  return (
    <ActionBtnWithConfirmDialog
      {...props}
      loading={submitting}
      title={title || t1('submit')}
      icon={icon || 'send'}
      textConfirm={textConfirm || t1('do_you_want_to_do_this?')}
      actionHandler={handleAction}
    />
  );
};

ApiRequestBtnWithConfirmDialog.propTypes = {
  actionHandler: PropTypes.func,
  actionProps: PropTypes.shape(),
  buttonLabelStyle: PropTypes.shape(),
  buttonStyle: PropTypes.shape(),
  className: PropTypes.string,
  closeDialogAfterActionSuccessFull: PropTypes.bool,
  contentDialog: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconButton: PropTypes.bool,
  label: PropTypes.string,
  noConfirm: PropTypes.bool,
  onRequestSuccessful: PropTypes.func,
  primary: PropTypes.bool,
  raisedButton: PropTypes.bool,
  renderComponent: PropTypes.func,
  secondary: PropTypes.bool,
  style: PropTypes.bool,
  textConfirm: PropTypes.string,
  title: PropTypes.string,

  executeOnFailure: PropTypes.func,
  closeModal: PropTypes.bool,
  modalKey: PropTypes.string,
  formidToSubmitOnSuccess: PropTypes.string,
  successMessage: PropTypes.string,
  failureMessage: PropTypes.string,
  params: PropTypes.string,
  url: PropTypes.string,
};

export default connect()(ApiRequestBtnWithConfirmDialog);
