import React from 'react';
import get from 'lodash.get';
import 'react-pdf/dist/pdf.worker.entry';
import './stylesheets.scss';

class Layout extends React.Component {
  render() {
    const file = get(this.props, 'layoutOptionsProperties.file');
    const readOnly = get(
      this.props,
      'layoutOptionsProperties.shouldDisabledSaveButton',
    );
    const fieldNames = get(this.props, 'groups.default.fieldNames', {});

    return (
      <>
        <div className="m-b-30">
          {!!fieldNames.attachments && <div>{fieldNames.attachments}</div>}
        </div>

        {readOnly && !file && (
          <div className="text-center">
            Chưa có file đính kèm BM3/PL2 CV4530 tải lên.
          </div>
        )}

        <div className="text-center m-t-30">
          <button className="btn btn-secondary text-center" disabled={readOnly}>
            Lưu
          </button>
        </div>
      </>
    );
  }
}

export default Layout;
