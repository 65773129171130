import get from 'lodash.get';
import TableForm from './TableForm';
import { getOverallResultsOfTheAssessment } from '../util';
import overallCommentSchema from './overallCommentSchema';
import personalOrientationSchema from './personalOrientationSchema';
import LayoutFreeStyle from './LayoutWhenEdit';
import LayoutWhenView from './LayoutWhenView';
import Attachments from 'schema-form/elements/attachments';
import { ASSESSMENT_STATUS } from 'components/temis/constant.js';
import { VIEWER_FILE_EXTENSIONS } from 'components/temis/constant';

const schema = (
  formid,
  values,
  localStep,
  xpath,
  {
    mySelfAssessment,
    peersAssessment,
    peerAssess,
    readOnly,
    tcnnType,
    assessment_name,
    dataSource,
    score_scale,
    userRoot,
    ignoreUserIidParamToEvidenceAPI,
    useTeamLeaderResult,
    isUserSelfAssessment,
    hasAssessment,
    finalAggregateAssessment,
    userForAssessment,
  },
) => ({
  task: {
    readOnly,
    peerAssess,
    tcnnType,
    assessment_name,
    dataSource,
    score_scale,
    peersAssessment,
    mySelfAssessment,
    ignoreUserIidParamToEvidenceAPI,
    type: TableForm,
    fullWidth: true,
    rubricIid: get(values, 'rubric_iid'),
    userIid: get(values, 'target.iid'),
    scholastic: get(values, 'scholastic'),
    useTeamLeaderResult,
    hasAssessment,
  },
  overall_comment: {
    type: 'section',
    schema: overallCommentSchema(
      isUserSelfAssessment,
      userRoot,
      readOnly,
      finalAggregateAssessment,
    ),
  },
  personal_orientation: {
    type: 'section',
    schema: personalOrientationSchema,
  },
  attachments: {
    type: Attachments,
    allowDownload: true,
    fullWidth: true,
    multiple: false,
    noFileManager: true,
    viewerFileExtensions: VIEWER_FILE_EXTENSIONS,
    disabled: readOnly,
    title: 'Đính kèm BM03/PL2 CV4529',
  },
  status: {
    type: 'text',
    defaultValue: ASSESSMENT_STATUS.APPROVED,
  },
});

const ui = (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  { isUserSelfAssessment, readOnly, assessorIid },
) => {
  return [
    {
      id: 'default',
      fields: [
        'task',
        'status',
        'overall_comment',
        (isUserSelfAssessment || readOnly) &&
          !assessorIid &&
          'personal_orientation',
      ].filter(Boolean),
    },
  ];
};

export const finalProcessBeforeSubmit = (fullData, doNotFillAllTasks) => {
  const result = getOverallResultsOfTheAssessment(
    get(fullData, 'task'),
    doNotFillAllTasks,
    get(fullData, 'rubric_iid'),
  );

  if (typeof result.final !== 'undefined') {
    fullData.result = result;
  }
  return fullData;
};

const layout = (
  step,
  values,
  xpath,
  {
    tcnnType,
    assessment_name,
    dataSource,
    score_scale,
    mySelfAssessment,
    readOnly,
    assessorIid,
    doNotFillAllTasks,
  } = {},
) => {
  return {
    component: readOnly ? LayoutWhenView : LayoutFreeStyle,
    freestyle: 1,
    optionsProperties: {
      finalAggregateAssessment: get(values, 'final_aggregate_assessment'),
      mySelfAssessment,
      tcnnType,
      assessment_name,
      dataSource,
      score_scale,
      assessorIid,
      doNotFillAllTasks,
      rubricIid: values.rubric_iid,
    },
  };
};

export default () => ({
  schema,
  ui,
  layout,
});
