import getUser from 'common/auth';
import get from 'lodash.get';
import { getUserProjects } from 'utils/Util';

const projectConfigKey = 'PROJECT_CONFIGURATION_KEY';

export const savProjectToLocalStorage = (project) => {
  const userInfo = getUser();
  const userId = get(userInfo, 'info.iid', '');
  const currentProject = getProjectOfAllUserFromLocalStorage() || {};
  const newProject = {
    ...currentProject,
    [userId]: project,
  };

  localStorage.setItem(projectConfigKey, JSON.stringify(newProject));
};

export const getProjectOfAllUserFromLocalStorage = () => {
  const projectString = localStorage.getItem(projectConfigKey);
  if (projectString) {
    return JSON.parse(projectString);
  }

  return null;
};

export const getProjectOfCurrentUser = () => {
  const userInfo = getUser();
  const userId = get(userInfo, 'info.iid', '');
  const projectFromLocalStorage = getProjectOfAllUserFromLocalStorage() || {};

  return get(projectFromLocalStorage, userId);
};

export const onSaveProject = (project, callback) => {
  savProjectToLocalStorage(project);

  if (typeof callback === 'function') {
    callback();
  }

  window.location.reload();
};

export const shouldShowSwitchProject = () => {
  return getUserProjects().length > 1;
};
