import React, { Component } from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import organizationApiUrls from '../../admin/organization/endpoints';
import Results from './Result';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import New from './add-category';
import withOutOfDeadlineAssessment from '../components/scholastic/withOutOfDeadlineAssessment';
import withScholastic from '../components/scholastic/withScholastic';
import { compose } from 'redux';
import withUserOrganizations from 'common/hoc/withUserOrganizations';
import get from 'lodash.get';

const formid = 'temis-organization-search';

class Category extends Component {
  renderResultComponent = (items, props) => {
    const { organizations } = this.props;

    return (
      <>
        {this.renderNewCategory()}
        <Results
          items={items}
          organizations={get(organizations, '[0]')}
          searchTcmFormId={formid}
          {...props}
        />
      </>
    );
  };

  renderNoResultComponent = () => {
    return (
      <>
        {this.renderNewCategory()}
        <SimpleNoResult />
      </>
    );
  };

  renderNewCategory = () => {
    const { scholastic, organizations, isOutOfDeadlineAssessment } = this.props;

    return (
      <div className="d-flex justify-content-end m-b-10">
        <New
          searchFormId={formid}
          viewOnly={isOutOfDeadlineAssessment}
          scholastic={scholastic}
          node={get(organizations, '[0]')}
        />
      </div>
    );
  };

  render() {
    const {
      scholastic,
      orgIids,
      organizations,
      isOutOfDeadlineAssessment,
    } = this.props;

    const hiddenFields = {
      _sand_expand: ['ancestor_iids', 'number_of_member'],
      view: 'grid',
      depth: -1,
      rootIids: orgIids,
      pIids: orgIids,
    };

    return (
      <div>
        <SearchWrapper
          formid={formid}
          schema={schema}
          hiddenFields={hiddenFields}
          renderResultsComponent={this.renderResultComponent}
          renderNoResultComponent={this.renderNoResultComponent}
          alternativeApi={organizationApiUrls.organization_search}
        />
      </div>
    );
  }
}

export default compose(
  withOutOfDeadlineAssessment,
  withScholastic,
  withUserOrganizations,
)(Category);
