import { t1 } from 'translate';
import React from 'react';

const schema = () => {
  return {
    text: {
      type: 'text',
      floatingLabelText: t1('Tìm kiếm theo tên tổ chuyên môn'),
    },
  };
};

const ui = () => {
  return [
    {
      id: 'default',
      fields: ['text'],
    },
  ];
};

const layoutFreeStyle = ({ groups, submitButton }) => {
  return (
    <div className="row">
      <div className="col-md-6">{groups.default.fieldNames.text}</div>
      <div className="col-md-3 m-t-10">{submitButton}</div>
    </div>
  );
};

const layout = {
  component: layoutFreeStyle,
  freestyle: 1,
};

export default { schema, ui, layout };
