import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Form from './Form';
import RaisedButton from 'components/common/mui/RaisedButton';
import { t1 } from 'translate';

const New = ({ searchFormId, scholastic, viewOnly, node }) => {
  const dialogKey = 'add-category';

  const renderPreview = ({ showFull }) => {
    return (
      <RaisedButton
        onClick={showFull}
        label={t1('Thêm mới tổ chuyên môn')}
        className="m-t-10 btn-secondary"
        disabled={viewOnly}
      />
    );
  };

  const renderFull = () => {
    return (
      <Form
        dialogKey={dialogKey}
        searchFormId={searchFormId}
        formid="new_category"
        hiddenFields={{
          scholastic,
          sub_type: 21, //Tổ chuyên môn
        }}
        node={node}
      />
    );
  };

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogKey={dialogKey}
      dialogOptionsProperties={{
        title: 'Tạo tổ chuyên môn mới',
        width: '60%',
        handleClose: true,
        modal: true,
      }}
    />
  );
};

export default New;
