import { t1 } from 'translate';
import Layout from './layout';

export const SCHOLASTIC_SELECT_FIELD_NAME = 'scholastic';

const schema = (formid, values, step, xpath, props) => {
  const { allScholastic = [] } = props;
  return {
    [SCHOLASTIC_SELECT_FIELD_NAME]: {
      type: 'select',
      fullWidth: true,
      floatingLabelText: t1('choose_scholastic'),
      className: 'scholastic__select',
      options: allScholastic,
    },
  };
};

const ui = () => [
  {
    id: 'default',
    fields: [SCHOLASTIC_SELECT_FIELD_NAME],
  },
];

export default {
  schema,
  ui,
  layout: {
    component: Layout,
    freestyle: 1,
  },
};
