import React, { Component } from 'react';
import Widget from 'components/common/Widget';
import TCNNReportSearch from './component/Form';
import withUserInfo from 'common/hoc/withUserInfo';
import { EXPORT_FILENAME, TCNN_TYPE } from 'components/temis/constant';
import { generateReportTitle } from 'components/temis/utils';

class TCNNTruong extends Component {
  render() {
    const {
      userInfo = {},
      scholastic,
      isResultByRubric,
      reportTitle,
      isResultForMN,
    } = this.props;

    const { organizations = [] } = userInfo;
    const school = organizations[0];
    const excelExportFileName = generateReportTitle(reportTitle);

    return (
      <div className="report-result">
        <div className="report-result__school">{`Trường: ${!!school &&
          school.name}`}</div>

        <div>
          <div className="report-result__school-export-excel">
            <div className="report-result__tnnn-type">{reportTitle}</div>
          </div>

          <hr />

          <div>
            <div className="report-result__container">
              <Widget className="title-default">
                <TCNNReportSearch
                  tcnn={TCNN_TYPE.GV}
                  scholastic={scholastic}
                  isResultByRubric={isResultByRubric}
                  isResultForMN={isResultForMN}
                  exportProps={{
                    fileName: EXPORT_FILENAME.overallResultAssessmentByTCNNGV,
                  }}
                  userInfo={userInfo}
                  excelExportFileName={excelExportFileName}
                />
              </Widget>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withUserInfo(TCNNTruong);
