import React from 'react';
import { t1 } from 'translate';
import LayoutFreeStyle from './LayoutFreeStyle';
import {
  organizations,
  phongbanInOrganization,
} from 'components/admin/organization/schema/elements';
import get from 'lodash.get';
import Store from 'store';
import { change } from 'redux-form';

const schema = (
  formid,
  {
    user_organizations,
    passed,
    organizationRootIids,
    includeRootOrganizations,
    getOnlyOrganizationWhereUserHasPermission,
  } = {},
  step,
  xpath,
  props,
  domainInfo,
) => {
  const { grade = '' } = props;
  return {
    text: {
      type: 'text',
      hintText: 'Nhập Tên, Mã hoặc Email',
      floatingLabelText: 'Giáo viên',
      defaultValue: '',
      errorText: '',
      fullWidth: true,
    },
    phongban_id: phongbanInOrganization({
      orgTypes: get(domainInfo, 'school.org_types'),
      ancestor_iids: user_organizations,
    }),
    user_organizations: organizations({
      formid,
      label: 'Đơn vị',
      defaultValue: props.orgIids,
      fullWidth: true,
      readOnly: true,
      validate: [],
      rootIids: organizationRootIids,
      includeRoot: includeRootOrganizations,
      getOnlyOrganizationWhereUserHasPermission: getOnlyOrganizationWhereUserHasPermission,
      defaultOrganizations:
        Array.isArray(props.orgIids) && props.orgIids.length > 0
          ? props.orgIids
          : null,
      includeSubOrganizations: 1,
      includeSubOrganizationsLabel: t1('include_users_in_sub_organizations'),
      multiple: false,
    }),
    passed: {
      type: 'radio',
      options: [
        {
          label: 'Hiển thị tất cả các giáo viên',
          value: 'all',
        },
        {
          label:
            'TCNN >= Khá (Trong đó các Tiêu chí trong điều 5 phải đạt mức tốt)',
          value: 'rather',
        },
      ],
      defaultValue: 'all',
    },
    passed_rather: {
      type: 'multiCheckbox',
      label: t1('tick_if_send_all'),
      fullWidth: true,
      options: [
        {
          label: 'Năm KN >= 5',
          value: 'teaching_exp_years',
        },
        {
          label: 'Có nguyện vọng trở thành GVCC',
          value: 'have_desire_to_become_master',
        },
      ],
    },
  };
};

const ui = (step, values = {}) => {
  const fields = ['user_organizations', 'phongban_id', 'text', 'passed'];

  if (values.passed === 'rather') {
    fields.push('passed_rather');
  }

  return [
    {
      id: 'id',
      fields: fields,
    },
  ];
};

const layout = () => {
  return { component: LayoutFreeStyle, freestyle: 1 };
};

export default {
  schema,
  ui,
  layout,
};
