import React from 'react';
import { Badge } from 'antd';
import {
  communicationDeliveryMethods,
  communicationTitle,
  communicationContent,
  communicationNote,
} from 'components/common/communication/utils';

const schema = (formId, values, localStep, xpath, props) => {
  const {
    totalWhenSelectAll = [],
    iidSelected = [],
    objectTitle = '',
    paramsFilterWhenSelectAll = {},
  } = props;

  const totalMemberToSend = values.send_all
    ? totalWhenSelectAll
    : iidSelected.length;

  const { communicationOptions = [] } = props;

  return {
    view_description: {
      type: 'cascade',
      component: (
        <h3 className="notify-send-for">
          <span className="m-r-3">Gửi thông báo đến </span>
          <Badge
            count={totalMemberToSend}
            style={{ backgroundColor: '#52c41a', fontWeight: 'bold' }}
            overflowCount={totalMemberToSend + 1}
          />
          <span className="m-l-5">{objectTitle}</span>
        </h3>
      ),
    },
    iids: {
      type: 'hidden',
      value: iidSelected,
      defaultValue: iidSelected,
    },
    send_all: {
      type: 'checkbox',
      label: `Gửi đến tất cả các ${objectTitle}`,
      fullWidth: true,
      className: 'notify-send-all',
    },
    delivery_methods: communicationDeliveryMethods({ communicationOptions }),
    subject: communicationTitle(),
    content: communicationContent(),
    note: communicationNote({
      message: `Thông báo sẽ được gửi đến ${objectTitle} qua Thư điện tử.`,
    }),
  };
};

const ui = (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  { objectTitle, disableSendAll },
) => {
  const fields = [
    (objectTitle || values.send_all) && 'view_description',
    !disableSendAll && 'send_all',
    'delivery_methods',
    'subject',
    'content',
    objectTitle && 'note',
    values.send_all ? 'params_filter_when_select_all' : 'iids',
  ].filter(Boolean);

  return [
    {
      id: 'default',
      fields,
    },
  ];
};

const layout = {
  new: '',
};
export default { schema, ui, layout };
