import React from 'react';
import fetchData from 'components/common/fetchData';
import endpoints from 'components/temis/assessment/endpoints';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import AssessmentPeerInOrganization from './AssessmentPeerInOrganization';
import get from 'lodash.get';
import endPoints from 'components/temis/assessment/endpoints';
import { formatDataSourceFromDataServerToRenderTableAssess } from 'components/temis/assessment/util';

const ViewAssessmentOfPeersInPhongBan = ({
  user,
  peers,
  rubricIid,
  assessment_name,
  score_scale,
  dataSource,
  phongban,
  scholastic,
  mySelfAssessment,
} = {}) => {
  return (
    <>
      {!rubricIid ||
      !Array.isArray(peers) ||
      !peers.length ||
      !Array.isArray(dataSource) ||
      !dataSource.length ? (
        <SimpleNoResult text="Bạn không có đánh giá trong tổ." />
      ) : (
        <AssessmentPeerInOrganization
          assessment_name={assessment_name}
          score_scale={score_scale}
          dataSource={dataSource}
          user={user}
          peers={peers}
          phongban={phongban}
          scholastic={scholastic}
          mySelfAssessment={mySelfAssessment}
        />
      )}
    </>
  );
};

const fetchSelfAssessment = (props) => {
  const rubricIid = get(props, 'rubricIid');
  const userIid = get(props, 'user.iid');

  return {
    baseUrl: endPoints.assessmentResult,
    fetchCondition: rubricIid && userIid,
    refetchCondition: (prevProps) =>
      rubricIid &&
      userIid &&
      (!get(prevProps, 'rubricIid') || !get(prevProps, 'user.iid')),
    params: {
      rubricIid: rubricIid,
      userIid: userIid,
    },
    method: 'get',
    propKey: 'mySelfAssessment',
  };
};

const fetchRubricDoAssessment = (props) => {
  const rubricIid = get(props, 'rubricIid');
  const rubricIidByUserPassed = get(props, 'user.rubric_iid');
  const scholastic = get(props, 'scholastic');
  return {
    baseUrl: endPoints.dataToAssessment,
    params: {
      rubric_iid: rubricIidByUserPassed ? rubricIidByUserPassed : rubricIid,
      scholastic,
    },
    propKey: 'rubric',
    formatDataResult: ({ rubrics, score_scale, assessment_name } = {}) => {
      const dataSource = formatDataSourceFromDataServerToRenderTableAssess(
        rubrics,
      );
      return {
        assessment_name,
        dataSource,
        score_scale,
      };
    },
    fetchCondition: rubricIidByUserPassed || rubricIid,
  };
};

const assessmentOfPeer = (props) => {
  const scholastic = get(props, 'scholastic');
  return {
    baseUrl: endpoints.assessmentOfPeersInPhongBan,
    propKey: 'listOfPeersAssignedToAssessGroupByTCNN',
    params: {
      scholastic,
    },
    formatDataResult: ({ rubric_iid, assessment_of_peers, phongban } = {}) => {
      if (
        !rubric_iid ||
        !Array.isArray(assessment_of_peers) ||
        !assessment_of_peers.length
      ) {
        return {};
      }
      return {
        phongban,
        rubricIid: rubric_iid,
        peers: assessment_of_peers.map(({ assessment, ...target }) => {
          return {
            rubric_iid,
            ...assessment,
            target,
            final_aggregate_assessment: true,
          };
        }),
      };
    },
    fetchCondition: true,
    refetchCondition: (prevProps) =>
      scholastic !== get(prevProps, 'scholastic'),
  };
};

export default fetchData(fetchSelfAssessment)(
  fetchData(assessmentOfPeer)(
    fetchData(fetchRubricDoAssessment)(ViewAssessmentOfPeersInPhongBan),
  ),
);
