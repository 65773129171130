import React, { Component } from 'react';
import NodeNew from 'components/admin/node/new';
import { connect } from 'react-redux';
import userSchema from 'components/admin/user/schema/form';
import { t1 } from 'translate';

const UserChangeByField = ({
  mode,
  ntype,
  step,
  node,
  searchFormId,
  schoolScope,
  hiddenFields,
  alternativeApi,
  title,
  formid = 'change_password',
  schema,
  requestSuccessful,
}) => {
  return (
    <div>
      <NodeNew
        title={title}
        ntype={ntype}
        mode={mode}
        step={step}
        node={node}
        schema={schema || userSchema}
        closeModal
        searchFormId={searchFormId}
        formid={formid}
        hiddenFields={Object.assign(
          {},
          schoolScope ? {} : { _sand_is_system: 1 },
          hiddenFields,
        )}
        submitLabel={t1('update')}
        alternativeApi={alternativeApi}
        oldSubmitButton
        turnOffFailureNotifications
        requestSuccessful={requestSuccessful}
      />
    </div>
  );
};

export default connect()(UserChangeByField);
