import {
  caphoc,
  simpleOrganization,
  targetUser,
} from 'components/common/elements';
import subTypes from 'configs/constants/org-sub-types';
import { isBo, isVGDStaff } from 'components/admin/user/utils';

const schema = () => {
  return {
    sgd: simpleOrganization(
      {
        floatingLabelText: 'SGD',
        classWrapper: 'col-md-12',
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_SO_GD],
        },
      },
      false,
      {
        value: '',
        label: 'Tất cả các đơn vị',
      },
    ),
    target_user: targetUser({
      type: 'radio',
      classWrapper: 'col-md-3',
    }),
    caps: caphoc({
      classWrapper: 'col-md-9',
    }),
  };
};

const ui = (step, values, themeConfig, xpath, formid, { userInfo }) => {
  return [
    {
      id: 'default',
      fields: [
        ...(isBo(userInfo) || isVGDStaff(userInfo) ? ['sgd'] : []),
        'target_user',
        'caps',
      ],
    },
  ];
};

export default { schema, ui };
