import React from 'react';
import BaseAction from './BaseAction';

const RejectedAction = (props) => (
  <BaseAction
    formid="review-rejected-request"
    labelButton="Từ chối"
    classButton="btn-tertiary"
    {...props}
  />
);

export default RejectedAction;
