import React from 'react';
import { Element } from 'schema-form/elements';
import fetchData from 'components/common/fetchData';
import organizationApiUrls from 'components/admin/organization/endpoints';
import { userGrades } from 'configs/constants/user';

const allGrade = {
  [userGrades.PRIMARY]: 1,
  [userGrades.SECONDARY]: 2,
  [userGrades.HIGH]: 3,
};

const MULTI_GRADE_PRIMARY_SECONDARY = 12;
const MULTI_GRADE_SECONDARY_HIGH = 23;
const MULTI_GRADE_ALL = 123;

const RadioGrade = ({
  floatingLabelText,
  options = [],
  validate,
  readOnly,
  defaultValue,
  organizationDetail = {},
  userOrganizationId,
}) => {
  const { grade = 0 } = organizationDetail;
  const isMultiGrade = () => {
    return (
      grade === MULTI_GRADE_PRIMARY_SECONDARY ||
      grade === MULTI_GRADE_SECONDARY_HIGH ||
      grade === MULTI_GRADE_ALL
    );
  };

  const getOptions = () => {
    if (isMultiGrade()) {
      const gradeString = String(grade);
      const optionWithDisabled = options.map((item) => ({
        ...item,
        disabled:
          userOrganizationId &&
          !gradeString.includes(String(allGrade[item.value])),
      }));
      return optionWithDisabled;
    }

    return options;
  };

  return (
    <div>
      <Element
        schema={{
          name: 'grade',
          type: 'radio',
          floatingLabelText,
          inline: true,
          options: getOptions(),
          validate,
          readOnly,
          defaultValue,
          userGrades: userGrades,
        }}
      />
    </div>
  );
};

const fetchOrganizationDetail = fetchData(({ userOrganizationId = '' }) => ({
  baseUrl: organizationApiUrls.get_organization_info,
  params: {
    iid: userOrganizationId,
  },
  propKey: 'organizationDetail',
  fetchCondition: userOrganizationId,
}));

export default fetchOrganizationDetail(RadioGrade);
