import React, { Fragment } from 'react';
import { t1 } from 'translate/index';
import {
  organizations,
  phongbanInOrganization,
} from 'components/admin/organization/schema/elements';
import get from 'lodash.get';
import { required } from 'common/validators';
import taphuanSubTypes from 'configs/constants/org-sub-types';

const schema = (formid, values, step, xpath, props, domainInfo) => {
  const orgTypes = get(domainInfo, 'school.org_types');
  const enableAssessmentForMamnon = get(
    domainInfo,
    'conf.enable_assessment_for_mamnon',
  );

  return {
    user_organizations: organizations({
      formid,
      multiple: false,
      label: `${t1('organizations')}`,
      defaultValue: props.orgIids,
      hasSearchDialog: true,
      subTypesDiscarded: props.isSo
        ? [
            taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD,
            !enableAssessmentForMamnon &&
              taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
          ]
        : [
            !enableAssessmentForMamnon &&
              taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
          ],
    }),
    text: {
      type: 'text',
      hintText: t1('Nhập Tên, Mã hoặc Email'),
      floatingLabelText: t1('Tìm kiếm giáo viên'),
      defaultValue: '',
      errorText: '',
      fullWidth: true,
    },
  };
};

const ui = () => [
  {
    id: 'default',
    fields: ['user_organizations', 'text'],
  },
];

const LayoutFreeStyle = ({ groups, submitButton }) => (
  <Fragment>
    <div className="row search-form-assessment">
      <div className="col-md-5">
        {get(groups, 'default.fieldNames.user_organizations')}
      </div>
      <div className="col-md-5">{get(groups, 'default.fieldNames.text')}</div>
      <div className="col-md-2 text-center m-t-5">{submitButton}</div>
    </div>
  </Fragment>
);

const layout = () => ({
  component: LayoutFreeStyle,
  freestyle: 1,
});

export default {
  schema,
  ui,
  layout,
};
