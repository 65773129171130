import React, { Component } from 'react';
import './style.scss';

class SimpleStatusSendAssessment extends Component {
  render() {
    const { className } = this.props;

    return (
      <div className={`simple-status-send-assessment m-b-15 ${className}`}>
        <div className="simple-status-send-assessment__sended-assessment">
          <span className="ve-check-circle" />
          Đã gửi đánh giá
        </div>
        <div className="simple-status-send-assessment__unsent-assessment">
          <span className="ve-edit-alt" />
          Chưa gửi đánh giá
        </div>
      </div>
    );
  }
}

export default SimpleStatusSendAssessment;
