import React from 'react';
import DeleteItem from 'components/common/action-button/DeleteBtnWithConfirmDialog';
import { t1 } from 'translate';
import apiUrls from 'api-endpoints';
import Edit from './edit-category';
import EditMembers from './edit-members';
import lodashGet from 'lodash.get';

class ResultActions extends React.Component {
  render() {
    const { item, ntype, organizations, searchTcmFormId } = this.props;
    const numberOfMember = !!lodashGet(item, '__expand.number_of_member');
    const showRemoveButton =
      (!Array.isArray(item.children) || !item.children.length) &&
      !numberOfMember;
    const removeLabel = t1('remove');

    return (
      <div className="d-flex justify-content-center">
        <EditMembers
          phongban={item}
          organizations={organizations}
          searchTcmFormId={searchTcmFormId}
        />

        <Edit
          item={item}
          organizations={organizations}
          searchFormId={searchTcmFormId}
        />

        {showRemoveButton && (
          <DeleteItem
            title={removeLabel}
            alternativeApi={apiUrls.category_delete}
            textConfirm={t1('are_you_sure_you_want_to_delete_%s?', [item.name])}
            formid={searchTcmFormId}
            ntype={ntype}
            itemId={item.id}
            params={{
              delete_from_database: 1,
              type: item.type,
              ntype,
            }}
            iconButton
          />
        )}
      </div>
    );
  }
}

export default ResultActions;
