import './stylesheet.scss';

import React from 'react';
import { connect } from 'react-redux';
import videoTypes from 'components/admin/video/schema/videoTypes';
import DisplayHtml from 'components/common/html';
import Loading from 'components/common/loading';
import { getNodeSelector } from 'components/admin/node/utils';
import MediaLecture from './media-lecture';
import Pdf from './pdf';
import Image from './Image';
import Swf from './Swf';
import CustomScrollbars from 'components/common/custom-scrollbars';
import Discussion from '../discussion';
import ItemInBlock from './ItemInBlock';
import { saveLearningProgress as saveItemProgress } from 'common/learn';
import MicrosoftOffice from './microsoft-office';
import BlockVideo from 'components/learn/items/sco-spl/block/video';
import BlockLink from 'components/learn/items/sco-spl/block/link';
import BlockH5P from 'components/learn/items/sco-spl/block/h5p';

const TIME_READ_AVERAGE_PER_MIN = 700; // words, exam: 400 words/min
const DEFAULT_TIME_HAVE_TO_ON_PAGE = 2; // second

class LectureItem extends React.Component {
  constructor(props) {
    super(props);

    this.timeInterval = null;
  }

  componentDidMount() {
    const { lecture, progress } = this.props;
    const passed = progress && progress.pf;

    if (this.shouldAutoActivateCompleteProgress(lecture.type) && !passed) {
      let secondTime = DEFAULT_TIME_HAVE_TO_ON_PAGE;

      if (lecture.type === videoTypes.TYPE_TEXT) {
        secondTime = (lecture.content || '').length / TIME_READ_AVERAGE_PER_MIN;
      }

      this.autoActivateCompleteProgress(secondTime);
    }
  }

  componentWillUnmount() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  }

  shouldAutoActivateCompleteProgress = (type) =>
    [videoTypes.TYPE_SWF, videoTypes.TYPE_IMAGE, videoTypes.TYPE_TEXT].includes(
      type,
    );

  autoActivateCompleteProgress = (secondTime) => {
    const { learnItemIid } = this.props;

    this.timeInterval = setInterval(() => {
      this.saveLearningProgress([
        {
          tco_iid: learnItemIid,
          p: 100,
          cp: 100,
          pf: 1,
        },
      ]);

      clearInterval(this.timeInterval);
    }, secondTime * 1000);
  };

  saveLearningProgress = (progress = []) => {
    const { courseIid, dispatch, isPreview, isReview } = this.props;

    saveItemProgress(progress, courseIid, dispatch, isPreview, isReview);
  };

  getContent(lecture, props, renderLectureItem) {
    const showContent =
      lecture.content &&
      ![
        videoTypes.TYPE_VIDEO,
        videoTypes.TYPE_SWF,
        videoTypes.TYPE_PDF,
        videoTypes.TYPE_WORD,
        videoTypes.TYPE_EXCEL,
        videoTypes.TYPE_PPT,
      ].includes(lecture.type);

    const showDiscussion =
      lecture.ntype === videoTypes.TYPE_VIDEO && !!lecture.show_discussion_box;
    return (
      <div
        className={`learn-lecture ${showDiscussion ? 'show-discussion' : ''}`}
      >
        {renderLectureItem(lecture, props, 'learn-lecture__main')}
        {showContent && (
          <div className="learn-lecture__content learn-lecture__text-large font-size-medium">
            <DisplayHtml content={lecture.content} />
            {showDiscussion && <Discussion />}
          </div>
        )}
        {!showContent && showDiscussion && (
          <div className="learn-lecture__content">
            <Discussion />
          </div>
        )}
      </div>
    );
  }

  renderLectureItem = (lecture, props, className) => {
    switch (lecture.type) {
      case videoTypes.TYPE_AUDIO: {
        return (
          <MediaLecture
            className={`${className} learn-content-border`}
            type="audio"
            {...props}
            saveLearningProgress={this.saveLearningProgress}
          />
        );
      }
      case videoTypes.TYPE_IMAGE: {
        return (
          <Image
            className={`${className} learn-content-border`}
            {...props}
            saveLearningProgress={this.saveLearningProgress}
          />
        );
      }
      case videoTypes.TYPE_WORD:
      case videoTypes.TYPE_PPT:
      case videoTypes.TYPE_EXCEL: {
        return (
          <MicrosoftOffice
            className={`${className} learn-content-border`}
            {...props}
            lectureType={lecture.type}
            saveLearningProgress={this.saveLearningProgress}
            hiddenHeading={true}
          />
        );
      }
      case videoTypes.TYPE_PDF: {
        return (
          <Pdf
            className={className}
            {...props}
            saveLearningProgress={this.saveLearningProgress}
            notShowFullscreenButton
          />
        );
      }
      case videoTypes.TYPE_SWF: {
        return <Swf className={className} {...props} />;
      }
      default:
        return <div />;
    }
  };

  render() {
    const {
      disableScrollbarMode,
      course,
      lecture,
      isPreview,
      isReview,
      syllabus,
    } = this.props;

    if (!lecture) {
      return <Loading />;
    }

    const props = {
      ...this.props,
      node: lecture,
    };

    let content;
    const fixedHeight =
      disableScrollbarMode ||
      [
        videoTypes.TYPE_PDF,
        videoTypes.TYPE_VIDEO,
        videoTypes.TYPE_WORD,
        videoTypes.TYPE_PPT,
        videoTypes.TYPE_EXCEL,
      ].includes(lecture.type);

    if (lecture.type === videoTypes.TYPE_VIDEO) {
      content = (
        <BlockVideo
          item={lecture}
          course={course}
          isPreview={isPreview}
          isReview={isReview}
          hiddenHeading={true}
          style={{ width: '100%', height: '100%' }}
        />
      );
    } else if (lecture.type === videoTypes.TYPE_LINK) {
      content = (
        <BlockLink
          item={lecture}
          course={course}
          isPreview={isPreview}
          isReview={isReview}
          hiddenHeading={true}
        />
      );
    } else if (lecture.type === videoTypes.TYPE_H5P) {
      content = (
        <BlockH5P
          item={lecture}
          course={course}
          isPreview={isPreview}
          isReview={isReview}
          hiddenHeading={true}
        />
      );
    } else {
      if (fixedHeight) {
        content = (
          <div
            className="learn-lecture-wrapper"
            style={{ height: 'unset !important' }}
          >
            {this.getContent(lecture, props, this.renderLectureItem)}
          </div>
        );
      }

      // TODO: check ntype == 'video' && all the types that should be displayed in block
      else if (lecture.block_settings) {
        content = (
          <ItemInBlock
            course={course}
            item={lecture}
            iid={lecture.iid}
            syllabus={syllabus}
          />
        );
      } else {
        content = this.getContent(lecture, props, this.renderLectureItem);
      }
    }

    if (lecture.sco_iid) {
      content = <>{content}</>;
    }

    if (fixedHeight) {
      return content;
    }

    if (lecture.block_settings) {
      return content;
    }

    return (
      <div className="learn-lecture-wrapper learn-content-border">
        <CustomScrollbars
          className="nav-scroll"
          style={{ minHeight: '450px', position: 'relative' }}
        >
          {content}
        </CustomScrollbars>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const lecture = props.lecture;
  if (lecture) {
    return { lecture };
  }
  const learnItemIid = state.learn.itemIid;
  const parentIid = state.learn.parentIid;
  const progress = state.trackerProgress && state.trackerProgress[learnItemIid];

  return {
    learnItemIid,
    progress,
    lecture: getNodeSelector(state)(learnItemIid, parentIid) || {},
    isPreview: state.learn.isPreview,
    isReview: state.learn.isReview,
  };
};

export default connect(mapStateToProps)(LectureItem);
