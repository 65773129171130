import React from 'react';
import NodeNew from 'components/admin/node/new';
import { t1 } from 'translate';
import useDisplayErrorDialogWhenDeleteFailed from 'components/admin/user/account/delete-users/useDisplayErrorDialogWhenDeleteFailed';
import withGlobalDialogs from 'components/common/modal/withGlobalDialogs';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import userApiEndpoints from 'components/admin/user/endpoints';

const getSchema = ({ canSelectAssessmentPhase }) => {
  return {
    schema: {
      assessment_phases: {
        fullWidth: true,
        type: 'multiCheckbox',
        defaultValue: ['in_taphuan'],
        options: 'async',
        paramsasync: {
          __url__: '/assessment/phase/get-all',
          transformData: (data) => {
            return [
              {
                label: 'Tài khoản trên Tập Huấn',
                value: 'in_taphuan',
                disabled: true,
              },
            ].concat(
              Array.isArray(data)
                ? data.map(({ name, id }) => {
                    return {
                      label: `Tài khoản trên TEMIS năm ${name}`,
                      value: id,
                    };
                  })
                : [],
            );
          },
        },
      },
    },
    ui: () => [
      {
        id: 'default',
        fields: [...(canSelectAssessmentPhase ? ['assessment_phases'] : [])],
      },
    ],
  };
};

const Form = ({
  formid,
  searchFormId,
  ids,
  onSelectRowKeysChange,
  hiddenFields = {},
  canSelectAssessmentPhase,
  formDialogKey,

  openDialog,
  closeDialog,

  selectedAssessmentPhasesOptions,
}) => {
  const errorDialogKey = 'button_delete_accounts_errors';

  const executeOnFailure = useDisplayErrorDialogWhenDeleteFailed({
    openDialog,
    closeDialog,
    dialogKey: errorDialogKey,
    onCloseDialog: () => {
      closeDialog(formDialogKey);
    },
    selectedAssessmentPhasesOptions,
  });

  return (
    <NodeNew
      schema={getSchema({ canSelectAssessmentPhase })}
      searchFormId={searchFormId}
      formid={formid}
      submitLabel={t1('delete')}
      alternativeApi={userApiEndpoints.delete_user}
      hiddenFields={{
        ...hiddenFields,
        ids,
      }}
      requestSuccessful={() => {
        closeDialog(formDialogKey);
        if (typeof onSelectRowKeysChange === 'function') {
          onSelectRowKeysChange([]);
        }
      }}
      requestFailedCallback={executeOnFailure}
    />
  );
};

export default connect(
  createSelector(
    (state, props) => {
      const { formid } = props;
      const selectFormValue = formValueSelector(formid);
      return selectFormValue(state, 'assessment_phases');
    },
    (selectedAssessmentPhasesOptions) => ({
      selectedAssessmentPhasesOptions,
    }),
  ),
)(withGlobalDialogs()(Form));
