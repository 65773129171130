import React, { Component } from 'react';
import { compose } from 'redux';
import withTemisConfig from 'common/hoc/withTemisConfig';
import withUserInfo from 'common/hoc/withUserInfo';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import './stylesheet.scss';
import TableResult from './TableResult';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { isSGDStaff } from 'components/admin/user/utils';
import endpoints from '../../endpoints';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import { TCNN_TYPE } from 'components/temis/constant';

const formid = 'approve_teacher-to-become-master';

class ChooseManager extends Component {
  renderResult = (apiResponseData = []) => {
    const { scholastic, isOutOfDeadlineAssessment } = this.props;
    return (
      <TableResult
        apiResponseData={apiResponseData}
        searchFormId={formid}
        scholastic={scholastic}
        viewOnly={isOutOfDeadlineAssessment}
      />
    );
  };

  renderNoResult = () => (
    <div className="col-md-12">
      <SimpleNoResult />
    </div>
  );

  render() {
    const { userInfo, scholastic } = this.props;
    const org_province_id = get(userInfo, 'organizations[0].org_province_id');
    const org_district_id = get(userInfo, 'organizations[0].org_district_id');
    const hiddenFields = {
      tcnn: TCNN_TYPE.HT,
      scholastic,
    };
    const isSo = isSGDStaff(userInfo);
    return (
      <SearchWrapper
        key={`${formid}_${scholastic}`}
        formid={formid}
        alternativeApi={endpoints.listUserHaveDesireToBecomeMaster}
        schema={schema}
        renderResultsComponent={this.renderResult}
        renderNoResultComponent={this.renderNoResult}
        hiddenFields={hiddenFields}
        org_province_id={org_province_id}
        org_district_id={org_district_id}
        isSo={isSo}
        classPaginationWrapper="pagination-default"
      />
    );
  }
}

const mapStateToProps = (state) => {
  const allOrgTypes = get(state, 'domainInfo.school.org_types') || [];
  const orgTypes = allOrgTypes.filter((orgType) => orgType.has_perm);

  return {
    orgTypes,
  };
};

export default compose(
  connect(mapStateToProps),
  withOutOfDeadlineAssessment,
  withScholastic,
  withUserInfo,
  withTemisConfig,
)(ChooseManager);
