import React from 'react';
import {
  isSo,
  isPhong,
  isBoOrAdminSP,
  isAdminTruongOrHieuTruongOrPho,
  isAmTinh,
} from 'components/admin/user/utils';
import withTemisConfig from 'common/hoc/withTemisConfig';
import get from 'lodash.get';
import BDTXTruong from './BDTXTruong';
import BDTXPhong from './BDTXPhong';
import BDTXSo from './BDTXSo';
import BDTXBo from './BDTXBo';

import TabList from '../../components/tabs';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import { getReportCTitle } from '../utils';
import queryString from 'query-string';

const TemisReport = ({ userRoot, scholastic, ...props }) => {
  const isResultForCBQL = get(props, 'route.isResultForCBQL', false);
  const isResultForMN = get(props, 'route.isResultForMN', false);
  const isPlanDevelopment = get(props, 'route.isPlanDevelopment', false);
  const queryParams = queryString.parse(window.location.search);
  const isThongKeReport = get(queryParams, 'reportType') === 'thongke';

  let reportTitle = getReportCTitle(
    isResultForMN,
    isThongKeReport,
    isResultForCBQL,
    isPlanDevelopment,
  );

  const tabs = [
    isAdminTruongOrHieuTruongOrPho(userRoot) && {
      name: 'bdtx_truong',
      label: 'BDTX trường',
      component: (
        <BDTXTruong
          scholastic={scholastic}
          isResultForMN={isResultForMN}
          isResultForCBQL={isResultForCBQL}
          isPlanDevelopment={isPlanDevelopment}
          isReportOfTruong
          reportTitle={reportTitle}
        />
      ),
    },
    isPhong(userRoot) && {
      name: 'bdtx_p',
      label: 'BDTX Phòng',
      component: (
        <BDTXPhong
          scholastic={scholastic}
          isResultForMN={isResultForMN}
          isResultForCBQL={isResultForCBQL}
          isPlanDevelopment={isPlanDevelopment}
          reportTitle={reportTitle}
        />
      ),
    },
    (isSo(userRoot) || isAmTinh(userRoot)) && {
      name: 'bdtx_so',
      label: 'BDTX Sở',
      component: (
        <BDTXSo
          scholastic={scholastic}
          isResultForMN={isResultForMN}
          isResultForCBQL={isResultForCBQL}
          isPlanDevelopment={isPlanDevelopment}
          reportTitle={reportTitle}
        />
      ),
    },
    isBoOrAdminSP(userRoot) && {
      name: 'bdtx_bo',
      label: 'BDTX Bộ',
      component: (
        <BDTXBo
          scholastic={scholastic}
          isResultForMN={isResultForMN}
          isResultForCBQL={isResultForCBQL}
          isPlanDevelopment={isPlanDevelopment}
          reportTitle={reportTitle}
        />
      ),
    },
  ].filter(Boolean);

  return <TabList tabList={tabs} key={scholastic} />;
};

export default withScholastic(withTemisConfig(TemisReport));
