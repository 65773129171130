import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import CoworkerAssessmentResult from 'components/temis/assessment/coworker-assessment-result';
import './style.scss';

const SummaryOpinion = ({ userIid = '', buttonClass = '' }) => (
  <DetailOnDialog
    renderPreview={({ showFull }) => (
      <button
        type="button"
        className={`btn btn-secondary btn-outline ${buttonClass}`}
        onClick={() => showFull()}
      >
        Xem tổng hợp ý kiến của GV, NV
      </button>
    )}
    renderFull={() => <CoworkerAssessmentResult userIid={userIid} />}
    dialogKey="summary_opinion"
    dialogOptionsProperties={{
      width: '100%',
      className: 'modal-full-height',
      title: 'Tổng hợp ý kiến của GV, NV',
    }}
  />
);

export default SummaryOpinion;
