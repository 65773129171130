import React from 'react';
import getLodash from 'lodash.get';
import Loading from 'components/common/loading';
import fetchData from 'components/common/fetchData';
import { t1 } from 'translate';
import List from 'antd/lib/list';
import Button from 'antd/lib/button';
import connect from 'react-redux/es/connect/connect';
import { logout } from 'actions/auth';
import Skeleton from 'antd/lib/skeleton';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Avatar from 'components/common/avatar';
import actions from '../../../../actions/node/creators';
import sagaActions from '../../../../actions/node/saga-creators';
import Warning from '../../../common/Warning';
import Spin from 'antd/lib/spin';
import './style.scss';

const MatchingUserField = ({ user, field }) => {
  const matchingKeys = getLodash(user, 'matching_keys');
  const isMatching =
    matchingKeys && matchingKeys.length && matchingKeys.includes(field);
  const text = getLodash(user, field);
  if (isMatching) {
    return <Warning inline>{text} (*) Bị Trùng</Warning>;
  } else {
    return <span>{text}</span>;
  }
};

const MatchingUserDescription = ({ matchedUser }) => {
  const matchingKeys = getLodash(matchedUser, 'matching_keys');
  return (
    <div>
      email: <MatchingUserField user={matchedUser} field="mail" /> <br />
      phone: <MatchingUserField user={matchedUser} field="phone" /> <br />
      CMT: <MatchingUserField
        user={matchedUser}
        field="identification_card"
      />{' '}
      <br />
      {!matchingKeys && (
        <div>
          <Warning inline>
            Có GV trùng với bạn về: Ngày sinh, Tên trong cùng đơn vị của bạn
            chọn
          </Warning>
          <div />
        </div>
      )}
    </div>
  );
};

const CheckDuplicateAndSaveProfile = ({
  loadingStatus,
  duplicateedUsers,
  dispatch,
  closeDialog,
  sendFeedback,
  user,
}) => {
  if (loadingStatus !== 'finished') {
    return (
      <div className="text-center check-data-loading">
        <Spin tip={`${t1('check_your_information')}...`} />
      </div>
    );
  }

  if (
    loadingStatus === 'finished' &&
    !(Array.isArray(duplicateedUsers) && duplicateedUsers.length)
  ) {
    return <h3>{t1('error')}</h3>;
  }

  return (
    <div>
      <h1>
        Hệ thống tìm thấy những tài khoản sau có khả năng trùng với tài khoản
        của bạn
      </h1>
      <b>Vui lòng kiểm tra lại</b>

      <List
        itemLayout="horizontal"
        dataSource={duplicateedUsers}
        renderItem={(item) => {
          return (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={<Avatar user={item} />}
                title={item.name}
                description={<MatchingUserDescription matchedUser={item} />}
              />
              <div>
                <Button ghost type="primary" onClick={() => dispatch(logout())}>
                  đây là tôi
                </Button>
              </div>
            </List.Item>
          );
        }}
      />
      <div className="text-center m-t-10">
        <Button
          type="danger"
          onClick={() => {
            closeDialog();
            sendFeedback(user);
          }}
        >
          Gửi yêu cầu hỗ trợ
        </Button>
        <div>(Tài khoản của bạn sẽ được rà soát và liên hệ khi cần thiết)</div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch, props) => {
  return {
    sendFeedback: (params = {}) => {
      dispatch(
        sagaActions.getDataRequest(
          {
            url: '/temis/profile/send-feedback-duplicated-users',
            post: true,
          },
          params,
        ),
      );
    },
    dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  fetchData(({ user, validateSuccessDoesNotOverlap = () => {} }) => {
    return {
      baseUrl: '/temis/profile/get-duplicated-users',
      params: {
        identification_card: getLodash(user, 'identification_card'),
        phone: getLodash(user, 'phone'),
        name: getLodash(user, 'name'),
        user_organizations: getLodash(user, 'user_organizations'),
        birthday: getLodash(user, 'birthday'),
        mail: getLodash(user, 'mail'),
      },
      propKey: 'duplicateedUsers',
      formatDataResult: (duplicateedUsers = {}, props, loadingStatus) => {
        if (
          loadingStatus === 'finished' &&
          (!Array.isArray(duplicateedUsers) || !duplicateedUsers.length)
        ) {
          validateSuccessDoesNotOverlap();
          return {};
        }
        return {
          duplicateedUsers,
        };
      },
      fetchCondition: true,
      refetchCondition: () => false,
    };
  })(CheckDuplicateAndSaveProfile),
);
