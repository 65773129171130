export default {
  bdtx_get_chosen_module: '/bdtx/assign-module/api/get-chosen-modules',
  bdtx_get_assign_module: '/bdtx/assign-module/api/list',
  bdtx_update_assign_module:
    '/bdtx/training-phase/deploy/update-assign-modules',
  bdtx_deployment: 'bdtx/api/auto-create-eps-for-tp',
  bdtx_get_training_plan_by_school_year:
    'bdtx/api/get-training-plan-by-school-year',
  bdtx_get_organizations_to_deploy: 'bdtx/api/get-organizations-to-deploy',
  bdtx_get_list_training_phase: 'bdtx/training-phase/api/search',
  bdtx_get_training_phase: 'bdtx/training-phase/api/get',
  bdtx_update_training_phase: 'bdtx/training-phase/api/update',
  bdtx_new_training_phase: 'bdtx/training-phase/api/new',
  bdtx_delete_training_phase: 'bdtx/training-phase/api/delete',
  bdtx_preview_tps: 'bdtx/training-phase/deploy/get-deployment-status',
  bdtx_get_caps_of_training_phase: 'bdtx/training-phase/schema-form/get-caps',
  bdtx_cancel_deploy_for_organization:
    'bdtx/training-phase/deploy/cancel-deploy-for-organization',
};
