import React, { useEffect, useState } from 'react';
import { submit, change } from 'redux-form';
import NodeNew from 'components/admin/node/new';
import getSchema, { getDefaultValueOfGrade } from './schema';
import RaisedButton from 'components/common/mui/RaisedButton';
import { t1 } from 'translate';
import fetchData from 'components/common/fetchData';
import Skeleton from 'antd/lib/skeleton';
import CheckDuplicateAndSaveProfile from './CheckDuplicateAndSaveProfile';
import DetailOnDialog from 'components/common/detail-on-dialog';
import { createSelector } from 'reselect';
import { getOrgTypes } from 'configs/constants';
import { confSelector } from 'common/selectors';
import { getOrganizationSubTypesToDisplayInTemisProfileFormGivenConf } from 'common/conf';
import { connect } from 'react-redux';
import lodashGet from 'lodash.get';
import actions from 'actions/node/creators';
import {
  isAdminTruong,
  isAmTinh,
  isLTTUStaff,
} from 'components/admin/user/utils';
import { extractObject } from 'common/utils/Array';
import { t3 } from 'translate';

export const formId = 'new_temis';

const updateProfileForm = (props) => {
  return (
    <NodeNew
      ntype={'temis'}
      mode="edit"
      step="temis"
      formid={formId}
      alternativeApi={'/temis/profile/update'}
      submitButton={
        <RaisedButton
          primary
          label={t1('save')}
          type="submit"
          className="save-submit-button"
          raisedButton
        />
      }
      closeModal={false}
      turnOffNotifications
      {...props}
    />
  );
};

const TemisProfileForm = ({
  user,
  orgTypes,
  markAsHaveEnterTemisProfileInfoWhenSubmit,
  onSuccess,
  dispatch,
  refetchUser,
  notShowCardShadow,
  showAlertMessage,
  updateField,
  isEditAccount,
}) => {
  const [values, setValue] = useState(null);
  const [reponseUpdate, setReponseUpdate] = useState(null);
  const isHRMS = lodashGet(user, 'hrms');

  const schema = React.useMemo(
    () =>
      getSchema({
        title: t3('etep_profile_form_title'),
      }),
    [],
  );

  const hiddenFields = React.useMemo(
    () => {
      const value = {};
      if (markAsHaveEnterTemisProfileInfoWhenSubmit) {
        value.have_enter_temis_profile_info = 1;
      }

      const showExpGroup =
        !isAdminTruong(user) && !isLTTUStaff(user) && !isAmTinh(user);

      if (showExpGroup) {
        return value;
      }

      return Object.assign(
        value,
        extractObject(user, [
          'qualifications',
          'teaching_specialization_select',
          'teaching_specialization',
          'leader_position',
          'current_position',
          'other_current_position',
          'ngay_vao_bien_che',
          'managing_date',
          'teaching_exp_years',
          'managing_exp_years',
          'grade',
        ]),
      );
    },
    [markAsHaveEnterTemisProfileInfoWhenSubmit, user],
  );

  if (!user) return <Skeleton />;

  const propsRender = {
    reponseUpdate,
    node: user,
    requestSuccessful: (res) => {
      setReponseUpdate(res);
      if (typeof onSuccess === 'function') {
        onSuccess(res);
      }
    },
    requestFailedCallback: (res) => {
      if (!res || !res.success) {
        dispatch(actions.snackbar('error', res.message || t1('update_failed')));
      }
      setReponseUpdate(res);
    },
    schema,
    hiddenFields,
    allOrgTypes: orgTypes,
    refetchUser: refetchUser,
    notShowCardShadow,
    showAlertMessage,
    updateField,
    isEditAccount,
  };

  return window.isETEP ? (
    <DetailOnDialog
      renderPreview={({ showFull }) => {
        propsRender.submitButton = (formValues) => {
          setValue(formValues);
          return (
            <button
              type="button"
              onClick={showFull}
              className="btn btn-primary"
            >
              {t1('complete')}
            </button>
          );
        };
        return updateProfileForm(propsRender);
      }}
      renderFull={({ closeDialog }) => {
        return (
          <CheckDuplicateAndSaveProfile
            user={values}
            closeDialog={closeDialog}
            validateSuccessDoesNotOverlap={() => {
              dispatch(submit(formId));
              closeDialog();
            }}
          />
        );
      }}
      dialogKey="temis_find_duplicate"
    />
  ) : (
    updateProfileForm(propsRender)
  );
};

const mapStateToProps = createSelector(
  (state) => getOrgTypes(state, '*'),
  (state) => lodashGet(state, `form.${formId}.values`),
  confSelector,
  (allOrgTypes, formValues, conf) => {
    const organizationSubTypesToDisplayInTemisProfileForm = getOrganizationSubTypesToDisplayInTemisProfileFormGivenConf(
      conf,
    );

    const orgTypes = (allOrgTypes || []).filter((ot) => {
      return (
        organizationSubTypesToDisplayInTemisProfileForm &&
        organizationSubTypesToDisplayInTemisProfileForm.find(
          (subType) => subType == lodashGet(ot, 'value'),
        )
      );
    });

    return {
      orgTypes,
      formValues,
    };
  },
);

const mapDispatchToProps = (dispatch) => ({
  updateField: (field, value) => dispatch(change(formId, field, value)),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  fetchData(({ userId }) => ({
    baseUrl: '/temis/profile/get',
    fetchCondition: userId,
    refetchCondition: (prevProps) => userId && userId !== prevProps.userId,
    params: {
      id: userId,
    },
    method: 'get',
    propKey: 'user',
    fetchFunctionPropKey: 'refetchUser',
  }))(TemisProfileForm),
);
