import React, { Component } from 'react';
import TCNNReportSearch from './component/Form';
import withUserInfo from 'common/hoc/withUserInfo';
import { EXPORT_FILENAME, TCNN_TYPE } from 'components/temis/constant';
import { generateReportTitle } from 'components/temis/utils';

class TCNNPhong extends Component {
  render() {
    const {
      userInfo = {},
      scholastic,
      isResultByRubric,
      isResultForCBQL,
      reportTitle,
      showSearchForm,
      isResultForMN,
    } = this.props;

    const { organizations = [] } = userInfo;
    const phong = organizations[0];

    const excelExportFileName = generateReportTitle(reportTitle);

    return (
      <div className="report-result">
        <div className="report-result__organization m-b-10">{`Phòng giáo dục: ${!!phong &&
          phong.name}`}</div>

        <div>
          <div className="report-result__school-export-excel">
            <div className="report-result__tnnn-type">{reportTitle}</div>
          </div>

          <hr />

          <div className="report-result__container">
            {isResultForCBQL ? (
              <TCNNReportSearch
                tcnn={TCNN_TYPE.HT}
                scholastic={scholastic}
                isResultForCBQL
                isResultForMN={isResultForMN}
                isResultByRubric={isResultByRubric}
                exportProps={{
                  fileName: EXPORT_FILENAME.overallResultAssessmentByTCNNHT,
                }}
                hideSubmitButton
                autoSearchWhenValuesChange
                userInfo={userInfo}
                excelExportFileName={excelExportFileName}
                showSearchForm={showSearchForm}
              />
            ) : (
              <TCNNReportSearch
                tcnn={TCNN_TYPE.GV}
                scholastic={scholastic}
                isResultForMN={isResultForMN}
                isResultByRubric={isResultByRubric}
                exportProps={{
                  fileName: EXPORT_FILENAME.overallResultAssessmentByTCNNGV,
                }}
                hideSubmitButton
                autoSearchWhenValuesChange
                userInfo={userInfo}
                excelExportFileName={excelExportFileName}
                showSearchForm={showSearchForm}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withUserInfo(TCNNPhong);
