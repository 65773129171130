import React from 'react';
import get from 'lodash.get';

const LayoutFreeStyle = (props) => {
  const fieldNames = get(props, 'groups.id.fieldNames') || {};

  return (
    <div className="row search-form-assessment disabled-select-organizations">
      <div className="col-md-3">{fieldNames.user_organizations}</div>
      <div className="col-md-3">{fieldNames.phongban_id}</div>
      <div className="col-md-4">{fieldNames.text}</div>
      <div className="col-md-2 text-center m-t-10">
        {get(props, 'submitButton')}
      </div>
    </div>
  );
};

export default LayoutFreeStyle;
