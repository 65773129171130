import React from 'react';
import CommonAntdTable from 'components/common/antd/table';
import Title from 'components/temis/common/Title';
import get from 'lodash.get';
import { generateClassByStatusAssessment } from 'components/temis/utils';
import ExportData from 'components/common/export-data';
import TooltipAssessment from 'components/temis/components/tooltip-assessment';
import { isSmallScreen } from 'common';
import './stylesheets.scss';
import { EXPORT_FILENAME } from 'components/temis/constant';

const getColumns = ({
  assessment_name,
  score_scale,
  peers,
  mySelfAssessment,
}) => {
  const parts = get(score_scale, 'parts');
  let labelScoreById = null;
  if (Array.isArray(parts) && parts.length) {
    labelScoreById = {};
    parts.forEach(({ id, name }) => {
      labelScoreById[id] = name;
    });
  }

  const numberOfAssess = Array.isArray(peers) ? peers.length : 0;

  return [
    {
      title: assessment_name,
      fixed: 'left',
      render: ({ isHeader, name, description, depth }) => {
        return {
          children: (
            <div
              style={{
                fontWeight: isHeader ? 'bold' : '',
                marginLeft: 20 * depth,
              }}
            >
              <span className="m-r-5">{name}</span>
              {!isHeader && description && (
                <TooltipAssessment content={description} />
              )}
            </div>
          ),
        };
      },
      width: isSmallScreen ? 220 : 500,
    },
    {
      title: 'Kết quả tự đánh giá',
      className: 'text-center',
      width: 120,
      render: ({ isHeader, iid }) => {
        const answerByRubric = get(mySelfAssessment, 'task', []).find(
          (as) => String(iid) === String(as && as.rubric_iid),
        );
        let part = {};
        if (answerByRubric && typeof answerByRubric.answer !== 'undefined') {
          part = score_scale.parts.find(
            ({ id }) => String(id) === String(answerByRubric.answer),
          );
        }
        return {
          children: get(part, 'name'),
          props: {
            colSpan: isHeader ? 0 : 1,
            className: `text-center ${generateClassByStatusAssessment(
              get(part, 'name'),
            )}`,
          },
        };
      },
    },
    {
      title: (
        <div className="text-center">
          <Title />
        </div>
      ),
      className: 'text-center',
      children:
        numberOfAssess > 0
          ? peers.map(({ target: { iid, name }, task }) => {
              if (!iid) {
                return false;
              }

              const tasks = Array.isArray(task) ? task : [];
              return {
                title: [<div>{name}</div>],
                className: 'text-center',
                render: ({ isHeader, iid }) => {
                  const answerByRubric = tasks.find(
                    ({ rubric_iid }) => String(rubric_iid) == String(iid),
                  );
                  const answerSelected = get(
                    labelScoreById,
                    get(answerByRubric, 'answer'),
                  );

                  return {
                    children: answerSelected,
                    props: {
                      colSpan: isHeader ? 0 : 1,
                      className: `text-center ${generateClassByStatusAssessment(
                        answerSelected,
                      )}`,
                    },
                  };
                },
              };
            })
          : null,
    },
  ].filter(Boolean);
};

const AssessmentPeerInOrganization = ({
  assessment_name,
  score_scale,
  dataSource,
  assessments,
  peers,
  phongban,
  scholastic,
  mySelfAssessment,
}) => (
  <>
    <div className="rubric m-b-15">
      <p className="m-b-0">Tổ chuyên môn: {get(phongban, 'name')}</p>
      <ExportData
        apiExport="/temis/export/export-assessment-result-of-peers-in-phongban"
        buttonText="Xuất excel BM3/PL2"
        formId="search-score-by-rubric"
        params={{
          scholastic,
        }}
        fileName={EXPORT_FILENAME.teacher.BM3_PL2}
      />
    </div>
    <CommonAntdTable
      columns={getColumns({
        assessment_name,
        score_scale,
        dataSource,
        assessments,
        peers,
        mySelfAssessment,
      })}
      dataSource={dataSource}
      pagination={false}
      bordered
      size="middle"
      className="learn-default assessment-peer-in-organization"
      scroll={{
        x: true,
      }}
    />
  </>
);

export default AssessmentPeerInOrganization;
