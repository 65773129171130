import React from 'react';
import DoAssessment from '../do-assessment';
import fetchData from 'components/common/fetchData';
import lodashGet from 'lodash.get';
import Skeleton from 'antd/lib/skeleton';
import endPoints from '../endpoints';

const TcnnAssessment = ({
  formId,
  loadingStatus,
  node,
  userIid,
  peerAssess,
  readOnly,
  requestSuccessful = null,
  dialogKey,
  title = '',
  ignoreUserIidParamToEvidenceAPI,
  hideBecomeMaster,
  scholastic,
  useTeamLeaderResult,
  useTeacherResult,
  usePrincipalResult,
  isPrincipalNotHasSelfAssessment,
  currentStatusOfParentToDoAssessment,
  assessorIid,
  finalAggregateAssessment,
  userForAssessment,
}) => {
  if (typeof loadingStatus !== 'undefined' && loadingStatus !== 'finished') {
    return <Skeleton active />;
  }

  const hiddenFields = {
    target: {
      iid: userIid,
      ntype: 'user',
    },
    scholastic,
    final_aggregate_assessment: finalAggregateAssessment,
  };
  if (peerAssess) {
    hiddenFields.peer_assess = 1;
  }

  const getNode = () => {
    if (useTeacherResult || usePrincipalResult) {
      return node
        ? { ...node, status: currentStatusOfParentToDoAssessment }
        : {};
    }

    if (!useTeamLeaderResult) {
      return node || {};
    }

    return {
      ...(node && node.length ? node[0] : {}),
      status: currentStatusOfParentToDoAssessment,
    };
  };

  return (
    <>
      {!!title && <h1 className={'text-center temis-main-title'}>{title}</h1>}

      <DoAssessment
        formId={formId}
        readOnly={readOnly}
        hiddenFields={hiddenFields}
        peerAssess={peerAssess}
        requestSuccessful={requestSuccessful}
        node={getNode()}
        dialogKey={dialogKey}
        ignoreUserIidParamToEvidenceAPI={ignoreUserIidParamToEvidenceAPI}
        hideBecomeMaster={hideBecomeMaster}
        scholastic={scholastic}
        useTeamLeaderResult={useTeamLeaderResult}
        isPrincipalNotHasSelfAssessment={isPrincipalNotHasSelfAssessment}
        assessorIid={assessorIid}
        finalAggregateAssessment={finalAggregateAssessment}
        userForAssessment={userForAssessment}
      />
    </>
  );
};

const getParams = (peerAssess, props) => {
  const useTeamLeaderResult = lodashGet(props, 'useTeamLeaderResult');
  const useTeacherResult = lodashGet(props, 'useTeacherResult');
  const usePrincipalResult = lodashGet(props, 'usePrincipalResult');
  const userIid = lodashGet(props, 'userIid');
  const scholastic = lodashGet(props, 'scholastic');
  const assessorIid = lodashGet(props, 'assessorIid');
  const finalAggregateAssessment = lodashGet(props, 'finalAggregateAssessment');

  if (useTeamLeaderResult) {
    return {
      user_iid: userIid,
      useSuperiorAssessment: 0,
      peer_assess: peerAssess ? 1 : 0,
      scholastic,
      final_aggregate_assessment: 0,
    };
  }

  const peeAssessParam =
    peerAssess && !useTeacherResult && !usePrincipalResult ? 1 : 0;

  return {
    userIid,
    peer_assess: peeAssessParam,
    assessorIid: peeAssessParam && assessorIid ? assessorIid : null,
    scholastic,
    final_aggregate_assessment: finalAggregateAssessment,
  };
};

const isRefresh = (prevProps, props) => {
  const useTeamLeaderResult = lodashGet(props, 'useTeamLeaderResult');
  const useTeacherResult = lodashGet(props, 'useTeacherResult');
  const usePrincipalResult = lodashGet(props, 'usePrincipalResult');
  const scholastic = lodashGet(props, 'scholastic');

  const prevUseTeamLeaderResult = lodashGet(prevProps, 'useTeamLeaderResult');
  const prevUseTeacherResult = lodashGet(prevProps, 'useTeacherResult');
  const prevUsePrincipalResult = lodashGet(prevProps, 'usePrincipalResult');
  const prevScholastic = lodashGet(prevProps, 'scholastic');

  return (
    (useTeamLeaderResult && useTeamLeaderResult !== prevUseTeamLeaderResult) ||
    (useTeacherResult && useTeacherResult !== prevUseTeacherResult) ||
    (usePrincipalResult && usePrincipalResult !== prevUsePrincipalResult) ||
    scholastic !== prevScholastic
  );
};

export default fetchData(({ node, peerAssess, ...props }) => {
  const useTeamLeaderResult = lodashGet(props, 'useTeamLeaderResult');
  return {
    baseUrl: useTeamLeaderResult
      ? endPoints.assessOfPeers
      : endPoints.assessmentResult,
    fetchCondition: !node && !!props && lodashGet(props, 'userIid'),
    refetchCondition: (prevProps) => isRefresh(prevProps, props),
    params: getParams(peerAssess, props),
    method: 'get',
    propKey: !node && 'node',
  };
})(TcnnAssessment);
