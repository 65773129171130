import React, { Component } from 'react';
import get from 'lodash.get';
import AntdTable from 'antd/lib/table';
import { t1 } from 'translate/index';
import PreviewUserInDialog from 'components/admin/user/account/common/PreviewInDialog';
import Avatar from 'antd/lib/avatar';
import withTemisConfig from 'common/hoc/withTemisConfig';
import { timestampToDateTimeString } from 'common/utils/Date';
import Button from 'antd/lib/button';

class SearchPeersToAssignResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }

  handleAssignPeersToAssess = (iids = null) => {
    const { assignPeersToAssess } = this.props;

    if (typeof assignPeersToAssess !== 'function') {
      return;
    }

    assignPeersToAssess(iids || this.state.selectedRowKeys);
  };

  getColumns = (peerssAssignedToAssessment) => {
    const listPeer = peerssAssignedToAssessment.map(({ iid }) => iid);

    return [
      {
        title: t1('name'),
        key: 'name',
        render: (name, item) => {
          return (
            <div>
              {item.avatar && <Avatar src={item.avatar} />}
              <PreviewUserInDialog
                user={item}
                showFullDetailButton={false}
                hiddenItem={['positions']}
                field="name"
              />
              <br />
              <span className="text-faded">{item.mail}</span>
            </div>
          );
        },
      },
      // {
      //   title: t1('mail'),
      //   render: ({ mail }) => mail,
      // },
      {
        title: t1('birthday'),
        key: 'birthday',
        className: 'text-center',
        render: (birthday, item) => {
          return (
            <div>
              {item.birthday > 0 &&
                timestampToDateTimeString(item.birthday, {
                  showTime: false,
                  splitter: '/',
                })}
            </div>
          );
        },
      },
      // {
      //   title: t1('organizations'),
      //   width: '15%',
      //   render: (item) =>
      //     item.user_organizations &&
      //     !!item.user_organizations.length && (
      //       <div>
      //         <OrganizationsOrPhongBan
      //           item={item}
      //           attr={'user_organizations'}
      //           showParentsInfo
      //         />
      //         {Array.isArray(get(item, '__expand.phongbans')) &&
      //           !!get(item, '__expand.phongbans').length && [
      //             <br />,
      //             <OrganizationsOrPhongBan item={item} attr={'phongbans'} />,
      //           ]}
      //       </div>
      //     ),
      // },
      {
        title: t1('action'),
        className: 'text-center',
        render: ({ iid, name }) => {
          const isInList = listPeer && listPeer.includes(iid);
          return isInList ? (
            <div className="assigned-assessment">
              <span className="ve-check" />
              Đã giao
            </div>
          ) : (
            <button
              className="actions btn btn-secondary btn-outline"
              onClick={() => this.handleAssignPeersToAssess([iid])}
            >
              {t1('Giao')}
            </button>
          );
        },
      },
    ];
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { items, peerssAssignedToAssessment = [] } = this.props;
    const { selectedRowKeys } = this.state;
    const idInCurrentPage = Array.isArray(items)
      ? items.map(({ iid }) => iid)
      : [];

    return [
      <AntdTable
        locale={{ emptyText: 'Không có dữ liệu' }}
        columns={this.getColumns(peerssAssignedToAssessment)}
        dataSource={Array.isArray(items) ? items : []}
        rowKey="iid"
        bordered
        pagination={false}
        childrenColumnName={null}
        className={'learn-default'}
        rowSelection={{
          selectedRowKeys,
          hideDefaultSelections: true,
          onChange: this.onSelectChange,
          getCheckboxProps: (record) => {
            const listPeer = peerssAssignedToAssessment.map(({ iid }) => iid);
            const isInList = listPeer && listPeer.includes(record.iid);
            return {
              disabled: isInList,
            };
          },
          selections: [
            {
              key: 'select_current_page',
              text: t1('select_data_in_current_page'),
              onSelect: () => {
                this.setState((state) => {
                  let currentSelectedRowKeys = get(
                    state,
                    'selectedRowKeys',
                    [],
                  );
                  currentSelectedRowKeys = currentSelectedRowKeys.concat(
                    idInCurrentPage
                      .map(
                        (iid) => !currentSelectedRowKeys.includes(iid) && iid,
                      )
                      .filter(Boolean),
                  );

                  return { selectedRowKeys: currentSelectedRowKeys };
                });
              },
            },
            {
              key: 'invert_current_page',
              text: t1('invert_data_in_current_page'),
              onSelect: () => {
                this.setState((state) => {
                  let currentSelectedRowKeys = idInCurrentPage.filter(
                    (iid) => !get(state, 'selectedRowKeys', []).includes(iid),
                  );
                  return { selectedRowKeys: currentSelectedRowKeys };
                });
              },
            },
            Array.isArray(selectedRowKeys) &&
              !!selectedRowKeys.length &&
              !selectedRowKeys.every((iid) =>
                idInCurrentPage.includes(iid),
              ) && {
                key: 'remove_all',
                text: t1('remove_all_data_selected'),
                onSelect: () => {
                  this.setState(() => ({ selectedRowKeys: [] }));
                },
              },
          ].filter(Boolean),
        }}
      />,
      <Button
        type="primary"
        onClick={() => this.handleAssignPeersToAssess()}
        disabled={!Array.isArray(selectedRowKeys) || !selectedRowKeys.length}
        className="m-t-30 btn btn-secondary"
      >
        {selectedRowKeys.length
          ? `Giao nhiệm vụ cho ${selectedRowKeys.length} giáo viên`
          : 'Giao nhiệm vụ'}
      </Button>,
    ];
  }
}

export default withTemisConfig(SearchPeersToAssignResult);
