import React from 'react';
import apiUrls from 'components/temis/endpoints';
import schema from './schema';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import withUserOrganizations from 'common/hoc/withUserOrganizations';
import SearchFormResult from './SearchFormResult';
import './style.scss';

const formId = 'school-account';
const Account = ({
  orgIids = [],
  hiddenFields = {},
  renderResultComponent,
  searchFormId,
  alwaysShowResult = true,
  showResult = false,
  userInfo = {},
}) => {
  const resultComponent =
    typeof renderResultComponent === 'function'
      ? renderResultComponent
      : (items, props, objects, searchValues) => (
          <SearchFormResult
            items={items}
            props={props}
            objects={objects}
            searchFormId={searchFormId || formId}
            searchValues={searchValues}
          />
        );

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Không có tài khoản" />;
  };

  return (
    <SearchWrapper
      formid={searchFormId || formId}
      schema={schema}
      hiddenFields={{
        orgIids,
        temis_inactive_account: 1,
        include_sub_organizations: 1,
        _sand_step: 'students',
        _sand_expand: ['user_organizations', 'positions', 'phongbans'],
        ...hiddenFields,
      }}
      userRoot={userInfo}
      defaultValues={{ user_organizations: orgIids }}
      showResult={showResult}
      alwaysShowResult={alwaysShowResult}
      renderResultsComponent={resultComponent}
      renderNoResultComponent={renderNoResultComponent}
      alternativeApi={apiUrls.get_accounts}
      orgIids={orgIids}
    />
  );
};

export default withUserOrganizations(Account);
