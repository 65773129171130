import React from 'react';
import { t1 } from 'translate';
import PropTypes from 'prop-types';
import lodashGet from 'lodash.get';

import Table from 'antd/lib/table';
import Edit from './edit';
import AntDeleteItem from 'components/common/action-button/AtnDeleteBtnWithConfirmDialog';
import apiUrls from 'components/temis/endpoints';
import {
  populateRowSpanInfoToRenderListOfItemAsTable,
  unwind,
} from 'common/utils/Array';
import FileViewer from 'schema-form/elements/attachments/core/file-viewer';
import { breadCrumb } from 'common/utils/string';
import Tooltip from 'antd/lib/tooltip';

const Results = ({ items, searchFormId, scholastic, viewOnly }) => {
  let rows = unwind(items, 'attach_to_assessments', 'attach_to_assessment');
  rows = populateRowSpanInfoToRenderListOfItemAsTable(rows, [
    'id',
    'attach_to_assessment',
  ]);

  const columns = [
    {
      title: t1('code'),
      key: 'code',
      render: (row) => {
        return {
          children: lodashGet(row, 'non_unique_code'),
          props: {
            rowSpan: lodashGet(row, ['rowSpans', 'id']),
          },
        };
      },
    },
    {
      title: t1('support_evidence_name'),
      width: '12%',
      key: 'name',
      render: (row) => {
        const { name = '' } = row;
        const nameTruncate = breadCrumb(name, 50);
        return {
          children: <Tooltip title={name}>{nameTruncate}</Tooltip>,
          props: {
            rowSpan: lodashGet(row, ['rowSpans', 'id']),
          },
        };
      },
    },
    {
      title: t1('description'),
      key: 'description',
      render: (row) => {
        const { description = '' } = row;
        const descriptionTruncate = breadCrumb(description, 100);
        return {
          children: (
            <Tooltip title={description}>{descriptionTruncate}</Tooltip>
          ),
          props: {
            rowSpan: lodashGet(row, ['rowSpans', 'id']),
          },
        };
      },
    },
    {
      title: 'Tiêu chuẩn',
      key: 'Tiêu chuẩn',
      render: (row) => {
        return lodashGet(row, 'attach_to_assessment.tieu_chuan.name');
      },
    },
    {
      title: 'Tiêu chí',
      key: 'Tiêu chí',
      render: (row) => {
        return lodashGet(row, 'attach_to_assessment.tieu_chi.name');
      },
    },
    // {
    //   title: t1('apply_to'),
    //   key: 'attach_to_assessment',
    //   children: [
    //     {
    //       title: 'Tiêu chuẩn',
    //       key: 'Tiêu chuẩn',
    //       render: (row) => {
    //         return lodashGet(row, 'attach_to_assessment.tieu_chuan.name');
    //       },
    //     },
    //     {
    //       title: 'Tiêu chí',
    //       key: 'Tiêu chí',
    //       render: (row) => {
    //         return lodashGet(row, 'attach_to_assessment.tieu_chi.name');
    //       },
    //     },
    //   ],
    // },
    {
      title: t1('content'),
      width: 200,
      key: 'content',
      render: (row) => {
        return {
          children:
            row.content &&
            Array.isArray(row.content.attachments) &&
            row.content.attachments.map((item) => {
              const name = `${lodashGet(item, 'name')}.${lodashGet(
                item,
                'ext',
              )}`;
              return (
                <div className="evidence-content">
                  <a
                    className="evidence-content__info"
                    href={item.link}
                    title={name}
                    target="_blank"
                    download
                  >
                    {name}
                    <i className="evidence-content__icon-download mi mi-file-download" />
                  </a>
                  <span className="m-l-10 evidence-content__preview ">
                    <FileViewer attachments={[item]} />
                  </span>
                </div>
              );
            }),
          props: {
            rowSpan: lodashGet(row, ['rowSpans', 'id']),
          },
        };
      },
    },
    !viewOnly && {
      title: t1('actions'),
      width: '10%',
      key: 'actions',
      render: (row) => {
        const evidenceFromSystem = lodashGet(row, 'evidence_from_system', {});
        const isEvidenceFromSystem =
          Object.keys(evidenceFromSystem).length !== 0;

        return {
          children: (
            <div>
              <Edit
                key={lodashGet(row, 'id')}
                evidence={row}
                searchFormId={searchFormId}
                scholastic={scholastic}
              />
              <span style={{ paddingLeft: 10 }}> </span>
              {!isEvidenceFromSystem && (
                <AntDeleteItem
                  formid={searchFormId}
                  alternativeApi={apiUrls.delete_assessment_evidence}
                  itemId={lodashGet(row, 'id')}
                  icon={'ve-delete m-t-3 m-l-5 cursor-pointer'}
                  iconButton
                />
              )}
            </div>
          ),
          props: {
            rowSpan: lodashGet(row, ['rowSpans', 'id']),
          },
        };
      },
    },
  ].filter(Boolean);

  return (
    <Table
      columns={columns}
      dataSource={rows}
      pagination={false}
      childrenColumnName={null}
      className={'white-background learn-default'}
      bordered
      size="middle"
    />
  );
};

Results.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
};

Results.defaultProps = {
  items: [],
};

export default Results;
