import React from 'react';
import Search from 'schema-form/elements/advance-search';
import {
  includeSubOrganizations,
  organizations,
} from 'components/admin/organization/schema/elements';
import { required, validationWithCondition } from 'common/validators';
import { addPropsToEverySchemaElements } from 'common/utils/schema-form';
import { commonFormLayouts, elementDisplayModes } from 'schema-form/constants';
import { hasOrganization } from 'common/conf';
import MinimalSearchRecapFreeStyleLayout from 'schema-form/layouts/common-freestyle-layouts/MinimalSearchRecap';
import { caphoc, modules, targetUser } from 'components/common/elements';

const defaultSchema = (forRecap = false) => (
  formid,
  values,
  step,
  xpath,
  props,
  domainInfo,
) => {
  let element = {
    organizations: organizations({
      formid,
      defaultValue: props.orgIids,
      fullWidth: true,
      validate: [
        validationWithCondition(required(), values.requireOrganization),
      ],
    }),
    include_sub_organizations: includeSubOrganizations(domainInfo.conf),
    caps: caphoc({
      defaultValue: [],
    }),
    target_users: targetUser({
      defaultValue: [],
    }),
    training_plan_module: modules({
      defaultValue: '',
    }),
  };

  if (forRecap) {
    return addPropsToEverySchemaElements(element, {
      elementDisplayMode: elementDisplayModes.RECAP,
    });
  }

  return element;
};

const defaultUi = () => (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  props,
  mode,
  domainInfo,
) => {
  let fields = ['training_plan_module'];

  if (hasOrganization(domainInfo)) {
    fields.push('organizations', 'include_sub_organizations');
  }

  fields.push('caps', 'target_users');

  return [
    {
      id: 'id',
      fields,
    },
  ];
};

const getDefaultSchema = (forRecap = false) => ({
  schema: defaultSchema(forRecap),
  ui: defaultUi(),
  layout: forRecap
    ? {
        freestyle: 1,
        component: MinimalSearchRecapFreeStyleLayout,
      }
    : commonFormLayouts.DEFAULT,
});

const schema = (formid, values, localStep, xpath, props, domainInfo) => {
  return {
    name: {
      type: Search,
      schema: getDefaultSchema(false),
      recapSchema: getDefaultSchema(true),
    },
  };
};

const ui = () => {
  return [
    {
      id: 'id',
      fields: ['name'],
    },
  ];
};

export default {
  schema,
  ui,
  isAdvanceSearch: true,
};
