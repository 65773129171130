import React from 'react';
import get from 'lodash.get';
import Overview from '../overview';
import withUserInfo from 'common/hoc/withUserInfo';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import Tab from '../../../components/tabs';
import {
  isAdminTruong,
  isHieuPho,
  isHieuTruong,
  isPhong,
  isSo,
  isSGDStaff,
  isPGDStaff,
  isAmTinh,
} from 'components/admin/user/utils';

const AssessmentOrganization = ({
  userInfo = {},
  scholastic,
  isResultOfHP,
  isResultOfHT,
  isSo,
  isPhong,
}) => {
  const currentOrganizations = get(userInfo, 'user_organizations');
  return (
    <Overview
      currentOrganizations={currentOrganizations}
      scholastic={scholastic}
      isResultOfHP={isResultOfHP}
      isResultOfHT={isResultOfHT}
      isSo={isSo}
      isPhong={isPhong}
    />
  );
};

const AssessmentsInOrganizationSchool = ({ userInfo, scholastic }) => {
  const tabList = [
    ...(isHieuTruong(userInfo) || isHieuPho(userInfo) || isAdminTruong(userInfo)
      ? [
          {
            name: 'assessment_organization-for-teacher',
            label: 'Tổng hợp kết quả đánh giá GV',
            component: (
              <AssessmentOrganization
                userInfo={userInfo}
                scholastic={scholastic}
              />
            ),
          },
        ]
      : []),
    ...(isHieuTruong(userInfo) || isAdminTruong(userInfo)
      ? [
          {
            name: 'assessment_organization-for-hieu-pho',
            label: `Tổng hợp kết quả đánh giá ${
              isAdminTruong(userInfo) ? 'CBQL' : 'Hiệu Phó'
            }`,
            component: (
              <AssessmentOrganization
                userInfo={userInfo}
                scholastic={scholastic}
                isResultOfHP
                isResultOfHT={isAdminTruong(userInfo)}
              />
            ),
          },
        ]
      : []),
    ...(isPhong(userInfo) || isSo(userInfo) || isAmTinh(userInfo)
      ? [
          {
            name: 'assessment_organization-for-hieu-truong',
            label: 'Tổng hợp kết quả đánh giá Hiệu Trưởng',
            component: (
              <AssessmentOrganization
                userInfo={userInfo}
                scholastic={scholastic}
                isResultOfHT
                isSo={isSGDStaff(userInfo) || isAmTinh(userInfo)}
                isPhong={isPGDStaff(userInfo)}
              />
            ),
          },
        ]
      : []),
  ].filter(Boolean);
  return (
    <div>
      <Tab tabList={tabList} />
    </div>
  );
};

export default withScholastic(withUserInfo(AssessmentsInOrganizationSchool));
