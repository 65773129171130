import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import Checkbox from 'antd/lib/checkbox';
import fetchData from 'components/common/fetchData';
import Html from 'components/common/html/index';
import Widget from 'components/common/Widget';
import DetailOnDialog from 'components/common/detail-on-dialog';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import Icon from 'antd/lib/icon';
import Tooltip from 'antd/lib/tooltip';
import { isRequiredMC } from '../util';
import './style.scss';
import apiUrls from 'components/temis/endpoints';
import Evidence from './evidence';
import CommonAntdTable from 'components/common/antd/table';
import {
  generateClassByStatusAssessment,
  isFromQualifiedAssessment,
} from '../../utils';
import EvidenceForm from 'components/temis/evidence/templateless-evidence/edit/Form';
import TooltipAssessment from 'components/temis/components/tooltip-assessment';
import ListAttachmentsToDownloadAsTable from 'components/common/attachment/ListAttachmentsToDownloadAsTable';

const canEvidenceAppliedToAnswer = (evidence, tieuChiIid) => {
  return (get(evidence, 'attach_to_assessments') || []).some((cond) => {
    return get(cond, 'tieu_chi_iid') == tieuChiIid;
  });
};

const getPercentWidthToDisplay = (numberColumn) => {
  return Math.floor(100 / numberColumn);
};

const getColumns = ({
  score_scale = {},
  value,
  onChange,
  assessment_name,
  readOnly,
  peerAssess = false,
  peersAssessment = null,
  mySelfAssessment = null,
  assessmentEvidences = [],
  refetchAssessmentEvidenceOptions,
  hasAssessment,
}) => {
  const numberOfParts =
    score_scale.parts.length +
    (mySelfAssessment ? 2 : 0) +
    (Array.isArray(peersAssessment) ? peersAssessment.length : 0);
  const percentWidth = getPercentWidthToDisplay(
    numberOfParts + (peerAssess ? 3 : 5),
  );

  return [
    {
      title: assessment_name,
      render: ({ isHeader, name, description, depth, ...row }) => {
        return {
          children: (
            <div
              style={{
                fontWeight: isHeader ? 'bold' : '',
                marginLeft: 10 * depth,
              }}
            >
              <span className="m-r-5">{name}</span>
              {!isHeader && description && (
                <TooltipAssessment content={description} />
              )}
            </div>
          ),
          props: {
            colSpan: isHeader ? (peerAssess ? 1 : 2) + numberOfParts : 1,
          },
        };
      },
    },
    mySelfAssessment && {
      title: `"${get(mySelfAssessment, 'peer.name')}" tự đánh giá`,
      children: [
        {
          title: 'Kết quả đánh giá',
          width: `${percentWidth}%`,
          className: 'text-center',
          render: ({ isHeader, iid }) => {
            const answerByRubric = get(mySelfAssessment, 'task', []).find(
              (as) => String(iid) === String(as && as.rubric_iid),
            );
            let part = {};
            if (
              answerByRubric &&
              typeof answerByRubric.answer !== 'undefined'
            ) {
              part = score_scale.parts.find(
                ({ id }) => String(id) === String(answerByRubric.answer),
              );
            }
            return {
              children: get(part, 'name'),
              props: {
                colSpan: isHeader ? 0 : 1,
                style: { maxWidth: 170 },
              },
            };
          },
        },
        {
          title: 'Minh chứng',
          render: ({ isHeader, iid, scaled_children, ...row }) => {
            const answerByRubric = get(mySelfAssessment, 'task', []).find(
              (as) => String(iid) === String(as && as.rubric_iid),
            );

            if (
              !answerByRubric ||
              !Array.isArray(answerByRubric.evidence_iids) ||
              !answerByRubric.evidence_iids.length ||
              !Array.isArray(assessmentEvidences) ||
              !assessmentEvidences.length
            ) {
              return {
                children: '',
                props: {
                  colSpan: isHeader ? 0 : 1,
                  style: { minWidth: 150 },
                },
              };
            }

            const evidenceIids = answerByRubric.evidence_iids.map((edIid) =>
              String(edIid),
            );
            const evidenceSelected = assessmentEvidences.filter((ed) =>
              evidenceIids.includes(String(ed.iid)),
            );

            return {
              children: (
                <ul>
                  {evidenceSelected.map(({ content, name }) => {
                    if (
                      content &&
                      !content.text &&
                      Array.isArray(content.attachments) &&
                      !content.attachments.length
                    ) {
                      return null;
                    }

                    return (
                      <DetailOnDialog
                        dialogKey="view_evidence"
                        renderPreview={({ showFull }) => {
                          return (
                            <li
                              style={{ cursor: 'pointer' }}
                              onClick={showFull}
                              title={t1('view_detail')}
                            >
                              {name}
                            </li>
                          );
                        }}
                        renderFull={({ closeDialog }) => (
                          <div>
                            {content.text && (
                              <Widget title={t1('text_evidence')}>
                                <Html content={content.text} />
                              </Widget>
                            )}
                            {Array.isArray(content.attachments) &&
                              !!content.attachments.length && (
                                <Widget title={t1('files_evidence')}>
                                  <ListAttachmentsToDownloadAsTable
                                    attachments={content.attachments}
                                  />
                                </Widget>
                              )}
                          </div>
                        )}
                      />
                    );
                  })}
                </ul>
              ),
              props: {
                colSpan: isHeader ? 0 : 1,
                style: { minWidth: 150 },
              },
            };
          },
        },
      ],
    },
    Array.isArray(peersAssessment) &&
      !!peersAssessment.length && {
        title: 'Kết quả đánh giá của đồng nghiệp',
        children: peersAssessment.map(({ peer, target, ...peerAssessment }) => {
          return {
            title: get(peer, 'name'),
            width: `${percentWidth}%`,
            className: 'text-center',
            render: ({ isHeader, iid }) => {
              const answerByRubric = get(peerAssessment, 'task', []).find(
                (as) => String(iid) === String(as && as.rubric_iid),
              );
              let part = {};
              if (
                answerByRubric &&
                typeof answerByRubric.answer !== 'undefined'
              ) {
                part = score_scale.parts.find(
                  ({ id }) => String(id) === String(answerByRubric.answer),
                );
              }
              return {
                children: get(part, 'name'),
                props: {
                  colSpan: isHeader ? 0 : 1,
                  style: { maxWidth: 150 },
                },
              };
            },
          };
        }),
      },
    {
      title: score_scale.name,
      key: 'code',
      children: score_scale.parts.map(({ id, name }) => ({
        title: name,
        width: 80,
        className: `text-center ${generateClassByStatusAssessment(name)}`,
        render: ({ isHeader, iid, scaled_children, ...row }) => {
          const scaledCell =
            Array.isArray(scaled_children) &&
            scaled_children.find(
              ({ scale_id }) => String(scale_id) === String(id),
            );
          const description =
            get(scaledCell, 'detailed_description') ||
            get(scaledCell, 'description');
          const currentValue =
            (Array.isArray(value) &&
              value.find(
                (val) => String(get(val, 'rubric_iid')) === String(iid),
              )) ||
            {};
          const hasValue = String(get(currentValue, 'answer')) === String(id);

          return {
            children: [
              readOnly ? (
                hasValue && <span className="ve-check icon-check" />
              ) : (
                <Checkbox
                  className="checkbox-assessmemt"
                  checked={hasValue}
                  onChange={(event) => {
                    const checked = get(event, 'target.checked');
                    onChange({
                      rubric_iid: iid,
                      answer: checked ? id : null,
                    });
                  }}
                />
              ),
              description && (
                <span style={{ position: 'absolute', top: 0, right: 0 }}>
                  <Tooltip
                    title={<Html content={description} />}
                    placement="topRight"
                  >
                    <Icon
                      type="question-circle"
                      style={{ color: 'rgba(0,0,0,.45)' }}
                    />
                  </Tooltip>
                </span>
              ),
            ],
            props: {
              colSpan: isHeader ? 0 : 1,
              className: 'text-center position-relative',
            },
          };
        },
      })),
    },
    !peerAssess && {
      title: 'Minh chứng',
      className: 'text-center position-relative',
      width: 150,
      render: ({ isHeader, iid, ancestor_iids }) => {
        const currentValue =
          (Array.isArray(value) &&
            value.find(
              (val) => String(get(val, 'rubric_iid')) === String(iid),
            )) ||
          {};

        const assessmentEvidenceOptions = (assessmentEvidences || []).filter(
          (evidence) => canEvidenceAppliedToAnswer(evidence, String(iid)),
        );

        const evidenceIidsSelected = get(currentValue, 'evidence_iids') || [];
        const currentEvidenceObjs = assessmentEvidenceOptions.filter(
          ({ iid }) => evidenceIidsSelected.includes(iid),
        );

        const currentEvidenceIids = currentEvidenceObjs.map((obj) => obj.iid);

        const shouldBeShowWarningEvidence =
          isRequiredMC &&
          isFromQualifiedAssessment(currentValue) &&
          !hasAssessment &&
          !peerAssess &&
          !currentEvidenceObjs.length;

        return {
          children: [
            readOnly ? (
              !!currentEvidenceObjs.length &&
              currentEvidenceObjs.map((obj) => {
                return (
                  <DetailOnDialog
                    renderPreview={({ showFull }) => (
                      <span
                        key={obj.iid}
                        className="m-b-10 text-left name-evidence d-flex align-items-center justify-content-between"
                        title={obj.name}
                        onClick={() => showFull()}
                      >
                        <span className="text-ellipsis">{obj.name}</span>
                        <span className="ve ve-eye view-action-link" />
                      </span>
                    )}
                    renderFull={() => (
                      <EvidenceForm
                        readOnly
                        evidence={obj}
                        dialogKey={`view-evidence-file-${obj.iid}`}
                        searchFormId={`view-evidence-file-${obj.iid}`}
                      />
                    )}
                    dialogKey={`view-evidence-file-${obj.iid}`}
                    dialogOptionsProperties={{
                      title: t1('Tài liệu minh chứng'),
                      width: '80%',
                    }}
                  />
                );
              })
            ) : (
              <div>
                {!!currentEvidenceObjs.length && (
                  <div className="m-b-10">
                    {currentEvidenceObjs.map((obj) => (
                      <div
                        className="m-b-10 text-left name-evidence"
                        key={obj.value}
                        title={obj.name}
                      >
                        <Tooltip title={obj.name}>{obj.name}</Tooltip>
                      </div>
                    ))}
                  </div>
                )}
                <div>
                  <Evidence
                    assessmentEvidence={assessmentEvidenceOptions}
                    tieuChiIid={iid}
                    tieuChuanIid={get(ancestor_iids, 0)}
                    assessmentRubricIid={get(ancestor_iids, 1)}
                    currentEvidenceIids={currentEvidenceIids}
                    onChangeEvidence={(evidenceIids) => {
                      onChange({
                        rubric_iid: iid,
                        evidence_iids: evidenceIids,
                      });
                    }}
                    refetchEvidence={() => {
                      if (
                        typeof refetchAssessmentEvidenceOptions === 'function'
                      ) {
                        refetchAssessmentEvidenceOptions();
                      }
                    }}
                  />

                  {shouldBeShowWarningEvidence && (
                    <div className="m-b-10 text-left name-evidence warning-evidence">
                      Thiếu tài liệu MC
                    </div>
                  )}
                </div>
              </div>
            ),
          ],
          props: { colSpan: isHeader ? 0 : 1, style: { maxWidth: 150 } },
        };
      },
    },
  ].filter(Boolean);
};

const handleRowOnChange = (onChange, dataSource, values, valueChanged = {}) => {
  if (typeof onChange !== 'function') {
    return;
  }

  onChange(
    dataSource
      .map(({ isHeader, iid }) => {
        if (isHeader) {
          return false;
        }

        const currentValue =
          (Array.isArray(values) &&
            values.find(
              (value) => String(get(value, 'rubric_iid')) === String(iid),
            )) ||
          {};
        const newValue =
          String(valueChanged.rubric_iid) === String(iid) ? valueChanged : {};

        return Object.assign({}, currentValue, newValue, { rubric_iid: iid });
      })
      .filter(Boolean),
  );
};

const TableDoTask = ({
  dataSource,
  score_scale,
  value,
  onChange,
  assessment_name,
  readOnly,
  peerAssess = false,
  assessmentEvidences,
  tcnnType,
  peersAssessment,
  mySelfAssessment,
  refetchAssessmentEvidenceOptions,
  hasAssessment,
}) => {
  return (
    <CommonAntdTable
      className="temis-assessment-table learn-default"
      dataSource={Array.isArray(dataSource) ? dataSource : []}
      columns={getColumns({
        score_scale,
        value,
        onChange: (newValue) =>
          handleRowOnChange(onChange, dataSource, value, newValue),
        assessment_name,
        readOnly,
        peerAssess,
        peersAssessment,
        mySelfAssessment,
        assessmentEvidences,
        refetchAssessmentEvidenceOptions,
        hasAssessment,
      })}
      pagination={false}
      bordered
      size="middle"
    />
  );
};

const getParams = (props) => {
  let params = {
    scholastic: get(props, 'scholastic'),
  };
  if (props.userIid && !props.ignoreUserIidParamToEvidenceAPI) {
    params.userIid = props.userIid;
  }
  return params;
};

const fetchAssessmentEvidenceOptions = fetchData((props) => ({
  baseUrl: apiUrls.get_evidences_to_do_assessment,
  params: getParams(props),
  loadingStatusPropKey: 'loadingAssessmentEvidencesStatus',
  propKey: 'assessmentEvidences',
  fetchFunctionPropKey: 'refetchAssessmentEvidenceOptions',
}));

export default fetchAssessmentEvidenceOptions(
  makeReduxFormCompatible({})(TableDoTask),
);
