import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Account from '../../account';
import { leaderPositions } from 'configs/constants/user';
import ResultMembersInPhongBan from './ResultMembersInPhongBan';

const EditMembres = ({ phongban = {}, searchTcmFormId = '' }) => {
  const dialogKey = `edit-members-in-category-${phongban.iid}`;
  const title = `Danh sách giáo viên trong tổ chuyên môn "${phongban.name}"`;

  const renderPreview = ({ showFull }) => {
    return (
      <span
        className="ve-eye m-r-10 cursor-pointer"
        title={title}
        onClick={showFull}
      >
        {' '}
      </span>
    );
  };

  const renderFull = () => {
    const searchFormId = 'search-member-in-phong-ban';
    return (
      <Account
        resetForm
        showResult
        alwaysShowResult
        searchFormId={searchFormId}
        hiddenFields={{
          phongban_id: phongban.iid,
          temis_account_status: ['activated'],
        }}
        renderResultComponent={(items, props, objects, searchValues) => (
          <ResultMembersInPhongBan
            phongban={phongban}
            items={items}
            props={props}
            objects={objects}
            searchFormId={searchFormId}
            searchTcmFormId={searchTcmFormId}
            searchValues={searchValues}
          />
        )}
      />
    );
  };

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogKey={dialogKey}
      dialogOptionsProperties={{
        title,
        width: '80%',
        handleClose: true,
        modal: true,
      }}
    />
  );
};

export default EditMembres;
