import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import {
  isAdminTruongOrHieuTruongOrPho,
  isBoOrAdminSP,
  isMamNon,
} from 'components/admin/user/utils';
import withTemisConfig from 'common/hoc/withTemisConfig';
import { REPORT_GROUP_ROUTER } from '../constant';
import { renderRoutes } from 'react-router-config';
import { routesTemisReport } from '../routes';
import PageNotFound from '../../common/404';
import {
  renderReportList,
  reportA,
  reportB,
  reportC,
  reportBCThongKe,
  reportATitle,
  reportCTitle,
  reportBTitle,
  reportBCThongKeTitle,
  checkPermissionByPathname,
  reportMN,
} from './utils';
import './styles.scss';

const listReportCV = [
  {
    title: 'Báo cáo theo công văn',
    path: REPORT_GROUP_ROUTER.cv,
  },
];

const TemisReport = ({
  userRoot,
  reportType,
  pathname,
  search,
  enableAssessmentForMamnon,
}) => {
  const isMN = isMamNon(userRoot);
  const shouldShowReportMN =
    isMN ||
    (!isAdminTruongOrHieuTruongOrPho(userRoot) && enableAssessmentForMamnon);
  const listItemReportCv = !isBoOrAdminSP(userRoot) ? listReportCV : [];

  const reportAClass = reportA(userRoot);
  const reportBClass = reportB(userRoot);
  const reportCClass = reportC(userRoot);
  const reportBCThongKeClass = reportBCThongKe(userRoot);
  const reportMNClass = reportMN(userRoot);

  if (!reportType) {
    return (
      <div className="container-fluid">
        {!isMN && (
          <>
            {!!reportAClass.length && (
              <>
                <h3 className="text-transform">{reportATitle.a}</h3>
                <div>{renderReportList(reportAClass)}</div>
                <hr />
              </>
            )}
            {!!reportBClass.length && (
              <>
                <h3>{reportBTitle.b}</h3>
                <div>{renderReportList(reportBClass)}</div>
                <hr />
              </>
            )}

            {!!reportCClass.length && (
              <>
                <h3 className="text-transform">{reportCTitle.c}</h3>
                <div>{renderReportList(reportCClass)}</div>
                <hr />
              </>
            )}
            {!!reportBCThongKeClass.length && (
              <>
                <h3 className="text-transform">{reportBCThongKeTitle.tk}</h3>
                <div>{renderReportList(reportBCThongKeClass)}</div>
                <hr />
              </>
            )}
            {!!listItemReportCv.length && (
              <>
                <h3 className="text-transform">
                  Danh sách báo cáo theo công văn
                </h3>
                <div>{renderReportList(listItemReportCv)}</div>
                <hr />
              </>
            )}
          </>
        )}
        <>
          {shouldShowReportMN && (
            <>
              <h3 className="text-transform">Danh sách báo cáo Mầm Non</h3>
              <div>{renderReportList(reportMNClass)}</div>
              <hr />
            </>
          )}
        </>
      </div>
    );
  }

  const hasPermission = checkPermissionByPathname(pathname, search, [
    ...listItemReportCv,
    ...reportAClass,
    ...reportBClass,
    ...reportCClass,
    ...reportBCThongKeClass,
    ...reportMNClass,
  ]);

  if (!hasPermission) {
    return <PageNotFound />;
  }

  return <div>{renderRoutes(routesTemisReport())}</div>;
};

const mapStateToProps = (state, props) => {
  return {
    search: get(props, 'location.search'),
    pathname: get(props, 'location.pathname'),
    reportType: get(props, 'match.params.reportType'),
  };
};

export default connect(mapStateToProps)(withTemisConfig(TemisReport));
