import React from 'react';
import PreviewUserInDialog from 'components/admin/user/account/common/PreviewInDialog';
import { sexAsText } from 'common/sex';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { t1 } from 'translate';
import CommonAntdTable from 'components/common/antd/table';
import get from 'lodash.get';
import ChangePassword from './change-password';
import { parseTSToDateTimeString } from 'common/utils/Date';
import { leaderPositions, leaderPositionToText } from 'configs/constants/user';
import { isTeacher } from 'components/admin/user/utils';
import AccountEdit from './edit';
import Button from 'components/common/primary-button';
import Warning from 'components/common/Warning';
import ApiRequestBtnWithConfirmDialog from 'components/common/action-button/ApiRequestBtnWithConfirmDialog';

const SearchFormResult = ({ items = [], searchFormId }) => {
  const defaultColumnWidth = 150;

  const columns = [
    {
      title: t1('general_information'),
      key: 'name',
      width: 250,
      render: (name, item) => (
        <PreviewUserInDialog user={item} showFullDetailButton={false}>
          <div>
            <div>Họ tên: {get(item, 'name')}</div>

            <div>
              {t1('email')}: {get(item, 'mail')}
            </div>
            <div>Điện thoại: {get(item, 'phone')}</div>
          </div>
        </PreviewUserInDialog>
      ),
    },
    {
      title: 'Mã',
      key: 'code',
      width: defaultColumnWidth,
      render: (code, item) => <div>{get(item, 'code')}</div>,
    },
    {
      title: 'Ngày sinh',
      key: 'birthday',
      width: 120,
      render: (birthday, item) => (
        <div>{!!item.birthday && parseTSToDateTimeString(item.birthday)}</div>
      ),
    },
    {
      title: t1('gender'),
      key: 'sex',
      width: 90,
      render: (sex, item) => <div>{item && sexAsText(item.sex)}</div>,
    },
    {
      title: 'Vị trí/chức vụ',
      key: 'positions',
      width: defaultColumnWidth,
      render: (item) => {
        let leaderPosition = get(item, 'leader_position');
        if (!leaderPosition) {
          return null;
        }

        const subLeaderPosition = get(item, 'sub_leader_position');

        if (
          leaderPosition === leaderPositions.TEACHER &&
          get(item, 'current_position') === 'leader'
        ) {
          leaderPosition = leaderPositions.TO_TRUONG;
        }

        return `${leaderPositionToText(leaderPosition)}${
          subLeaderPosition
            ? `(Kiêm nghiệm chức vụ: ${leaderPositionToText(
                subLeaderPosition,
              )})`
            : ''
        }`;
      },
    },
    {
      title: 'Tổ chuyên môn',
      width: 100,
      render: (item) => {
        const phongbans = get(item, '__expand.phongbans', []).filter(Boolean);
        if ((!phongbans || !phongbans.length) && isTeacher(item)) {
          return <div className="text-danger">Chưa có tổ chuyên môn</div>;
        }

        return phongbans.map((phongban) => <div>{get(phongban, 'name')}</div>);
      },
    },
    {
      title: 'Kinh nghiệm',
      key: 'experience',
      width: 110,
      render: (item) =>
        item.teaching_exp_years && (
          <span>
            {item.teaching_exp_years} {t1('year')}
          </span>
        ),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      width: defaultColumnWidth,
      render: (item) => {
        const { iid, id, temis_inactive_account } = item;

        return (
          <div className="account-status">
            {!temis_inactive_account && (
              <>
                <div className="text-primary">Đang hoạt động</div>
                <ApiRequestBtnWithConfirmDialog
                  renderComponent={({ onClick }) => {
                    return (
                      <Button
                        className="btn btn-outline btn-tertiary p-10"
                        onClick={onClick}
                      >
                        Dừng hoạt động
                      </Button>
                    );
                  }}
                  url="/user/update"
                  params={{
                    id,
                    iid,
                    temis_inactive_account: 1,
                    _sand_step: 'temis_inactive_account',
                  }}
                  formidToSubmitOnSuccess={searchFormId}
                  textConfirm={
                    <Warning>
                      Dừng hoạt động.
                      <br />
                    </Warning>
                  }
                  contentConfirm="Tài khoản này sẽ không thể đăng nhập, danh sách,
                    số liệu báo cáo sẽ không bao gồm tài khoản này khi bạn thực hiện hành động này"
                  closeModal
                  modalKey="account-inactive"
                />
              </>
            )}

            {!!temis_inactive_account && (
              <>
                <div className="text-danger m-b-5">Không hoạt động</div>
                <ApiRequestBtnWithConfirmDialog
                  renderComponent={({ onClick }) => {
                    return (
                      <button
                        className="btn btn-outline btn-primary"
                        onClick={onClick}
                      >
                        Hoạt động
                      </button>
                    );
                  }}
                  url="/user/update"
                  params={{
                    id,
                    iid,
                    _sand_step: 'temis_inactive_account',
                  }}
                  formidToSubmitOnSuccess={searchFormId}
                  textConfirm="Hoạt động"
                  contentConfirm="Tài khoản này có thể đăng nhập trở lại, danh sách,
                    số liệu báo cáo sẽ bao gồm tài khoản này khi bạn thực hiện hành động này."
                  closeModal
                  modalKey="account-active"
                />
              </>
            )}
          </div>
        );
      },
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 100,
      type: TYPE_OF_TABLE_DATA.ACTION,
      render: (action, item) => {
        return (
          <div className="d-flex justify-content-center">
            <PreviewUserInDialog user={item} showFullDetailButton={false}>
              <span className="ve-eye m-r-10" title={t1('view')} />
            </PreviewUserInDialog>
            <ChangePassword user={item} />
            <AccountEdit id={item.id} />
          </div>
        );
      },
    },
  ].filter(Boolean);

  return (
    <div className="account-search-result">
      <CommonAntdTable
        dataSource={items}
        columns={columns}
        pagination={false}
        rowKey="iid"
        className="learn-default"
      />
    </div>
  );
};

export default SearchFormResult;
