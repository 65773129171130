import React, { useState, useEffect } from 'react';
import Form from 'components/temis/assessment/edit/Form';
import ExportData from 'components/common/export-data';
import './styles.scss';
import get from 'lodash.get';
import InfoSelfAssessment from '../assessment/my-assessment/info-sellf-assessment';
import endPoints from '../assessment/endpoints';
import withUserInfo from 'common/hoc/withUserInfo';
import withTemisConfig from 'common/hoc/withTemisConfig';
import fetchData from 'components/common/fetchData';
import {
  formatDataSourceFromDataServerToRenderTableAssess,
  getOverallResultsOfTheAssessment,
} from '../assessment/util';
import { Link } from 'react-router-dom';
import { editTcnn } from '../routes';
import {
  generateClassByStatusAssessment,
  isApprovedAssessment,
} from '../utils';
import NoAssessmentResult from '../components/no-assessment-result';
import Widget from '../../common/Widget';
import withScholastic from '../components/scholastic/withScholastic';
import { compose } from 'redux';
import { EXPORT_FILENAME, TCNN_TYPE } from 'components/temis/constant';
import { isHieuPho, isMamNon } from 'components/admin/user/utils';

const elementExportBM1PL2 = (scholastic, exportFileName) => (
  <ExportData
    apiExport="/temis/export/export-my-assessment"
    buttonText="Xuất excel BM1/PL2"
    formId="search-score-by-rubric"
    params={{
      scholastic,
    }}
    fileName={exportFileName}
  />
);

const NoAssessment = ({ userIid, temisConfig, isHieuTruong }) => (
  <>
    <NoAssessmentResult />
    <div className="d-flex justify-content-center">
      <Link to={editTcnn}>
        <button type="button" className=" btn btn-secondary">
          {'Đánh giá'}
        </button>
      </Link>
    </div>
  </>
);

const MyAssessment = ({
  user,
  rubric_iid,
  readOnly = false,
  score_scale,
  node,
  temisConfig,
  isHieuTruong,
  tcnnType = TCNN_TYPE.GV,
  scholastic,
} = {}) => {
  const name = get(user, 'name');
  const parts = get(score_scale, 'parts', []);
  const value = get(node, 'task');
  const scoreBasedOnSelectedTask = isHieuPho(user);
  const result = getOverallResultsOfTheAssessment(
    value,
    scoreBasedOnSelectedTask,
    rubric_iid,
  );
  const userIid = get(user, 'iid');
  const status = get(node, 'status');

  const [isApproved, setApprovedAssessment] = useState(
    isApprovedAssessment(status),
  );

  useEffect(
    () => {
      const approved = isApprovedAssessment(status);
      setApprovedAssessment(approved);
    },
    [status],
  );

  if (typeof result.final === 'undefined') {
    return (
      <NoAssessment
        userIid={userIid}
        isHieuTruong={isHieuTruong}
        temisConfig={temisConfig}
      />
    );
  }

  const part = parts.find(({ id }) => id === result.final);
  const updatedTs = get(node, 'updated_ts') || get(node, 'ts');

  const onSetApprovedAssessment = (status) => {
    setApprovedAssessment(status);
  };

  const exportFileName = isHieuTruong
    ? EXPORT_FILENAME.principal.BM1_PL2
    : EXPORT_FILENAME.teacher.BM1_PL2;

  return (
    <>
      {!readOnly ? (
        <>
          <div className="text-center">
            <h2>PHIẾU TỰ ĐÁNH GIÁ CNN</h2>
          </div>
          <div>
            <Form userIid={user && user.iid} readOnly scholastic={scholastic} />
            {elementExportBM1PL2(scholastic, exportFileName)}
          </div>
        </>
      ) : (
        <>
          {!!updatedTs ? (
            isApproved ? (
              <>
                <div className="rubric my-assessment-result">
                  <p>Đánh giá CNN: {name}</p>
                  <div className="m-t-10">
                    Xếp loại đánh giá:{' '}
                    <span
                      className={` status-text ${generateClassByStatusAssessment(
                        get(part, 'name') || '',
                      )}`}
                    >
                      {get(part, 'name') || ''}
                    </span>
                  </div>
                  {elementExportBM1PL2(scholastic, exportFileName)}
                </div>
                <div className="mui-paper-no-box-shadow mui-paper-no-padding">
                  <Form
                    rubricIid={rubric_iid}
                    userIid={user && user.iid}
                    readOnly
                    scholastic={scholastic}
                  />
                </div>
              </>
            ) : (
              <div className="row m-t-30 d-flex justify-content-center">
                <div className="col-md-4 col-sm-6 text-center">
                  <Widget className="box-assessment">
                    <InfoSelfAssessment
                      node={node}
                      viewOnly={readOnly}
                      status={status}
                      isHieuTruong={isHieuTruong}
                      temisConfig={temisConfig}
                      userIid={userIid}
                      updatedTs={updatedTs}
                      value={get(node, 'task')}
                      rubricIid={rubric_iid}
                      score_scale={score_scale}
                      tcnnType={isHieuTruong ? TCNN_TYPE.HT : TCNN_TYPE.GV}
                      onSetApprovedAssessment={onSetApprovedAssessment}
                    />
                  </Widget>
                </div>
              </div>
            )
          ) : (
            <NoAssessment
              userIid={userIid}
              isHieuTruong={isHieuTruong}
              temisConfig={temisConfig}
            />
          )}
        </>
      )}
    </>
  );
};

const fetchSelfAssessment = (props) => {
  const userIid = get(props, 'userInfo.iid');
  const scholastic = get(props, 'scholastic');
  return {
    baseUrl: endPoints.assessmentResult,
    fetchCondition: scholastic && userIid,
    refetchCondition: (prevProps) =>
      (userIid && !get(prevProps, 'userInfo.iid')) ||
      scholastic !== get(prevProps, 'scholastic'),
    params: {
      userIid: userIid,
      scholastic,
    },
    method: 'get',
    keyState: 'selfAssessment',
    propKey: 'node',
  };
};

const fetchRubricDoAssessment = (props) => {
  const scholastic = get(props, 'scholastic');
  return {
    baseUrl: endPoints.dataToAssessment,
    params: {
      scholastic,
    },
    propKey: 'rubric',
    formatDataResult: ({
      rubrics,
      score_scale,
      assessment_name,
      rubric_iid,
    } = {}) => {
      const dataSource = formatDataSourceFromDataServerToRenderTableAssess(
        rubrics,
      );
      return {
        rubric_iid,
        assessment_name,
        dataSource,
        score_scale,
      };
    },
    fetchCondition: !!scholastic,
    refetchCondition: (prevProps) =>
      scholastic !== get(prevProps, 'scholastic'),
  };
};

export default compose(
  withUserInfo,
  withTemisConfig,
  fetchData(fetchSelfAssessment),
  fetchData(fetchRubricDoAssessment),
  withScholastic,
)(MyAssessment);
