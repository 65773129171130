import React, { Component } from 'react';
import { compose } from 'redux';
import './stylesheet.scss';
import Form from '../../../../assessment/edit/Form';
import DetailOnDialog from 'components/common/detail-on-dialog';
import PeersAssessment from '../../../../report/PeersAssessment';
import endPoints from '../../../endpoints';
import withTemisConfig from 'common/hoc/withTemisConfig';
import withUserInfo from 'common/hoc/withUserInfo';
import fetchData from 'components/common/fetchData';
import get from 'lodash.get';
import {
  generateClassByStatusAssessment,
  generateStatusResultAssessment,
} from '../../../../utils';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import {
  isHieuTruong,
  isTeacher,
  isToTruong,
} from 'components/admin/user/utils';
import TeamLeaderAssessOffline from '../../team-leader-assess-offline';
import { t1 } from 'translate';
import SummaryOpinion from 'components/temis/components/summary-opinion';
import Modal from 'antd/lib/modal';

class HeaderAssessment extends Component {
  getButtonText = () => {
    const {
      userInfo,
      userForAssessment,
      finalAggregateAssessment,
    } = this.props;

    if (isToTruong(userInfo) && !finalAggregateAssessment) {
      return 'Xem thủ trưởng đánh giá';
    }

    if (isToTruong(userForAssessment)) {
      return 'Xem tổ chuyên môn đánh giá';
    }

    return 'Xem đánh giá của đồng nghiệp';
  };

  render() {
    const {
      name,
      userForAssessment,
      userInfo,
      userIid,
      rubricIid,
      viewPeersAssessment,
      viewSelfAssessment,
      dialogKey,
      node,
      assessOfPeers = [],
      setUseTeamLeaderResult,
      showButtonUseTeamLeaderResult = false,
      scholastic,
      showDisabledUseTeamLeaderResultButton,
      setUseTeacherResult,
      showButtonUseTeacherResult = false,
      showDisabledUseTeacherResultButton,
      showButtonSummaryOpinion,
      finalAggregateAssessment,
    } = this.props;

    const assessmentSelfResult = parseInt(get(node, 'result.final'));
    const hasAssessmentPeerResult =
      Array.isArray(assessOfPeers) && assessOfPeers.length;
    const hasFinalAssessment = !isNaN(assessmentSelfResult);

    return (
      <div className="row m-b-15 header-assessment">
        <div className="col-md-10">
          <div className="header-assessment__content">
            <div className="title">Đánh giá CNN{name && `: ${name}`}</div>

            <div className="header-assessment__buttons">
              {viewSelfAssessment && (
                <DetailOnDialog
                  renderPreview={({ showFull }) => (
                    <button
                      type="button"
                      className="text-center btn btn-secondary btn-outline m-r-15"
                      onClick={() => showFull()}
                    >
                      {'Xem bản tự đánh giá'}
                    </button>
                  )}
                  renderFull={({ closeDialog }) => (
                    <>
                      <div className="assessment-result d-flex">
                        <div className="title">{`Đánh giá CNN: ${name}`}</div>
                        {hasFinalAssessment && (
                          <div className="m-l-35 assessment-result__result">
                            Xếp loại đánh giá:{' '}
                            <span
                              className={` status-text ${generateClassByStatusAssessment(
                                generateStatusResultAssessment(
                                  assessmentSelfResult,
                                ) || '',
                              )}`}
                            >
                              {generateStatusResultAssessment(
                                assessmentSelfResult,
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                      {hasFinalAssessment ? (
                        <div className="temis-manager">
                          <div className="mui-paper-no-padding mui-paper-no-box-shadow">
                            <Form
                              formId="self-assessment"
                              userIid={userIid}
                              requestSuccessful={() => {
                                closeDialog();
                              }}
                              readOnly
                              dialogKey={dialogKey}
                              scholastic={scholastic}
                              userForAssessment={userForAssessment}
                            />
                          </div>
                        </div>
                      ) : (
                        <SimpleNoResult text="Chưa gửi bản tự đánh giá" />
                      )}
                    </>
                  )}
                  dialogKey={dialogKey}
                  dialogOptionsProperties={{
                    width: '100%',
                    autoDetectWindowHeight: false,
                    className: 'modal-full-height',
                  }}
                />
              )}

              {viewPeersAssessment && (
                <>
                  {isToTruong(userForAssessment) ? (
                    <TeamLeaderAssessOffline
                      userForAssessment={userForAssessment}
                      buttonText={this.getButtonText()}
                    />
                  ) : (
                    <DetailOnDialog
                      renderPreview={({ showFull }) => (
                        <button
                          type="button"
                          className={`text-center btn btn-secondary btn-outline m-r-15 ${
                            !hasAssessmentPeerResult &&
                            !finalAggregateAssessment
                              ? 'button-disabled'
                              : ''
                          }`}
                          onClick={() => {
                            if (
                              !hasAssessmentPeerResult &&
                              !finalAggregateAssessment
                            ) {
                              Modal.warning({
                                centered: true,
                                content: (
                                  <span>Chưa có đánh giá của thủ trưởng</span>
                                ),
                              });
                            } else {
                              showFull();
                            }
                          }}
                        >
                          {this.getButtonText()}
                        </button>
                      )}
                      renderFull={() => {
                        return (
                          <PeersAssessment
                            user={userForAssessment}
                            dialogKey={dialogKey}
                            showExportButton={false}
                            useSuperiorAssessment={
                              isToTruong(userForAssessment) ||
                              (!finalAggregateAssessment &&
                                isToTruong(userInfo) &&
                                isTeacher(userForAssessment))
                            }
                            scholastic={scholastic}
                            viewPeersAssessment
                            viewSuperiorAssessment={isToTruong(userInfo)}
                            finalAggregateAssessment={finalAggregateAssessment}
                          />
                        );
                      }}
                      dialogKey={dialogKey}
                      dialogOptionsProperties={{
                        width: '100%',
                        autoDetectWindowHeight: false,
                        className:
                          'modal-full-height view-assessment-my-peer-modal',
                      }}
                    />
                  )}
                </>
              )}

              {showButtonUseTeacherResult && (
                <button
                  type="button"
                  className="text-center btn btn-secondary btn-outline m-r-15"
                  onClick={() => {
                    const currentMilliseconds = new Date().getTime();
                    setUseTeacherResult(currentMilliseconds);
                  }}
                  disabled={showDisabledUseTeacherResultButton}
                >
                  {t1('use_teacher_result')}
                </button>
              )}

              {showButtonSummaryOpinion && (
                <SummaryOpinion
                  userIid={userForAssessment.iid}
                  buttonClass={'m-r-15'}
                />
              )}

              {showButtonUseTeamLeaderResult && (
                <button
                  type="button"
                  className="text-center btn btn-secondary btn-outline"
                  onClick={() => {
                    const currentMilliseconds = new Date().getTime();
                    setUseTeamLeaderResult(currentMilliseconds);
                  }}
                  disabled={showDisabledUseTeamLeaderResultButton}
                >
                  {t1('use_team_leader_result')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const fetchSelfAssessment = (props) => {
  const { userIid, scholastic } = props;
  return {
    baseUrl: endPoints.assessmentResult,
    fetchCondition: scholastic && userIid,
    params: {
      userIid: userIid,
      scholastic,
    },
    method: 'get',
    propKey: 'node',
  };
};

const fetchAssessOfPeers = (props) => {
  const {
    rubricIid,
    userForAssessment,
    userInfo,
    scholastic,
    finalAggregateAssessment,
  } = props;
  const rubricIidByUserPassed = get(props, 'userForAssessment.rubric_iid');
  const useSuperiorAssessment =
    isToTruong(userForAssessment) ||
    (!finalAggregateAssessment &&
      isToTruong(userInfo) &&
      isTeacher(userForAssessment));
  return {
    baseUrl: endPoints.assessOfPeers,
    params: {
      ...(userForAssessment && userForAssessment.iid
        ? {
            user_iid: userForAssessment.iid,
          }
        : {}),
      useSuperiorAssessment: useSuperiorAssessment ? 1 : 0,
      scholastic,
    },
    propKey: 'assessOfPeers',
    fetchCondition: scholastic,
    refetchCondition: (prevProps) => props.scholastic !== prevProps.scholastic,
  };
};

export default compose(
  withUserInfo,
  withTemisConfig,
  fetchData(fetchSelfAssessment),
  fetchData(fetchAssessOfPeers),
)(HeaderAssessment);
