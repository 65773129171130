import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import Results from './Results';
import schema from './search-schema';
import endpoints from '../endpoints';
import withUserInfo from 'common/hoc/withUserInfo';

const FORM_ID = 'material-search';

const Layout = ({ userInfo }) => {
  const renderResultComponent = (items, props) => {
    return <Results items={items} {...props} searchFormId={FORM_ID} />;
  };

  return (
    <SearchWrapper
      ntype="module"
      formid={FORM_ID}
      renderResultsComponent={renderResultComponent}
      alternativeApi={endpoints.get_deal}
      schema={schema}
      hiddenFields={{
        _sand_step: 'seller',
      }}
      userInfo={userInfo}
      autoSearchWhenStart
    />
  );
};

export default withUserInfo(Layout);
