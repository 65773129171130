import React from 'react';
import NodeNew from 'components/admin/node/new';
import { connect } from 'react-redux';
import apiUrls from 'components/bdtx/module/endpoints';
import schema from '../schema/form';

export const newModuleFormId = 'new_module';

const Form = ({ mode, step, node, searchFormId, title, readOnly }) => {
  const alternativeApi = node ? apiUrls.update_module : apiUrls.new_module;

  return (
    <NodeNew
      title={title}
      alternativeApi={alternativeApi}
      schema={schema}
      mode={mode}
      step={step}
      node={node}
      closeModal
      searchFormId={searchFormId}
      formid={newModuleFormId}
      readOnly={readOnly}
    />
  );
};

export default connect()(Form);
