import { t1 } from 'translate';
import { taphuanCategoryElement } from 'components/admin/training-plan/schema/element/taphuan-category';
import { addPropsToEverySchemaElements } from 'common/utils/schema-form';
import { elementDisplayModes } from 'schema-form/constants';

const cotCanDataByCategory = ({ forRecap } = {}) => {
  let elementsSchema = {
    training_plan_category: taphuanCategoryElement({
      floatingLabelText: t1('training_plan_category_cotcan'),
      cotcan: true,
      modifyOptions: (options) => {
        return [
          {
            value: null,
            label: t1('any_module'),
          },
        ].concat(options);
      },
    }),
    is_cot_can: {
      type: 'multiCheckbox',
      floatingLabelText: t1('is_cot_can'),
      options: [
        {
          value: 0,
          label: t1('no'),
        },
        {
          value: 1,
          label: t1('yes'),
        },
      ],
      inline: true,
    },
    passed_cot_can: {
      type: 'multiCheckbox',
      floatingLabelText: t1('passed_cot_can'),
      options: [
        {
          value: 0,
          label: t1('no'),
        },
        {
          value: 1,
          label: t1('yes'),
        },
      ],
      inline: true,
    },
  };

  if (forRecap) {
    elementsSchema = addPropsToEverySchemaElements(elementsSchema, {
      elementDisplayMode: elementDisplayModes.RECAP,
    });
  }

  return {
    type: 'section',
    schema: {
      schema: elementsSchema,
      ui: () => [
        {
          id: 'default',
          fields: ['training_plan_category', 'is_cot_can', 'passed_cot_can'],
        },
      ],
    },
  };
};

export default cotCanDataByCategory;
