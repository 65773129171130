import React, { Component } from 'react';
import Result from './Result';
import get from 'lodash.get';
import withTemisConfig from 'common/hoc/withTemisConfig';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schemaSearch from './schema';
import endPoints from '../../endpoints';
import './style.scss';
import { TCNN_TYPE } from 'components/temis/constant';
import { leaderPositions } from 'configs/constants/user';
import withScholastic from '../../../components/scholastic/withScholastic';

class AssessMyPeers extends Component {
  renderResultsComponent = (items, props, objects, searchValues) => {
    const tcnn = get(props, 'formValues.type_of_assessment[0]');
    const assessment_of_organization_type = get(
      props,
      'formValues.assessment_of_organization_type',
    );

    const {
      scholastic,
      isResultOfHT,
      isResultOfHP,
      isSo,
      isPhong,
    } = this.props;

    return (
      <Result
        tcnn={tcnn}
        assessment_of_organization_type={assessment_of_organization_type}
        items={items}
        searchValues={searchValues}
        scholastic={scholastic}
        isResultOfHP={isResultOfHP}
        isResultOfHT={isResultOfHT}
        isSo={isSo}
        isPhong={isPhong}
      />
    );
  };

  render() {
    const formId = 'overview_in_organization';
    const {
      enableAssessmentForMamnon,
      currentOrganizations,
      scholastic,
      userIid,
      isResultOfHP,
      isResultOfHT,
      isSo,
      isPhong,
    } = this.props;
    const isResultCBQL = isResultOfHT || isResultOfHP;

    const hiddenFields = {
      user_organizations: currentOrganizations,
      type_of_assessment: [isResultCBQL ? TCNN_TYPE.HT : TCNN_TYPE.GV],
      leader_position: [
        isResultOfHT && leaderPositions.LEADER,
        isResultOfHP && leaderPositions.VICE_LEADER,
        !isResultOfHT && !isResultOfHP && leaderPositions.TEACHER,
      ].filter(Boolean),
      status_of_assessment: ['not_yet', 'awaiting', 'not_passed', 'passed'],
      scholastic,
      userIid,
      block_users_in_organization_inactive: 1,
    };

    return (
      <SearchWrapper
        key={`${formId}_${scholastic}_${
          isResultCBQL ? TCNN_TYPE.HT : TCNN_TYPE.GV
        }`}
        formid={`${formId}_${isResultCBQL ? TCNN_TYPE.HT : TCNN_TYPE.GV}`}
        step="overview"
        hiddenFields={hiddenFields}
        renderResultsComponent={this.renderResultsComponent}
        schema={schemaSearch}
        showResult={true}
        alternativeApi={endPoints.searchUser}
        autoSearchWhenStart
        paginationProps={{
          theme: 'light',
        }}
        isResultOfHP={isResultOfHP}
        isResultOfHT={isResultOfHT}
        isSo={isSo}
        isPhong={isPhong}
        enableAssessmentForMamnon={enableAssessmentForMamnon}
        prepareDataBeforeSearch={(data) => {
          if (!(isSo || isPhong)) {
            delete data.organizations;
          }
          return data;
        }}
        classPaginationWrapper="pagination-default custom-m-pagination custom-pagination-ellipsis"
      />
    );
  }
}

export default withScholastic(withTemisConfig(AssessMyPeers));
