import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import Results from './Results';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import lodashGet from 'lodash.get';

const RenderResultsComponent = ({
  items,
  type,
  tcnn,
  hideChart,
  setLastTimeFinishedSyncingData,
}) => {
  React.useEffect(
    () => {
      const shouldShowLastTime =
        typeof setLastTimeFinishedSyncingData === 'function' &&
        items &&
        items.last_time_finished_syncing;

      if (shouldShowLastTime) {
        setLastTimeFinishedSyncingData(items.last_time_finished_syncing);
      }
    },
    [setLastTimeFinishedSyncingData, items],
  );

  if (!items || !items.data || !items.data.length) {
    return <SimpleNoResult text="Không có dữ liệu" />;
  }

  return (
    <Results dataResult={items} type={type} tcnn={tcnn} hideChart={hideChart} />
  );
};

const TCNNReportSearch = ({
  tcnn,
  type,
  hideChart,
  scholastic,
  formIdDefault,
  setLastTimeFinishedSyncingData,
}) => {
  if (!scholastic) {
    return null;
  }

  const formId = `temis-report-${tcnn}-${type}-${scholastic}`;
  return (
    <SearchWrapper
      key={formIdDefault || formId}
      formid={formIdDefault || formId}
      tcnn={tcnn}
      hideChart={hideChart}
      schema={schema}
      hiddenFields={{
        tcnn,
        type,
        scholastic,
      }}
      showResultsWhenSubmitSucceeded
      alternativeApi="/assessment/api/get-data-tcnn-report"
      renderResultsComponent={(items, { type, tcnn, hideChart }) => (
        <RenderResultsComponent
          items={items}
          type={type}
          tcnn={tcnn}
          hideChart={hideChart}
          setLastTimeFinishedSyncingData={setLastTimeFinishedSyncingData}
        />
      )}
      showResult
    />
  );
};

export default TCNNReportSearch;
