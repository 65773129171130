import React, { Component } from 'react';
import TCNNReportSearch from './component/Form';
import withUserInfo from 'common/hoc/withUserInfo';
import { EXPORT_FILENAME, TCNN_TYPE } from 'components/temis/constant';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import { generateReportTitle } from 'components/temis/utils';

class TCNNBo extends Component {
  render() {
    const {
      userInfo = {},
      scholastic,
      isResultByRubric,
      isAdminHVQL,
      isResultForCBQL,
      reportTitle,
      showSearchForm,
      isResultForMN,
    } = this.props;

    const { organizations = [] } = userInfo;
    const bo = organizations[0];
    const excelExportFileName = generateReportTitle(reportTitle);

    return (
      <div className="report-result">
        <div className="report-result__organization m-b-10">
          {`${!!bo && bo.name}`}
        </div>
        <div>
          <div className="report-result__school-export-excel">
            <div className="report-result__tnnn-type">{reportTitle}</div>
          </div>

          <hr />

          <div className="report-result__container">
            {isResultForCBQL ? (
              <TCNNReportSearch
                tcnn={TCNN_TYPE.HT}
                scholastic={scholastic}
                isResultForCBQL
                isResultForMN={isResultForMN}
                hideSubmitButton
                autoSearchWhenValuesChange
                exportProps={{
                  fileName: EXPORT_FILENAME.overallResultAssessmentByTCNNHT,
                }}
                userInfo={userInfo}
                isAdminHVQL={isAdminHVQL}
                excelExportFileName={excelExportFileName}
                showSearchForm={showSearchForm}
              />
            ) : (
              <TCNNReportSearch
                tcnn={TCNN_TYPE.GV}
                scholastic={scholastic}
                isResultForMN={isResultForMN}
                isResultByRubric={isResultByRubric}
                hideSubmitButton
                autoSearchWhenValuesChange
                exportProps={{
                  fileName: EXPORT_FILENAME.overallResultAssessmentByTCNNGV,
                }}
                userInfo={userInfo}
                excelExportFileName={excelExportFileName}
                showSearchForm={showSearchForm}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withScholastic(withUserInfo(TCNNBo));
