import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import TableResults from './TableResults';
import apiEndpoints from '../../../endpoints';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import ExportData from 'components/common/export-data';
import { isBoOrAdminSP, isPhong, isSo } from 'components/admin/user/utils';
import ReportProgressSyncerButton from '../../syncer';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import lastTimeFinishedSyncing from '../../syncer/lastTimeFinishedSyncing';

const renderResultsComponent = (
  resultData = [],
  {
    isResultForCBQL,
    isResultForMN,
    exportProps,
    userInfo,
    tcnn,
    isPlanDevelopment,
    isReportOfTruong,
    isOutOfDeadlineAssessment,
    formid = '',
    reportForNcbdtx,
  },
  objects,
  searchValues = {},
) => {
  const { organizations = [], scholastic } = searchValues;

  const hasData = Array.isArray(resultData) && resultData.length;

  const getFileNameExportData = (type) => {
    const roles = {
      so: isSo(userInfo),
      phong: isPhong(userInfo),
      bo: isBoOrAdminSP(userInfo),
    };
    const nameByRole = Object.keys(roles).filter((key) => roles[key]);

    return `Bao_cao_tong_hop_ket_qua_cua_${
      nameByRole[0]
    }_danh_gia_xep_loai_${type}`;
  };

  return (
    <>
      <div className="m-b-15">
        <div className="d-flex justify-content-end">
          {!!hasData && !!objects && !!objects.last_time_finished_syncing && (
            <div className="m-t-12 m-r-10">
              {lastTimeFinishedSyncing(objects.last_time_finished_syncing)}
            </div>
          )}

          <div className="report-result__syncer m-r-10">
            <ReportProgressSyncerButton
              formId={formid}
              params={{
                scholastic,
                organizations,
              }}
              disabled={isOutOfDeadlineAssessment}
            />
          </div>

          {!!hasData && (
            <div className="report-result__export-excel">
              <ExportData
                buttonClass={'m-b-15'}
                apiExport={
                  isPlanDevelopment
                    ? apiEndpoints.export_summary_report_ncbdtx_by_tcnn
                    : apiEndpoints.export_summary_report_criteria_with_the_lowest_ranking_level
                }
                buttonText="Xuất excel"
                params={{
                  ...(tcnn ? { tcnn: tcnn } : {}),
                  ...(isResultForCBQL ? { isResultForCBQL: 1 } : {}),
                  isResultForMN: isResultForMN ? 1 : 0,
                  scholastic,
                }}
                fileName={getFileNameExportData(
                  isResultForCBQL ? 'CBQL' : 'giao_vien',
                )}
                tableExport={
                  <TableResults
                    skipFormatNumber={true}
                    dataResult={resultData}
                    isResultForCBQL={isResultForCBQL}
                    isResultForMN={isResultForMN}
                    tcnn={tcnn}
                    defaultExpandAllRows
                    isPlanDevelopment={isPlanDevelopment}
                    isReportOfTruong={isReportOfTruong}
                    reportForNcbdtx={reportForNcbdtx}
                  />
                }
                {...exportProps}
              />
            </div>
          )}
        </div>

        {hasData ? (
          <TableResults
            dataResult={resultData}
            isResultForCBQL={isResultForCBQL}
            isResultForMN={isResultForMN}
            tcnn={tcnn}
            isPlanDevelopment={isPlanDevelopment}
            isReportOfTruong={isReportOfTruong}
            reportForNcbdtx={reportForNcbdtx}
          />
        ) : (
          <SimpleNoResult text="Không có dữ liệu" />
        )}
      </div>
    </>
  );
};

const BDTXReportSearch = ({
  tcnn,
  isPlanDevelopment,
  scholastic,
  isResultForCBQL,
  showSearchForm,
  hideSubmitButton = false,
  autoSearchWhenValuesChange = false,
  userInfo,
  exportProps,
  isResultForMN,
  isReportOfTruong,
  formId = '',
  reportForNcbdtx,
  autoGetDataWithoutScholastic,
}) => {
  if (!scholastic && !autoGetDataWithoutScholastic) {
    return null;
  }

  return (
    <SearchWrapper
      key={formId}
      formid={formId}
      schema={showSearchForm ? schema : undefined}
      hiddenFields={{
        ...(tcnn ? { tcnn: tcnn } : {}),
        ...(isResultForCBQL ? { isResultForCBQL: 1 } : {}),
        ...(autoGetDataWithoutScholastic ? { without_scholastic: 1 } : {}),
        isResultForMN: isResultForMN ? 1 : 0,
        scholastic,
      }}
      alternativeApi={
        isPlanDevelopment
          ? apiEndpoints.summary_report_ncbdtx_by_tcnn
          : apiEndpoints.summary_report_criteria_with_the_lowest_ranking_level
      }
      renderResultsComponent={renderResultsComponent}
      isResultForCBQL={isResultForCBQL}
      isResultForMN={isResultForMN}
      hideSubmitButton={hideSubmitButton || !showSearchForm}
      showResult
      showResultsWhenSubmitSucceeded
      autoSearchWhenStart={!autoSearchWhenValuesChange}
      autoSearchWhenValuesChange={autoSearchWhenValuesChange}
      userInfo={userInfo}
      tcnn={tcnn}
      isPlanDevelopment={isPlanDevelopment}
      isReportOfTruong={isReportOfTruong}
      exportProps={exportProps}
      reportForNcbdtx={reportForNcbdtx}
    />
  );
};

export default withOutOfDeadlineAssessment(BDTXReportSearch);
