import React from 'react';
import withTemisConfig from 'common/hoc/withTemisConfig';
import { t1 } from 'translate';
import { organizations } from 'components/admin/organization/schema/elements';
import { required } from 'common/validators';
import get from 'lodash.get';
import taphuanSubTypes from 'configs/constants/org-sub-types';
import { isBoOrAdminSP, isSo } from 'components/admin/user/utils';

const orgTypesReached = [taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD];

const schema = (formid, values, step, xpath, props) => ({
  organizations: organizations({
    formid,
    multiple: false,
    floatingLabelText: t1('display_template'),
    defaultValue: props.orgIids,
    validate: required(t1('organization_can_not_empty')),
    ...(isBoOrAdminSP(props.userInfo)
      ? { orgTypesReached: orgTypesReached }
      : {}),
    ...(isSo(props.userInfo)
      ? {
          subTypesDiscarded: [
            taphuanSubTypes.TAPHUAN_SUBTYPE_TIEUHOC,
            taphuanSubTypes.TAPHUAN_SUBTYPE_THCS,
            taphuanSubTypes.TAPHUAN_SUBTYPE_THPT,
            taphuanSubTypes.TAPHUAN_SUBTYPE_LIENCAP,
            taphuanSubTypes.TAPHUAN_SUBTYPE_LTTU,
            taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
            taphuanSubTypes.TAPHUAN_SUBTYPE_GIAODUC_THUONGXUYEN,
            taphuanSubTypes.TAPHUAN_SUBTYPE_TUTHUC_LIENCAP,
          ],
        }
      : {}),
  }),
});

const ui = () => [
  {
    id: 'default',
    fields: ['organizations'],
  },
];

const LayoutFreeStyle = withTemisConfig(({ groups, submitButton }) => (
  <>
    <div>{get(groups, 'default.fieldNames.organizations')}</div>
    <div className="text-center">{submitButton}</div>
  </>
));

const layout = () => ({
  component: LayoutFreeStyle,
  freestyle: 1,
});

export default {
  schema,
  ui,
  layout,
};
