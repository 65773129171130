import React from 'react';
import get from 'lodash.get';
import endPoints from '../assessment/endpoints';
import withUserInfo from 'common/hoc/withUserInfo';
import withTemisConfig from 'common/hoc/withTemisConfig';
import fetchData from 'components/common/fetchData';
import { formatDataSourceFromDataServerToRenderTableAssess } from '../assessment/util';
import PeersResultAssessRolePrincipal from '../assessment/my-assessment/PeersResultAssessRolePrincipal';
import PeersResultAssessRoleOfTeacher from '../assessment/my-assessment/PeersResultAssessRoleOfTeacher';
import { isHieuTruongOrPho } from 'components/admin/user/utils';
import { compose } from 'redux';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import Loading from 'components/common/loading';

const PeersAssessment = ({
  assessment_name,
  dataSource,
  score_scale,
  node,
  user,
  scholastic,
  rubricToAssessment,
  useSuperiorAssessment,
  viewPeersAssessment,
  viewSuperiorAssessment,
  isPeersAssess,
  loadingStatus,
  finalAggregateAssessment,
}) => {
  if (loadingStatus !== 'finished') {
    return <Loading circularLoadingIcon />;
  }

  const PeerResult = isHieuTruongOrPho(user)
    ? PeersResultAssessRolePrincipal
    : PeersResultAssessRoleOfTeacher;

  return (
    <PeerResult
      assessment_name={assessment_name}
      dataSource={dataSource}
      score_scale={score_scale}
      selfAssess={node}
      user={user}
      useSuperiorAssessment={useSuperiorAssessment}
      scholastic={scholastic}
      rubricToAssessment={rubricToAssessment}
      viewPeersAssessment={viewPeersAssessment}
      isPeersAssess={isPeersAssess}
      viewSuperiorAssessment={viewSuperiorAssessment}
      finalAggregateAssessment={finalAggregateAssessment}
    />
  );
};

const fetchSelfAssessment = (props) => {
  const rubricIid = get(props, 'rubricToAssessment');
  const userIid = get(props, 'userInfo.iid');
  const scholastic = get(props, 'scholastic');
  const { finalAggregateAssessment = 0 } = props;
  return {
    baseUrl: endPoints.assessmentResult,
    fetchCondition: scholastic && userIid,
    refetchCondition: (prevProps) =>
      rubricIid &&
      userIid &&
      (!get(prevProps, 'rubricToAssessment') ||
        !get(prevProps, 'userInfo.iid') ||
        scholastic !== get(prevProps, 'scholastic')),
    params: {
      userIid: userIid,
      scholastic,
      final_aggregate_assessment: finalAggregateAssessment,
    },
    method: 'get',
    propKey: 'node',
  };
};

const fetchRubricDoAssessment = (props) => {
  const userIid = get(props, 'user.iid');
  const scholastic = get(props, 'scholastic');

  return {
    baseUrl: endPoints.dataToAssessment,
    params: {
      user_iid: userIid,
      scholastic,
    },
    propKey: 'rubric',
    formatDataResult: ({
      rubrics,
      score_scale,
      assessment_name,
      rubric_iid,
    } = {}) => {
      const dataSource = formatDataSourceFromDataServerToRenderTableAssess(
        rubrics,
      );
      return {
        rubric_iid,
        assessment_name,
        dataSource,
        score_scale,
      };
    },
    fetchCondition: userIid && scholastic,
    refetchCondition: (prevProps) =>
      scholastic !== get(prevProps, 'scholastic') ||
      userIid !== get(prevProps, 'user.iid'),
  };
};

export default compose(
  withScholastic,
  withTemisConfig,
  withUserInfo,
  fetchData(fetchSelfAssessment),
  fetchData(fetchRubricDoAssessment),
)(PeersAssessment);
