import React from 'react';
import get from 'lodash.get';
import Widget from 'components/common/Widget';
import { radarChartResultsAssess } from '../util';
import Alert from 'antd/lib/alert';
import NoAssessmentResult from '../../components/no-assessment-result';

const LayoutFreeStyle = (props) => {
  const submitButton = get(props, 'submitButton');

  const fieldNames = get(props, 'groups.default.fieldNames', {});
  const task = get(props, 'formValues.task', []);
  const {
    tcnnType,
    dataSource,
    score_scale,
    finalAggregateAssessment,
    assessorIid,
    doNotFillAllTasks,
    rubricIid,
  } = get(props, 'layoutOptionsProperties', {});
  if (!task || !task.length) {
    return <NoAssessmentResult text="Bạn chưa đánh giá." />;
  }

  return (
    <div>
      <Widget className="m-t-20">{fieldNames.task}</Widget>
      {!!fieldNames.overall_comment && (
        <Widget title="Nhận xét (ghi rõ)">
          <div className="row title-default__overall-comment">
            {fieldNames.overall_comment}
          </div>
        </Widget>
      )}
      {!!fieldNames.personal_orientation && (
        <Widget title="Kế hoạch học tập, bồi dưỡng phát triển năng lực nghề nghiệp trong năm tiếp theo">
          <div className="row title-default__overall-comment">
            {fieldNames.personal_orientation}
          </div>
        </Widget>
      )}
      {!!finalAggregateAssessment &&
        !assessorIid &&
        (() => {
          const scoreFinal = get(
            props,
            'layoutOptionsProperties.mySelfAssessment.result.final',
          );
          const scaleFinal = get(score_scale, 'parts', []).find(
            ({ id }) => String(id) === String(scoreFinal),
          );
          return (
            <Widget
              title="Ý kiến tự nhận xét và đánh giá"
              className="m-t-20"
              noMinHeight
            >
              <ul>
                <li>{`Điểm mạnh: ${get(
                  props,
                  'layoutOptionsProperties.mySelfAssessment.overall_comment.strengths',
                ) || ''}`}</li>
                <li>{`Những vấn đề cần cải thiện: ${get(
                  props,
                  'layoutOptionsProperties.mySelfAssessment.overall_comment.weakness',
                ) || ''}`}</li>
                <li>{`Xếp loại kết quả đánh giá: ${get(scaleFinal, 'name') ||
                  ''}`}</li>
              </ul>
            </Widget>
          );
        })()}

      {!!fieldNames.attachments && (
        <Widget title="Tệp đính kèm" className="view-attachment">
          <div>{fieldNames.attachments}</div>
        </Widget>
      )}

      {submitButton && (
        <div className="m-t-20">
          <div className="col-md-6 col-md-offset-3 text-center">
            <div>
              {radarChartResultsAssess(
                dataSource,
                task,
                score_scale,
                tcnnType,
                doNotFillAllTasks,
                rubricIid,
              )}
            </div>

            <div className="m-t-10">
              <Alert
                type="error"
                showIcon
                message='Đừng quên bấm nút "Gửi đánh giá" dưới đây'
              />
            </div>

            <div>{submitButton}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LayoutFreeStyle;
