import React, { Component, Fragment } from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from '../common/schema';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import apiUrls from 'components/temis/endpoints';
import TableResult from './TableResult';
import withUserInfo from 'common/hoc/withUserInfo';
import get from 'lodash.get';
import { isSGDStaff } from 'components/admin/user/utils';
import SimpleStatusSendAssessment from '../../../components/simple-status-send-assessment';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import { leaderPositions } from 'configs/constants/user';

const searchFormId = 'temis-school-in-organization';

class SchoolInOrganization extends Component {
  renderResult = (apiResponseData, { total }, objects, paramsSearchForm) => {
    const {
      handleToAssessment,
      scholastic,
      isOutOfDeadlineAssessment,
    } = this.props;
    return (
      <Fragment>
        <SimpleStatusSendAssessment className="m-b-15" />

        <TableResult
          apiResponseData={apiResponseData}
          handleToAssessment={handleToAssessment}
          totalItems={total}
          paramsSearchForm={paramsSearchForm}
          searchFormId={searchFormId}
          scholastic={scholastic}
          viewOnly={isOutOfDeadlineAssessment}
        />
      </Fragment>
    );
  };

  renderNoResult = () => <SimpleNoResult />;

  render() {
    const { userInfo, scholastic } = this.props;
    const org_province_id = get(userInfo, 'organizations[0].org_province_id');
    const org_district_id = get(userInfo, 'organizations[0].org_district_id');
    const isSo = isSGDStaff(userInfo);
    return (
      <SearchWrapper
        key={`${searchFormId}_${scholastic}`}
        formid={searchFormId}
        alternativeApi={apiUrls.get_leader_of_organization}
        schema={schema}
        renderResultsComponent={this.renderResult}
        renderNoResultComponent={this.renderNoResult}
        org_province_id={org_province_id}
        org_district_id={org_district_id}
        isSo={isSo}
        classPaginationWrapper="pagination-default"
        hiddenFields={{
          scholastic,
        }}
        defaultValues={{
          leader_position: [
            leaderPositions.LEADER,
            leaderPositions.VICE_LEADER,
            leaderPositions.TEACHER,
          ],
        }}
      />
    );
  }
}

export default withOutOfDeadlineAssessment(
  withScholastic(withUserInfo(SchoolInOrganization)),
);
