import React from 'react';
import { t1 } from 'translate';
import { mail } from '../../admin/user/schema/elements';

const schema = () => {
  return {
    secondary_phone: {
      type: 'phone',
      floatingLabelText: t1('phone'),
      fullWidth: true,
    },
    secondary_mail: mail({
      fullWidth: true,
      required: false,
      floatingLabelText: t1('mail'),
    }),
  };
};

const ui = () => [
  {
    id: 'default',
    fields: ['secondary_phone', 'secondary_mail'],
  },
];

export default {
  schema,
  ui,
};
