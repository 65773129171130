import React from 'react';
import { caphoc, chuong_trinh, targetUser } from 'components/common/elements';
import { simpleOrganization } from 'components/common/elements';
import subTypes from 'configs/constants/org-sub-types';
import { isBo } from 'components/admin/user/utils';
import { CHUONG_TRINH_03 } from 'components/bdtx/configs';
import { t1 } from 'translate';

const schema = () => {
  return {
    code: {
      type: 'text',
      floatingLabelText: 'Mã module',
      classWrapper: 'col-md-4',
    },
    sgd: simpleOrganization(
      {
        floatingLabelText: 'SGD',
        classWrapper: 'col-md-4',
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_SO_GD],
        },
      },
      false,
      {
        value: '',
        label: 'Tất cả đơn vị',
      },
    ),
    chuong_trinh: chuong_trinh(
      {
        classWrapper: 'col-md-3',
      },
      {
        excludeChuongTrinhs: [CHUONG_TRINH_03],
        shortName: true,
      },
    ),
    target_user: targetUser({
      type: 'radio',
      classWrapper: 'col-md-3',
    }),
    caps: caphoc({
      classWrapper: 'col-md-5',
    }),
    status: {
      type: 'multiCheckbox',
      floatingLabelText: t1('status'),
      floatingLabelFixed: true,
      options: [
        {
          value: 'approved',
          label: t1('approved'),
        },
        {
          value: 'queued',
          label: t1('queued'),
        },
      ],
      fullWidth: true,
      inline: true,
      classWrapper: 'col-md-4',
    },
  };
};

const ui = (step, values, themeConfig, xpath, formid, { userInfo = {} }) => {
  let fields = ['target_user', 'caps', 'status', 'chuong_trinh', 'code'];
  if (isBo(userInfo)) {
    fields = [...fields, 'sgd'];
  }

  return [
    {
      id: 'id',
      fields: fields,
    },
  ];
};

export default {
  schema,
  ui,
};
