import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import Results from './Results';
import { leaderPositions } from 'configs/constants/user';

class DetailReport extends React.PureComponent {
  renderResultsComponent = (item = {}, props, objects, searchValues) => {
    const { assessment_name, rubrics, score_scale, users } = item;

    if (!Array.isArray(users) || !users.length) {
      return <SimpleNoResult />;
    }

    return (
      <Results
        users={users}
        rubrics={rubrics}
        score_scale={score_scale}
        assessment_name={assessment_name}
        searchValues={searchValues}
      />
    );
  };

  render() {
    const { scholastic } = this.props;

    return (
      <SearchWrapper
        formid={`detail-report-temis`}
        schema={schema}
        hiddenFields={{ scholastic }}
        autoSearchWhenStart={false}
        renderResultsComponent={this.renderResultsComponent}
        alternativeApi="/temis/api/search-detail-assessment"
      />
    );
  }
}

export default withScholastic(DetailReport);
