import React, { useCallback } from 'react';
import get from 'lodash.get';
import orgApiUrls from 'components/admin/organization/endpoints';
import { Element } from 'schema-form/elements';
import fetchData from 'components/common/fetchData';
import { requiredPhongBan } from 'common/validators';
import { unaccentVietnamese } from 'common/utils/string/vn';
import TooltipAssessment from 'components/temis/components/tooltip-assessment';
import { leaderPositions } from 'configs/constants/user';

const Label = ({
  // floatingLabelText = 'Tổ chuyên môn (*)',
  floatingLabelText = 'Tổ chuyên môn',
  emptyMessage = '',
}) => {
  return (
    <>
      <span>{floatingLabelText}</span>
      {/*<span className="m-l-10">*/}
      {/*  <TooltipAssessment content={emptyMessage} />*/}
      {/*</span>*/}
    </>
  );
};

const CategorySelect = ({
  orgTypes,
  ancestor_iids,
  sub_leader_position,
  floatingLabelText,
  firstOption,
  defaultValue,
  requiredMessage = 'Hãy chọn tổ chuyên môn',
  emptyMessage = 'Nếu không có tổ chuyên môn hãy liên hệ với trường để thêm tổ chuyên môn',
  categories = [],
  formId,
  ...props
}) => {
  const getOptions = useCallback(
    () => {
      const options = categories.map((category) => ({
        value: category.value,
        label: category.name,
      }));

      const defaultFirstOption = { label: 'Tất cả bộ môn', value: '' };
      const customFirstOption =
        firstOption && typeof firstOption === 'object'
          ? firstOption
          : defaultFirstOption;
      return [customFirstOption, ...options];
    },
    [categories, firstOption],
  );

  const filterOption = (input, option) => {
    return (
      unaccentVietnamese(option.props.children)
        .toLowerCase()
        .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0
    );
  };

  return (
    <Element
      schema={{
        name: 'phongban_id',
        type: 'select',
        floatingLabelText: (
          <Label
            floatingLabelText={floatingLabelText}
            emptyMessage={emptyMessage}
          />
        ),
        options: getOptions(),
        defaultValue: defaultValue ? parseInt(defaultValue) : null,
        optionFilterProp: 'children',
        filterOption,
        disabledWhenOptionEmpty: false,
        validate:
          sub_leader_position === leaderPositions.TO_TRUONG
            ? requiredPhongBan(`${requiredMessage} (${emptyMessage})`)
            : [],
        ...props,
      }}
    />
  );
};

const fetchCategories = fetchData(({ ancestor_iids = '', orgTypes = [] }) => {
  const subType = Array.isArray(orgTypes)
    ? orgTypes.filter(({ is_phongban }) => is_phongban).map(({ type }) => type)
    : [];

  return {
    baseUrl: orgApiUrls.get_organizations_for_select_options,
    params: {
      p_iids: ancestor_iids,
      sub_type: subType,
    },
    propKey: 'categories',
    refetchCondition: (prevProps) =>
      ancestor_iids !== get(prevProps, 'ancestor_iids') ||
      orgTypes !== get(prevProps, 'orgTypes'),
  };
});

export default fetchCategories(CategorySelect);
