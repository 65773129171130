import { t1 } from 'translate';
import { constants } from 'configs/constants';
import sex from '../../../admin/group/schema/elements/filterset/filter/sex/sex';
import { organizations } from '../../../admin/organization/schema/elements';
import dtts from '../../../admin/group/schema/elements/filterset/filter/schema/dtts';
import dia_ban_khokhan from '../../../admin/group/schema/elements/filterset/filter/schema/dia_ban_khokhan';
import {
  leaderPositions,
  userGradeOptions,
  userGrades,
} from 'configs/constants/user';
import { taphuanTargetUserMulti } from '../../../admin/group/schema/elements/filterset/filter/schema/taphuanTargetUser';
import { required } from 'common/validators';
import apiUrls from '../../assessment/endpoints';
import { SCHOOL_TYPE, TCNN_TYPE } from '../../constant';

const schema = (formid, values, localStep, xpath, props) => {
  const tcnn = (Array.isArray(values.leader_position)
    ? values.leader_position
    : []
  ).includes(leaderPositions.TEACHER)
    ? TCNN_TYPE.GV
    : TCNN_TYPE.HT;

  const assessment_of_organization_type = (Array.isArray(values.grade)
    ? values.grade
    : []
  ).includes(userGrades.NURSERY)
    ? SCHOOL_TYPE.MN
    : SCHOOL_TYPE.GVPT;

  return {
    user_organizations: organizations({
      formid,
      label: `${t1('organizations')} (*)`,
      defaultValue: props.orgIids,
      fullWidth: true,
      multiple: false,
      rootIids: values.organizationRootIids,
      includeRoot: values.includeRootOrganizations,
      getOnlyOrganizationWhereUserHasPermission:
        values.getOnlyOrganizationWhereUserHasPermission,
      defaultOrganizations:
        Array.isArray(props.orgIids) && props.orgIids.length > 0
          ? props.orgIids
          : undefined,
      includeSubOrganizations: 0,
    }),
    sex: sex({ classWrapper: 'col-md-2' }),
    dtts: dtts({ inline: true, classWrapper: 'col-md-3 m-t-5' }),
    dia_ban_khokhan: dia_ban_khokhan({
      inline: true,
      classWrapper: 'col-md-3 m-t-5',
    }),
    target_user: taphuanTargetUserMulti(formid, {
      type: 'radio',
      classWrapper: 'col-md-3',
      floatingLabelText: 'TCĐGNN',
      defaultValue: 'gv',
    }),
    leader_position: {
      type: 'multiCheckbox',
      classWrapper: 'col-md-5',
      floatingLabelText: 'Chức vụ',
      fullWidth: true,
      inline: true,
      validate: required(t1('leader_position_is_required')),
      defaultValue: [leaderPositions.TEACHER],
      options: [
        {
          value: leaderPositions.TEACHER,
          label: 'Giáo viên',
          disabled:
            (Array.isArray(values.leader_position)
              ? values.leader_position
              : []
            ).includes(leaderPositions.VICE_LEADER) ||
            (Array.isArray(values.leader_position)
              ? values.leader_position
              : []
            ).includes(leaderPositions.LEADER),
        },
        {
          value: leaderPositions.LEADER,
          label: 'Hiệu trưởng',
          disabled: (Array.isArray(values.leader_position)
            ? values.leader_position
            : []
          ).includes(leaderPositions.TEACHER),
        },
        {
          value: leaderPositions.VICE_LEADER,
          label: 'Phó hiệu trưởng',
          disabled: (Array.isArray(values.leader_position)
            ? values.leader_position
            : []
          ).includes(leaderPositions.TEACHER),
        },
      ],
    },
    grade: {
      type: 'multiCheckbox',
      floatingLabelText: t1('user_grade'),
      classWrapper: 'col-md-5',
      options: userGradeOptions().map((option) => {
        if (!Array.isArray(values.grade) || !values.grade.length) {
          return option;
        }
        let disabledMN = true;
        if ((values.grade || []).includes(userGrades.NURSERY)) {
          disabledMN = false;
        }

        option.disabled =
          option.value === userGrades.NURSERY ? disabledMN : !disabledMN;

        return option;
      }),
      inline: true,
      validate: required(t1('grade_is_required')),
      defaultValue: [userGrades.PRIMARY, userGrades.SECONDARY, userGrades.HIGH],
    },
    assessment_type: {
      type: 'radio',
      floatingLabelText: null,
      classWrapper: 'col-md-6',
      defaultValue: 'self_assessment',
      options: [
        {
          value: 'self_assessment',
          label: t1('self_assessment'),
        },
        {
          value: 'final_assessment',
          label: t1('final_assessment'),
        },
      ],
      inline: true,
    },
    assessment_status: {
      type: 'select',
      floatingLabelText: 'Hoàn thành đánh giá',
      classWrapper: 'col-md-6',
      defaultValue: '',
      options: [
        {
          value: '',
          label: 'Tất cả',
        },
        {
          value: 'not_yet_assessment',
          label: 'Chưa đánh giá',
        },
        {
          value: 'finish_assessment',
          label: 'Đã đánh giá',
        },
      ],
      inline: true,
    },
    assessment_result: {
      type: 'select',
      floatingLabelText: 'Kết quả đánh giá',
      classWrapper: 'col-md-6',
      defaultValue: '',
      options: [
        {
          value: 0,
          label: 'Chưa đạt',
        },
        {
          value: 1,
          label: 'Đạt',
        },
        {
          value: 2,
          label: 'Khá',
        },
        {
          value: 3,
          label: 'Tốt',
        },
      ],
      inline: true,
    },
    personal_orientation_content: {
      type: 'select',
      multiple: true,
      floatingLabelText: 'Nội dung đăng ký học tập, bồi dưỡng',
      classWrapper: 'col-md-4',
      defaultValue: '',
      options: 'async',
      paramsasync: {
        __url__: apiUrls.get_personal_orientation_content,
        key: `get_personal_orientation_content-${tcnn}-${assessment_of_organization_type}`,
        value: {
          tcnn,
          assessment_of_organization_type,
        },
        transformData: (options) => {
          if (!Array.isArray(options) || !options.length) {
            return [];
          }
          return options.map(({ iid, name }) => ({
            value: iid,
            label: name,
            primaryText: name,
          }));
        },
      },
    },
    personal_orientation_time_doing: {
      type: 'select',
      multiple: true,
      floatingLabelText: 'Thời gian hoàn thành mô đun bồi dưỡng',
      classWrapper: 'col-md-4',
      defaultValue: '',
      options: 'async',
      paramsasync: {
        __url__: apiUrls.get_personal_orientation_time_doing,
        key: `get_personal_orientation_time_doing-${tcnn}-${assessment_of_organization_type}`,
        value: {
          tcnn,
          assessment_of_organization_type,
        },
        transformData: (options) => {
          if (!Array.isArray(options) || !options.length) {
            return [];
          }
          return options.map(({ iid, name }) => ({
            value: iid,
            label: name,
            primaryText: name,
          }));
        },
      },
    },
    personal_orientation_performance_conditions: {
      type: 'select',
      multiple: true,
      floatingLabelText: 'Điều kiện thực hiện',
      classWrapper: 'col-md-4',
      defaultValue: '',
      options: 'async',
      paramsasync: {
        __url__: apiUrls.get_personal_orientation_performance_conditions,
        key: `get_personal_orientation_performance_conditions-${tcnn}-${assessment_of_organization_type}`,
        value: {
          tcnn,
          assessment_of_organization_type,
        },
        transformData: (options) => {
          if (!Array.isArray(options) || !options.length) {
            return [];
          }
          return options.map(({ iid, name }) => ({
            value: iid,
            label: name,
            primaryText: name,
          }));
        },
      },
    },
  };
};

const ui = (step, values = {}) => {
  return [
    {
      id: 'default',
      fields: [
        'user_organizations',
        'sex',
        'leader_position',
        'grade',
        'dtts',
        'dia_ban_khokhan',
        'assessment_type',
      ],
    },
    {
      id: 'assessment',
      fields: [
        'assessment_status',
        values.assessment_status === 'finish_assessment' && 'assessment_result',
      ].filter(Boolean),
    },
    values &&
      values.assessment_type === 'self_assessment' &&
      Array.isArray(values.leader_position) &&
      !!values.leader_position.length && {
        id: 'personal_orientation',
        fields: [
          'personal_orientation_content',
          'personal_orientation_time_doing',
          'personal_orientation_performance_conditions',
        ],
      },
  ].filter(Boolean);
};

export default {
  schema,
  ui,
};
