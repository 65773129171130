import React from 'react';
import TcnnEdit from './assessment/edit';
import Dashboard from './Dashboard';
import EditProfile from './profile/edit/Temis';
import CompleteProfileBeforeUsing from './profile/complete-before-using';
import Evidence from './evidence';
import Report from './report';
import ResultAssessment from './assessment/result-assessment';

import MyAssessments from './assessment/my-assessment/Dashboard';
import AssignPeersToAssessment from './assessment/approve-assessment/search';
import PeersToAssessment from './assessment/approve-assessment/peer-list';
import ChooseTeacherMaster from './assessment/approve-assessment/choose-teacher-master';

import AssessmentsInOrganization from './assessment/assessments-in-organization/my-assessment';
import AssessmentsInOrganizationMyAssessment from './assessment/assessments-in-organization/my-assessment';
import AssessmentsInOrganizationAssessmentMyPeers from './assessment/assessments-in-organization/assessment-my-peer';
import AssessmentsInOrganizationAllAssessment from './assessment/assessments-in-organization/all-assessment';
import AssessmentsInOrganizationSchool from './assessment/assessments-in-organization/school';

import ReportAssessments from './report/OrganizationReport';

import ManagerAssessment from './assessment/manager-assessment/school-in-organization';
import ManagerAssessmentSchoolInOrganization from './assessment/manager-assessment/school-in-organization';
import ManagerAssessmentPrincipalAssessment from './assessment/manager-assessment/principal-assessment';
import ManagerAssessmentChooseManager from './assessment/manager-assessment/choose-manager';
import ManagerAssessmentApproveTeacher from './assessment/manager-assessment/approve-teacher';
import ManagerCoworkerAssessmentResult from './assessment/coworker-assessment-result';

import {
  ACCOUNT_ROUTER,
  ASSESS_ASSESSMENT_GROUP_ROUTER,
  ASSESS_ASSESSMENT_ROUTER,
  ASSESSMENTS_IN_ORGANIZATION_GROUP_ROUTER,
  ASSESSMENTS_IN_ORGANIZATION_ROUTER,
  CATEGORY_ROUTER,
  MANAGER_ASSESSMENT_GROUP_ROUTER,
  MANAGER_ASSESSMENT_ROUTER,
  PROFILE_GROUP_ROUTER,
  PROFILE_ROUTER,
  REPORT_GROUP_ROUTER,
  SUMMARY_ASSESSMENT_RESULT_ROUTER,
  SUMMARY_ASSESSMENT_RESULT_ROUTER_GROUP_ROUTER,
} from './constant';
import CVReport from './report/report-cv';
import CBQLReport from './report/report-cbql';
import DetaiAssessmentReport from './report/detail';
import Account from './account';
import Category from './category';
import CTBDTXReport from './report/report-ctbdtx';
import NCBDTXReport from './report/report-ncbdtx';
import SubOrganization from './sub-organization';

import TemisProfileContainer from 'components/temis/profile/pages/Container';
import { stringify } from 'query-string';

const ProfileDashboard = () => <TemisProfileContainer updateType="dashboard" />;
const UpdateInfo = () => <TemisProfileContainer updateType="info" />;
const UpdateAvatar = () => <TemisProfileContainer updateType="avatar" />;
const ChangePass = () => <TemisProfileContainer updateType="change-password" />;

const CoworkerAssessment = () => (
  <PeersToAssessment finalAggregateAssessment={0} />
);
const PeersAssessment = () => (
  <PeersToAssessment finalAggregateAssessment={1} />
);

export const editAccount = (id) => `/temis/account/edit/${id}`;

export const editBdtx = (userId, surveyIid) =>
  `/surveys/bdtx/${userId}/${surveyIid}`;

export const temisAssessments = (type) => `/temis/assessment/${type}`;

export const editTcnn = temisAssessments('do-assessment');

export const getEditTcnnLink = ({ scholasticId } = {}) => {
  const queryStr = stringify({
    scholastic: scholasticId,
  });

  return `${editTcnn}${queryStr ? `?${queryStr}` : ''}`;
};

export const temisDashboard = '/temis';

export const temisEvidence = '/temis/evidence';

export const temisConfig = '/temis/config';

export const selfAssessment = '/temis/assessment/self';

export const getSelfAssessmentLink = ({ scholasticId } = {}) => {
  const queryStr = stringify({
    scholastic: scholasticId,
  });

  return `${selfAssessment}${queryStr ? `?${queryStr}` : ''}`;
};

export const temisSubOrganization = '/temis/sub-organization';

const routes = () => [
  {
    path: '/temis',
    component: Dashboard,
    exact: true,
  },
  {
    path: PROFILE_ROUTER,
    component: ProfileDashboard,
    exact: true,
  },
  {
    path: PROFILE_GROUP_ROUTER.update_info,
    component: UpdateInfo,
    exact: true,
  },
  {
    path: PROFILE_GROUP_ROUTER.update_avatar,
    component: UpdateAvatar,
    exact: true,
  },
  {
    path: PROFILE_GROUP_ROUTER.change_password,
    component: ChangePass,
    exact: true,
  },
  {
    path: temisAssessments('do-assessment'),
    component: TcnnEdit,
    exact: true,
  },
  {
    path: '/temis/profile/complete-before-using',
    component: CompleteProfileBeforeUsing,
    exact: true,
  },
  {
    path: '/temis/evidence',
    component: Evidence,
    exact: true,
  },
  {
    path: temisAssessments('result-assessment'),
    component: ResultAssessment,
    exact: true,
  },
  {
    path: '/temis/report:slash(|/):reportType(|[0-9a-zA-Z_-]*)',
    component: Report,
    exact: true,
  },
  {
    path: temisAssessments('self'),
    component: MyAssessments,
    exact: true,
  },
  {
    path: ASSESS_ASSESSMENT_ROUTER,
    component: AssignPeersToAssessment,
    exact: true,
  },
  {
    path: ASSESS_ASSESSMENT_GROUP_ROUTER.assign,
    component: AssignPeersToAssessment,
    exact: true,
  },
  {
    path: ASSESS_ASSESSMENT_GROUP_ROUTER.coworker,
    component: CoworkerAssessment,
    exact: true,
  },
  {
    path: ASSESS_ASSESSMENT_GROUP_ROUTER.peers,
    component: PeersAssessment,
    exact: true,
  },
  {
    path: ASSESSMENTS_IN_ORGANIZATION_ROUTER,
    component: AssessmentsInOrganization,
    exact: true,
  },
  {
    path: ASSESSMENTS_IN_ORGANIZATION_GROUP_ROUTER.my_assessment,
    component: AssessmentsInOrganizationMyAssessment,
    exact: true,
  },
  {
    path: ASSESSMENTS_IN_ORGANIZATION_GROUP_ROUTER.assessment_my_peer,
    component: AssessmentsInOrganizationAssessmentMyPeers,
    exact: true,
  },
  {
    path: ASSESSMENTS_IN_ORGANIZATION_GROUP_ROUTER.all_assessment,
    component: AssessmentsInOrganizationAllAssessment,
    exact: true,
  },
  {
    path: SUMMARY_ASSESSMENT_RESULT_ROUTER,
    component: AssessmentsInOrganizationSchool,
    exact: true,
  },
  {
    path: SUMMARY_ASSESSMENT_RESULT_ROUTER_GROUP_ROUTER.school,
    component: AssessmentsInOrganizationSchool,
    exact: true,
  },
  {
    path: SUMMARY_ASSESSMENT_RESULT_ROUTER_GROUP_ROUTER.choose_teacher,
    component: ChooseTeacherMaster,
    exact: true,
  },
  {
    path: MANAGER_ASSESSMENT_ROUTER,
    component: ManagerAssessment,
    exact: true,
  },
  {
    path: MANAGER_ASSESSMENT_GROUP_ROUTER.school_in_organization,
    component: ManagerAssessmentSchoolInOrganization,
    exact: true,
  },
  {
    path: MANAGER_ASSESSMENT_GROUP_ROUTER.principal_assessment,
    component: ManagerAssessmentPrincipalAssessment,
    exact: true,
  },
  {
    path: MANAGER_ASSESSMENT_GROUP_ROUTER.approve_teacher,
    component: ManagerAssessmentApproveTeacher,
    exact: true,
  },
  {
    path: MANAGER_ASSESSMENT_GROUP_ROUTER.choose_manager,
    component: ManagerAssessmentChooseManager,
    exact: true,
  },
  {
    path: temisAssessments('coworker-assessment-result'),
    component: ManagerCoworkerAssessmentResult,
    exact: true,
  },
  {
    path: ACCOUNT_ROUTER,
    component: Account,
    exact: true,
  },
  {
    path: CATEGORY_ROUTER,
    component: Category,
    exact: true,
  },
  {
    path: `${ACCOUNT_ROUTER}/edit/:id`,
    component: EditProfile,
    exact: true,
  },
  {
    path: temisSubOrganization,
    component: SubOrganization,
    exact: true,
  },
];

export const routesTemisReport = () => [
  {
    path: REPORT_GROUP_ROUTER.cv,
    component: CVReport,
    exact: true,
  },
  {
    path: REPORT_GROUP_ROUTER.detail,
    component: DetaiAssessmentReport,
    exact: true,
  },
  {
    path: REPORT_GROUP_ROUTER.cbql_gvpt,
    component: CBQLReport,
    exact: true,
  },
  {
    path: REPORT_GROUP_ROUTER.cbql_gvmn,
    component: CBQLReport,
    isResultForMN: true,
    exact: true,
  },
  {
    path: REPORT_GROUP_ROUTER.cbql_cbpt,
    component: CBQLReport,
    exact: true,
    isResultForCBQL: true,
  },
  {
    path: REPORT_GROUP_ROUTER.cbql_cbmn,
    component: CBQLReport,
    isResultForMN: true,
    exact: true,
    isResultForCBQL: true,
  },
  {
    path: REPORT_GROUP_ROUTER.cbql_gvpt_by_rubric,
    component: CBQLReport,
    exact: true,
    isResultByRubric: true,
    showSearchForm: false,
  },
  {
    path: REPORT_GROUP_ROUTER.cbql_gvmn_by_rubric,
    component: CBQLReport,
    exact: true,
    isResultForMN: true,
    isResultByRubric: true,
    showSearchForm: false,
  },
  {
    path: REPORT_GROUP_ROUTER,
    component: CBQLReport,
    isResultForMN: true,
    exact: true,
    isResultByRubric: true,
    showSearchForm: false,
  },
  {
    path: REPORT_GROUP_ROUTER.cbql_cbpt_by_rubric,
    component: CBQLReport,
    exact: true,
    isResultForCBQL: true,
    isResultByRubric: true,
    showSearchForm: false,
  },
  {
    path: REPORT_GROUP_ROUTER.cbql_cbmn_by_rubric,
    component: CBQLReport,
    exact: true,
    isResultForMN: true,
    isResultForCBQL: true,
    isResultByRubric: true,
    showSearchForm: false,
  },
  {
    path: REPORT_GROUP_ROUTER.ctbdtx_gvpt,
    component: CTBDTXReport,
    exact: true,
  },
  {
    path: REPORT_GROUP_ROUTER.ctbdtx_cbql,
    component: CTBDTXReport,
    exact: true,
    isResultForCBQL: true,
  },
  {
    path: REPORT_GROUP_ROUTER.ncbdtx_gvpt,
    component: NCBDTXReport,
    exact: true,
  },
  {
    path: REPORT_GROUP_ROUTER.ncbdtx_gvmn,
    component: NCBDTXReport,
    isResultForMN: true,
    exact: true,
  },
  {
    path: REPORT_GROUP_ROUTER.ncbdtx_cbql,
    component: NCBDTXReport,
    exact: true,
    isResultForCBQL: true,
  },
  {
    path: REPORT_GROUP_ROUTER.ncbdtx_cbmn,
    component: NCBDTXReport,
    isResultForMN: true,
    exact: true,
    isResultForCBQL: true,
  },
  {
    path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_gvpt,
    component: NCBDTXReport,
    exact: true,
    isPlanDevelopment: true,
  },
  {
    path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_gvmn,
    component: NCBDTXReport,
    isResultForMN: true,
    exact: true,
    isPlanDevelopment: true,
  },
  {
    path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_cbpt,
    component: NCBDTXReport,
    exact: true,
    isResultForCBQL: true,
    isPlanDevelopment: true,
  },
  {
    path: REPORT_GROUP_ROUTER.ncbdtx_plan_develop_cbmn,
    component: NCBDTXReport,
    isResultForMN: true,
    exact: true,
    isResultForCBQL: true,
    isPlanDevelopment: true,
  },
  {
    path: '/temis/report/:type',
    component: ReportAssessments,
    exact: true,
  },
];

export default routes;
