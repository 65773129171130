import React from 'react';
import NodeNew from 'components/admin/node/new';
import schema from './schema';
import { t } from 'translate';
import { getCategoryStructureLevelNameSelector } from 'common/category-structure/selectors';
import { getOrgTypes, schoolSubTypes } from 'configs/constants';
import { connect } from 'react-redux';

const From = ({
  node = {},
  childrenOrganizationLevelName,
  orgTypes,
  searchFormId,
  formid,
  dialogKey,
  hiddenFields = {},
}) => {
  hiddenFields.orgTypes = orgTypes;

  return (
    <NodeNew
      ntype="organization"
      closeModal
      formid={formid}
      searchFormId={searchFormId}
      schema={schema}
      params={{
        pid: node.id,
        p_code: node.code,
        identifier: t(childrenOrganizationLevelName),
        type: 'organization',
      }}
      hiddenFields={hiddenFields}
      alternativeApi="/organization/api/new"
      dialogKey={dialogKey}
    />
  );
};

const mapStateToProps = (state, props) => {
  const { node = {} } = props;
  const level = (node.level || 0) + 1;
  const childrenOrganizationLevelName = getCategoryStructureLevelNameSelector(
    state,
  )(node.type, level);
  return {
    childrenOrganizationLevelName,
    orgTypes: getOrgTypes(
      state,
      window.isTaphuan && schoolSubTypes.includes(node.sub_type)
        ? 'phongban'
        : '*',
    ),
  };
};

export default connect(mapStateToProps)(From);
