import React, { Component } from 'react';
import withUserInfo from 'common/hoc/withUserInfo';
import withTemisConfig from 'common/hoc/withTemisConfig';
import { compose } from 'redux';
import ReportMyAssessment from '../../../report/MyAssessment';
import { isHieuTruongOrPho } from 'components/admin/user/utils';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import { TCNN_TYPE } from 'components/temis/constant';

class AssessmentsInOrganizationMyAssessment extends Component {
  render() {
    const { userInfo = {}, rubricToAssessment, scholastic } = this.props;
    return (
      <ReportMyAssessment
        user={userInfo}
        rubricIid={rubricToAssessment}
        readOnly
        tcnnType={isHieuTruongOrPho(userInfo) ? TCNN_TYPE.HT : TCNN_TYPE.GV}
        scholastic={scholastic}
      />
    );
  }
}

export default compose(
  withScholastic,
  withTemisConfig,
  withUserInfo,
)(AssessmentsInOrganizationMyAssessment);
