import React from 'react';
import './style.scss';
import get from 'lodash.get';
import Tooltip from 'antd/lib/tooltip';
import {
  isToTruong,
  isHieuTruong,
  isTeacher,
} from 'components/admin/user/utils';

const PeerResult = ({
  result = {},
  date,
  showPopup,
  isApproved,
  viewOnly = false,
  hasSelfAssessment = false,
  hasPeerAssessment = false,
  userInfo,
  userForAssessment,
  phongBanOfUser,
  finalAggregateAssessment = false,
}) => {
  const id = get(result, 'id');
  const isPeerAssessment =
    isHieuTruong(userInfo) &&
    finalAggregateAssessment &&
    isTeacher(userForAssessment) &&
    !isToTruong(userForAssessment);

  const isDisabled = isPeerAssessment ? !hasPeerAssessment : !hasSelfAssessment;

  let noAssessmentText = 'Chưa có bản tự đánh giá';
  if (hasSelfAssessment && isPeerAssessment) {
    noAssessmentText = !!phongBanOfUser
      ? 'Chưa có đánh giá của tổ trưởng'
      : 'Chưa chọn tổ chuyên môn';
  }

  if (id === undefined || !hasSelfAssessment) {
    return (
      <div className="text-center">
        <button
          className="btn btn-secondary btn-outline"
          onClick={showPopup}
          disabled={viewOnly || isDisabled}
        >
          Đánh giá
        </button>

        {!viewOnly && isDisabled && (
          <div className="text-danger text-center">
            <span className="ve-edit-alt m-r-5 icon-small-text" />
            {noAssessmentText}
          </div>
        )}
      </div>
    );
  }

  const name = get(result, 'name');

  return (
    <div className="peer-result">
      <div className="peer-result__left">
        <span className={`${isApproved ? 've-check-circle' : 've-edit-alt'}`} />
      </div>
      <div className="peer-result__middle">
        {date && <div className="peer-result__date">Đã đánh giá: {date}</div>}
        <div className="peer-result__pass-fail">
          Kết quả: <span className={`result-${id}`}> {name} </span>
        </div>
      </div>
      <div className="peer-result__right">
        <Tooltip title="Cập nhật đánh giá">
          <span
            className="ve-edit cursor-pointer"
            onClick={() => showPopup()}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default PeerResult;
