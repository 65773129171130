import { put, takeEvery } from 'redux-saga/effects';
import { SCHOLASTIC_CONFIG_LOADING } from 'actions/scholastic-config/types';
import {
  loadScholasticConfigError,
  loadScholasticConfigSuccess,
} from 'actions/scholastic-config';
import Requester from 'common/network/http/Request';
import apiUrls from 'components/temis/endpoints/index';

function* loadScholasticConfig() {
  try {
    const apiResponse = yield Requester.get(apiUrls.get_scholastic_config);

    if (apiResponse && apiResponse.success) {
      yield put(loadScholasticConfigSuccess(apiResponse.result));
    } else {
      yield put(
        loadScholasticConfigError(
          new Error('Could not load scholastic config'),
        ),
      );
    }
  } catch (error) {
    yield put(loadScholasticConfigError(error));
  }
}

export default function* loadScholasticConfigSaga() {
  yield takeEvery(SCHOLASTIC_CONFIG_LOADING, loadScholasticConfig);
}
