import React from 'react';
import { t1 } from 'translate/index';
import { organizations } from 'components/admin/organization/schema/elements/index';
import get from 'lodash.get';
import taphuanSubTypes from 'configs/constants/org-sub-types';

const schema = (formid, values, step, xpath, props, domainInfo) => {
  const { isSo = false } = props;
  const enableAssessmentForMamnon = get(
    domainInfo,
    'conf.enable_assessment_for_mamnon',
  );
  return {
    organizations: organizations({
      formid,
      multiple: false,
      label: `${t1('organizations')}`,
      defaultValue: props.orgIids,
      subTypesDiscarded: isSo
        ? [
            taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD,
            !enableAssessmentForMamnon &&
              taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
          ]
        : [
            !enableAssessmentForMamnon &&
              taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
          ],
    }),
    text: {
      type: 'text',
      hintText: 'Nhập Tên, Mã hoặc Email',
      floatingLabelText: 'Tìm kiếm CBQL',
      fullWidth: true,
    },
    assessment_status: {
      type: 'multiCheckbox',
      inline: true,
      options: [
        {
          value: 'have_sent',
          label: 'Hiệu trưởng đã gửi đánh giá',
        },
        {
          value: 'have_not_sent_yet',
          label: 'Hiệu trưởng chưa gửi đánh giá',
        },
        {
          value: 'have_assessed',
          label: `${isSo ? 'Sở' : 'Phòng'} đã đánh giá`,
        },
        {
          value: 'have_not_assessed_yet',
          label: `${isSo ? 'Sở' : 'Phòng'} chưa đánh giá`,
        },
      ],
    },
  };
};

const ui = () => [
  {
    id: 'default',
    fields: ['organizations', 'text', 'assessment_status'],
  },
];

const LayoutFreeStyle = ({ groups, submitButton }) => (
  <div className="row search-form-assessment">
    <div className="col-md-10">
      <div className="row">
        <div className="col-md-6">
          {get(groups, 'default.fieldNames.organizations')}
        </div>
        <div className="col-md-6">{get(groups, 'default.fieldNames.text')}</div>
      </div>
      <div>{get(groups, 'default.fieldNames.assessment_status')}</div>
    </div>
    <div className="col-md-2 text-center m-t-5">{submitButton}</div>
  </div>
);

const layout = () => ({
  component: LayoutFreeStyle,
  freestyle: 1,
});

export default {
  schema,
  ui,
  layout,
};
