import React from 'react';
import Search from './search';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import topMenuSchema from './top-menu-schema';

const Deals = () => {
  return (
    <>
      <SubTopMenuContext
        lastBreadcrumbName="Bản quyền học liệu"
        schema={topMenuSchema()}
      />

      <h1>Danh sách các học liệu đã được mua</h1>
      <Search />
    </>
  );
};

export default Deals;
