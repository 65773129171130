import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import UpdateContactInfoForm from './UpdateContactInfoForm';
import { t1 } from 'translate';
import withUserInfo from 'common/hoc/withUserInfo';
import { shouldShowUpdateContactInfoDialog } from './utils';

const UpdateContactInfo = ({ userInfo }) => {
  if (!shouldShowUpdateContactInfoDialog(userInfo)) {
    return null;
  }

  return (
    <DetailOnDialog
      renderFull={({ closeDialog }) => (
        <div>
          <span>{t1('confirm_contact_info_description')}</span>

          <UpdateContactInfoForm
            userInfo={userInfo}
            requestSuccessful={closeDialog}
          />
        </div>
      )}
      dialogOptionsProperties={{
        title: t1('confirm_contact_info'),
        modal: false,
        handleClose: true,
        width: '450px',
      }}
      dialogKey="confirm_contact_info_dialog"
      defaultRenderFull={true}
    />
  );
};

export default withUserInfo(UpdateContactInfo);
