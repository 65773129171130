import React from 'react';
import { generateClassByStatusAssessment } from '../utils';
import { assessmentResultShortText } from './constant';

const Title = () => {
  return (
    <div className="title-evaluation">
      Kết quả đánh giá(Chưa đạt (
      <span
        className={`color-status-${generateClassByStatusAssessment(
          assessmentResultShortText.CD,
        )}`}
      >
        CĐ
      </span>
      ) Đạt (
      <span
        className={`color-status-${generateClassByStatusAssessment(
          assessmentResultShortText.D,
        )}`}
      >
        Đ
      </span>
      ) Khá (
      <span
        className={`color-status-${generateClassByStatusAssessment(
          assessmentResultShortText.K,
        )}`}
      >
        K
      </span>
      ) Tốt (
      <span
        className={`color-status-${generateClassByStatusAssessment(
          assessmentResultShortText.T,
        )}`}
      >
        T
      </span>
      ))
    </div>
  );
};

export default Title;
