import React, { Component } from 'react';
import Widget from 'components/common/Widget';
import BDTXReportSearch from './component/Form';
import withUserInfo from 'common/hoc/withUserInfo';
import { TCNN_TYPE } from 'components/temis/constant';
import { convertStringToElement } from '../utils';
import { generateReportTitle } from 'components/temis/utils';

class BDTXPhong extends Component {
  render() {
    const {
      userInfo = {},
      scholastic,
      isResultForCBQL,
      isPlanDevelopment,
      reportTitle,
      isResultForMN,
    } = this.props;
    const { organizations = [] } = userInfo;
    const phong = organizations[0];
    const tcnn = isResultForCBQL ? TCNN_TYPE.HT : TCNN_TYPE.GV;
    const formId = `temis-report-survey-needs-${tcnn}-${
      isPlanDevelopment ? 'khbd' : 'ncbd'
    }-${scholastic}`;

    const excelExportFileName = generateReportTitle(reportTitle);

    return (
      <div className="report-result">
        <div className="report-result__organization m-b-10">{`Phòng giáo dục: ${!!phong &&
          phong.name}`}</div>

        <div>
          <div className="report-result__school-export-excel">
            <div className="report-result__tnnn-type">
              {convertStringToElement(reportTitle)}
            </div>
          </div>

          <hr />

          <div>
            <div className="report-result__container">
              <Widget className="title-default">
                <BDTXReportSearch
                  formId={formId}
                  tcnn={tcnn}
                  scholastic={scholastic}
                  exportProps={{
                    fileName: excelExportFileName,
                  }}
                  userInfo={userInfo}
                  isResultForMN={isResultForMN}
                  isResultForCBQL={isResultForCBQL}
                  isPlanDevelopment={isPlanDevelopment}
                />
              </Widget>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withUserInfo(BDTXPhong);
