import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import apiUrls from 'components/temis/endpoints';
import Results from './Results';
import New from './new';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';

const TemisTemplatelessEvidence = ({
  formid = 'temis_templateless_evidence',
  scholastic,
  viewOnly,
}) => {
  const renderResultComponent = React.useCallback(
    (items, props) => {
      return (
        <Results
          items={items}
          searchFormId={formid}
          {...props}
          scholastic={scholastic}
          viewOnly={viewOnly}
        />
      );
    },
    [formid, scholastic, viewOnly],
  );

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Không có tài liệu minh chứng" />;
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <New
          searchFormId={formid}
          viewOnly={viewOnly}
          scholastic={scholastic}
        />
      </div>
      <SearchWrapper
        key={`${formid}_${scholastic}`}
        formid={formid}
        renderResultsComponent={renderResultComponent}
        alternativeApi={apiUrls.templateless_assessment_evidence_search}
        destroyOnUnmount
        renderNoResultComponent={renderNoResultComponent}
        showSearchButton={false}
        hiddenFields={{
          scholastic,
        }}
      />
    </div>
  );
};

export default TemisTemplatelessEvidence;
