import lodashGet from 'lodash.get';

export default ({ store }) => {
  //==============UTILITY FUNCTIONS FOR DEBUGGING REDUX STORE ============================

  /**
     * to log the current redux state
     * if you only want to log a small part of state, pass 'path' param
     *
     * E.g:
     * state = {
     *   a: 'value of a',
     *   b: 'value of b'
     * }
     *
     * logState() => {a: 'value of a', b: 'value of b'}
     * logState('a') => 'value of a'

     * @param path
     */
  window.logState = (path) => {
    const state = store.getState();
    if (path) {
      console.log(lodashGet(state, path));
    } else {
      console.log(state);
    }
  };
  //=====================================================================================

  //==============UTILITY FUNCTIONS FOR DEBUGGING TRANSLATION ============================
  window.setDebugTranslation = () => {
    sessionStorage.debugTranslation = 1;
  };

  window.unsetDebugTranslation = () => {
    delete sessionStorage.debugTranslation;
  };

  window.setSaveTranslationKey = () => {
    sessionStorage.saveTranslationKey = 1;
    sessionStorage.usedTranslationKeys = '[]';
  };

  window.unsetSaveTranslationKey = () => {
    delete sessionStorage.saveTranslationKey;
    delete sessionStorage.usedTranslationKeys;
  };
  //======================================================================================
};
