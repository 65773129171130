import React, { useState, useCallback } from 'react';
import CommonAntdTable from 'components/common/antd/table';
import RaisedButton from 'components/common/mui/RaisedButton';
import { t1 } from 'translate';
import NewEvidence from './NewEvidence';
import { connect } from 'react-redux';
import actions from 'actions/node/creators';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import lodashGet from 'lodash.get';
import './stylesheet.scss';

const getColumns = (searchFormId) => {
  return [
    {
      title: 'Mã',
      width: 150,
      className: 'text-center',
      key: 'code',
      dataIndex: 'non_unique_code',
    },
    {
      title: t1('name'),
      className: 'text-center',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Mô tả',
      width: 250,
      className: 'text-center',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Nội dung',
      className: 'text-center',
      width: 250,
      key: 'content',
      render: (row) => {
        return {
          children:
            row.content &&
            Array.isArray(row.content.attachments) &&
            row.content.attachments.map((item) => {
              const name = `${lodashGet(item, 'name')}.${lodashGet(
                item,
                'ext',
              )}`;
              return (
                <div className="evidence-content">
                  <a
                    className="evidence-content__info"
                    href={item.link}
                    title={name}
                    target="_blank"
                    download
                  >
                    {name}
                    <i className="evidence-content__icon-download mi mi-file-download m-l-10 m-r-10" />
                  </a>
                </div>
              );
            }),
          props: {
            rowSpan: lodashGet(row, ['rowSpans', 'id']),
          },
        };
      },
    },
  ];
};
const TableForm = ({
  assessmentEvidence = [],
  tieuChiIid,
  tieuChuanIid,
  assessmentRubricIid,
  onChangeEvidence,
  refetchEvidence,
  currentEvidenceIids,
  dispatch,
  dialogKey,
  searchFormId,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState(currentEvidenceIids);
  const [assessmentTableData, setAssessmentTableData] = useState(
    assessmentEvidence,
  );

  const iidInCurrentPage = assessmentEvidence.map(({ iid = '' }) => iid);
  const handleSelectEvidence = useCallback(
    () => {
      onChangeEvidence(selectedRowKeys);
      dispatch(actions.handleOpenDialog({ openDialog: false }, dialogKey));
    },
    [selectedRowKeys, onChangeEvidence, dispatch, dialogKey],
  );

  const onNewEvidence = (evidence) => {
    if (!evidence || !evidence.iid) {
      return;
    }

    setSelectedRowKeys([evidence.iid, ...selectedRowKeys]);
    setAssessmentTableData([evidence, ...assessmentTableData]);

    if (typeof refetchEvidence === 'function') {
      refetchEvidence();
    }
  };

  const defaultPageSize = 10;
  return (
    <>
      <NewEvidence
        tieuChiIid={tieuChiIid}
        tieuChuanIid={tieuChuanIid}
        assessmentRubricIid={assessmentRubricIid}
        onNewEvidence={onNewEvidence}
      />

      {!!assessmentTableData.length ? (
        <>
          <CommonAntdTable
            dataSource={assessmentTableData}
            columns={getColumns()}
            pagination={
              !Array.isArray(assessmentTableData) ||
              assessmentTableData.length <= defaultPageSize
                ? false
                : {
                    pageSizeOptions: [10],
                    defaultPageSize,
                    showSizeChanger: true,
                  }
            }
            bordered
            className="learn-default wrapper-table-evidence"
            rowKey="iid"
            rowSelection={{
              selectedRowKeys,
              hideDefaultSelections: true,
              onChange: setSelectedRowKeys,
              selections: [
                {
                  key: 'select_current_page',
                  text: t1('select_data_in_current_page'),
                  onSelect: () => {
                    const currentSelectedRowKeys = Array.isArray(
                      selectedRowKeys,
                    )
                      ? selectedRowKeys
                      : [];
                    setSelectedRowKeys(
                      currentSelectedRowKeys.concat(
                        iidInCurrentPage
                          .map(
                            (iid) =>
                              !currentSelectedRowKeys.includes(iid) && iid,
                          )
                          .filter(Boolean),
                      ),
                    );
                  },
                },
                {
                  key: 'invert_current_page',
                  text: t1('invert_data_in_current_page'),
                  onSelect: () => {
                    setSelectedRowKeys(
                      (Array.isArray(iidInCurrentPage)
                        ? iidInCurrentPage
                        : []
                      ).filter((iid) => !selectedRowKeys.includes(iid)),
                    );
                  },
                },
                Array.isArray(selectedRowKeys) &&
                  !!selectedRowKeys.length &&
                  !selectedRowKeys.every((id) =>
                    iidInCurrentPage.includes(id),
                  ) && {
                    key: 'remove_all',
                    text: t1('remove_all_data_selected'),
                    onSelect: () => {
                      setSelectedRowKeys([]);
                    },
                  },
              ].filter(Boolean),
            }}
            scroll={{
              x: false,
            }}
          />
          <RaisedButton
            className="m-t-10 btn btn-secondary"
            label={`Chọn ${selectedRowKeys.length} tài liệu minh chứng`}
            onClick={handleSelectEvidence}
          />
        </>
      ) : (
        <SimpleNoResult text="Không có minh chứng" />
      )}
    </>
  );
};

export default connect()(TableForm);
