import React, { Component } from 'react';
import AssessMyPeers from '../../peers-assessment';
import withUserInfo from 'common/hoc/withUserInfo';
import { isToTruong } from 'components/admin/user/utils';
import CoworkerAssessment from '../../coworker-assessment';

const PeerList = ({ finalAggregateAssessment, userInfo }) => {
  return (
    <div className="peer-list">
      {isToTruong(userInfo) && !finalAggregateAssessment && (
        <div>
          <CoworkerAssessment />
          <hr />
        </div>
      )}
      <AssessMyPeers finalAggregateAssessment={finalAggregateAssessment} />
    </div>
  );
};

export default withUserInfo(PeerList);
