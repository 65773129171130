import React from 'react';
import AntdTable from 'antd/lib/table';
import './stylesheet.scss';
import { isSmallScreen } from 'common';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';

const getClassNameByType = (item) => {
  let className = '';
  switch (item.type) {
    case TYPE_OF_TABLE_DATA.TEXT:
      return (className = 'text-left');
    case TYPE_OF_TABLE_DATA.NUMBER:
      return (className = 'text-right');
    case TYPE_OF_TABLE_DATA.DATE:
    case TYPE_OF_TABLE_DATA.ACTION:
    case TYPE_OF_TABLE_DATA.TITLE:
      return (className = 'text-center');
    default:
      return (className = 'text-left');
  }
};

const standardColumns = (columns = []) => {
  const columnsStandard = [];
  if (!columns || !columns.length) {
    return [];
  }

  columns.forEach((column) => {
    columnsStandard.push({
      ...column,
      children:
        column.children &&
        column.children.length &&
        standardColumns(column.children),
      className: `${
        column.className ? column.className : ''
      } ${getClassNameByType(column)}`,
    });
  });

  return columnsStandard;
};

const CommonAntdTable = ({ disabled, className, columns, ...props }) => {
  const cssClass = 'common-antd-table';

  const { stt, searchValues, sttProps = {}, locale } = props;
  let newColumns = standardColumns(columns);

  if (stt && columns && searchValues) {
    const page = lodashGet(searchValues, 'page') || 0;
    const itemsPerPage = lodashGet(searchValues, 'items_per_page') || 10;

    const sttCol = {
      title: '#',
      key: 'stt',
      width: 20,
      className: 'text-center stt',
      ...sttProps,
      render: (text, item, idx) => (
        <div>{(page - 1) * itemsPerPage + idx + 1}</div>
      ),
    };

    newColumns = [sttCol, ...newColumns];
  }

  return (
    <AntdTable
      className={`${className || ''} ${cssClass} ${
        disabled ? `${cssClass}--disabled` : ''
      }`}
      scroll={{
        x: isSmallScreen,
      }}
      columns={newColumns || columns}
      locale={locale || { emptyText: t1('no_data') }}
      {...props}
    />
  );
};

export default CommonAntdTable;
