import React from 'react';
import './style.scss';
const JobStandardResult = (id, name) => {
  return (
    <div className="job-standard-result text-center text-transform">
      <span className={`result-${id}`}> {name} </span>
    </div>
  );
};

export default JobStandardResult;
