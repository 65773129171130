import React from 'react';
import {
  isSo,
  isPhong,
  isBoOrAdminSP,
  isAdminTruongOrHieuTruongOrPho,
  isAmTinh,
} from 'components/admin/user/utils';
import withTemisConfig from 'common/hoc/withTemisConfig';
import get from 'lodash.get';
import TCNNPhong from './TCNNPhong';
import TCNNSo from './TCNNSo';
import TCNNBo from './TCNNBo';
import TCNNTruong from './TCNNTruong';

import TabList from '../../components/tabs';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import { TCNN_TYPE } from '../../constant';
import { isHVQLStaff } from 'components/admin/user/utils';
import { getReportATitle, reportATitle, reportBCThongKeTitle } from '../utils';

const TemisReport = ({ userRoot, scholastic, ...props }) => {
  const isResultByRubric = get(props, 'route.isResultByRubric', false);
  const showSearchForm = get(props, 'route.showSearchForm', true);
  const isResultForCBQL = get(props, 'route.isResultForCBQL', false);
  const isResultForMN = get(props, 'route.isResultForMN', false);

  const reportTitle = getReportATitle(
    isResultForMN,
    isResultByRubric,
    isResultForCBQL,
  );

  const propsRender = {
    scholastic,
    isResultByRubric,
    isResultForMN,
    reportTitle,
    showSearchForm,
    isResultForCBQL,
  };

  const tabs = [
    isAdminTruongOrHieuTruongOrPho(userRoot) && {
      name: TCNN_TYPE.GV,
      label: 'TTNN trường',
      component: <TCNNTruong {...propsRender} />,
    },
    isPhong(userRoot) && {
      name: 'tcnn_p',
      label: 'TTNN Phòng',
      component: <TCNNPhong {...propsRender} />,
    },
    (isSo(userRoot) || isAmTinh(userRoot)) && {
      name: 'tcnn_so',
      label: 'TTNN Sở',
      component: <TCNNSo {...propsRender} />,
    },
    isBoOrAdminSP(userRoot) && {
      name: 'tcnn_bo',
      label: 'TTNN Bộ',
      component: <TCNNBo {...propsRender} />,
    },
  ].filter(Boolean);

  return <TabList tabList={tabs} key={scholastic} />;
};

export default withScholastic(withTemisConfig(TemisReport));
