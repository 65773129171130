import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import fetchData from 'components/common/fetchData';
import { t1 } from 'translate';
import NodeNew from 'components/admin/node/new/index';
import schema, { finalProcessBeforeSubmit } from './schema';
import endPoints from '../endpoints';
import {
  formatDataSourceFromDataServerToRenderTableAssess,
  getTypeOfTCNN,
  noDataToAssessment,
  isValidTaskAnswer,
  isRequiredMC,
} from '../util';
import Skeleton from 'antd/lib/skeleton';
import withTemisConfig from 'common/hoc/withTemisConfig';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import StatusAssessment from 'components/temis/assessment/do-assessment/statusAssessment';
import sagaActions from 'actions/node/saga-creators';
import store from 'store';
import { ASSESSMENT_STATUS } from 'components/temis/constant';
import { isApprovedAssessment } from 'components/temis/utils';
import nodeActions from 'actions/node/creators';
import {
  isHieuPho,
  isMamNon,
  isSoOrPhong,
  isSubLeaderPositionHieuPho,
  isSubLeaderPositionHieuTruong,
} from 'components/admin/user/utils';
import Warning from 'components/common/Warning';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isDirty, initialize } from 'redux-form';
import ConfirmToLeave from './confirm-to-leave';
import Modal from 'antd/lib/modal';
import ModalWarningRequiredMC from 'components/temis/assessment/do-assessment/ModalWarningRequiredMC';

const formDefaultId = 'do-evaluation-criteria';

const Form = ({
  hiddenFields = {},
  node = {},
  searchFormId,
  readOnly,
  peerAssess = false,
  requestSuccessful = () => {},
  dialogKey,
  tcnnType,
  assessment_name,
  dataSource,
  score_scale,
  mySelfAssessment,
  peersAssessment,
  loadingStatus,
  userRoot,
  formId = formDefaultId,
  ignoreUserIidParamToEvidenceAPI,
  rubrics,
  hideBecomeMaster,
  useTeamLeaderResult,
  isPrincipalNotHasSelfAssessment,
  assessorIid,
  dispatch,
  dirty,
  userForAssessment,
  finalAggregateAssessment,
}) => {
  const [showConfirm, setShowConfirm] = useState(true);
  useEffect(
    () => {
      dispatch(initialize(formId, node));
    },
    [node, dispatch, formId],
  );

  if (loadingStatus !== 'finished') {
    return <Skeleton active />;
  }

  if (isPrincipalNotHasSelfAssessment) {
    return <SimpleNoResult text="Chưa có bản đánh giá của hiệu trưởng" />;
  }

  if (noDataToAssessment(dataSource, score_scale)) {
    return <SimpleNoResult text={t1('no_content_to_assessment')} />;
  }

  const isUserSelfAssessment =
    Number(userRoot.iid) === Number(hiddenFields.target.iid);

  const doNotFillAllTasks =
    isHieuPho(userForAssessment) &&
    !isSubLeaderPositionHieuPho(userForAssessment) &&
    !isSubLeaderPositionHieuTruong(userForAssessment);

  const hasSentAssessment = get(node, 'status') === ASSESSMENT_STATUS.APPROVED;
  const hasAssessment = get(node, 'updated_ts') || get(node, 'ts');

  const handleSubmitForm = (formValues = {}, status, saveDraft) => {
    const tasks = get(formValues, 'task', []);

    if (
      !isValidTaskAnswer(
        tasks,
        peerAssess,
        doNotFillAllTasks,
        hasAssessment && hasSentAssessment,
      ) &&
      !saveDraft
    ) {
      errorNotification();
      return;
    }

    let fullData = Object.assign({}, formValues, hiddenFields);
    const processedData = finalProcessBeforeSubmit(fullData, doNotFillAllTasks);

    const payload = {
      apiUrl: endPoints.doAssessment,
      data: {
        ...processedData,
        ...hiddenFields,
        status: status,
      },
      requestSuccessful: requestSuccessful,
    };

    store.dispatch(sagaActions.upsertNodeRequest(payload));

    // don't show confirmation when saved data
    setShowConfirm(false);
  };

  const errorNotification = () => {
    store.dispatch(
      nodeActions.snackbar(
        'warning',
        'Các tiêu chí đánh giá không được để trống',
      ),
    );
  };

  const status = get(node, 'status');

  return (
    <>
      <ConfirmToLeave
        shouldShowConfirm={dirty && showConfirm}
        confirmMessage={t1(
          'if_you_leave_your_data_can_be_lost_are_you_sure_you_want_to_leave',
        )}
        cancelText="Quay lại hoàn thành đánh giá"
        okText="Bỏ các thay đổi đánh giá"
      />

      <ModalWarningRequiredMC
        show={!hasSentAssessment && !peerAssess && isRequiredMC}
      />

      {useTeamLeaderResult && !node.id && (
        <div className="text-center m-b-20">
          <Warning>{t1('team_leader_have_not_assessed_yet')}</Warning>
        </div>
      )}

      <NodeNew
        tcnnType={tcnnType}
        assessment_name={assessment_name}
        dataSource={dataSource}
        score_scale={score_scale}
        mySelfAssessment={mySelfAssessment}
        peersAssessment={peersAssessment}
        peerAssess={peerAssess}
        rubrics={rubrics}
        schema={schema(node)}
        node={{ ...node, status: ASSESSMENT_STATUS.APPROVED }}
        readOnly={readOnly}
        searchFormId={searchFormId}
        hiddenFields={hiddenFields}
        alternativeApi={endPoints.doAssessment}
        formid={formId}
        requestSuccessful={requestSuccessful}
        userForAssessment={userForAssessment}
        submitButton={(formValues) => {
          if (readOnly) {
            return null;
          }

          const tasks = get(formValues, 'task', []);
          const isValidTask = isValidTaskAnswer(
            tasks,
            peerAssess,
            doNotFillAllTasks,
            hasAssessment && hasSentAssessment,
          );

          return (
            <div className="footer-assessment m-t-30 m-b-30">
              {!!status && (
                <StatusAssessment
                  className="m-b-30"
                  isApprovedStatus={isApprovedAssessment(status)}
                />
              )}

              <div className="buttons-assessment">
                <button
                  name="saveDraft"
                  type="button"
                  className={`btn btn-secondary btn-outline m-r-20 ${
                    isApprovedAssessment(status) ? 'button-disabled' : ''
                  }`}
                  onClick={() => {
                    if (isApprovedAssessment(status)) {
                      Modal.warning({
                        centered: true,
                        content: (
                          <span>
                            Bạn đã lưu và gửi đi bản tự đánh giá, nên bạn không
                            thể Lưu và chưa gửi đi được
                          </span>
                        ),
                      });
                    } else {
                      handleSubmitForm(
                        formValues,
                        ASSESSMENT_STATUS.QUEUED,
                        true,
                      );
                    }
                  }}
                >
                  {isSoOrPhong(userRoot)
                    ? 'Duyệt và chưa gửi đi'
                    : 'Lưu và chưa gửi đi'}
                </button>

                <button
                  name="send"
                  type="button"
                  className="btn btn-secondary"
                  disabled={!isValidTask}
                  onClick={() =>
                    handleSubmitForm(formValues, ASSESSMENT_STATUS.APPROVED)
                  }
                >
                  {isSoOrPhong(userRoot) ? 'Duyệt và gửi đi' : 'Lưu và gửi đi'}
                </button>
              </div>
            </div>
          );
        }}
        closeModal={!!dialogKey}
        dialogKey={dialogKey}
        userRoot={userRoot}
        isUserSelfAssessment={isUserSelfAssessment}
        ignoreUserIidParamToEvidenceAPI={ignoreUserIidParamToEvidenceAPI}
        hideBecomeMaster={hideBecomeMaster}
        useTeamLeaderResult={useTeamLeaderResult}
        hasAssessment={hasAssessment && hasSentAssessment}
        assessorIid={assessorIid}
        finalAggregateAssessment={finalAggregateAssessment}
        doNotFillAllTasks={doNotFillAllTasks}
      />
    </>
  );
};

const fetchAssessmentData = fetchData((props) => {
  const scholastic = get(props, 'scholastic');
  return {
    baseUrl: endPoints.dataToAssessment,
    params: {
      userIid: get(props, 'hiddenFields.target.iid'),
      scholastic,
    },
    propKey: 'rubric',
    formatDataResult: ({
      rubrics,
      score_scale,
      assessment_name,
      rubric_iid,
    } = {}) => {
      const dataSource = formatDataSourceFromDataServerToRenderTableAssess(
        rubrics,
        rubric_iid,
      );
      const hiddenFields = props.hiddenFields || {};

      return {
        rubrics,
        tcnnType: getTypeOfTCNN(rubric_iid),
        assessment_name,
        dataSource,
        score_scale,
        rubricIid: rubric_iid,
        hiddenFields: { ...hiddenFields, rubric_iid },
      };
    },
    fetchCondition: true,
    refetchCondition: (prevProps) =>
      scholastic !== get(prevProps, 'scholastic'),
  };
});

const mapStateToProps = (state, { formId = formDefaultId }) => ({
  dirty: isDirty(formId)(state),
});

export default compose(
  connect(mapStateToProps),
  withTemisConfig,
  fetchAssessmentData,
)(Form);
