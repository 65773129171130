import React from 'react';
import BaseAction from './BaseAction';

const ResetAction = (props) => (
  <BaseAction
    formid="review-reset-request"
    labelButton="Huỷ"
    classButton="btn-tertiary"
    {...props}
  />
);

export default ResetAction;
