import React, { useEffect } from 'react';
import NodeNew from 'components/admin/node/new';
import fetchData from 'components/common/fetchData';
import schema from './schema';
import { compose } from 'redux';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import endpoints from 'components/temis/endpoints';
import ConfirmToLeave from 'components/temis/assessment/do-assessment/confirm-to-leave';
import { connect } from 'react-redux';
import { isDirty, initialize } from 'redux-form';
import { t1 } from 'translate/index';

const formDefaultId = 'form-bm3pl2-cv4530';

const FileUpload = ({
  bm3pl2Cv,
  handleRefetch,
  isOutOfDeadlineAssessment,
  scholastic,
  dispatch,
  formId = formDefaultId,
  dirty,
}) => {
  useEffect(
    () => {
      dispatch(initialize(formId, bm3pl2Cv));
    },
    [dispatch, formId, bm3pl2Cv],
  );

  return (
    <>
      <ConfirmToLeave
        shouldShowConfirm={dirty}
        confirmMessage="Bạn chưa lưu file. Nếu bạn di chuyển sang trang khác sẽ mất hết dữ liệu đã nhập"
        cancelText="Quay lại hoàn thành lưu file"
        okText="Bỏ lưu file"
      />
      <NodeNew
        schema={schema}
        closeModal
        node={bm3pl2Cv}
        formid="form-bm3pl2-cv4530"
        alternativeApi={endpoints.save_bm3_pl2}
        requestSuccessful={() => handleRefetch()}
        readOnly={isOutOfDeadlineAssessment}
        hiddenFields={{
          scholastic,
          type: 'bm3_pl2_cv4530',
        }}
        shouldDisabledSaveButton={isOutOfDeadlineAssessment}
      />
    </>
  );
};

const fetchDataBM3PL2 = fetchData((props) => {
  return {
    baseUrl: endpoints.get_bm3_pl2,
    fetchCondition: true,
    params: {
      scholastic: props.scholastic,
      type: 'bm3_pl2_cv4530',
    },
    keyState: 'bm3pl2Cv',
    propKey: 'bm3pl2Cv',
    refetchCondition: (prevProps) => props.scholastic !== prevProps.scholastic,
  };
});

const mapStateToProps = (state, { formId = formDefaultId }) => ({
  dirty: isDirty(formId)(state),
});
export default compose(
  connect(mapStateToProps),
  withScholastic,
  fetchDataBM3PL2,
  withOutOfDeadlineAssessment,
)(FileUpload);
