import React, { useEffect } from 'react';
import Modal from 'antd/lib/modal';
import Warning from 'components/common/Warning';

const ModalWarningRequiredMC = ({ show }) => {
  useEffect(
    () => {
      if (show) {
        Modal.warning({
          title: 'Thông báo',
          content: (
            <span>
              Theo yêu cầu của BQL ETEP - Bộ GD&ĐT, tất cả GV/CBQL đánh giá từ
              ngày <Warning inline>18/01/2021</Warning> sẽ{' '}
              <Warning inline>bắt buộc nhập minh chứng</Warning> tương ứng với
              tiêu chí ở mức <b>Đạt</b> trở lên
            </span>
          ),
        });
      }
    },
    [show],
  );

  return null;
};

export default ModalWarningRequiredMC;
