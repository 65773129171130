import get from 'lodash.get';

const subTypes = {
  TAPHUAN_SUBTYPE_BO_GD: 1,
  TAPHUAN_SUBTYPE_SO_GD: 2,
  TAPHUAN_SUBTYPE_PHONG_GD: 12,
  TAPHUAN_SUBTYPE_TIEUHOC: 5,
  TAPHUAN_SUBTYPE_THCS: 6,
  TAPHUAN_SUBTYPE_THPT: 7,
  TAPHUAN_SUBTYPE_LIENCAP: 8,
  TAPHUAN_SUBTYPE_LTTU: 9,
  TAPHUAN_SUBTYPE_MAM_NON: 13,
  TAPHUAN_SUBTYPE_GIAODUC_THUONGXUYEN: 14,
  TAPHUAN_SUBTYPE_TUTHUC_LIENCAP: 15,

  ORG_TYPE_RGEP: 10,
  ORG_TYPE_ETEP: 11,
  ORG_TYPE_RGEP_AND_ETEP: 4,
  ORG_TYPE_VU_GIAO_DUC: 3,
  TAPHUAN_SUBTYPE_TO_CHUYEN_MOM: 21,
};

export default subTypes;

export const isLeafOrg = (subType, orgTypesReached) => {
  return [
    subTypes.TAPHUAN_SUBTYPE_TIEUHOC,
    subTypes.TAPHUAN_SUBTYPE_THCS,
    subTypes.TAPHUAN_SUBTYPE_THPT,
    subTypes.TAPHUAN_SUBTYPE_LIENCAP,
    subTypes.TAPHUAN_SUBTYPE_MAM_NON,
    subTypes.TAPHUAN_SUBTYPE_GIAODUC_THUONGXUYEN,
    subTypes.TAPHUAN_SUBTYPE_TUTHUC_LIENCAP,
    ...(orgTypesReached || []),
  ].includes(subType);
};

export const isSo = (organizations) =>
  get(organizations, '[0].sub_type') === subTypes.TAPHUAN_SUBTYPE_SO_GD;
export const isPhong = (organizations) =>
  get(organizations, '[0].sub_type') === subTypes.TAPHUAN_SUBTYPE_PHONG_GD;

export const isSoPhongTruong = (subType) => {
  const isTruong = isLeafOrg(subType);

  return (
    isTruong ||
    [
      subTypes.TAPHUAN_SUBTYPE_SO_GD,
      subTypes.TAPHUAN_SUBTYPE_PHONG_GD,
    ].includes(subType)
  );
};
