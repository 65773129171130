import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import nodeActions from 'actions/node/creators';
import { Link } from 'react-router-dom';
import get from 'lodash.get';
import withTemisConfig from 'common/hoc/withTemisConfig';
import Loading from 'components/common/loading';
import { editTcnn } from '../../routes';
import fetchData from 'components/common/fetchData';
import withUserInfo from 'common/hoc/withUserInfo';
import Widget from 'components/common/Widget';
import MyResultsAssess from './MyResultsAssess';
import {
  formatDataSourceFromDataServerToRenderTableAssess,
  radarChartResultsAssess,
  getTypeOfTCNN,
  isRequiredMC,
} from '../util';
import endPoints from '../endpoints';
import InfoSelfAssessment from './info-sellf-assessment';
import NoAssessmentResult from '../../components/no-assessment-result';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import ExportData from 'components/common/export-data';
import {
  ASSESSMENT_STATUS,
  EXPORT_FILENAME,
  TCNN_TYPE,
} from 'components/temis/constant';
import {
  isHieuPho,
  isMamNon,
  isSubLeaderPositionHieuPho,
  isSubLeaderPositionHieuTruong,
} from 'components/admin/user/utils';

const styles = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
};
const selfAssessmentResultKeySate = 'myAssessment';

const elementExportBM1PL2 = (scholastic, exportFileName) => (
  <ExportData
    apiExport="/temis/export/export-my-assessment"
    buttonText="Xuất excel BM1/PL2"
    formId="search-score-by-rubric"
    params={{
      scholastic,
    }}
    fileName={exportFileName}
  />
);

const TemisAssessmentIndex = ({
  node = {},
  isHieuTruong,
  temisConfig,
  userInfo,
  loadingStatus,
  assessment_name,
  dataSource,
  score_scale,
  scholastic,
  isOutOfDeadlineAssessment,
  clearDataApiRequestResultsByKeyState,
}) => {
  useEffect(
    () => {
      clearDataApiRequestResultsByKeyState(selfAssessmentResultKeySate);
    },
    [clearDataApiRequestResultsByKeyState],
  );

  if (loadingStatus !== 'finished') {
    return <Loading circularLoadingIcon />;
  }

  const userIid = get(userInfo, 'iid');
  const updatedTs = get(node, 'updated_ts') || get(node, 'ts');
  const status = get(node, 'status');
  const task = get(node, 'task');
  const scoreBasedOnSelectedTask =
    isHieuPho(userInfo) &&
    !isSubLeaderPositionHieuPho(userInfo) &&
    !isSubLeaderPositionHieuTruong(userInfo);

  let isLackOfAnswers =
    status === ASSESSMENT_STATUS.QUEUED && !!task.find((obj) => !obj.answer);

  if (scoreBasedOnSelectedTask) {
    isLackOfAnswers =
      status === ASSESSMENT_STATUS.QUEUED &&
      !task.find((obj) => obj.answer !== undefined);
  }

  const isLackOfEvidence =
    isRequiredMC &&
    status === ASSESSMENT_STATUS.QUEUED &&
    !!task.find(
      (obj) =>
        Number(obj.answer) > 0 &&
        (!obj.evidence_iids ||
          (obj.evidence_iids && obj.evidence_iids.length < 1)),
    );

  const isDisabled = isLackOfAnswers || isLackOfEvidence;

  const isAnswerInvalid = () => {
    const selfTasks = (node && node.task) || [];
    if (scoreBasedOnSelectedTask) {
      return !selfTasks.find((task) => task.answer !== undefined);
    }

    return selfTasks.find((task) => task.answer === undefined);
  };
  const exportFileName = isHieuTruong
    ? EXPORT_FILENAME.principal.BM1_PL2
    : EXPORT_FILENAME.teacher.BM1_PL2;

  return (
    <div className="container-fluid">
      <h1 className="text-center temis-main-title">
        {isHieuTruong
          ? 'PHIẾU HIỆU TRƯỞNG/PHÓ HIỆU TRƯỞNG TỰ ĐÁNH GIÁ '
          : 'PHIẾU ĐÁNH GIÁ GIÁO VIÊN THEO CHUẨN NNGVPT'}
      </h1>

      {!!updatedTs ? (
        <>
          <div className="row m-t-30 d-flex">
            <div
              className={
                updatedTs
                  ? 'col-md-5 col-sm-offset-1 text-center'
                  : 'col-md-12  text-center'
              }
            >
              <Widget className="box-assessment" style={styles}>
                <InfoSelfAssessment
                  node={node}
                  status={status}
                  isHieuTruong={isHieuTruong}
                  temisConfig={temisConfig}
                  userIid={userIid}
                  updatedTs={updatedTs}
                  viewOnly={isOutOfDeadlineAssessment || isAnswerInvalid()}
                  value={get(node, 'task')}
                  rubricIid={get(node, 'rubric_iid')}
                  score_scale={score_scale}
                  tcnnType={isHieuTruong ? TCNN_TYPE.HT : TCNN_TYPE.GV}
                  scholastic={scholastic}
                  isDisabled={isDisabled}
                  shouldBeShowMessageLackEvidence={isLackOfEvidence}
                  scoreBasedOnSelectedTask={scoreBasedOnSelectedTask}
                />
              </Widget>
            </div>
            <div className="col-md-5 text-center">
              <Widget className="box-chart" style={{ height: '100%' }}>
                {radarChartResultsAssess(
                  dataSource,
                  get(node, 'task'),
                  score_scale,
                  isHieuTruong ? TCNN_TYPE.HT : TCNN_TYPE.GV,
                  scoreBasedOnSelectedTask,
                  get(node, 'rubric_iid'),
                )}
              </Widget>
            </div>
          </div>
          <div className="m-t-20 mui-paper-no-box-shadow mui-paper-no-padding">
            <div className="table-title assessment-title">
              <span>{'Kết quả chi tiết'}</span>
              <div className="assessment-title__export">
                {elementExportBM1PL2(scholastic, exportFileName)}
              </div>
            </div>

            <MyResultsAssess
              assessment_name={assessment_name}
              dataSource={dataSource}
              score_scale={score_scale}
              selfAssess={node}
              scholastic={scholastic}
            />
          </div>
        </>
      ) : (
        <>
          <NoAssessmentResult />

          <div
            className={`d-flex justify-content-center ${
              isOutOfDeadlineAssessment ? 'disabled' : ''
            }`}
          >
            <Link to={editTcnn}>
              <button
                type="button"
                className=" btn btn-secondary"
                disabled={isOutOfDeadlineAssessment}
              >
                {'Đánh giá'}
              </button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

const fetchSelfAssessment = (props) => {
  const userIid = get(props, 'userInfo.iid');
  const scholastic = get(props, 'scholastic');

  return {
    baseUrl: endPoints.assessmentResult,
    fetchCondition: userIid && scholastic,
    refetchCondition: (prevProps) =>
      (userIid && !get(prevProps, 'userInfo.iid')) ||
      scholastic !== get(prevProps, 'scholastic'),
    params: {
      userIid: userIid,
      scholastic,
    },
    method: 'get',
    keyState: selfAssessmentResultKeySate,
    propKey: 'node',
  };
};

const fetchRubricDoAssessment = (props) => {
  const scholastic = get(props, 'scholastic');
  return {
    baseUrl: endPoints.dataToAssessment,
    params: {
      scholastic,
    },
    propKey: 'rubric',
    formatDataResult: ({
      rubric_iid,
      rubrics,
      score_scale,
      assessment_name,
    } = {}) => {
      const dataSource = formatDataSourceFromDataServerToRenderTableAssess(
        rubrics,
      );
      return {
        tcnnType: getTypeOfTCNN(rubric_iid),
        assessment_name,
        dataSource,
        score_scale,
      };
    },
    fetchCondition: !!scholastic,
    refetchCondition: (prevProps) =>
      scholastic !== get(prevProps, 'scholastic'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearDataApiRequestResultsByKeyState: (keyState) => {
    dispatch(nodeActions.clearDataApiRequestResultsByKeyState(keyState));
  },
});
export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withOutOfDeadlineAssessment,
  withScholastic,
  withUserInfo,
  withTemisConfig,
  fetchData(fetchSelfAssessment),
  fetchData(fetchRubricDoAssessment),
)(TemisAssessmentIndex);
