import React from 'react';
import get from 'lodash.get';
import withTemisConfig from 'common/hoc/withTemisConfig';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import DisplayHtml from 'components/common/html';
import { t1 } from 'translate';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import { isEnableTemis } from '../../../utils';
import { getNumberFormat } from '../../utils';
import { convertNumberToRomanNumber } from 'common/utils/Number';
import { userGradeToText } from 'configs/constants/user';
import { getSchoolYearByTrainingPlanCategory } from 'components/admin/training-plan/common';

const BDTX_TYPE = {
  totalMembers: 'totalMembers',
  onlineMembers: 'onlineMembers',
  finishedSurvey: 'finishedSurvey',
  finishedModule: 'finishedModule',
  numberAndRateResponses: 'numberAndRateResponses',
};

const gradeType = ['primary', 'secondary', 'high', 'unsorted'];

const getTitleColumn = (resultType, isResultForCBQL, trainingPlanCategory) => {
  let title = '';
  switch (resultType) {
    case BDTX_TYPE.totalMembers:
      title = isResultForCBQL ? 'Tổng số CBQL</i>' : 'Tổng số GV</i>';
      break;
    case BDTX_TYPE.finishedSurvey:
      title = isResultForCBQL
        ? 'CBQL đã trả lời phiếu khảo sát</i>'
        : 'Giáo viên đã trả lời phiếu khảo sát</i>';
      break;
    case BDTX_TYPE.finishedModule:
      const schoolYear = getSchoolYearByTrainingPlanCategory(
        trainingPlanCategory,
      );
      let mdbd = 'các MĐBD';
      switch (schoolYear) {
        case 2020: {
          mdbd = 'MĐBD 1,2';
          break;
        }
        case 2021: {
          mdbd = 'MĐBD 3,4';
          break;
        }
        default: {
          break;
        }
      }

      title = isResultForCBQL
        ? `Tổng số CBQL CSGDPT hoàn thành ${mdbd} năm ${schoolYear}`
        : `Tổng số GVPT CSGDPT hoàn thành ${mdbd} năm ${schoolYear}` +
          ' <br><i>(% = SL/Tổng)</i>';

      break;
    case BDTX_TYPE.onlineMembers:
      title = isResultForCBQL
        ? 'Tổng số CBQL tham gia học tập trực tuyến'
        : 'Tổng số GVPT tham gia học tập trực tuyến';
      break;
    case BDTX_TYPE.numberAndRateResponses:
      title =
        'Số lượng và tỷ lệ % HV hài lòng <br><i>(có điểm TB ở 4/5 mục từ 3.0 trở lên (không có mục nào điểm TB dưới 2.5))</i>';
      break;
    default:
      title = isResultForCBQL
        ? 'CBQL đã trả lời phiếu khảo sát'
        : 'Giáo viên đã trả lời phiếu khảo sát';
  }

  return title;
};

const renderColumnData = (
  detail,
  total,
  skipFormatNumber,
  reportType,
  props = {},
) => {
  let text = detail;

  if (reportType === 'percentage' && detail > 0 && total) {
    text = parseFloat(((text / total) * 100).toFixed(2));
  }

  return {
    children: total > 0 ? getNumberFormat(text, skipFormatNumber) : 0,
    props: {
      ...props,
      className: 'text-right',
      width: 90,
    },
  };
};

const getDemographics = (viewGrade = false) =>
  [
    {
      title: 'Tổng',
      key: 'all_users',
    },
    viewGrade && {
      title: 'Tiểu học',
      key: 'primary',
    },
    viewGrade && {
      title: 'THCS',
      key: 'secondary',
    },
    viewGrade && {
      title: 'THPT',
      key: 'high',
    },
    {
      title: 'Nữ',
      key: 'female',
    },
    {
      title: t1('ethnic_minority_short_text'),
      key: 'dtts',
    },
    {
      title: 'Nữ DTTS',
      key: 'female_dtts',
    },
    {
      title: 'Vùng khó khăn',
      key: 'poor_area',
    },
  ].filter(Boolean);

const getDataReportOfOrganizationByDemographic = (row, key) => {
  if (key === 'all_users') {
    return get(row, 'data_report', {});
  } else if (['primary', 'secondary', 'high'].includes(key)) {
    return get(row, `data_report.data_report_of_${key}`, {});
  }

  const children = get(row, 'data_report.children');

  if (!children || !Array.isArray(children) || !children.length) {
    return {};
  }

  return children.find(({ id }) => id === `${key}_in_there`) || {};
};

const getColumns = (
  isGradeView,
  questionGroups,
  userRoot,
  numberOfDataSource = 0,
  isResultForCBQL,
  skipFormatNumber,
  trainingPlanCategory,
) => {
  const countDemographic = getDemographics().length;
  const countQuestionGroups = Array.isArray(questionGroups)
    ? questionGroups.length
    : 0;
  const getColSpan = ({ hiddenData }, merge = false) => {
    if (!hiddenData) {
      return 1;
    } else if (!merge) {
      return 0;
    }

    if (isEnableTemis()) {
      return (
        1 +
        1 +
        countDemographic +
        countDemographic * 2 +
        countDemographic * 2 +
        countDemographic * 3 +
        countQuestionGroups * countDemographic * 3
      );
    }
    return (
      1 +
      countDemographic +
      countDemographic * 2 +
      (isGradeView ? 0 : 3) * 2 +
      countDemographic * 2 +
      (isGradeView ? 0 : 3) * 2 +
      countDemographic * 2 +
      (isGradeView ? 0 : 3) * 2 +
      countDemographic * 3 +
      countQuestionGroups * countDemographic * 3
    );
  };

  return [
    {
      title: t1('organization'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: ({ name, ...row }) => {
        return {
          children: name,
          props: {
            style: { width: 180 },
            colSpan: getColSpan(row, true),
          },
        };
      },
    },
    isEnableTemis() && {
      title: (
        <DisplayHtml
          content={getTitleColumn(
            BDTX_TYPE.totalMembers,
            isResultForCBQL,
            trainingPlanCategory,
          )}
        />
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      render: (row) => {
        const total_members = get(row, 'data_report.total_members');
        return {
          children: getNumberFormat(total_members, skipFormatNumber) || 0,
          props: {
            style: { width: 100 },
            className: 'text-right',
            colSpan: getColSpan(row),
          },
        };
      },
    },
    {
      title: (
        <DisplayHtml
          content={getTitleColumn(
            BDTX_TYPE.onlineMembers,
            isResultForCBQL,
            trainingPlanCategory,
          )}
        />
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: getDemographics().map(({ title, key }) => {
        return {
          title,
          className: 'text-center',
          render: (row) => {
            const dataReport = getDataReportOfOrganizationByDemographic(
              row,
              key,
            );

            const total_members_learned = get(
              dataReport,
              'total_members_learned',
            );
            return {
              children:
                getNumberFormat(total_members_learned, skipFormatNumber) || 0,
              props: {
                style: { width: 100 },
                className: 'text-right',
                colSpan: getColSpan(row),
              },
            };
          },
        };
      }),
    },
    !isEnableTemis() && {
      title: (
        <DisplayHtml
          content={getTitleColumn(
            BDTX_TYPE.finishedModule,
            isResultForCBQL,
            trainingPlanCategory,
          )}
        />
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: getDemographics(!isGradeView).map(({ title, key }) => {
        return {
          title,
          className: 'text-center',
          children: [
            {
              title: `SL`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              width: 100,
              render: (row) => {
                const dataReport = getDataReportOfOrganizationByDemographic(
                  row,
                  key,
                );
                const total_members_learned = get(
                  dataReport,
                  'total_members_learned',
                );
                const total_members_passed_learning = get(
                  dataReport,
                  'total_members_passed_learning',
                );

                return renderColumnData(
                  total_members_passed_learning,
                  total_members_learned,
                  skipFormatNumber,
                  '',
                  {
                    colSpan: getColSpan(row),
                  },
                );
              },
            },
            {
              title: `%`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              width: 100,
              render: (row) => {
                const dataReport = getDataReportOfOrganizationByDemographic(
                  row,
                  key,
                );
                const total_members_learned = get(
                  dataReport,
                  'total_members_learned',
                );
                const total_members_passed_learning = get(
                  dataReport,
                  'total_members_passed_learning',
                );

                return renderColumnData(
                  total_members_passed_learning,
                  total_members_learned,
                  skipFormatNumber,
                  'percentage',
                  {
                    colSpan: getColSpan(row),
                  },
                );
              },
            },
          ],
        };
      }),
    },
    {
      title: (
        <DisplayHtml
          content={getTitleColumn(
            BDTX_TYPE.finishedSurvey,
            isResultForCBQL,
            trainingPlanCategory,
          )}
        />
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: getDemographics(!isGradeView).map(({ title, key }) => {
        return {
          title,
          className: 'text-center',
          children: [
            {
              title: `SL`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              width: 100,
              render: (row) => {
                const dataReport = getDataReportOfOrganizationByDemographic(
                  row,
                  key,
                );
                const total_members_learned = get(
                  dataReport,
                  'total_members_learned',
                );
                const total_members_finished_survey = get(
                  dataReport,
                  'total_members_passed_learning',
                );

                return renderColumnData(
                  total_members_finished_survey,
                  total_members_learned,
                  skipFormatNumber,
                  null,
                  {
                    colSpan: getColSpan(row),
                  },
                );
              },
            },
            {
              title: `%`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              width: 100,
              render: (row) => {
                const dataReport = getDataReportOfOrganizationByDemographic(
                  row,
                  key,
                );
                const total_members_learned = get(
                  dataReport,
                  'total_members_learned',
                );
                const total_members_finished_survey = get(
                  dataReport,
                  'total_members_passed_learning',
                );

                return renderColumnData(
                  total_members_finished_survey,
                  total_members_learned,
                  skipFormatNumber,
                  'percentage',
                  {
                    colSpan: getColSpan(row),
                  },
                );
              },
            },
          ],
        };
      }),
    },
    {
      title: (
        <DisplayHtml
          content={getTitleColumn(
            BDTX_TYPE.numberAndRateResponses,
            isResultForCBQL,
            trainingPlanCategory,
          )}
        />
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: getDemographics(!isGradeView).map(({ title, key }) => {
        return {
          title,
          className: 'text-center',
          children: [
            {
              title: `SL`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              width: 100,
              render: (row) => {
                const dataReport = getDataReportOfOrganizationByDemographic(
                  row,
                  key,
                );
                const total_members_happy_survey = get(
                  dataReport,
                  'total_members_happy_survey',
                );
                const total_members_finished_survey = get(
                  dataReport,
                  'total_members_passed_learning',
                );

                return renderColumnData(
                  total_members_happy_survey,
                  total_members_finished_survey,
                  skipFormatNumber,
                  '',
                  {
                    colSpan: getColSpan(row),
                  },
                );
              },
            },
            {
              title: `%`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              width: 100,
              render: (row) => {
                const dataReport = getDataReportOfOrganizationByDemographic(
                  row,
                  key,
                );
                const total_members_happy_survey = get(
                  dataReport,
                  'total_members_happy_survey',
                );
                const total_members_finished_survey = get(
                  dataReport,
                  'total_members_passed_learning',
                );

                return renderColumnData(
                  total_members_happy_survey,
                  total_members_finished_survey,
                  skipFormatNumber,
                  'percentage',
                  {
                    colSpan: getColSpan(row),
                  },
                );
              },
            },
          ],
        };
      }),
    },
    {
      title: (
        <>
          Thông tin điểm TB của tất cả các item
          <br />
          (có điểm TB của tổng các item từ 3.0 (không có mục nào dưới 2.5))
        </>
      ),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: getDemographics().map(({ title, key }) => {
        return {
          title,
          className: 'text-center',
          children: [
            {
              title: `Điểm TB`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              width: 100,
              render: (row) => {
                const dataReport = getDataReportOfOrganizationByDemographic(
                  row,
                  key,
                );
                const average_passed_survey_score = get(
                  dataReport,
                  'average_passed_survey_score',
                );
                const total_members_finished_survey = get(
                  dataReport,
                  'total_members_passed_learning',
                );

                return {
                  children: total_members_finished_survey
                    ? average_passed_survey_score
                      ? average_passed_survey_score.toFixed(2)
                      : average_passed_survey_score
                    : '',
                  props: {
                    colSpan: getColSpan(row),
                  },
                };
              },
            },
            {
              title: `SL`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              width: 100,
              render: (row) => {
                const dataReport = getDataReportOfOrganizationByDemographic(
                  row,
                  key,
                );
                const total_members_passed_survey = get(
                  dataReport,
                  'total_members_passed_survey',
                );
                const total_members_finished_survey = get(
                  dataReport,
                  'total_members_passed_learning',
                );

                return renderColumnData(
                  total_members_passed_survey,
                  total_members_finished_survey,
                  skipFormatNumber,
                  '',
                  {
                    colSpan: getColSpan(row),
                  },
                );
              },
            },
            {
              title: `%`,
              type: TYPE_OF_TABLE_DATA.TITLE,
              width: 100,
              render: (row) => {
                const dataReport = getDataReportOfOrganizationByDemographic(
                  row,
                  key,
                );
                const total_members_passed_survey = get(
                  dataReport,
                  'total_members_passed_survey',
                );
                const total_members_finished_survey = get(
                  dataReport,
                  'total_members_passed_learning',
                );

                return renderColumnData(
                  total_members_passed_survey,
                  total_members_finished_survey,
                  skipFormatNumber,
                  'percentage',
                  {
                    colSpan: getColSpan(row),
                  },
                );
              },
            },
          ],
        };
      }),
    },
  ]
    .concat(
      Array.isArray(questionGroups) && questionGroups.length > 0
        ? questionGroups.map((questionGroup, index) => {
            return {
              title: (
                <>
                  {get(questionGroup, 'content')}
                  <br />
                  (có điểm TB của tổng các item từ 3.0)
                </>
              ),
              type: TYPE_OF_TABLE_DATA.TITLE,
              children: getDemographics().map(({ title, key }) => {
                return {
                  title,
                  className: 'text-center',
                  children: [
                    {
                      title: 'Điểm TB',
                      width: 100,
                      render: (row) => {
                        const dataReport = getDataReportOfOrganizationByDemographic(
                          row,
                          key,
                        );
                        const detail = get(dataReport, 'detail');
                        const total_members_finished_survey = get(
                          dataReport,
                          'total_members_passed_learning',
                        );

                        let score = get(
                          detail,
                          `${get(
                            questionGroup,
                            'question_id',
                          )}.average_passed_score`,
                        );
                        if (score) {
                          score = score.toFixed(2);
                        }

                        return renderColumnData(
                          score ? score : null,
                          total_members_finished_survey,
                          skipFormatNumber,
                          '',
                          {
                            colSpan: getColSpan(row),
                          },
                        );
                      },
                    },
                    {
                      title: `SL`,
                      type: TYPE_OF_TABLE_DATA.TITLE,
                      width: 100,
                      render: (row) => {
                        const dataReport = getDataReportOfOrganizationByDemographic(
                          row,
                          key,
                        );
                        const detail = get(dataReport, 'detail');
                        const total_members_finished_survey = get(
                          dataReport,
                          'total_members_passed_learning',
                        );

                        return renderColumnData(
                          get(
                            detail,
                            `${get(questionGroup, 'question_id')}.passed_item`,
                          ),
                          total_members_finished_survey,
                          skipFormatNumber,
                          '',
                          {
                            colSpan: getColSpan(row),
                          },
                        );
                      },
                    },
                    {
                      title: `%`,
                      type: TYPE_OF_TABLE_DATA.TITLE,
                      width: 100,
                      render: (row) => {
                        const dataReport = getDataReportOfOrganizationByDemographic(
                          row,
                          key,
                        );
                        const detail = get(dataReport, 'detail');
                        const total_members_finished_survey = get(
                          dataReport,
                          'total_members_passed_learning',
                        );

                        return renderColumnData(
                          get(
                            detail,
                            `${get(questionGroup, 'question_id')}.passed_item`,
                          ),
                          total_members_finished_survey,
                          skipFormatNumber,
                          'percentage',
                          {
                            colSpan: getColSpan(row),
                          },
                        );
                      },
                    },
                  ],
                };
              }),
            };
          })
        : [],
    )
    .filter(Boolean);
};

const getDataForOrg = ({ data_report, ...organization }) => {
  if (!organization.iid && !organization.id) {
    return organization;
  }

  const dataReportBySchoolLevel = Array.isArray(data_report) ? data_report : [];

  organization.data_report = dataReportBySchoolLevel.find(
    ({ school_level }) => school_level === 'all_school',
  );

  organization.children = gradeType.map((grade) => {
    return {
      name: userGradeToText(grade, true),
      code: `${organization.code}-${grade}`,
      iid: `${organization.iid}-${grade}`,
      id: `${organization.id}-${grade}`,
      data_report: dataReportBySchoolLevel.find(
        ({ school_level }) => school_level === grade,
      ),
    };
  });

  return organization;
};

const formatDataForGradeViewChildrenItem = (organizations = []) => {
  if (!Array.isArray(organizations) || !organizations.length) {
    return [];
  }

  let result = [];

  organizations.forEach(({ children, ...organization }) => {
    result.push(getDataForOrg(organization));
    if (Array.isArray(children) && children.length) {
      children.forEach((childOrg) => {
        result.push(getDataForOrg(childOrg));
      });
    }
  });

  return result;
};

const formatDataReportForTreeView = (dataReport) => {
  let result = {};

  if (!Array.isArray(dataReport) || !dataReport.length) {
    return result;
  }

  dataReport.forEach(({ school_level, ...row }) => {
    if (school_level === 'all_school') {
      result = Object.assign(result, row);
    } else {
      result[`data_report_of_${school_level}`] = row;
    }
  });

  return result;
};

const formatDataForTreeView = (organizations = []) => {
  if (!Array.isArray(organizations) || !organizations.length) {
    return [];
  }

  let result = [];

  organizations.forEach(({ children, data_report, ...organization }) => {
    organization.data_report = formatDataReportForTreeView(data_report);

    if (Array.isArray(children) && children.length) {
      organization.children = formatDataForTreeView(children);
    }

    result.push(organization);
  });

  return result;
};

const formatDataSourceFromDataServerToRenderTable = (
  organizations,
  subTypesOfChildrenDisplay,
  filter = false,
  isGradeView,
) => {
  if (!Array.isArray(organizations) || !organizations.length) {
    return [];
  }

  return organizations
    .map(({ children, sub_type, ...organization }, id) => {
      if (
        Array.isArray(subTypesOfChildrenDisplay) &&
        filter &&
        !subTypesOfChildrenDisplay.includes(sub_type)
      ) {
        return false;
      }

      const newChildren = formatDataSourceFromDataServerToRenderTable(
        children,
        subTypesOfChildrenDisplay,
        isGradeView ? false : true,
      );

      if (Array.isArray(newChildren) && newChildren.length) {
        organization.children = newChildren;
      }

      if (!organization.id) {
        organization.id = id;
        organization.hiddenData = true;
      }

      return organization;
    })
    .filter(Boolean);
};

const getPropsToExpandedRowKeys = (dataSource) => {
  if (!Array.isArray(dataSource) || !dataSource.length) {
    return [];
  }

  return dataSource.reduce((result, { id, children }) => {
    result.push(id);

    if (!Array.isArray(children) || !children.length) {
      return result;
    }

    return result.concat(getPropsToExpandedRowKeys(children));
  }, []);
};

const TableResult = ({
  dataResult,
  userRoot,
  isResultForCBQL,
  defaultExpandAllRows,
  subTypesOfChildrenDisplay = [],
  skipFormatNumber,
  isGradeView,
  trainingPlanCategory,
}) => {
  let data = get(dataResult, 'data', []);
  if (isGradeView) {
    data = formatDataForGradeViewChildrenItem(data);
  } else {
    data = formatDataForTreeView(data);
  }

  const dataSource = formatDataSourceFromDataServerToRenderTable(
    data,
    subTypesOfChildrenDisplay,
    false,
    isGradeView,
  );

  const expandedRowKeys = getPropsToExpandedRowKeys(dataSource);
  const questionGroups = get(dataResult, 'question_groups', []);

  if (!Array.isArray(dataSource) || !Array.isArray(questionGroups)) {
    return <SimpleNoResult />;
  }

  const props = {
    bordered: true,
    rowKey: 'id',
    pagination: false,
    className: 'white-background learn-default',
    columns: getColumns(
      isGradeView,
      questionGroups,
      userRoot,
      dataSource.length,
      isResultForCBQL,
      skipFormatNumber,
      trainingPlanCategory,
    ),
    dataSource,
    scroll: { x: 'max-content' },
    defaultExpandedRowKeys: expandedRowKeys,
  };

  if (defaultExpandAllRows) {
    props.expandedRowKeys = expandedRowKeys;
  }

  return <CommonAntdTable {...props} />;
};

export default withTemisConfig(TableResult);
