import React, { Component } from 'react';
import withUserInfo from 'common/hoc/withUserInfo';
import withTemisConfig from 'common/hoc/withTemisConfig';
import { compose } from 'redux';
import AssessmentOfPeersInPhongBan from 'components/temis/report/tcnn-in-phongban';
import withScholastic from 'components/temis/components/scholastic/withScholastic';

class AssessmentsInOrganizationAllAssessment extends Component {
  render() {
    const { userInfo = {}, rubricToAssessment, scholastic } = this.props;
    return (
      <AssessmentOfPeersInPhongBan
        user={userInfo}
        rubricIid={rubricToAssessment}
        scholastic={scholastic}
      />
    );
  }
}

export default compose(
  withScholastic,
  withTemisConfig,
  withUserInfo,
)(AssessmentsInOrganizationAllAssessment);
