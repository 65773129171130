import React from 'react';
import lodashGet from 'lodash.get';
import { t1 } from 'translate';
import { loadingStatuses } from 'configs/constants';
import fetchData from 'components/common/fetchData';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import CommonAntdTable from 'components/common/antd/table';
import Loading from 'components/common/loading';
import DisplayHtml from 'components/common/html';
import ExportBtnWithConfirmDialog from 'components/common/action-button/ExportBtnWithConfirmDialog';
import ChuongTrinhTag from 'components/bdtx/common/ChuongTrinhTag';
import endpoints from 'components/bdtx/choose-module-for-ncbdtx/endpoints';
import bdtxReportEndpoints from 'components/bdtx/report/endpoints';
import {
  getUserDiaBanKhoKhan,
  getUserEthnicity,
  getUserGender,
} from 'components/admin/user/utils';

const Results = ({
  usersChosenNcbdtx,
  ncbdtxModules,
  searchValues,
  loadingStatus,
}) => {
  if (loadingStatus !== loadingStatuses.FINISHED) {
    return <Loading circularLoadingIcon />;
  }

  const hasModules = ncbdtxModules && ncbdtxModules.length;

  const columns = (forExport = false) => {
    return [
      {
        title: t1('user_information'),
        width: 300,
        dataIndex: 'user',
        render: (user) => (
          <>
            {lodashGet(user, 'name')} <br />
            {`(${lodashGet(user, 'code')})`}
          </>
        ),
      },
      {
        title: t1('other_user_info'),
        type: TYPE_OF_TABLE_DATA.TITLE,
        children: [
          {
            title: t1('sex'),
            type: TYPE_OF_TABLE_DATA.TITLE,
            dataIndex: 'user',
            width: 100,
            render(user) {
              return getUserGender(lodashGet(user, 'sex'));
            },
          },
          {
            title: t1('ethnicity'),
            type: TYPE_OF_TABLE_DATA.TITLE,
            dataIndex: 'user',
            width: 100,
            render(user) {
              return getUserEthnicity(user);
            },
          },
          {
            title: t1('congtac_thuoc_diaban_khokhan'),
            type: TYPE_OF_TABLE_DATA.TITLE,
            dataIndex: 'user',
            width: 100,
            render(user) {
              return getUserDiaBanKhoKhan(
                lodashGet(user, 'congtac_thuoc_diaban_khokhan'),
              );
            },
          },
        ],
      },
      {
        title: t1('user_organization_information'),
        type: TYPE_OF_TABLE_DATA.TITLE,
        children: [
          {
            title: t1('user_organization_name'),
            dataIndex: 'user',
            width: 150,
            render: (user) =>
              lodashGet(user, '__expand.user_organizations.0.name'),
          },
          {
            title: t1('user_organization_district'),
            dataIndex: 'user',
            width: 150,
            render: (user) =>
              lodashGet(
                user,
                '__expand.user_organizations.0.__expand.org_district_id.name',
              ),
          },
          {
            title: t1('user_organization_province'),
            dataIndex: 'user',
            width: 150,
            render: (user) =>
              lodashGet(
                user,
                '__expand.user_organizations.0.__expand.org_province_id.name',
              ),
          },
        ],
      },
      {
        title: (
          <>
            <span>{t1('list_ncbdtx_modules')}</span>
            {!ncbdtxModules || !ncbdtxModules.length ? (
              <div className="text-danger m-t-5">
                {t1('organization_is_not_set_modules_for_ncbdtx')}
              </div>
            ) : null}
          </>
        ),
        type: TYPE_OF_TABLE_DATA.TITLE,
        children: (ncbdtxModules || []).map((module) => {
          let title = (
            <>
              <span>{lodashGet(module, '__expand.module.name')}</span>
              <div className="m-t-5">
                <ChuongTrinhTag
                  chuongTrinh={lodashGet(module, 'chuong_trinh')}
                  shortName
                />
              </div>
            </>
          );

          if (forExport) {
            title = (
              <>
                <span>
                  [
                  <ChuongTrinhTag
                    chuongTrinh={lodashGet(module, 'chuong_trinh')}
                    shortName
                  />
                  ]
                </span>

                <span>{lodashGet(module, '__expand.module.name')}</span>
              </>
            );
          }

          return {
            title: title,
            type: TYPE_OF_TABLE_DATA.TITLE,
            width: 200,
            render: (userChosenNcbdtx) => {
              const modules = lodashGet(userChosenNcbdtx, 'modules') || [];

              if (!modules.includes(lodashGet(module, 'module_iid'))) {
                return null;
              }

              if (forExport) {
                return <div>{t1('user_chosen')}</div>;
              }

              return (
                <>
                  <span className="ve-check text-primary" />
                </>
              );
            },
          };
        }),
      },
      {
        title: t1('other_ncbdtx'),
        dataIndex: 'other_ncbdtx',
        width: 300,
        render: (otherNcbdtx) => {
          if (!otherNcbdtx) {
            return null;
          }

          return <DisplayHtml content={otherNcbdtx} />;
        },
      },
    ];
  };

  return (
    <>
      {hasModules ? (
        <ExportBtnWithConfirmDialog
          label={t1('export_detail')}
          url={bdtxReportEndpoints.export_details_ncbdtx_by_module}
          params={searchValues}
          fetchingCacheOnly
        />
      ) : null}

      <div className="m-t-10 m-b-10">{t1('table_guide_to_user_scroll')}</div>

      <CommonAntdTable
        dataSource={usersChosenNcbdtx}
        columns={columns()}
        rowKey="id"
        pagination={false}
        className="learn-default"
        border={false}
        scroll={{
          x: true,
        }}
      />
    </>
  );
};

const fetchNcbdtxModules = fetchData(({ searchValues }) => {
  const cap = lodashGet(searchValues, 'cap');
  const targetUser = lodashGet(searchValues, 'target_user');

  return {
    baseUrl: endpoints.get_list_ncbdtx_modules,
    params: {
      organizations: lodashGet(searchValues, 'organizations'),
      chuong_trinh: lodashGet(searchValues, 'chuong_trinh'),
      caps: cap ? [cap] : [],
      target_users: targetUser ? [targetUser] : [],
      school_year: lodashGet(searchValues, 'school_year'),
      page: 1,
      items_per_page: -1,
    },
    propKey: 'ncbdtxModules',
  };
});

export default fetchNcbdtxModules(Results);
