import { isTeacher } from '../../admin/user/utils';

export const shouldShowUpdateContactInfoDialog = (userInfo) => {
  return (
    userInfo &&
    isTeacher(userInfo) &&
    !userInfo.secondary_phone &&
    !userInfo.secondary_mail
  );
};
