import { isToTruong } from 'components/admin/user/utils';

const schema = (isUserSelfAssessment, userRoot, readOnly) => {
  return {
    strengths: {
      type: 'text',
      rowsMax: 2,
      rows: 2,
      multiLine: true,
      defaultValue: '',
      errorText: '',
      fullWidth: true,
      classWrapper: 'm-l-15 m-r-15',
      floatingLabelText: 'Điểm mạnh',
      disabled: readOnly,
    },
    weakness: {
      type: 'text',
      rowsMax: 2,
      rows: 2,
      multiLine: true,
      defaultValue: '',
      errorText: '',
      fullWidth: true,
      classWrapper: 'm-l-15 m-r-15',
      floatingLabelText: 'Những vấn đề cần cải thiện',
      disabled: readOnly,
    },
    proposing_development_direct_and_remedies: {
      type: 'text',
      rowsMax: 4,
      rows: 4,
      multiLine: true,
      defaultValue: '',
      errorText: '',
      fullWidth: true,
      classWrapper:
        !isUserSelfAssessment && isToTruong(userRoot) && !readOnly
          ? 'm-l-15 m-r-15 m-t-5 proposing-development-remedies'
          : 'display-none',
      floatingLabelText: 'Đề xuất hướng phát huy điểm mạnh, khắc phục điểm yếu',
      disabled: readOnly,
    },
  };
};

const ui = (userRoot, finalAggregateAssessment) => [
  {
    id: 'overall_comment',
    fields: [
      'strengths',
      'weakness',
      ...(!finalAggregateAssessment && isToTruong(userRoot)
        ? ['proposing_development_direct_and_remedies']
        : []),
    ],
  },
];

export default (
  isUserSelfAssessment,
  userRoot,
  readOnly,
  finalAggregateAssessment,
) => ({
  schema: schema(isUserSelfAssessment, userRoot, readOnly),
  ui: () => ui(userRoot, finalAggregateAssessment),
});
