import React from 'react';
import withTemisConfig from 'common/hoc/withTemisConfig';
import DetailOnDialog from 'components/common/detail-on-dialog';
import SearchFormSpeers from './SearchFormSpeers';
import get from 'lodash.get';
import { getTemisConfByUser } from 'common/hoc/withTemisConfig';
import Widget from 'components/common/Widget';
import { t1 } from 'translate';
import './style.scss';
import AntDeleteItem from 'components/common/action-button/AtnDeleteBtnWithConfirmDialog';

const dialogOptionsProperties = ({ name }) => ({
  handleClose: true,
  title: t1('Giao nhiệm vụ đánh giá CNN cho: %s', [name]),
  width: '80%',
});
const AssignPeersToAssess = ({ user, assignPeersToAssess, userRoot }) => {
  const { rubricToAssessment } = getTemisConfByUser(user);
  const peerssAssignedToAssessment = get(
    user,
    'assessment_of_peers_assigned_to_assessment',
    [],
  )
    .map(
      ({ target, peer, final_aggregate_assessment, result, score_scale }) => {
        if (
          String(target.iid) === String(peer.iid) ||
          final_aggregate_assessment
        ) {
          return false;
        }
        return {
          ...peer,
          didAssessment: result && score_scale,
        };
      },
    )
    .filter(Boolean);
  const target = { iid: user.iid, ntype: 'user' };
  return (
    <DetailOnDialog
      dialogKey="edit_assign_peers_to_assess"
      dialogOptionsProperties={dialogOptionsProperties(user)}
      renderPreview={({ showFull }) => {
        return [
          peerssAssignedToAssessment.map(({ iid, name, didAssessment }) => (
            <div className="name-assign m-b-15">
              {didAssessment ? (
                <span className="ve-check action" />
              ) : (
                <AntDeleteItem
                  icon="action ve-close text-black action-assign cursor-pointer"
                  customDeleteAction={() => {
                    assignPeersToAssess(
                      'remove-assign',
                      target,
                      [iid],
                      rubricToAssessment,
                    );
                  }}
                  title="Bạn có muốn bỏ gán nhiệm vụ đánh giá đồng cấp?"
                />
              )}
              <span key={iid}>{name}</span>
            </div>
          )),
          <span
            className="action action-assign ve-plus-circle icon-assign-secondary"
            onClick={showFull}
          />,
        ];
      }}
      renderFull={({ closeDialog }) => {
        return (
          <Widget className="search-teacher">
            <SearchFormSpeers
              user={user}
              peerssAssignedToAssessment={peerssAssignedToAssessment}
              userRoot={userRoot}
              assignPeersToAssess={(peersIid) => {
                assignPeersToAssess(
                  'assign',
                  target,
                  peersIid,
                  rubricToAssessment,
                );
                closeDialog();
              }}
            />
          </Widget>
        );
      }}
    />
  );
};

export default withTemisConfig(AssignPeersToAssess);
