import React, { useState } from 'react';
import withUserInfo from 'common/hoc/withUserInfo';
import lodashGet from 'lodash.get';
import Form from 'components/temis/profile/edit/Form';
import Warning from 'components/common/Warning';
import { bindActionCreators } from 'redux';
import { findCourse } from 'actions/course';
import { connect } from 'react-redux';
import { t1, t3 } from 'translate';

const CompleteProfileBeforeUsing = ({ userInfo, findCourses }) => {
  const [isFinishTemisForm, setFinishTemisForm] = useState(false);
  const userId = lodashGet(userInfo, 'id');

  const handleFormSubmitSuccess = () => {
    localStorage.setItem('isFinishTemisForm', 'true');
    setFinishTemisForm(true);

    return;
    findCourses(userId);
  };

  return (
    <div>
      {!isFinishTemisForm && (
        <div className="text-center">
          <Warning>
            {t1(
              'please_provide_complete_personal_information_to_be_able_to_continue_using_the_system',
            )}
          </Warning>
        </div>
      )}

      <div className="container-fluid">
        <Form
          userId={userId}
          title={t3('etep_profile_form_title')}
          markAsHaveEnterTemisProfileInfoWhenSubmit
          onSuccess={handleFormSubmitSuccess}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  findCourses: bindActionCreators(findCourse, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(withUserInfo(CompleteProfileBeforeUsing));
