import React from 'react';
import get from 'lodash.get';

const AspirationMaster = ({ item }) => {
  const haveAspiration = get(item, 'have_desire_to_become_master');

  return (
    <div className={`text-transform ${haveAspiration ? '' : 'text-danger'}`}>
      {haveAspiration ? 'Có' : 'Không'}
    </div>
  );
};

export default AspirationMaster;
