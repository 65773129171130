import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Icon from 'components/common/Icon';
import { t1 } from 'translate';
import Communicate from './Form';

const DialogGoToCommunication = ({
  renderPreview,
  optionsProperties = {},
  ...propsRender
}) => {
  return (
    <DetailOnDialog
      renderPreview={
        typeof renderPreview === 'function'
          ? renderPreview
          : ({ showFull }) => (
              <Icon
                icon="email"
                onClick={showFull}
                style={{ fontSize: 20, cursor: 'pointer' }}
                title={t1('send_communication')}
              />
            )
      }
      renderFull={() => <Communicate {...propsRender} />}
      dialogKey="dialog_communication"
      dialogOptionsProperties={{
        handleClose: true,
        width: '80%',
        ...optionsProperties,
      }}
    />
  );
};

export default DialogGoToCommunication;
