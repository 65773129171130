import { t1 } from 'translate';
import { password } from 'components/admin/user/schema/elements';

const schema = () => ({
  pass: password(t1('password')),
});

const ui = () => [
  {
    id: 'id',
    fields: ['pass'],
  },
];

export default { schema, ui };
