import React from 'react';
import get from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from '../../../../common/utils/type-of-table-data';
import PreviewUserInDialog from '../../../admin/user/account/common/PreviewInDialog';
import Avatar from 'antd/lib/avatar';
import lodashGet from 'lodash.get';
import Positions from '../../../admin/group/edit/member/search-results/Positions';
import OrganizationsOrPhongBan from '../../../admin/group/common/OrganizationsOrPhongBanInResultTable';
import { t1 } from '../../../../translate';

const getTextPartByScore = (final, parts) => {
  if (typeof final === 'undefined') {
    return '';
  }

  const part = parts.find(
    ({ id }) => Number.parseInt(final) === Number.parseInt(id),
  );

  return get(part, 'name');
};

const getColumnsRenderRubrics = (rubrics, parts) => {
  if (!Array.isArray(rubrics) || !rubrics.length) {
    return [];
  }

  return rubrics.map(({ children, short_name, name, iid }) => {
    const columns = getColumnsRenderRubrics(children, parts);

    if (Array.isArray(columns) && columns.length) {
      return {
        title: short_name || name,
        children: columns,
      };
    }

    return {
      title: short_name || name,
      width: 100,
      render: (item) => {
        const task = get(item, 'assessment.task');

        if (!Array.isArray(task) || !task.length) {
          return null;
        }

        const answer = task.find(
          ({ rubric_iid }) =>
            Number.parseInt(rubric_iid) === Number.parseInt(iid),
        );

        return getTextPartByScore(get(answer, 'answer'), parts);
      },
    };
  });
};

/*const getColumnsRenderPersonalOrientation = (targetUser) => {
  return [
    {
      title: 'Định hướng phát triển',
      children: [
        {
          title: 'Nội dung đăng ký học tập, bồi dưỡng',
          width: 500,
          render: (item) => {
            const content = get(
              item,
              'assessment.personal_orientation.content',
              [],
            );

            return (
              <Checkbox.Group
                disabled
                options={
                  targetUser === 'gv'
                    ? contentOptionForTeacher
                    : contentOptionForPrincipal
                }
                defaultValue={content}
              />
            );
          },
        },
        {
          title: 'Thời gian hoàn thành mô đun bồi dưỡng',
          width: 500,
          render: (item) => {
            const timeDoing = get(
              item,
              'assessment.personal_orientation.time_doing',
              [],
            );

            return (
              <Checkbox.Group
                disabled
                options={timeDoingOption}
                defaultValue={timeDoing}
              />
            );
          },
        },
        {
          title: 'Điều kiện thực hiện',
          width: 500,
          render: (item) => {
            const performanceConditions = get(
              item,
              'assessment.personal_orientation.performance_conditions',
            );

            return (
              <Checkbox.Group
                disabled
                options={performanceConditionsOption}
                defaultValue={performanceConditions}
              />
            );
          },
        },
      ],
    },
  ];
};*/

const getColumns = (assessment_name, rubrics, parts, searchValues = {}) => {
  const assessmentType = get(searchValues, 'assessment_type');

  return [
    {
      title: t1('full_name'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: (user) => {
        return (
          <>
            <PreviewUserInDialog
              user={user}
              hiddenItem={['code']}
              openDetailInNewTab
              showFullDetailButton={false}
            >
              <Avatar user={user} size={30} />{' '}
              <strong>{lodashGet(user, 'name')}</strong>
            </PreviewUserInDialog>
          </>
        );
      },
    },
    {
      title: 'Đơn vị',
      render: (item) =>
        [
          item.positions && !!item.positions.length && (
            <Positions item={item} textOnly={true} />
          ),
          item.user_organizations && !!item.user_organizations.length && (
            <OrganizationsOrPhongBan
              item={item}
              attr={'user_organizations'}
              showParentsInfo
              showCode
            />
          ),
          item.phongbans && !!item.phongbans.length && (
            <OrganizationsOrPhongBan
              item={item}
              attr={'phongbans'}
              showParentsInfo={false}
            />
          ),
        ].filter(Boolean),
    },
    {
      title: assessment_name,
      children: [
        {
          title: 'Kết quả đánh giá',
          render: (item) => {
            const final = get(item, 'assessment.result.final');
            return getTextPartByScore(final, parts);
          },
        },
      ],
    },
  ];
};

const TableResult = ({
  assessment_name,
  rubrics,
  score_scale,
  users,
  searchValues,
}) => {
  const parts = get(score_scale, 'parts');

  return (
    <CommonAntdTable
      bordered
      rowKey="id"
      size="middle"
      pagination={false}
      scroll={{ x: true }}
      className="white-background learn-default"
      columns={getColumns(assessment_name, rubrics, parts, searchValues)}
      dataSource={Array.isArray(users) ? users : []}
    />
  );
};

export default TableResult;
