import {
  SCHOLASTIC_CONFIG_LOADING,
  SCHOLASTIC_CONFIG_LOADING_SUCCESS,
  SCHOLASTIC_CONFIG_LOADING_ERROR,
} from './types';

export function loadScholasticConfig() {
  return {
    type: SCHOLASTIC_CONFIG_LOADING,
  };
}

export function loadScholasticConfigSuccess(config) {
  return {
    type: SCHOLASTIC_CONFIG_LOADING_SUCCESS,
    payload: { config },
  };
}

export function loadScholasticConfigError(error) {
  return {
    type: SCHOLASTIC_CONFIG_LOADING_ERROR,
    payload: { error },
  };
}
