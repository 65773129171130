import './stylesheet.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, Select, Spin } from 'antd';
import makeReduxFormCompatible from 'components/common/makeReduxFormCompatible';
import Requester from 'common/network/http/Request';
import debounce from 'lodash.debounce';
import { getPopupContainerOfElement } from 'common/utils/schema-form';

const { Option } = Select;

const AutoCompleteElement = ({
  floatingLabelText,
  readOnly,
  errorText,
  onChange,
  value,
  requestUrl,
  defaultRequestParams,
  searchKeys = ['iid', 'name'],
  transformData,
  useCaseSensitive,
  debounceTime = 300,
  multiple,
  onSearched,
  placeholder,
  ...remainProps
}) => {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);

  const convertDataToOptions = useCallback(
    (data) => {
      if (typeof transformData === 'function') {
        return transformData(data);
      }

      return (data || []).map((item) => ({
        value: item.iid || item.id || item.code,
        label: item.name || item.label || item.text,
      }));
    },
    [transformData],
  );

  const loadOption = useCallback(
    (searchValue = '') => {
      if (!requestUrl) {
        return;
      }

      const params = {};
      (searchKeys || []).forEach((key) => {
        params[key] = useCaseSensitive
          ? searchValue
          : searchValue.toLowerCase();
      });

      setFetching(true);

      let requestParams = {
        ...(params || {}),
        ...(defaultRequestParams || {}),
      };

      Requester.get(requestUrl, requestParams).then((response) => {
        if (response.success && Array.isArray(response.result)) {
          const results = response.result;
          const dataTransformed = convertDataToOptions(results);
          setOptions(dataTransformed);

          if (typeof onSearched === 'function') {
            onSearched(results);
          }
        }

        setFetching(false);
      });
    },
    [
      convertDataToOptions,
      defaultRequestParams,
      onSearched,
      requestUrl,
      searchKeys,
      useCaseSensitive,
    ],
  );

  useEffect(
    () => {
      if ((options && options.length) || fetching) {
        return;
      }

      loadOption();
    },
    [fetching, loadOption, options],
  );

  const onSearch = useMemo(
    () => {
      return debounce(loadOption, debounceTime);
    },
    [debounceTime, loadOption],
  );

  const handleValueChange = useCallback(
    (value) => {
      if (typeof onChange === 'function') {
        onChange(value || '');
      }
    },
    [onChange],
  );

  return (
    <div className={'autocomplete'}>
      {floatingLabelText && (
        <div className={'input-text-label'}>
          <label>{floatingLabelText}</label>
        </div>
      )}
      <Form.Item
        validateStatus={errorText ? 'error' : ''}
        help={errorText || ''}
        className="m-b-0"
      >
        <Select
          className="full-width"
          mode={multiple ? 'multiple' : 'default'}
          filterOption={false}
          onSearch={onSearch}
          onChange={handleValueChange}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          placeholder={placeholder || floatingLabelText}
          {...remainProps}
          showSearch
          disabled={readOnly}
          getPopupContainer={getPopupContainerOfElement}
        >
          {(options || []).map((opt) => {
            return (
              <Option key={opt.value} value={opt.value}>
                {opt.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
};

export default makeReduxFormCompatible({})(AutoCompleteElement);
