import React from 'react';
import endpoints from '../../endpoints';
import ViewChooseMyTeacherMaster from './TableResult';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import schemaSearch from './schema-form';
import withUserInfo from 'common/hoc/withUserInfo';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';

const searchFormId = 'choose_teacher_assessment_search_form';

const ChooseTeacherMaster = ({ scholastic, isOutOfDeadlineAssessment }) => {
  const renderResultsComponent = (items) => {
    if (!items || !items.length) {
      return (
        <div className="col-md-12">
          <SimpleNoResult text="Không tìm thấy giáo viên để chọn làm giáo viên cốt cán" />
        </div>
      );
    }
    return (
      <div className="choose-teacher-master">
        <ViewChooseMyTeacherMaster
          users={items}
          searchFormId={searchFormId}
          viewOnly={isOutOfDeadlineAssessment}
          scholastic={scholastic}
        />
      </div>
    );
  };

  return (
    <SearchWrapper
      key={`${searchFormId}_${scholastic}`}
      hiddenFields={{
        scholastic,
      }}
      formid={searchFormId}
      renderResultsComponent={renderResultsComponent}
      schema={schemaSearch}
      showResult={true}
      alternativeApi={endpoints.listUserHaveDesireToBecomeMaster}
      autoSearchWhenStart
      paginationProps={{
        theme: 'light',
      }}
      classPaginationWrapper="pagination-default"
    />
  );
};

export default withOutOfDeadlineAssessment(
  withScholastic(withUserInfo(ChooseTeacherMaster)),
);
