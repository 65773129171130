import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Form from './Form';
import RaisedButton from 'components/common/mui/RaisedButton';
import { t1 } from 'translate';

const New = ({ searchFormId, scholastic, viewOnly }) => {
  const dialogKey = 'view_assessment_evidence_content';

  const renderPreview = React.useCallback(
    ({ showFull }) => {
      return (
        <RaisedButton
          onClick={showFull}
          label={t1('add_support_evidence')}
          className="m-t-10 btn-secondary"
          disabled={viewOnly}
        />
      );
    },
    [viewOnly],
  );

  const renderFull = React.useCallback(
    () => {
      return (
        <Form
          dialogKey={dialogKey}
          searchFormId={searchFormId}
          formid="new_templateless_evidence"
          getOnlyAssessmentThatUserCanDo
          hiddenFields={{
            scholastic,
          }}
        />
      );
    },
    [searchFormId, scholastic],
  );

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogKey={dialogKey}
      dialogOptionsProperties={{
        title: t1('new_assessment_evidence'),
        width: '80%',
        handleClose: true,
        modal: true,
      }}
    />
  );
};

export default New;
