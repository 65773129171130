import React from 'react';
import moment from 'moment';
import get from 'lodash.get';
import dateTimeConfig from 'configs/dateTime';
import './style.scss';
import SchemaForm from 'schema-form/Form';
import schema, { SCHOLASTIC_SELECT_FIELD_NAME } from './schema';

import fetchData from 'components/common/fetchData';
import apiUrls from '../../endpoints';
export const SCHOLASTIC_FORM_ID = 'scholastic_form';

const Scholastic = ({ allScholastic = [] }) => {
  const getScholasticLabel = (startDate) => {
    if (!startDate) {
      return '';
    }

    const yearFormat = moment
      .unix(startDate)
      .format(dateTimeConfig.YEAR_FORMAT);
    const yearConvert = parseInt(yearFormat);

    return `${yearConvert}-${yearConvert + 1}`;
  };

  const convertAllScholastic = () => {
    if (!allScholastic || !allScholastic.length) {
      return [];
    }

    return allScholastic.map((item) => {
      const startDate = get(item, 'duration.start_date');
      const label = get(item, 'name') || getScholasticLabel(startDate);
      return {
        value: item.id,
        primaryText: label,
        label,
      };
    });
  };
  const defaultScholastic = get(allScholastic, `[0].id`);

  return (
    <SchemaForm
      schema={schema}
      formid={SCHOLASTIC_FORM_ID}
      hideSubmitButton
      node={{
        [SCHOLASTIC_SELECT_FIELD_NAME]: defaultScholastic,
      }}
      allScholastic={convertAllScholastic()}
      key={`${SCHOLASTIC_FORM_ID}_${
        Array.isArray(allScholastic) ? allScholastic.length : '__'
      }`}
    />
  );
};

const fetchAllScholastic = fetchData(() => ({
  baseUrl: apiUrls.get_all_scholastic,
  method: 'get',
  keyState: 'allScholastic',
  propKey: 'allScholastic',
}));

export default fetchAllScholastic(Scholastic);
