import React from 'react';
import withTemisConfig from 'common/hoc/withTemisConfig';
import { t1 } from 'translate';
import { organizations } from 'components/admin/organization/schema/elements';
import { required } from 'common/validators';
import get from 'lodash.get';
import taphuanSubTypes from 'configs/constants/org-sub-types';
import { isBoOrAdminSP, isSo } from 'components/admin/user/utils';
import { shouldShowViewReportMode } from 'components/temis/utils';

const orgTypesReached = [taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD];

const schema = (formid, values, step, xpath, props) => ({
  organizations: organizations({
    formid,
    multiple: false,
    floatingLabelText: t1('display_template'),
    defaultValue: props.orgIids,
    validate: required(t1('organization_can_not_empty')),
    ...(isBoOrAdminSP(props.userInfo)
      ? { orgTypesReached: orgTypesReached }
      : {}),
    ...(isSo(props.userInfo)
      ? {
          subTypesDiscarded: [
            taphuanSubTypes.TAPHUAN_SUBTYPE_TIEUHOC,
            taphuanSubTypes.TAPHUAN_SUBTYPE_THCS,
            taphuanSubTypes.TAPHUAN_SUBTYPE_THPT,
            taphuanSubTypes.TAPHUAN_SUBTYPE_LIENCAP,
            taphuanSubTypes.TAPHUAN_SUBTYPE_LTTU,
            taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
            taphuanSubTypes.TAPHUAN_SUBTYPE_GIAODUC_THUONGXUYEN,
            taphuanSubTypes.TAPHUAN_SUBTYPE_TUTHUC_LIENCAP,
          ],
        }
      : {}),
  }),
  view_mode: {
    type: 'radio',
    inline: true,
    fullWidth: true,
    floatingLabelFixed: true,
    viewType: 'button',
    options: [
      {
        label: t1('detail_view'),
        value: 'normal',
      },
      {
        label: t1('tree_view'),
        value: 'tree',
      },
      {
        label: t1('grade_view'),
        value: 'grade',
      },
    ],
    defaultValue: 'normal',
    buttonStyle: 'solid',
  },
});

const ui = () => [
  {
    id: 'default',
    fields: ['organizations', 'view_mode'],
  },
];

const LayoutFreeStyle = withTemisConfig(
  ({ groups, submitButton, userRoot }) => {
    const organizationFieldName = get(
      groups,
      'default.fieldNames.organizations',
    );
    const viewModeFieldName = get(groups, 'default.fieldNames.view_mode');
    const enableViewMode = shouldShowViewReportMode(userRoot);

    return (
      <>
        <div className="flex-container-wrap">
          {!!organizationFieldName && (
            <div className="col-md-8 flex-item">{organizationFieldName}</div>
          )}
          {!!viewModeFieldName && enableViewMode && (
            <div className="col-md-4 flex-item text-center m-t-30">
              {viewModeFieldName}
            </div>
          )}
        </div>

        {!!submitButton && <div className="text-center">{submitButton}</div>}
      </>
    );
  },
);

const layout = () => ({
  component: LayoutFreeStyle,
  freestyle: 1,
});

export default {
  schema,
  ui,
  layout,
};
