import React, { useEffect } from 'react';
import get from 'lodash.get';
import { compose } from 'redux';
import NodeNew from 'components/admin/node/new';
import schema from './schema';
import fetchData from 'components/common/fetchData';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import endpoints from '../../endpoints';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import BM3PL2CV4529File from 'components/temis/template-files/bieu_mau_03_lay_y_kien_gv_trong_truong.xlsx';
import ConfirmToLeave from 'components/temis/assessment/do-assessment/confirm-to-leave';
import { connect } from 'react-redux';
import { initialize, isDirty } from 'redux-form';
import withUserInfo from 'common/hoc/withUserInfo';
import { isPhong, isSo } from 'components/admin/user/utils';

const formDefaultId = 'form-bm3pl2-cv4529';

const CoworkerAssessmentResult = ({
  bm3pl2Cv,
  standards,
  refetchDataBm3Pl2,
  isOutOfDeadlineAssessment,
  scholastic,
  userIid,
  formId = formDefaultId,
  dirty,
  dispatch,
  userInfo,
}) => {
  useEffect(
    () => {
      dispatch(initialize(formId, bm3pl2Cv));
    },
    [dispatch, bm3pl2Cv, formId],
  );
  return (
    <>
      <ConfirmToLeave
        shouldShowConfirm={dirty}
        confirmMessage="Bạn chưa lưu file. Nếu bạn di chuyển sang trang khác sẽ mất hết dữ liệu đã nhập"
        cancelText="Quay lại hoàn thành lưu file"
        okText="Bỏ lưu file"
      />

      {!userIid && (
        <div className="text-center temis-main-title text-transform m-b-25">
          Tổng hợp ý kiến của GV, NV
        </div>
      )}

      {!isPhong(userInfo) && !isSo(userInfo) && (
        <a
          className="d-flex justify-content-end"
          href={BM3PL2CV4529File}
          download
        >
          <button className="btn btn-secondary m-b-10">
            Tải file mẫu BM3/PL2 CV4529
          </button>
        </a>
      )}

      <NodeNew
        schema={schema}
        node={bm3pl2Cv}
        closeModal
        formid={formId}
        alternativeApi={endpoints.save_bm3_pl2}
        requestSuccessful={() => refetchDataBm3Pl2()}
        shouldDisabledSaveButton={isOutOfDeadlineAssessment}
        hiddenFields={{
          scholastic,
          type: 'bm3_pl2_cv4529',
        }}
        dataSource={standards}
        readOnly={!!userIid}
      />
    </>
  );
};

const fetchMm3Pl2 = fetchData((props) => ({
  baseUrl: endpoints.get_bm3_pl2,
  fetchCondition: true,
  params: {
    scholastic: props.scholastic,
    ...(props.userIid ? { user_iid: props.userIid } : {}),
    type: 'bm3_pl2_cv4529',
  },
  fetchFunctionPropKey: 'refetchDataBm3Pl2',
  propKey: 'bm3pl2Cv',
  refetchCondition: (prevProps) =>
    get(prevProps, 'scholastic') !== get(props, 'scholastic'),
}));

const fetchBm3pl2Standards = fetchData((props) => ({
  baseUrl: endpoints.get_bm3pl2_standards,
  params: {
    scholastic: props.scholastic,
  },
  propKey: 'standards',
}));

const mapStateToProps = (state, { formId = formDefaultId }) => ({
  dirty: isDirty(formId)(state),
});

export default compose(
  connect(mapStateToProps),
  withScholastic,
  withOutOfDeadlineAssessment,
  fetchMm3Pl2,
  fetchBm3pl2Standards,
  withUserInfo,
)(CoworkerAssessmentResult);
