import React, { Component } from 'react';
import { t1 } from 'translate';
import get from 'lodash.get';
import CommonAntdTable from 'components/common/antd/table';
import { timestampToDateTimeString } from 'common/utils/Date';
import JobStandardResult from '../../../components/job-standard-result';
import endPoints from '../../endpoints';
import NodeNew from 'components/admin/node/new';
import { BECOME_MASTER_STATUS } from '../../../constant';
import OrganizationsOrPhongBan from 'components/admin/group/common/OrganizationsOrPhongBanInResultTable';
import { leaderPositionToText } from 'configs/constants/user';
import { getResultFromTheAssessment } from '../../../utils';
import ReviewResult from '../../../components/review-result';
import AspirationMaster from 'components/temis/components/aspiration-master';
import { TCNN_TYPE } from 'components/temis/constant';

class TableResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
    };
  }
  setSelectedRowKeys = (value) => {
    this.setState({
      selectedRowKeys: value,
    });
  };
  getColumns = (searchFormId, viewOnly) => {
    const { scholastic } = this.props;
    return [
      {
        title: 'Thông tin giáo viên',
        key: 'principal',
        width: 250,
        render: (principal, item) => {
          const principalBirthday = get(item, 'birthday');
          return (
            <div className="principal">
              <div className="principal-name">{get(item, 'name')}</div>
              <div className="principal-email text-faded">
                {get(item, 'mail')}
              </div>
              {principalBirthday > 0 && (
                <div className="principal-birthday text-faded">
                  {timestampToDateTimeString(principalBirthday, {
                    showTime: false,
                    splitter: '/',
                  })}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: t1('organizations'),
        key: 'organizations',
        render: (organizations, item) => (
          <OrganizationsOrPhongBan
            item={item}
            attr={'user_organizations'}
            showParentsInfo={false}
          />
        ),
      },
      {
        title: 'Cấp trên lựa chọn',
        width: 200,
        className: 'text-center',
        key: 'become_master_status',
        render: (status, item) => (
          <ReviewResult
            viewOnly={viewOnly}
            searchFormId={searchFormId}
            status={get(
              item,
              'become_master_status',
              BECOME_MASTER_STATUS.SENT,
            )}
            item={item}
            tcnn="tcnn_ht"
            disabledAction={!this.isProspectiveBecomeMaster(item)}
            scholastic={scholastic}
          />
        ),
      },
      {
        title: 'Cấp quản lý',
        width: 130,
        key: 'level',
        render: (item) => {
          const organizations = get(item, '__expand.user_organizations[0]');
          const parent = get(organizations, 'parents[0].name');
          return <div> {parent}</div>;
        },
      },
      {
        title: 'Chức vụ',
        key: 'position',
        render: (position, item) => {
          return leaderPositionToText(get(item, 'leader_position'));
        },
      },
      {
        title: 'Năm KN',
        key: 'position',
        render: (experience, item) => get(item, 'teaching_exp_years'),
      },
      {
        title: 'TCNN',
        key: 'tcnn',
        render: (tcnn, item) => {
          const assessment = get(item, '__expand.temis.tcnn_ht');

          const result =
            get(assessment, 'status') === 'approved' &&
            getResultFromTheAssessment(assessment);

          if (!result) {
            return null;
          }

          return JobStandardResult(
            get(result, 'id', 0),
            get(result, 'name', 'Chưa đạt'),
          );
        },
      },
      {
        title: 'Nguyện vọng CBQLCC',
        key: 'have_desire_to_become_master',
        width: 100,
        render: (item) => {
          return <AspirationMaster item={item} />;
        },
      },
    ];
  };

  isProspectiveBecomeMaster = (item) => {
    return get(item, 'prospective_become_master');
  };

  isDisableChooseButton = () => {
    const { selectedRowKeys } = this.state;
    if (!selectedRowKeys.length) {
      return true;
    }
    const { apiResponseData } = this.props;
    const selectedRows = apiResponseData.filter((item) =>
      selectedRowKeys.includes(item.iid),
    );

    const notPassData = selectedRows.find(
      (item) => !this.isProspectiveBecomeMaster(item),
    );

    return !!notPassData;
  };

  render() {
    const { apiResponseData, searchFormId, scholastic, viewOnly } = this.props;
    const { selectedRowKeys } = this.state;
    const principalIds = apiResponseData.map(({ iid = '' }) => iid);
    let buttonText = 'Chọn CBQLCC';
    let buttonCancel = 'Từ chối CBQLCC';

    if (selectedRowKeys.length) {
      buttonText = `Chọn ${selectedRowKeys.length} trở thành CBQLCC`;
      buttonCancel = `Từ chối ${selectedRowKeys.length} CBQLCC`;
    }
    return (
      <div className="table-action">
        <CommonAntdTable
          columns={this.getColumns(searchFormId, viewOnly)}
          dataSource={apiResponseData}
          pagination={false}
          bordered
          size="middle"
          className="learn-default"
          rowKey="iid"
          rowSelection={{
            selectedRowKeys,
            hideDefaultSelections: true,
            onChange: this.setSelectedRowKeys,
            selections: [
              {
                key: 'select_current_page',
                text: t1('select_data_in_current_page'),
                onSelect: () => {
                  const currentSelectedRowKeys = Array.isArray(selectedRowKeys)
                    ? selectedRowKeys
                    : [];
                  this.setSelectedRowKeys(
                    currentSelectedRowKeys.concat(
                      principalIds
                        .map(
                          (iid) => !currentSelectedRowKeys.includes(iid) && iid,
                        )
                        .filter(Boolean),
                    ),
                  );
                },
              },
              {
                key: 'invert_current_page',
                text: t1('invert_data_in_current_page'),
                onSelect: () => {
                  this.setSelectedRowKeys(
                    (Array.isArray(principalIds) ? principalIds : []).filter(
                      (iid) => !selectedRowKeys.includes(iid),
                    ),
                  );
                },
              },
              Array.isArray(selectedRowKeys) &&
                !!selectedRowKeys.length &&
                !selectedRowKeys.every((id) => principalIds.includes(id)) && {
                  key: 'remove_all',
                  text: t1('remove_all_data_selected'),
                  onSelect: () => {
                    this.setSelectedRowKeys([]);
                  },
                },
            ].filter(Boolean),
          }}
        />
        <div className="d-flex">
          <NodeNew
            dialogKey="choose-manager"
            schema={{}}
            formid="choose-manager"
            searchFormId={searchFormId}
            hiddenFields={{
              iids: selectedRowKeys,
              become_master_status: BECOME_MASTER_STATUS.APPROVED,
              tcnn: TCNN_TYPE.HT,
              scholastic,
            }}
            alternativeApi={endPoints.chooseUsersToBecomeMaster}
            submitButton={() => {
              return (
                <div className="d-flex">
                  <button
                    className="btn btn-secondary m-t-10"
                    disabled={this.isDisableChooseButton() || viewOnly}
                  >
                    {buttonText}
                  </button>
                </div>
              );
            }}
          />

          <NodeNew
            schema={{}}
            formid="reject-manager"
            searchFormId={searchFormId}
            hiddenFields={{
              iids: selectedRowKeys,
              become_master_status: BECOME_MASTER_STATUS.REJECTED,
              tcnn: TCNN_TYPE.HT,
              scholastic,
            }}
            alternativeApi={endPoints.chooseUsersToBecomeMaster}
            submitButton={() => {
              return (
                <div className="d-flex">
                  <button
                    className="btn btn-tertiary m-t-10 m-l-10"
                    disabled={this.isDisableChooseButton() || viewOnly}
                  >
                    {buttonCancel}
                  </button>
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default TableResult;
