import React from 'react';
import BaseAction from './BaseAction';
import { TCNN_TYPE } from 'components/temis/constant';

const ApprovedAction = (props) => (
  <BaseAction
    formid="review-approved-request"
    labelButton={props.data.tcnn === TCNN_TYPE.HT ? 'Lựa chọn' : 'Duyệt'}
    classButton="btn-secondary"
    {...props}
  />
);

export default ApprovedAction;
