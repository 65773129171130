import React from 'react';
import endpoints from '../endpoints';
import ViewAssessMyPeers from './ViewAssessMyPeers';
import './style.scss';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import schemaSearch from './schema-form';
import withUserInfo from 'common/hoc/withUserInfo';
import get from 'lodash.get';
import {
  isHieuTruong,
  isTeacher,
  isToTruong,
} from 'components/admin/user/utils';
import { submit } from 'redux-form';
import store from 'store';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import PeerAssessmentForTeacher from './peers-assement-for-teacher';
import { compose } from 'redux';
import { leaderPositions } from 'configs/constants/user';

const AssessMyPeers = ({
  userInfo,
  scholastic,
  isOutOfDeadlineAssessment,
  finalAggregateAssessment,
}) => {
  const academic_categories = get(userInfo, 'academic_categories[0]');

  const searchFormId = `peer_assessment_search_form_${finalAggregateAssessment}`;

  const handleReload = () => {
    store.dispatch(submit(searchFormId));
  };

  const renderResultsComponent = (
    items,
    { total },
    objects,
    paramsSearchForm,
  ) => {
    if (!items || !items.length) {
      return <SimpleNoResult text="Không tìm thấy giáo viên" />;
    }
    return (
      <div className="peer-list-wrap">
        <ViewAssessMyPeers
          peers={items}
          totalItems={total}
          paramsSearchForm={paramsSearchForm}
          searchFormId={searchFormId}
          handleReload={handleReload}
          showSendMessageButton={
            (isToTruong(userInfo) || isHieuTruong(userInfo)) &&
            !isOutOfDeadlineAssessment
          }
          viewOnly={isOutOfDeadlineAssessment}
          scholastic={scholastic}
          userInfo={userInfo}
          finalAggregateAssessment={finalAggregateAssessment}
        />
      </div>
    );
  };

  if (isTeacher(userInfo) && !isToTruong(userInfo)) {
    return <PeerAssessmentForTeacher isPeersAssess />;
  }

  const hiddenFields = {
    scholastic,
    final_aggregate_assessment: finalAggregateAssessment,
  };

  if (!finalAggregateAssessment && userInfo.phongban_id) {
    hiddenFields.phongban_id = userInfo.phongban_id;
    hiddenFields.leader_position = [leaderPositions.TEACHER];
  }

  return (
    <SearchWrapper
      key={`${searchFormId}_${academic_categories}_${scholastic}`}
      formid={searchFormId}
      finalAggregateAssessment={finalAggregateAssessment}
      renderResultsComponent={renderResultsComponent}
      schema={schemaSearch}
      userRoot={userInfo}
      showResult={true}
      alternativeApi={endpoints.listOfPeersAssignedToAssess}
      autoSearchWhenStart
      paginationProps={{
        theme: 'light',
      }}
      classPaginationWrapper="pagination-default"
      node={{
        academic_categories: isHieuTruong(userInfo)
          ? undefined
          : academic_categories,
      }}
      shouldDisabled={!isHieuTruong(userInfo)}
      hiddenFields={hiddenFields}
      defaultValues={{
        leader_position: [
          '',
          leaderPositions.VICE_LEADER,
          leaderPositions.TEACHER,
        ],
      }}
    />
  );
};

export default compose(
  withOutOfDeadlineAssessment,
  withUserInfo,
)(AssessMyPeers);
