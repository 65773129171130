import React from 'react';

const SearchFormLayout = ({ groups, submitButton }) => {
  return (
    <div className="search-form-account">
      <div className="flex-container-wrap">
        {!!groups.id.fieldNames.user_organizations && (
          <div className="col-md-12 flex-item p-l-10 p-r-10 m-t-5">
            {groups.id.fieldNames.user_organizations}
          </div>
        )}
        <div className="col-md-4 flex-item p-l-10 p-r-10 m-t-5">
          {groups.id.fieldNames.text}
        </div>
        {!!groups.id.fieldNames.phongban_id && (
          <div className="col-md-4 flex-item p-l-10 p-r-10 m-t-5">
            {groups.id.fieldNames.phongban_id}
          </div>
        )}
        {!!groups.id.fieldNames.leader_position && (
          <div className="col-md-4 flex-item p-l-10 p-r-10 m-t-5">
            {groups.id.fieldNames.leader_position}
          </div>
        )}
        {!!groups.id.fieldNames.sex && (
          <div className="col-md-4 flex-item p-l-10 p-r-10">
            {groups.id.fieldNames.sex}
          </div>
        )}
        {!!groups.id.fieldNames.temis_account_status && (
          <div className="col-md-4 flex-item p-l-10 p-r-10">
            {groups.id.fieldNames.temis_account_status}
          </div>
        )}
      </div>
      <div className="text-center">{submitButton}</div>
    </div>
  );
};

export default SearchFormLayout;
