import { required } from 'common/validators';
import { slugifier } from 'common/normalizers';
import { t1 } from 'translate';
import get from 'lodash.get';
import { schoolTypes } from 'configs/constants';

const schema = (formid, values, step, xpath, props, domainInfo) => {
  const fieldsNotAllowedToEdit = get(props, 'fieldsNotAllowedToEdit', []);

  return {
    original_code: {
      type: 'text',
      hintText: 'Mã tổ chuyên môn',
      floatingLabelText: `${t1('code')}(*)`,
      validate: [required(t1('code_cannot_be_empty'))],
      defaultValue: '',
      errorText: '',
      fullWidth: true,
      normalize: slugifier,
      readOnly: fieldsNotAllowedToEdit.includes('code'),
    },
    name: {
      type: 'text',
      hintText: 'Tên tổ chuyên môn',
      floatingLabelText: 'Tên tổ chuyên môn(*)',
      validate: [required(t1('name_cannot_be_empty'))],
      defaultValue: '',
      errorText: '',
      fullWidth: true,
      readOnly: fieldsNotAllowedToEdit.includes('name'),
    },
    short_name: {
      type: 'text',
      hintText: 'Tên rút gọn của tổ chuyên môn',
      floatingLabelText: t1('short_name'),
      fullWidth: true,
      readOnly: fieldsNotAllowedToEdit.includes('short_name'),
    },
    sub_type: {
      type: 'radio',
      options: values.orgTypes || [],
      defaultValue:
        Array.isArray(values.orgTypes) && values.orgTypes.length === 1
          ? values.orgTypes[0].value
          : null,
      inline: true,
      floatingLabelText: `${t1('organization_sub_types')}(*)`,
      validate: [required(t1('sub_type_cannot_be_empty'))],
      errorText: '',
      readOnly: fieldsNotAllowedToEdit.includes('sub_type'),
    },
  };
};
const ui = (step) => {
  const fields = [
    {
      id: 'default',
      fields: ['original_code', 'name', 'short_name', 'sub_type'],
    },
  ];
  const config = {
    new: fields,
    edit: fields,
  };
  return config[step];
};

const finalProcessBeforeSubmit = (fullData) => {
  fullData.code = `${fullData.p_code}__${fullData.original_code}`;

  return fullData;
};

export default { schema, ui, finalProcessBeforeSubmit };
