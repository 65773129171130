import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import sagaActions from 'actions/node/saga-creators';
import { submit } from 'redux-form';
import endPoints from 'components/temis/endpoints';
import { t1 } from 'translate';
import { timestampToDateString } from '../../../../common/utils/Date';

class ReportProgressSyncerButton extends Component {
  syncReportProgress = () => {
    const { dispatch, params, formId, formIds, apiSync } = this.props;

    const url = apiSync || endPoints.sync_report_progress;
    dispatch(
      sagaActions.getDataRequest(
        {
          url,
          executeOnSuccess: () => {
            if (formIds) {
              formIds.forEach((formId) => {
                dispatch(submit(formId));
              });
            } else {
              dispatch(submit(formId));
            }
          },
          showMessage: true,
        },
        params,
      ),
    );
  };

  render() {
    const { disabled } = this.props;

    if (disabled) {
      return null;
    }

    return (
      <div class="table-action-syn-content text-center">
        <button
          onClick={this.syncReportProgress}
          className="table-action-syn btn btn-secondary btn-outline"
          disabled={disabled}
        >
          Đồng bộ dữ liệu
        </button>
      </div>
    );
  }
}

export default connect()(ReportProgressSyncerButton);
