import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t1 } from 'translate';
import Link from 'components/common/router/Link';
import routes from 'routes';
import lodashGet from 'lodash.get';
import withFeatureFlags from 'feature-flag/withFeatureFlags';
import CommonAntdTable from 'components/common/antd/table';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { displayOrganizationTypeLabel } from 'utils/Util';
import OrganizationsOrPhongBan from 'components/admin/group/common/OrganizationsOrPhongBanInResultTable';
import ResultActions from './ResultActions';

class Results extends Component {
  render() {
    const {
      items,
      ntype,
      readOnly,
      orgTypes,
      organizations,
      searchTcmFormId,
    } = this.props;
    const actionsLabel = t1('actions');

    const columns = [
      {
        title: t1('code'),
        key: 'code',
        type: TYPE_OF_TABLE_DATA.TEXT,
        render: (item) => {
          const { original_code, code } = item;
          return (
            <React.Fragment>
              {original_code || code}
              <span className="text-muted">
                {item.org_id && `(${t1('org_id')}: #${item.org_id})`}
              </span>
            </React.Fragment>
          );
        },
      },
      {
        title: 'Tên tổ chuyên môn',
        key: 'name',
        dataIndex: 'name',
        type: TYPE_OF_TABLE_DATA.TEXT,
        render: (name) => <span>{name}</span>,
      },
      {
        title: 'Số lượng GVPT',
        className: 'text-center',
        render: (text, row) => lodashGet(row, '__expand.number_of_member', 0),
      },
      {
        title: actionsLabel,
        key: 'action',
        width: 150,
        type: TYPE_OF_TABLE_DATA.ACTION,
        render: (item) => (
          <ResultActions
            item={item}
            ntype={ntype}
            organizations={organizations}
            searchTcmFormId={searchTcmFormId}
          />
        ),
      },
    ];

    return (
      <CommonAntdTable
        columns={columns}
        dataSource={items}
        className="learn-default"
        pagination={false}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  orgTypes: lodashGet(state, 'domainInfo.school.org_types'),
});

export default connect(mapStateToProps)(withFeatureFlags()(Results));
