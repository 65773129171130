import React, { useState } from 'react';
import get from 'lodash.get';
import isEqual from 'lodash.isequal';
import { t1 } from 'translate';
import Loading from 'components/common/loading';
import HorizontalScrolling from 'components/common/html/horizontal-scrolling';
import CommonAntdTable from 'components/common/antd/table';
import JobStandardResult from '../../../components/job-standard-result';
import endPoints from '../../endpoints';
import { BECOME_MASTER_STATUS } from '../../../constant';
import sagaActions from 'actions/node/saga-creators';
import store from 'store';
import NodeNew from 'components/admin/node/new/index';
import {
  leaderPositionToText,
  qualificationOptions,
} from 'configs/constants/user';
import { getResultFromTheAssessment } from 'components/temis/utils';
import ReviewResult from '../../../components/review-result';
import AspirationMaster from 'components/temis/components/aspiration-master';

const getColumns = () => {
  return [
    {
      title: 'Thông tin giáo viên',
      key: 'name',
      render: (name, item) => get(item, 'name'),
    },
    {
      title: 'Bộ môn',
      key: 'subject',
      render: (subject, item) => {
        return get(item, '__expand.phongbans[0].name');
      },
    },
    {
      title: 'Cấp trên duyệt',
      width: 150,
      key: 'become_master_status',
      render: (status, item) => (
        <ReviewResult
          status={get(item, 'become_master_status')}
          item={item}
          hasAction={false}
        />
      ),
    },
    {
      title: 'Chức vụ',
      className: 'text-center',
      key: 'position',
      render: (position, item) => {
        return leaderPositionToText(get(item, 'leader_position'));
      },
    },
    {
      title: 'Trình độ',
      className: 'text-center',
      key: 'level',
      width: 100,
      render: (level, item) => {
        const qualification = qualificationOptions().find(
          ({ value }) => value === get(item, 'qualifications'),
        );
        return get(qualification, 'label');
      },
    },
    {
      title: 'Năm KN',
      className: 'text-center',
      key: 'experience',
      render: (experience, item) => get(item, 'teaching_exp_years'),
    },
    {
      title: 'TCNN',
      key: 'tcnn',
      className: 'text-center',
      render: (tcnn, item) => {
        const assessment =
          get(item, '__expand.temis.tcnn_gv') ||
          get(item, '__expand.temis.tcnn_ht');

        const result =
          get(assessment, 'status') === 'approved' &&
          getResultFromTheAssessment(assessment);

        if (!result) {
          return null;
        }

        return JobStandardResult(
          get(result, 'id', 0),
          get(result, 'name', 'Chưa đạt'),
        );
      },
    },
    {
      title: 'Nguyện vọng GVCC/CBQLCC',
      className: 'text-center',
      key: 'have_desire_to_become_master',
      width: 130,
      render: (item) => {
        return <AspirationMaster item={item} />;
      },
    },
  ];
};

const ViewChooseMyTeacherMaster = ({
  users = [],
  loadingStatus,
  searchFormId,
  scholastic,
  viewOnly,
}) => {
  const getListUsersSavedDraft = () => {
    const listUserDraftHaveToBecomeMaster = users.filter(
      (user) => user.become_master_status === BECOME_MASTER_STATUS.SAVED,
    );

    return listUserDraftHaveToBecomeMaster.map((item) => item.iid);
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState(
    getListUsersSavedDraft(),
  );

  React.useMemo(
    () => {
      const listUserIid = users.map((item) => item.iid);
      const updateSelectedRowKeys = selectedRowKeys.filter((item) =>
        listUserIid.includes(item),
      );

      if (isEqual(selectedRowKeys, updateSelectedRowKeys)) {
        return;
      }

      setSelectedRowKeys(updateSelectedRowKeys);
    },
    [users, selectedRowKeys, setSelectedRowKeys],
  );

  if (typeof loadingStatus !== 'undefined' && loadingStatus !== 'finished') {
    return <Loading />;
  }

  if (!Array.isArray(users) || !users.length) {
    return <div>{t1('there_are_no_rubric_to_assess')}</div>;
  }

  const iidInCurrentPage = users.map(({ iid = '' }) => iid);

  const handleSaveDraft = (selectedRowKeys) => {
    const data = {
      apiUrl: endPoints.chooseUsersToBecomeMaster,
      data: {
        iids: selectedRowKeys,
        become_master_status: BECOME_MASTER_STATUS.SAVED,
        scholastic,
      },
      searchFormId,
    };

    store.dispatch(sagaActions.upsertNodeRequest(data));
  };

  const hasUserNotPass = () => {
    if (!selectedRowKeys.length) {
      return true;
    }

    const notPassUsers = users.filter(
      (item) =>
        !item.prospective_become_master ||
        item.become_master_status === BECOME_MASTER_STATUS.APPROVED,
    );
    const notPassUserIds = notPassUsers.map((item) => item.iid);

    return selectedRowKeys.find((item) => notPassUserIds.includes(item));
  };

  let buttonText = 'Chọn';
  if (!hasUserNotPass() && selectedRowKeys.length) {
    buttonText = `${buttonText} ${
      selectedRowKeys.length
    } người trở thành GVCC và gửi đi`;
  }

  return (
    <div className="table-action">
      <HorizontalScrolling>
        <CommonAntdTable
          columns={getColumns()}
          dataSource={users}
          pagination={false}
          bordered
          size="middle"
          className="learn-default"
          rowKey="iid"
          rowSelection={{
            selectedRowKeys,
            hideDefaultSelections: true,
            onChange: setSelectedRowKeys,
            selections: [
              {
                key: 'select_current_page',
                text: t1('select_data_in_current_page'),
                onSelect: () => {
                  const currentSelectedRowKeys = Array.isArray(selectedRowKeys)
                    ? selectedRowKeys
                    : [];
                  setSelectedRowKeys(
                    currentSelectedRowKeys.concat(
                      iidInCurrentPage
                        .map(
                          (iid) => !currentSelectedRowKeys.includes(iid) && iid,
                        )
                        .filter(Boolean),
                    ),
                  );
                },
              },
              {
                key: 'invert_current_page',
                text: t1('invert_data_in_current_page'),
                onSelect: () => {
                  setSelectedRowKeys(
                    (Array.isArray(iidInCurrentPage)
                      ? iidInCurrentPage
                      : []
                    ).filter((iid) => !selectedRowKeys.includes(iid)),
                  );
                },
              },
              Array.isArray(selectedRowKeys) &&
                !!selectedRowKeys.length &&
                !selectedRowKeys.every((id) =>
                  iidInCurrentPage.includes(id),
                ) && {
                  key: 'remove_all',
                  text: t1('remove_all_data_selected'),
                  onSelect: () => {
                    setSelectedRowKeys([]);
                  },
                },
            ].filter(Boolean),
          }}
        />
      </HorizontalScrolling>

      <NodeNew
        dialogKey={'choose-teacher-master'}
        schema={{}}
        formid="choose-teacher-master"
        searchFormId={searchFormId}
        hiddenFields={{
          iids: selectedRowKeys,
          become_master_status: BECOME_MASTER_STATUS.SENT,
          scholastic,
        }}
        alternativeApi={endPoints.chooseUsersToBecomeMaster}
        submitButton={() => {
          return (
            <div className="buttons-assessment d-flex">
              <button
                name="saveDraft"
                type="button"
                className="btn btn-secondary btn-outline m-r-20 m-t-10"
                disabled={hasUserNotPass() || viewOnly}
                onClick={() => {
                  handleSaveDraft(selectedRowKeys);
                }}
              >
                {t1('Lưu và chưa gửi đi')}
              </button>

              <button
                name="submit"
                type="submit"
                className="btn btn-secondary m-t-10"
                disabled={hasUserNotPass() || viewOnly}
              >
                {buttonText}
              </button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default ViewChooseMyTeacherMaster;
