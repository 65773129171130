import React, { Fragment, useState } from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import Loading from 'components/common/loading';
import {
  timestampToDateString,
  timestampToDateTimeString,
} from 'common/utils/Date';
import {
  getResultFromTheAssessment,
  isApprovedAssessment,
} from 'components/temis/utils';
import CommonAntdTable from 'components/common/antd/table';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Communicate from 'components/temis/components/temis-communication';
import PeerResult from 'components/temis/components/peer-result';
import SimpleStatusSendAssessment from '../../components/simple-status-send-assessment';
import './style.scss';
import ExportData from 'components/common/export-data';
import apiUrls from 'components/temis/endpoints';
import ViewAssessMyPeersContent from './components/ViewAssessMyPeersContent';
import { isHieuTruong } from 'components/admin/user/utils';
import { EXPORT_FILENAME } from '../../constant';

const dialogKey = 'assessment';

const getColumns = ({
  searchFormId,
  handleReload,
  viewOnly,
  scholastic,
  userInfo,
  finalAggregateAssessment,
}) => {
  return [
    {
      title: 'Thông tin giáo viên',
      width: 180,
      key: 'name',
      render: (name, item) => get(item, 'name'),
    },
    {
      title: t1('birthday'),
      width: 130,
      key: 'birthday',
      render: (birthday, item) => {
        return (
          <div>
            {timestampToDateString(get(item, 'birthday'), {
              showTime: false,
              splitter: '/',
            })}
          </div>
        );
      },
    },
    {
      title: t1('mail'),
      key: 'mail',
      width: 270,
      render: (mail, item) => get(item, 'mail'),
    },
    isHieuTruong(userInfo) && {
      title: 'Tổ chuyên môn',
      width: 270,
      render: (mail, item) => {
        return get(item, '__expand.phongbans[0].name');
      },
    },
    {
      title: 'Đánh giá CNN',
      width: 300,
      className: 'text-center',
      key: 'assessment_job_standard',
      render: (item) => {
        const resultFromPeer = item.resultFromPeer;
        const isApproved = isApprovedAssessment(item.status);
        const hasSelfAssessment =
          item.selfAssessment !== null &&
          isApprovedAssessment(item.selfAssessmentStatus);
        const hasPeerAssessment =
          item.peerAssessment !== null &&
          isApprovedAssessment(item.peerAssessmentStatus);
        const userForAssessment = item.userForAssessment;
        const phongBanOfUser = get(item, 'phongban_id');

        return (
          <Fragment>
            <DetailOnDialog
              renderPreview={({ showFull }) => {
                return (
                  <PeerResult
                    result={resultFromPeer}
                    date={item.date}
                    showPopup={showFull}
                    isApproved={isApproved}
                    viewOnly={viewOnly}
                    hasSelfAssessment={hasSelfAssessment}
                    hasPeerAssessment={hasPeerAssessment}
                    userInfo={userInfo}
                    userForAssessment={userForAssessment}
                    phongBanOfUser={phongBanOfUser}
                    finalAggregateAssessment={finalAggregateAssessment}
                  />
                );
              }}
              renderFull={({ closeDialog }) => (
                <ViewAssessMyPeersContent
                  assessorIid={item.peer_iid}
                  dialogKey={`self_${dialogKey}`}
                  searchFormId={searchFormId}
                  closeDialog={closeDialog}
                  handleReload={handleReload}
                  viewOnly={viewOnly}
                  scholastic={scholastic}
                  userForAssessment={item}
                  currentStatusOfParentToDoAssessment={item.status}
                  finalAggregateAssessment={finalAggregateAssessment}
                />
              )}
              dialogKey={dialogKey}
              dialogOptionsProperties={{
                width: '100%',
                autoDetectWindowHeight: false,
                className: 'modal-full-height',
              }}
            />
          </Fragment>
        );
      },
    },
  ].filter(Boolean);
};

const getDataSource = (peers) => {
  return peers.map((item) => ({
    ...item.target,
    result: item.result,
    peer_id: item.peer_id,
    peer_iid: item.peer_iid,
    rubric_iid: item.rubric_iid,
    scale: item.scale,
    score_scale: item.score_scale,
    task: item.task,
    finalAggregateAssessment: get(item, 'final_aggregate_assessment'),
    resultFromPeer: getResultFromTheAssessment(item),
    date: item.updated_ts ? timestampToDateTimeString(item.updated_ts) : '',
    status: get(item, 'status', ''),
    selfAssessment: get(item, 'target.__expand.temis.tudanhgia', null),
    selfAssessmentStatus: get(
      item,
      'target.__expand.temis.tudanhgia.status',
      null,
    ),
    peerAssessment: get(item, 'target.__expand.temis.peer_assessment', null),
    peerAssessmentStatus: get(
      item,
      'target.__expand.temis.peer_assessment.status',
      null,
    ),
    userForAssessment: get(item, 'target'),
  }));
};

const ViewAssessMyPeers = ({
  peers,
  loadingStatus,
  totalItems,
  searchFormId,
  paramsSearchForm,
  handleReload = () => {},
  showSendMessageButton,
  viewOnly,
  scholastic,
  userInfo,
  finalAggregateAssessment,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  if (typeof loadingStatus !== 'undefined' && loadingStatus !== 'finished') {
    return <Loading />;
  }

  if (!Array.isArray(peers) || !peers.length) {
    return <div>{t1('there_are_no_rubric_to_assess')}</div>;
  }

  const dataSource = getDataSource(peers);
  const iidInCurrentPage = dataSource.map(({ iid = '' }) => iid);

  let buttonText = 'Gửi cảnh báo';
  if (selectedRowKeys.length) {
    buttonText = `${buttonText} đến ${
      selectedRowKeys.length
    } giáo viên đã chọn`;
  }

  return (
    <div className="table-action">
      <div className="d-flex justify-content-end align-items-center m-b-20 wrap-table-header">
        <SimpleStatusSendAssessment className="m-r-25 status" />
        {!(isHieuTruong(userInfo) && finalAggregateAssessment) && (
          <ExportData
            apiExport={apiUrls.export_assessment_result_of_peers_in_phongban}
            buttonText="Xuất excel BM3/PL2"
            formId="search-score-by-rubric"
            className="m-b-15"
            params={paramsSearchForm}
            fileName={EXPORT_FILENAME.teacher.BM3_PL2}
          />
        )}
      </div>
      <CommonAntdTable
        columns={getColumns({
          searchFormId,
          handleReload,
          scholastic,
          viewOnly,
          userInfo,
          finalAggregateAssessment,
        })}
        dataSource={dataSource}
        pagination={false}
        bordered
        size="middle"
        className="learn-default"
        rowKey="iid"
        rowSelection={
          showSendMessageButton && {
            selectedRowKeys,
            hideDefaultSelections: true,
            onChange: setSelectedRowKeys,
            selections: [
              {
                key: 'select_current_page',
                text: t1('select_data_in_current_page'),
                onSelect: () => {
                  const currentSelectedRowKeys = Array.isArray(selectedRowKeys)
                    ? selectedRowKeys
                    : [];
                  setSelectedRowKeys(
                    currentSelectedRowKeys.concat(
                      iidInCurrentPage
                        .map(
                          (iid) => !currentSelectedRowKeys.includes(iid) && iid,
                        )
                        .filter(Boolean),
                    ),
                  );
                },
              },
              {
                key: 'invert_current_page',
                text: t1('invert_data_in_current_page'),
                onSelect: () => {
                  setSelectedRowKeys(
                    (Array.isArray(iidInCurrentPage)
                      ? iidInCurrentPage
                      : []
                    ).filter((iid) => !selectedRowKeys.includes(iid)),
                  );
                },
              },
              Array.isArray(selectedRowKeys) &&
                !!selectedRowKeys.length &&
                !selectedRowKeys.every((id) =>
                  iidInCurrentPage.includes(id),
                ) && {
                  key: 'remove_all',
                  text: t1('remove_all_data_selected'),
                  onSelect: () => {
                    setSelectedRowKeys([]);
                  },
                },
            ].filter(Boolean),
          }
        }
      />
      {showSendMessageButton && (
        <Communicate
          renderPreview={({ showFull }) => (
            <button
              className="btn btn-secondary m-t-10"
              disabled={!totalItems && !selectedRowKeys.length}
              onClick={showFull}
            >
              {buttonText}
            </button>
          )}
          optionsProperties={{
            title: 'Gửi thông báo cảnh báo đến giáo viên',
            className: 'send-message-alert',
          }}
          objectTitle={'Giáo Viên'}
          totalWhenSelectAll={totalItems}
          paramsFilterWhenSelectAll={paramsSearchForm}
          iidSelected={selectedRowKeys}
        />
      )}
    </div>
  );
};

export default ViewAssessMyPeers;
