import {
  caphoc,
  simpleOrganization,
  targetUser,
  trainingModel,
} from 'components/common/elements';
import subTypes from 'configs/constants/org-sub-types';
import { isBo, isVGDStaff } from 'components/admin/user/utils';
import { userGrades } from 'configs/constants/user';

const schema = () => {
  return {
    organization: simpleOrganization(
      {
        floatingLabelText: 'SGD',
        classWrapper: 'col-md-12',
        populateValue: true,
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_SO_GD],
        },
      },
      true,
    ),
    target_user: targetUser({
      type: 'radio',
      classWrapper: 'col-md-3',
    }),
    training_model: trainingModel({
      classWrapper: 'col-md-3',
    }),
    cap: caphoc({
      type: 'radio',
      classWrapper: 'col-md-6',
      defaultValue: userGrades.NURSERY,
    }),
    deployment_status: {
      type: 'multiCheckbox',
      floatingLabelText: 'Trạng thái triển khai',
      options: [
        {
          value: 'deployed',
          label: 'Đã triển khai',
        },
        {
          value: 'not-deploy',
          label: 'Chưa triển khai',
        },
      ],
      defaultValue: ['deployed', 'not-deploy'],
      classWrapper: 'col-md-6',
      inline: true,
    },
  };
};

const ui = (step, values, themeConfig, xpath, formid, { userInfo }) => {
  return [
    {
      id: 'default',
      fields: [
        ...(isBo(userInfo) || isVGDStaff(userInfo) ? ['organization'] : []),
        'target_user',
        'training_model',
        'cap',
        // 'deployment_status',
      ],
    },
  ];
};

export default { schema, ui };
