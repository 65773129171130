import React, { Component } from 'react';
import './stylesheet.scss';

class StatusAssessment extends Component {
  render() {
    const { isApprovedStatus } = this.props;

    return (
      <div className="justify-content-center status-assessment m-b-15">
        {isApprovedStatus && (
          <div className="sent-assessment-icon m-l-10 m-r-10">
            <span className="ve-check-circle m-r-10" />
            <span>{'Đã gửi đánh giá'}</span>
          </div>
        )}

        {!isApprovedStatus && (
          <div className="edit-assessment-icon m-l-10 m-r-10">
            <span className="ve-edit-alt m-r-10" />
            <span>{'Chưa gửi đánh giá'}</span>
          </div>
        )}
      </div>
    );
  }
}

export default StatusAssessment;
