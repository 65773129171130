import React from 'react';
import get from 'lodash.get';
import { Link } from 'react-router-dom';
import Tag from 'antd/lib/tag';
import { t1 } from 'translate';
import CommonAntdTable from 'components/common/antd/table';
import { parseTSToDateTimeString } from 'common/utils/Date';
import { getTargetUser, userGradeToText } from 'configs/constants/user';
import { getTrainingModelText } from 'components/taphuan/configs';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import DetailOnDialog from 'components/common/detail-on-dialog';
import Icon from 'components/common/Icon';
import NewTrainingPhase from 'components/bdtx/training-phase/mainstage/new';
import DeleteItem from 'components/common/action-button/DeleteBtnWithConfirmDialog';
import endpoints from 'components/bdtx/training-phase/endpoints';
import {
  getUrlByTrainingPhaseIid,
  MAIN_STAGE_VIEW_TYPE,
} from 'components/bdtx/training-phase/mainstage/routes';
import ConfirmDelete from 'components/bdtx/training-phase/search/configm-delete';

const TableResult = ({ trainingPhases = [], searchFormId }) => {
  const renderTableColumns = () => {
    return [
      {
        title: 'Đợt triển khai',
        render: (item) => (
          <Link
            to={getUrlByTrainingPhaseIid(
              item.iid,
              MAIN_STAGE_VIEW_TYPE.DASHBOARD,
            )}
          >
            {item.name}
          </Link>
        ),
      },
      {
        title: 'Thời hạn',
        render: (item) => {
          const startDate = get(item, 'start_date');
          const endDate = get(item, 'end_date');

          return (
            <>
              <div>Từ ngày: {parseTSToDateTimeString(startDate)}</div>
              <div>Đến ngày: {parseTSToDateTimeString(endDate)}</div>
            </>
          );
        },
      },
      {
        title: 'Đối tượng',
        dataIndex: 'target_user',
        render: (targetUser) => <Tag>{getTargetUser(targetUser)}</Tag>,
      },
      {
        title: 'Cấp',
        dataIndex: 'caps',
        render: (caps) =>
          Array.isArray(caps) &&
          caps.map((cap, i) => (
            <Tag className={'m-5'} key={`cap-${i}`}>
              {userGradeToText(cap)}
            </Tag>
          )),
      },
      {
        title: 'Mô hình triển khai',
        type: TYPE_OF_TABLE_DATA.TITLE,
        dataIndex: 'training_model',
        render: (model) => <span>{getTrainingModelText(model)}</span>,
      },
      {
        title: 'Nguồn tạo',
        type: TYPE_OF_TABLE_DATA.TITLE,
        width: 100,
        dataIndex: 'from_etep',
        render: (fromEtep) => {
          if (fromEtep) {
            return <span className="text-primary">Kết chuyển từ ETEP</span>;
          }

          return <span>BDTX</span>;
        },
      },
      {
        title: 'Tình trạng triển khai',
        type: TYPE_OF_TABLE_DATA.TITLE,
        render: (item) => {
          const fromEtep = get(item, 'from_etep');
          const status = get(item, 'status');
          const deployed = get(status, 'deployed');

          if (!deployed) {
            return (
              <>
                <div className="text-danger m-b-5">Chưa triển khai</div>
                {!fromEtep && (
                  <Link
                    className="btn btn-secondary btn-small"
                    to={getUrlByTrainingPhaseIid(
                      item.iid,
                      MAIN_STAGE_VIEW_TYPE.ASSIGN,
                    )}
                  >
                    Triển khai
                  </Link>
                )}
              </>
            );
          }

          return (
            <>
              <span className="text-primary m-b-5">Đã triển khai</span>
              <div className="d-flex justify-content-center m-t-5">
                <Link
                  className="btn btn-primary btn-small m-r-5"
                  to={getUrlByTrainingPhaseIid(
                    item.iid,
                    MAIN_STAGE_VIEW_TYPE.REPORT,
                  )}
                >
                  Xem thông tin
                </Link>

                {!fromEtep && (
                  <Link
                    className="btn btn-secondary btn-small"
                    to={getUrlByTrainingPhaseIid(
                      item.iid,
                      MAIN_STAGE_VIEW_TYPE.ASSIGN,
                    )}
                  >
                    Triển khai thêm
                  </Link>
                )}
              </div>
            </>
          );
        },
      },
      {
        title: 'Thao tác',
        type: TYPE_OF_TABLE_DATA.TITLE,
        render: (item) => {
          const name = get(item, 'name');
          const fromEtep = get(item, 'from_etep');
          const status = get(item, 'status');
          const deployed = get(status, 'deployed');

          if (fromEtep) {
            return null;
          }

          return (
            <div className="d-flex align-items-center justify-content-center">
              <DetailOnDialog
                renderPreview={({ showFull }) => (
                  <span
                    title={`Cập nhập cho đợt ${name}`}
                    onClick={showFull}
                    className="d-flex m-r-10 p-0 cursor-pointer"
                  >
                    <Icon icon="edit" />
                  </span>
                )}
                renderFull={() => (
                  <NewTrainingPhase
                    mode="edit"
                    node={item}
                    searchFormId={searchFormId}
                    alternativeApi={endpoints.bdtx_update_training_phase}
                    formid="edit-training-phase"
                    deployed={deployed}
                  />
                )}
                dialogOptionsProperties={{
                  modal: true,
                  handleClose: true,
                  title: `Cập nhập cho đợt ${name}`,
                }}
              />

              {deployed ? (
                <ConfirmDelete
                  trainingPhase={item}
                  searchFormId={searchFormId}
                />
              ) : (
                <DeleteItem
                  title={t1(`Xóa đợt triển khai ${name}`)}
                  textConfirm={`Bạn có chắc chắn muốn xóa bỏ đợt triển khai ${name}?`}
                  formid={searchFormId}
                  ntype={'training-phase'}
                  itemId={item.id}
                  iconButton
                  alternativeApi={endpoints.bdtx_delete_training_phase}
                  className="m-l-20"
                />
              )}
            </div>
          );
        },
      },
    ];
  };

  return (
    <CommonAntdTable
      dataSource={trainingPhases}
      columns={renderTableColumns()}
      pagination={false}
      className="learn-default"
    />
  );
};

export default TableResult;
