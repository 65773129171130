import React from 'react';
import get from 'lodash.get';
import moment from 'moment';
import Store from 'store';
import {
  ASSESSMENT_STATUS,
  MANAGER_ASSESSMENT_GROUP_ROUTER,
  SUMMARY_ASSESSMENT_RESULT_ROUTER_GROUP_ROUTER,
} from './constant';
import {
  isAdminTruong,
  isAmTinh,
  isBo,
  isHieuTruongOrPho,
  isLTTUStaff,
  isPhong,
  isSo,
  isSoOrPhong,
  isTeacher,
} from 'components/admin/user/utils';
import { nonAccentVietnamese } from 'common/utils/string/vn';
import {
  breadCrumb,
  getValueOfBooleanString,
  slugify,
} from 'common/utils/string';

export const getResultFromTheAssessment = (assessment) => {
  const scoreScale = get(assessment, 'score_scale');
  let tasks = get(assessment, 'task');
  tasks = Array.isArray(tasks) ? tasks : [];

  if (!tasks.length || !Array.isArray(scoreScale) || !scoreScale.length) {
    return null;
  }

  const final = String(get(assessment, 'result.final'));
  return scoreScale.find(({ id }) => String(id) == final);
};

export const generateClassByStatusAssessment = (status) => {
  const standardsStatus = nonAccentVietnamese(status);

  return standardsStatus.replace(/\s/g, '');
};

export const generateStatusResultAssessment = (statusEnum) => {
  if (statusEnum === undefined) {
    return;
  }
  switch (statusEnum) {
    case 0:
      return 'chưa đạt';
    case 1:
      return 'đạt';
    case 2:
      return 'khá';
    case 3:
      return 'tốt';
    default:
      return '';
  }
};

export const isApprovedAssessment = (status) => {
  return status === ASSESSMENT_STATUS.APPROVED;
};

export const getMenuTab = (userInfo, type) => {
  switch (type) {
    case 'manager-assessment':
      return isPhong(userInfo) || isSo(userInfo)
        ? [
            {
              link: MANAGER_ASSESSMENT_GROUP_ROUTER.school_in_organization,
              label: 'Các trường trong huyện/tỉnh',
            },
            {
              link: MANAGER_ASSESSMENT_GROUP_ROUTER.principal_assessment,
              label: 'Đánh giá hiệu trưởng',
            },
            // {
            //   link: MANAGER_ASSESSMENT_GROUP_ROUTER.choose_manager,
            //   label: 'Lựa chọn CBQLCC',
            // },
            // {
            //   link: MANAGER_ASSESSMENT_GROUP_ROUTER.approve_teacher,
            //   label: 'Duyệt GVCC',
            // },
          ]
        : [];
    case 'assessment-result':
      return [
        (isHieuTruongOrPho(userInfo) || isAdminTruong(userInfo)) && {
          link: SUMMARY_ASSESSMENT_RESULT_ROUTER_GROUP_ROUTER.school,
          label: 'Toàn trường',
        },
        // isHieuTruong(userInfo) && {
        //   link: SUMMARY_ASSESSMENT_RESULT_ROUTER_GROUP_ROUTER.choose_teacher,
        //   label: 'Lựa chọn GVCC',
        // },
      ].filter(Boolean);

    default:
      return [];
  }
};

const TC5Iid = '172548';
export const getAllTCInTC2 = (rubrics) => {
  if (!rubrics || !rubrics.length) {
    return;
  }

  const rubricTC5 = rubrics.find((rubric) => rubric.iid === TC5Iid);

  if (!rubricTC5) {
    return;
  }

  return rubricTC5.children;
};

const answerIsGood = 3;
const answerIsRather = 2;
const answerIsQualified = 1;

export const isGoodAssessment = (tc) => Number(tc.answer) === answerIsGood;
export const isRatherFinalAssessment = (final) =>
  Number(final) >= answerIsRather;
export const isFromQualifiedAssessment = (tc) =>
  Number(tc.answer) >= answerIsQualified;

const mainDay = 15;
const mainMonth = 7;
export const getDefaultStartDate = () => {
  const currentDate = moment().format('X');
  const mainDate = moment()
    .set('date', mainDay)
    .set('month', mainMonth)
    .format('X');

  let year = moment().get('year');

  if (currentDate < mainDate) {
    year = year - 1;
  }

  return moment()
    .set('date', mainDay)
    .set('month', mainMonth)
    .set('year', year)
    .format('X');
};

export const getDefaultEndDateBasedOnStartDate = (startDate) => {
  return moment(startDate * 1000)
    .add(1, 'year')
    .subtract(1, 'days')
    .format('X');
};

export const temisSystemText = 'Hệ thống quản lý thông tin GVPT và CBQLCSGDPT';

export const isEnableTemis = () => {
  return !!get(window, 'TEMIS_CONFIG.ENABLED');
};

export const getTemisCopyright = () => {
  return `© Copyright ${new Date().getFullYear()} - ${temisSystemText} - Bộ GDĐT-WB | Đơn vị đồng hành: Viettel`;
};

export const doesUserNeedToFillTemisNow = (conf, userInfo) => {
  const isGuest = !get(userInfo, 'id');
  const isUserMustEnterProfile = get(
    conf,
    'user_must_enter_temis_profile_info',
  );

  if (
    0 &&
    !isGuest &&
    !!isUserMustEnterProfile &&
    isTeacher(userInfo) &&
    !get(userInfo, 'phongban_id')
  ) {
    return true;
  }

  let bool =
    !!isUserMustEnterProfile &&
    !isLTTUStaff(userInfo) &&
    !isGuest &&
    !get(userInfo, 'can_skip_profile') &&
    (!getValueOfBooleanString(get(userInfo, 'have_enter_temis_profile_info')) ||
      getValueOfBooleanString(get(userInfo, 'force_re_enter_profile_info')));

  const isFinishTemisForm = JSON.parse(
    localStorage.getItem('isFinishTemisForm'),
  );

  return bool && !isFinishTemisForm;
};

export const csdlnMoetURL = 'https://truong.csdl.moet.gov.vn';

export const generateReportTitle = (title = '', maxLength = 180) => {
  if (!title) {
    return '';
  }

  const titleToSlug = slugify(title);
  if (titleToSlug.length <= maxLength) {
    return titleToSlug;
  }

  return breadCrumb(titleToSlug, maxLength);
};

export const shouldShowViewReportMode = (user) =>
  isBo(user) || isSoOrPhong(user) || isLTTUStaff(user) || isAmTinh(user);

export const isIVAUser = (userInfo) => {
  const user = userInfo || get(Store.getState(), 'user.info');

  const userCode = get(user, 'code');

  return (window.LIST_IVA_CODE || []).includes(userCode);
};
