// json schema fields
import { required } from 'common/validators';
import { t1 } from 'translate';
import { convertBooleanValueToInt } from 'common/normalizers';
import RTE from 'schema-form/elements/richtext';
import Attachments from '../../../schema-form/elements/attachments';
import { allowedFileTypes } from 'common/utils/File';

export const input = (labelText, isRequired, props = {}) => ({
  type: 'text',
  hintText: labelText,
  floatingLabelText: labelText,
  defaultValue: '',
  validate: isRequired ? [required()] : null,
  ...props,
});

export const number = (labelText, isRequired, options) => {
  return {
    type: 'number',
    hintText: labelText,
    floatingLabelText: labelText,
    defaultValue: '',
    validate: isRequired ? [required()] : null,
    ...options,
  };
};

export const avatar = () => ({
  type: 'text',
  hintText: t1('avatar'),
});

export const shareable = () => ({
  type: 'checkbox',
  label: t1('also_share_this_content_with_other_organizations'),
  defaultValue: 0,
});

export const checkbox = (labelText, options = {}) => ({
  type: 'checkbox',
  label: labelText,
  normalize: convertBooleanValueToInt,
  fullWidth: !!options.fullWidth,
  readOnly: options.readOnly,
  defaultValue: options.defaultValue,
  onChange: options.onChange,
});

export const rte = (label) => ({
  type: RTE,
  hintText: label,
  floatingLabelText: label,
  defaultValue: '',
  errorText: '',
});

export const attachment = (
  multiple = false,
  fileTypes = '*',
  previewer = null,
) => ({
  type: Attachments,
  keepAtLeastOneFile: true,
  allowDownload: true,
  multiple,
  accept: allowedFileTypes(fileTypes),
  AttachmentPreviewer: previewer,
});

export const textarea = (label) => ({
  type: 'text',
  multiLine: true,
  floatingLabelText: label,
  defaultValue: '',
  fullWidth: true,
  errorText: '',
});

// used in search form
export const statusMultiCheckbox = (
  options = ['approved', 'queued'],
  defaultValue = ['approved', 'queued'],
) => {
  return {
    type: 'multiCheckbox',
    inline: true,
    floatingLabelText: t1('status'),
    defaultValue: defaultValue,
    options: options.map((opt) => ({
      value: opt,
      label: t1(opt),
    })),
  };
};

export const selectElement = (label, options, defaultValue) => {
  return {
    type: 'select',
    floatingLabelText: label,
    floatingLabelFixed: true,
    options: options,
    fullWidth: true,
    defaultValue,
  };
};

export const multiCheckboxElement = (label, options, defaultValue) => {
  return {
    type: 'select',
    multiple: true,
    floatingLabelText: label,
    floatingLabelFixed: true,
    options: options,
    fullWidth: true,
    defaultValue,
  };
};

export const radioElement = (label, options, props = {}) => {
  return {
    type: 'radio',
    floatingLabelText: label,
    floatingLabelFixed: true,
    options: options,
    fullWidth: true,
    ...props,
  };
};
