import React, { Component } from 'react';
import withUserInfo from 'common/hoc/withUserInfo';
import fetchData from 'components/common/fetchData';
import get from 'lodash.get';
import endPoints from './assessment/endpoints';
import PieChart from 'components/common/charts/pie/CustomActiveShapePieChart';
import Card from 'antd/lib/card';
import {
  selfAssessment,
  temisAssessments,
  temisEvidence,
} from 'components/temis/routes';
import {
  ASSESS_ASSESSMENT_GROUP_ROUTER,
  MANAGER_ASSESSMENT_GROUP_ROUTER,
} from 'components/temis/constant';
import { isSmallScreen } from 'common';
import {
  isHieuPho,
  isHieuTruong,
  isHieuTruongOrPho,
  isPhong,
  isSo,
  isToTruong,
  isTeacher,
  isAmTinh,
} from 'components/admin/user/utils';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import { Link } from 'react-router-dom';

const listMissionOfDashboard = [
  {
    title: 'Nhiệm vụ của giáo viên',
    content: [
      'Hoàn thành việc tự đánh giá theo Chuẩn nghề nghiệp giáo viên cơ sở giáo dục phổ thông.',
      'Tải các kết quả tự đánh giá cùng các minh chứng lên hệ thống CNTT.',
    ],
  },
  {
    title: 'Nhiệm vụ của tổ trưởng chuyên môn',
    content: [
      'Hoàn thành việc tự đánh giá của cá nhân theo Chuẩn nghề nghiệp giáo viên cơ sở giáo dục phổ thông.',
      'Tải các kết quả tự đánh giá của bản thân cùng các minh chứng lên hệ thống CNTT.',
      'Tổ chức giáo viên trong tổ đánh giá lẫn nhau.',
      'Tải các kết quả tổng hợp đánh giá của đồng nghiệp đối với từng GV trong tổ lên hệ thống CNTT.',
    ],
  },
  {
    title: 'Nhiệm vụ của Hiệu phó cơ sở giáo dục phổ thông',
    content: [
      'Hoàn thành tự đánh giá của cá nhân theo Chuẩn hiệu phó cơ sở giáo dục phổ thông.',
      'Tải kết quả tự đánh giá của bản thân cùng các minh chứng lên hệ thống CNTT.',
    ],
  },
  {
    title: 'Nhiệm vụ của Hiệu trưởng cơ sở giáo dục phổ thông',
    content: [
      'Hoàn thành tự đánh giá của cá nhân theo Chuẩn hiệu trưởng cơ sở giáo dục phổ thông.',
      'Tải kết quả tự đánh giá của bản thân cùng các minh chứng lên hệ thống CNTT.',
      'Phân công cán bộ thực hiện lấy ý kiến giáo viên, nhân viên trong trường đối với hiệu trưởng và phó hiệu trưởng và tải kết quả tổng hợp và minh chứng kèm theo lên hệ thống CNTT.',
      'Thực hiện đánh giá và phân công cán bộ tải kết quả đánh giá, xếp loại GV, phó hiệu trưởng (hai năm một lần) lên hệ thống CNTT.',
    ],
  },
  {
    title: 'Nhiệm vụ của Trưởng phòng giáo dục cấp huyện',
    content: [
      'Chủ trì đánh giá hiệu trưởng trường tiểu học, trường trung học cơ sở, trường phổ thông có nhiều cấp học có cấp cao nhất là trung học cơ sở, trường phổ thông dân tộc bán trú, trường phổ thông dân tộc nội trú cấp huyện.',
      'Phân công cán bộ tải kết quả đánh giá của các hiệu trưởng trên địa bàn quản lý lên hệ thống CNTT.',
    ],
  },
  {
    title: 'Nhiệm vụ của Giám đốc sở giáo dục và đào tạo',
    content: [
      'Giám đốc sở giáo dục và đào tạo chủ trì đánh giá hiệu trưởng trường trung học phổ thông, trường phổ thông có nhiều cấp học có cấp cao nhất là trung học phổ thông, trường chuyên, trường phổ thông dân tộc nội trú cấp tỉnh.',
      'Giám đốc sở giáo dục và đào tạo phân công cán bộ tải kết quả đánh giá hiệu trưởng trên địa bàn quản lý lên hệ thống CNTT.',
    ],
  },
];

const LIST_DASHBOARD_ITEM_OBJECT = {
  finish_my_self_assessment: {
    text: 'Tự đánh giá',
    iconClass: 've-kh-hoanthanh',
    link: selfAssessment,
  },
  my_evidence: {
    text: 'Tài liệu minh chứng',
    iconClass: 've-kh-thamkhao',
    link: temisEvidence,
  },
  peers_assessment: {
    text: 'Đánh giá của đồng nghiệp',
    iconClass: 've-danhgia',
    link: ASSESS_ASSESSMENT_GROUP_ROUTER.peers,
  },
  can_bo_cot_can: {
    text: 'CBQLCC',
    iconClass: 've-quanly',
    link: MANAGER_ASSESSMENT_GROUP_ROUTER.choose_manager,
  },
  giao_vien_cot_can: {
    text: 'GVCC',
    iconClass: 've-quanly',
    link: MANAGER_ASSESSMENT_GROUP_ROUTER.approve_teacher,
  },
};

class TemisDashboardFrontend extends Component {
  generateCards = () => {
    const { overviewData = {} } = this.props;
    const { cards = {} } = overviewData;
    const keys = Object.keys(cards);
    if (!keys.length) {
      return [];
    }

    return keys
      .map((key) => {
        const itemInListDashboard = LIST_DASHBOARD_ITEM_OBJECT[key];
        if (!itemInListDashboard) {
          return;
        }

        const currentItem = cards[key];
        let number;
        if (typeof currentItem === 'object') {
          number = `${get(currentItem, 'finish')}/${get(currentItem, 'total')}`;
        } else {
          number = String(currentItem);
        }

        return {
          ...itemInListDashboard,
          number,
        };
      })
      .filter(Boolean);
  };

  getChartData = () => {
    const { overviewData = {} } = this.props;
    const { charts } = overviewData;

    if (!Array.isArray(charts) || !charts.length) {
      return null;
    }
    return charts.map((item) => ({ ...item, value: item.count }));
  };

  getChartDescription = () => {
    const { userInfo } = this.props;

    let title = 'Giáo viên tự đánh giá.';
    if (isHieuTruongOrPho(userInfo)) {
      title = 'Giáo viên trong trường tự đánh giá.';
    } else if (isPhong(userInfo) || isSo(userInfo)) {
      title = 'Các hiệu trưởng tự đánh giá.';
    }

    return title;
  };

  getMissionByRoles = () => {
    const { userInfo } = this.props;

    const roleKey = {
      toTruong: isToTruong(userInfo),
      teacher: isTeacher(userInfo),
      hieuPho: isHieuPho(userInfo),
      hieuTruong: isHieuTruong(userInfo),
      phong: isPhong(userInfo),
      so: isSo(userInfo),
    };

    const key = Object.keys(roleKey).find((key) => roleKey[key]);
    const data = listMissionOfDashboard[key] || {};
    const { title: missionTitle = '', content = [] } = data;

    let missionContent = (
      <ul className="m-b-0">
        {content.map((item, index) => (
          <li key={`mission-${index}`}> {item} </li>
        ))}
      </ul>
    );

    return { missionTitle, missionContent };
  };

  isObjectEmptyValue = (object) =>
    Object.values(object).every((item) =>
      typeof item === 'object' ? this.isObjectEmptyValue(item) : !item,
    );

  render() {
    const chartData = this.getChartData();
    const { overviewData = {}, userInfo } = this.props;
    const { cards = {} } = overviewData;
    let isShowChartDashboard = chartData && !this.isObjectEmptyValue(cards);

    if (isTeacher(userInfo) && !isToTruong(userInfo)) {
      isShowChartDashboard = false;
    }
    const contentMissionTeacher = () => {
      if (isTeacher(userInfo) && !isToTruong(userInfo)) {
        return (
          <ul className="list-style-none no-padding-left">
            <li>
              <Link to={temisAssessments('self')} className="text-primary">
                Hoàn thành tự đánh giá theo Chuẩn nghề nghiệp GV CSGDPT
              </Link>{' '}
              (Theo Biểu mẫu 01 – CV 4530 – Phiếu tự đánh giá của GV CSGDPT);
            </li>
            <li>
              Hoàn thành Phiếu lấy ý kiến của đồng nghiệp trong tổ chuyên môn
              (Theo Biểu mẫu 02 – CV 4530);
            </li>
            <li>
              Hoàn thành Phiếu lấy ý kiến về hiệu trưởng, phó hiệu trưởng (Theo
              Biểu mẫu 02 – CV 4529 – Phiếu lấy ý kiến của giáo viên, nhân viên
              trong trường);
            </li>
            <li>
              <Link to={temisEvidence} className="text-primary">
                Tải hồ sơ minh chứng đánh giá cá nhân lên hệ thống.
              </Link>
            </li>
          </ul>
        );
      }

      return (
        <ul className="list-style-none no-padding-left">
          <li>
            Hoàn thành tự đánh giá theo Chuẩn nghề nghiệp GV CSGDPT (Theo Biểu
            mẫu 01 – CV 4530 – Phiếu tự đánh giá của GV CSGDPT);
          </li>
          <li>
            Hoàn thành Phiếu lấy ý kiến của đồng nghiệp trong tổ chuyên môn
            (Theo Biểu mẫu 02 – CV 4530);
          </li>
          <li>
            Hoàn thành Phiếu lấy ý kiến về hiệu trưởng, phó hiệu trưởng (Theo
            Biểu mẫu 02 – CV 4529 – Phiếu lấy ý kiến của giáo viên, nhân viên
            trong trường);
          </li>
          <li>Tải hồ sơ minh chứng đánh giá cá nhân lên hệ thống.</li>
        </ul>
      );
    };

    const contentMissionToTruong = () => {
      if (isToTruong(userInfo)) {
        return (
          <ul className="list-style-none no-padding-left">
            <li>
              <Link to={temisAssessments('self')} className="text-primary">
                Hoàn thành tự đánh giá cá nhân theo Chuẩn nghề nghiệp GV CSGDPT
              </Link>{' '}
              (Theo Biểu mẫu 01 – CV 4530 – Phiếu tự đánh giá của GV CSGDPT);
            </li>

            <li>
              Hoàn thành Phiếu lấy ý kiến về đồng nghiệp trong tổ chuyên môn
              (Theo Biểu mẫu 02 – CV 4530);
            </li>

            <li>
              Tổ chức cho GV trong tổ chuyên môn đánh giá lẫn nhau và hoàn thành
              tổng hợp ý kiến về đồng nghiệp trong tổ (Theo Biểu mẫu 03 – CV
              4530 – Bảng tổng hợp kết quả đánh giá của đồng nghiệp trong tổ
              chuyên môn);
            </li>

            <li>
              Hoàn thành Phiếu lấy ý kiến về hiệu trưởng, phó hiệu trưởng (Theo
              Biểu mẫu 02 – CV 4529).
            </li>

            <li>
              <Link to={temisEvidence} className="text-primary">
                Tải hồ sơ minh chứng đánh giá cá nhân lên hệ thống;
              </Link>
            </li>

            <li>
              <Link
                to={temisAssessments('coworker-assessment')}
                className="text-primary"
              >
                Tải bảng tổng hợp kết quả đánh giá của đồng nghiệp trong tổ
                chuyên môn lên hệ thống.
              </Link>
            </li>
          </ul>
        );
      }

      return (
        <ul className="list-style-none no-padding-left">
          <li>
            Hoàn thành tự đánh giá cá nhân theo Chuẩn nghề nghiệp GV CSGDPT
            (Theo Biểu mẫu 01 – CV 4530 – Phiếu tự đánh giá của GV CSGDPT);
          </li>

          <li>
            Hoàn thành Phiếu lấy ý kiến về đồng nghiệp trong tổ chuyên môn (Theo
            Biểu mẫu 02 – CV 4530);
          </li>

          <li>
            Tổ chức cho GV trong tổ chuyên môn đánh giá lẫn nhau và hoàn thành
            tổng hợp ý kiến về đồng nghiệp trong tổ (Theo Biểu mẫu 03 – CV 4530
            – Bảng tổng hợp kết quả đánh giá của đồng nghiệp trong tổ chuyên
            môn);
          </li>

          <li>
            Hoàn thành Phiếu lấy ý kiến về hiệu trưởng, phó hiệu trưởng (Theo
            Biểu mẫu 02 – CV 4529).
          </li>

          <li>Tải hồ sơ minh chứng đánh giá cá nhân lên hệ thống;</li>

          <li>
            Tải bảng tổng hợp kết quả đánh giá của đồng nghiệp trong tổ chuyên
            môn lên hệ thống.
          </li>
        </ul>
      );
    };

    const contentMissionHieuPho = () => {
      if (isHieuPho(userInfo)) {
        return (
          <ul className="list-style-none no-padding-left">
            <li>
              <Link to={temisAssessments('self')} className="text-primary">
                Hoàn thành tự đánh giá theo chuẩn hiệu trưởng cơ sở giáo dục phổ
                thông
              </Link>{' '}
              (Theo Biểu mẫu 01 – CV 4529 – Phiếu hiệu trưởng/phó hiệu trưởng tự
              đánh giá);
            </li>
            <li>
              Hoàn thành Phiếu lấy ý kiến về đồng nghiệp trong tổ chuyên môn
              (Theo Biểu mẫu 02 – CV 4530);
            </li>
            <li>
              Hoàn thành Phiếu lấy ý kiến về hiệu trưởng (Theo Biểu mẫu 02 – CV
              4529).
            </li>
            <li>
              <Link to={temisEvidence} className="text-primary">
                Tải hồ sơ minh chứng đánh giá cá nhân lên hệ thống.
              </Link>
            </li>
            <li>
              <Link
                to={temisAssessments('coworker-assessment-result')}
                className="text-primary"
              >
                Tải bảng tổng hợp ý kiến của giáo viên, nhân viên trong trường
                đối với phó hiệu trưởng lên hệ thống.
              </Link>
            </li>
          </ul>
        );
      }

      return (
        <ul className="list-style-none no-padding-left">
          <li>
            Hoàn thành tự đánh giá theo chuẩn hiệu trưởng cơ sở giáo dục phổ
            thông (Theo Biểu mẫu 01 – CV 4529 – Phiếu hiệu trưởng/phó hiệu
            trưởng tự đánh giá);
          </li>
          <li>
            Hoàn thành Phiếu lấy ý kiến về đồng nghiệp trong tổ chuyên môn (Theo
            Biểu mẫu 02 – CV 4530);
          </li>
          <li>
            Hoàn thành Phiếu lấy ý kiến về hiệu trưởng (Theo Biểu mẫu 02 – CV
            4529).
          </li>
          <li>Tải hồ sơ minh chứng đánh giá cá nhân lên hệ thống.</li>
          <li>
            Tải bảng tổng hợp ý kiến của giáo viên, nhân viên trong trường đối
            với phó hiệu trưởng lên hệ thống.
          </li>
        </ul>
      );
    };

    const contentMissionHieuTruong = () => {
      if (isHieuTruong(userInfo)) {
        return (
          <ul className="list-style-none no-padding-left">
            <li>
              <Link to={temisAssessments('self')} className="text-primary">
                Hoàn thành tự đánh giá cá nhân theo chuẩn hiệu trưởng cơ sở giáo
                dục phổ thông
              </Link>{' '}
              (Theo Biểu mẫu 01 – CV 4529 – Phiếu hiệu trưởng/phó hiệu trưởng tự
              đánh giá);
            </li>
            <li>
              <Link
                to={temisAssessments('assess_assessment/peers')}
                className="text-primary"
              >
                Hoàn thành đánh giá giáo viên trong toàn trường
              </Link>{' '}
              (Theo Biểu mẫu 04 – CV 4530 - Bảng tổng hợp kết quả đánh giá giáo
              viên của cơ sở giáo dục phổ thông);
            </li>
            <li>
              Phân công cán bộ thực hiện lấy ý kiến của giáo viên, nhân viên
              trong trường đối với hiệu trưởng và phó hiệu trưởng (Theo Biểu mẫu
              03 – CV 4529 – Bảng tổng hợp kết quả lấy ý kiến của giáo viên,
              nhân viên trong trường);
            </li>

            <li>
              <Link
                to={temisAssessments('assess_assessment/peers')}
                className="text-primary"
              >
                Hoàn thành đánh giá phó hiệu trưởng
              </Link>{' '}
              (Theo Biểu mẫu 04 – CV 4529 – Phiếu cấp trên đánh giá hiệu
              trưởng/phó hiệu trưởng);
            </li>

            <li>
              <Link to={temisEvidence} className="text-primary">
                Tải hồ sơ minh chứng đánh giá cá nhân lên hệ thống.
              </Link>
            </li>

            <li>
              <Link
                to={temisAssessments('coworker-assessment-result')}
                className="text-primary"
              >
                Tải bảng tổng hợp ý kiến của giáo viên, nhân viên trong trường
                đối với hiệu trưởng lên hệ thống.
              </Link>
            </li>
          </ul>
        );
      }

      return (
        <ul className="list-style-none no-padding-left">
          <li>
            Hoàn thành tự đánh giá cá nhân theo chuẩn hiệu trưởng cơ sở giáo dục
            phổ thông (Theo Biểu mẫu 01 – CV 4529 – Phiếu hiệu trưởng/phó hiệu
            trưởng tự đánh giá);
          </li>
          <li>
            Hoàn thành đánh giá giáo viên trong toàn trường (Theo Biểu mẫu 04 –
            CV 4530 - Bảng tổng hợp kết quả đánh giá giáo viên của cơ sở giáo
            dục phổ thông);
          </li>
          <li>
            Phân công cán bộ thực hiện lấy ý kiến của giáo viên, nhân viên trong
            trường đối với hiệu trưởng và phó hiệu trưởng (Theo Biểu mẫu 03 – CV
            4529 – Bảng tổng hợp kết quả lấy ý kiến của giáo viên, nhân viên
            trong trường);
          </li>

          <li>
            Hoàn thành đánh giá phó hiệu trưởng (Theo Biểu mẫu 04 – CV 4529 –
            Phiếu cấp trên đánh giá hiệu trưởng/phó hiệu trưởng);
          </li>

          <li>Tải hồ sơ minh chứng đánh giá cá nhân lên hệ thống.</li>

          <li>
            Tải bảng tổng hợp ý kiến của giáo viên, nhân viên trong trường đối
            với hiệu trưởng lên hệ thống.
          </li>
        </ul>
      );
    };

    const contentMissionPhong = () => {
      if (isPhong(userInfo)) {
        return (
          <ul className="list-style-none no-padding-left">
            <li>
              <Link
                to={temisAssessments('manager-assessment')}
                className="text-primary d-md-inline"
              >
                Đánh giá hiệu trưởng trường tiểu học, trường trung học cơ sở,
                trường phổ thông có nhiều cấp học có cấp cao nhất là trung học
                cơ sở, trường phổ thông dân tộc bán trú, trường phổ thông dân
                tộc nội trú cấp huyện
              </Link>{' '}
              (Theo Biểu mẫu 04 – CV 4529 – Phiếu cấp trên đánh giá hiệu
              trưởng/phó hiệu trưởng).
            </li>
          </ul>
        );
      }

      return (
        <ul className="list-style-none no-padding-left">
          <li>
            Đánh giá hiệu trưởng trường tiểu học, trường trung học cơ sở, trường
            phổ thông có nhiều cấp học có cấp cao nhất là trung học cơ sở,
            trường phổ thông dân tộc bán trú, trường phổ thông dân tộc nội trú
            cấp huyện (Theo Biểu mẫu 04 – CV 4529 – Phiếu cấp trên đánh giá hiệu
            trưởng/phó hiệu trưởng).
          </li>
        </ul>
      );
    };

    const contentMissionSo = () => {
      if (isSo(userInfo) || isAmTinh(userInfo)) {
        return (
          <ul className="list-style-none no-padding-left">
            <li>
              <Link
                to={temisAssessments('manager-assessment')}
                className="text-primary d-md-inline"
              >
                Đánh giá hiệu trưởng trường trung học phổ thông, trường phổ
                thông có nhiều cấp học có cấp cao nhất là trung học phổ thông,
                trường chuyên, trường phổ thông dân tộc nội trú cấp tỉnh
              </Link>{' '}
              (Theo Biểu mẫu 04 – CV 4529 – Phiếu cấp trên đánh giá hiệu
              trưởng/phó hiệu trưởng).
            </li>
          </ul>
        );
      }

      return (
        <ul className="list-style-none no-padding-left">
          <li>
            Đánh giá hiệu trưởng trường trung học phổ thông, trường phổ thông có
            nhiều cấp học có cấp cao nhất là trung học phổ thông, trường chuyên,
            trường phổ thông dân tộc nội trú cấp tỉnh (Theo Biểu mẫu 04 – CV
            4529 – Phiếu cấp trên đánh giá hiệu trưởng/phó hiệu trưởng).
          </li>
        </ul>
      );
    };

    return (
      <div className="temis-dashboard">
        <div className="temis-dashboard__mission m-b-30">
          <Card title="Tổng quan về đánh giá GV/CBQLGD theo chuẩn">
            <div className="m-b-20 text-justify">
              Mục đích của công tác đánh giá đội ngũ theo chuẩn là nhằm theo
              dõi, đánh giá sự phát triển nghề nghiệp của giáo viên và cán bộ
              quản lý cơ sở giáo dục phổ thông trong cả hệ thống giáo dục, từng
              tỉnh, từng huyện, từng trường và từng cá nhân, làm căn cứ cho việc
              xây dựng chương trình, tài liệu và tổ chức bồi dưỡng. Kết quả đánh
              giá theo chuẩn cũng là căn cứ để đánh giá chất lượng, hiệu quả và
              cập nhật các chương trình bồi dưỡng thường xuyên của các địa
              phương và của Bộ GDĐT, đáp ứng yêu cầu thực hiện Chương trình giáo
              dục phổ thông 2018 và đổi mới giáo dục.
            </div>
            <ol>
              <li>
                <div className="temis-dashboard__mission-title">
                  Văn bản quy định và hướng dẫn đánh giá giáo viên gồm:
                </div>
                <ul className="list-style-none">
                  <li>
                    <a
                      href="https://taphuan-statics.csdl.edu.vn/ufiles/2020/e/11/5142da1a9c513f4a09000018/5fc212625d1951012249cf64.PDF"
                      className="text-primary"
                      target="_blank"
                      download
                    >
                      Thông tư số 20/2018/TT-BGDĐT,
                    </a>
                    <span className="m-l-5">
                      ngày 22/8/2018, ban hành quy định chuẩn nghề nghiệp giáo
                      viên cơ sở giáo dục phổ thông;{' '}
                    </span>
                  </li>
                  <li>
                    <a
                      href="https://taphuan-statics.csdl.edu.vn/ufiles/2020/e/11/5142da1a9c513f4a09000018/5fc21254221d9153d7156b6d.pdf"
                      className="text-primary"
                      target="_blank"
                      download
                    >
                      Công văn số 4530/BGD ĐT-NGCBQLGD,
                    </a>
                    <span className="m-l-5">
                      ngày 01/10/2018, Hướng dẫn thực hiện thông tư ban hành quy
                      định chuẩn nghề nghiệp GV CSGDPT.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <div className="temis-dashboard__mission-title">
                  Văn bản quy định và hướng dẫn đánh giá cán bộ quản lý cơ sở
                  giáo dục phổ thông gồm:
                </div>
                <ul className="list-style-none">
                  <li>
                    <a
                      href="https://taphuan-statics.csdl.edu.vn/ufiles/2020/e/11/5142da1a9c513f4a09000018/5fc2125b12b6d331892c579e.pdf"
                      className="text-primary"
                      target="_blank"
                      download
                    >
                      Thông tư số 14/2018/TT-BGDĐT,
                    </a>
                    <span className="m-l-5">
                      ngày 20/7/2018, ban hành quy định chuẩn hiệu trưởng
                      CSGDPT;
                    </span>
                  </li>
                  <li>
                    <a
                      href="https://taphuan-statics.csdl.edu.vn/ufiles/2020/e/11/5142da1a9c513f4a09000018/5fc2124ce1c466425f2ce440.PDF"
                      className="text-primary"
                      target="_blank"
                      download
                    >
                      Công văn số 4529/BGD ĐT-NGCBQLGD,
                    </a>
                    <span className="m-l-5">
                      ngày 01/10/2018, Hướng dẫn thực hiện thông tư ban hành quy
                      định chuẩn hiệu trưởng CSGDPT.
                    </span>
                  </li>
                </ul>
              </li>
              <li>
                <div className="temis-dashboard__mission-title">
                  Nhiệm vụ của các bên liên quan trong đánh giá theo chuẩn
                </div>
                <ul className="list-style-lower-roman">
                  <li>
                    <div className="temis-dashboard__mission-sub-title">
                      Nhiệm vụ của giáo viên:
                    </div>
                    {contentMissionTeacher()}
                  </li>

                  <li>
                    <div className="temis-dashboard__mission-sub-title">
                      Nhiệm vụ của tổ trưởng chuyên môn:
                    </div>
                    {contentMissionToTruong()}
                  </li>

                  <li>
                    <div className="temis-dashboard__mission-sub-title">
                      Nhiệm vụ của phó hiệu trưởng cơ sở giáo dục phổ thông:
                    </div>
                    {contentMissionHieuPho()}
                  </li>

                  <li>
                    <div className="temis-dashboard__mission-sub-title">
                      Nhiệm vụ của hiệu trưởng cơ sở giáo dục phổ thông:
                    </div>
                    {contentMissionHieuTruong()}
                  </li>

                  <li>
                    <div className="temis-dashboard__mission-sub-title">
                      Nhiệm vụ của trưởng phòng giáo dục cấp huyện:
                    </div>
                    {contentMissionPhong()}
                  </li>

                  <li>
                    <div className="temis-dashboard__mission-sub-title">
                      Nhiệm vụ của giám đốc sở giáo dục và đào tạo:
                    </div>
                    {contentMissionSo()}
                  </li>
                </ul>
              </li>
            </ol>
          </Card>
        </div>
        {isShowChartDashboard && !isAmTinh(userInfo) && (
          <div className="temis-dashboard__chart">
            <Card title="Tổng quan">
              <div
                style={{
                  height: '500px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <PieChart
                  data={this.getChartData()}
                  options={{
                    outerRadius: isSmallScreen ? '70%' : '100%',
                    colors: [
                      '#00918c',
                      '#3b7bbe',
                      '#fe7a3d',
                      '#e42b2b',
                      '#FDD835',
                      '#c4c4c4',
                    ],
                  }}
                />
              </div>

              <h4 className="temis-dashboard__chart-description text-center">
                {this.getChartDescription()}
              </h4>
            </Card>
          </div>
        )}
      </div>
    );
  }
}

const fetchOverviewData = fetchData((props) => ({
  baseUrl: endPoints.getOverviewData,
  params: {
    scholastic: get(props, 'scholastic'),
  },
  method: 'get',
  propKey: 'overviewData',
  fetchCondition: get(props, 'scholastic'),
  refetchCondition: (prevPros) =>
    get(props, 'scholastic') !== get(prevPros, 'scholastic'),
}));

export default withScholastic(
  withUserInfo(fetchOverviewData(TemisDashboardFrontend)),
);
