import React from 'react';
import { t1 } from 'translate';
import OverviewLayoutFreeStyle from './LayoutFreeStyle';
import {
  includeSubOrganizations,
  organizations,
  phongbanInOrganization,
} from 'components/admin/organization/schema/elements';
import get from 'lodash.get';
import taphuanSubTypes from 'configs/constants/org-sub-types';
import { SCHOOL_TYPE } from 'components/temis/constant';

const schema = (formid, values, step, xpath, props, domainInfo) => {
  const orgTypes = get(domainInfo, 'school.org_types');
  const enableAssessmentForMamnon = get(props, 'enableAssessmentForMamnon');
  return {
    assessment_of_organization_type: {
      type: 'radio',
      floatingLabelText: t1('Đối tượng đánh giá chuẩn nghề nghiệp'),
      options: [
        {
          value: SCHOOL_TYPE.GVPT,
          label: 'Đánh giá GVPT',
        },
        {
          value: SCHOOL_TYPE.MN,
          label: 'Đánh giá Mầm non',
        },
      ],
      defaultValue: SCHOOL_TYPE.GVPT,
      inline: true,
    },
    organizations: organizations({
      formid,
      multiple: false,
      label: `${t1('organizations')}`,
      defaultValue: props.orgIids,
      subTypesDiscarded: props.isSo
        ? [taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD].filter(Boolean)
        : [],
    }),
    text: {
      type: 'text',
      hintText: t1('name,_code,_or_email'),
      floatingLabelText: t1('name'),
      defaultValue: '',
      errorText: '',
      fullWidth: true,
    },
    phongban_id: phongbanInOrganization({
      orgTypes,
      ancestor_iids: values.user_organizations,
    }),
    include_sub_organizations: includeSubOrganizations(domainInfo.conf, {
      defaultValue: 1,
    }),
  };
};

const ui = (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  { enableAssessmentForMamnon, isResultOfHT },
) => {
  return [
    {
      id: 'id',
      fields: [
        !!enableAssessmentForMamnon &&
          isResultOfHT &&
          'assessment_of_organization_type',
        'phongban_id',
        'organizations',
      ].filter(Boolean),
    },
  ];
};

const layout = (step, values, xpath, props) => {
  return {
    component: OverviewLayoutFreeStyle,
    freestyle: 1,
    optionsProperties: {
      isResultOfHP: get(props, 'isResultOfHP'),
      isResultOfHT: get(props, 'isResultOfHT'),
    },
  };
};

export default {
  schema,
  ui,
  layout,
};
