import React from 'react';
import connect from 'react-redux/es/connect/connect';
import lodashGet from 'lodash.get';
import Warning from '../../../common/Warning';
import { t1 } from '../../../../translate';

const strings = () => ({
  mail: 'Email',
  identification_card: t1('identification_card_number'),
  birthday: t1('date_month_year_of_birth'),
  birthplace: t1('birthplace'),
  name: t1('name'),
  phone: t1('phone_number'),
  sex: t1('gender'),
  ethnicity: t1('ethnicity'),
  other_ethnicity: t1('ethnicity'),
  grade: t1('teaching_grade'),
  academic_categories: t1('training_academic_categories'),
  qualifications: t1('education_level'),
  teaching_specialization_select: t1('training_major'),
  teaching_specialization: t1('training_major_text_input_label'),
  user_organizations: t1('user_organization'),
  dia_ban: t1('working_area'),
  congtac_thuoc_diaban_khokhan: t1('work_in_a_difficult_area'),
  phongban_id: 'Tổ chuyên môn',
  teaching_exp_years: t1('number_of_years_of_teaching_experience'),
  training_grades: t1('teaching_khois'),
});

class TemisErrors extends React.Component {
  render() {
    const { errors } = this.props;
    if (errors) {
      const keys = Object.keys(errors);
      if (keys.length)
        return (
          <Warning>
            {keys.map((k) => (
              <div>
                <b>{strings()[k]}</b>: {errors[k]}
              </div>
            ))}
          </Warning>
        );
    }

    return null;
  }
}

const mapState = (state, props) => {
  return {
    errors: lodashGet(state, 'form.new_temis.syncErrors'),
  };
};

export default connect(mapState)(TemisErrors);
