import {
  birthday,
  birthplace,
  ethnicity,
  identification_card,
  mail,
  name,
  phone,
  sex,
} from 'components/admin/user/schema/elements';
import { t1 } from 'translate';
import { inRange, isCmnd, required } from 'common/validators';
import React from 'react';
import {
  org_district_id,
  org_province_id,
} from 'components/admin/pds/schema/elements';
import get from 'lodash.get';
import FormFreeStyle from './FormFreeStyle';
import isEqual from 'lodash.isequal';
import {
  leaderPositionOptions,
  subLeaderPositionOptions,
  leaderPositions,
  qualificationOptions,
  userGradeOptions,
  userSchoolLevels,
  userTrainingGradeOptions,
  teachingSpecializationOptions,
} from 'configs/constants/user';
import Tooltip from 'antd/lib/tooltip';
import Icon from 'antd/lib/icon';
import organizationApiUrls from 'components/admin/organization/endpoints';
import { change } from 'redux-form';
import Store from 'store';
import { organizationHasDistrict, organizationHasProvince } from 'common/conf';
import apiUrls from 'components/temis/endpoints';
import { addRquiredTick } from 'common/utils/schema-form';
import { unaccentVietnamese } from 'common/utils/string/vn';
import taphuanSubType from 'configs/constants/org-sub-types';
import { ethnics } from 'common';
import RadioGrade from './radio-grade';
import CategorySelect from './category-select';
import DatePicker from 'schema-form/elements/date-picker';
import { isCotCan } from 'components/admin/user/utils';
import { yesNoOptions } from '../../../../schema-form/elements/yesno';

const defaultReadOnlyField = {
  mail: true,
  other: false,
};

export const getDefaultValueOfGrade = (formValues, node) => {
  const subType = get(formValues, 'org_sub_type');
  switch (subType) {
    case taphuanSubType.TAPHUAN_SUBTYPE_TIEUHOC: {
      return userSchoolLevels.PRIMARY;
    }
    case taphuanSubType.TAPHUAN_SUBTYPE_THCS: {
      return userSchoolLevels.SECONDARY;
    }
    case taphuanSubType.TAPHUAN_SUBTYPE_THPT: {
      return userSchoolLevels.HIGH;
    }
    default: {
      return node && node.grade;
    }
  }
};

const checkAllowToEditGrade = (newValues, formValues) => {
  if (
    !isEqual(get(newValues, 'org_sub_type'), get(formValues, 'org_sub_type'))
  ) {
    return true;
  }

  const subType = get(formValues, 'org_sub_type');

  const currentValue = get(formValues, 'grade');

  if (
    ![
      taphuanSubType.TAPHUAN_SUBTYPE_TIEUHOC,
      taphuanSubType.TAPHUAN_SUBTYPE_THCS,
      taphuanSubType.TAPHUAN_SUBTYPE_THPT,
    ].includes(subType)
  ) {
    return true;
  }

  if (
    (subType === taphuanSubType.TAPHUAN_SUBTYPE_TIEUHOC &&
      currentValue !== userSchoolLevels.PRIMARY) ||
    (subType === taphuanSubType.TAPHUAN_SUBTYPE_THCS &&
      currentValue !== userSchoolLevels.SECONDARY) ||
    (subType === taphuanSubType.TAPHUAN_SUBTYPE_THPT &&
      currentValue !== userSchoolLevels.HIGH)
  ) {
    return true;
  }

  return false;
};

const checkCotCanUnderDHV = (user) => {
  if (!user || !user.cot_can_data) {
    return false;
  }

  if (
    Array.isArray(user.ancestor_organizations) &&
    user.ancestor_organizations.includes(100585)
  ) {
    return true;
  }

  if (
    Array.isArray(user.user_organizations) &&
    user.user_organizations.includes(100585)
  ) {
    return true;
  }

  return false;
};

const showGrade = (values) => {
  const subType = get(values, 'org_sub_type');

  if (
    [
      taphuanSubType.TAPHUAN_SUBTYPE_TIEUHOC,
      taphuanSubType.TAPHUAN_SUBTYPE_THCS,
      taphuanSubType.TAPHUAN_SUBTYPE_THPT,
      taphuanSubType.TAPHUAN_SUBTYPE_LIENCAP,
      taphuanSubType.TAPHUAN_SUBTYPE_GIAODUC_THUONGXUYEN,
      taphuanSubType.TAPHUAN_SUBTYPE_TUTHUC_LIENCAP,
    ].includes(subType)
  ) {
    return true;
  }

  return false;
};

const listReadOnlyFieldsByUser = (user) => {
  if (!user) {
    return null;
  }

  if (!window.isETEP || !user.hrms || isCotCan(user)) {
    return ['mail'];
  }

  return ['mail', 'org_sub_type', 'user_organizations'];
};

const isReadOnly = (readOnlyFields, field) => {
  return (
    readOnlyFields &&
    Array.isArray(readOnlyFields) &&
    readOnlyFields.includes(field)
  );
};

const isInputNumericECharacter = (event) => {
  if (event.keyCode === 69) {
    event.preventDefault();
  }
};

const isRequired = (field) => {
  const list = [
    'name',
    'birthday',
    'birthplace',
    'mail',
    'phone',
    'sex',
    'ethnicity',
    'other_ethnicity',
    'grade',
    'training_grades',
    'dia_ban',
    'congtac_thuoc_diaban_khokhan',
    'other_user_organizations_name',
    'teaching_exp_years',
    'ngay_vao_bien_che',
  ];
  return list.includes(field);
};

const getDtts = () => {
  const dtts = ethnics.filter((item) => item.value !== '01');
  return dtts.map((item) => ({ ...item, value: item.label }));
};

const schema = (
  formid,
  values,
  step,
  xpath,
  { allOrgTypes, node, formValues, user, updateField },
  domainInfo,
) => {
  const orgTypes = get(domainInfo, 'school.org_types');
  const isHRMS = get(user, 'hrms');
  const canSkipProfile = get(node, 'can_skip_temis_profile');

  const readOnlyFields = listReadOnlyFieldsByUser(node);

  const grade = values.grade;

  return {
    name: name({
      readOnly: checkCotCanUnderDHV(node),
      floatingLabelText: addRquiredTick(
        t1('full_name'),
        !canSkipProfile && isRequired('name'),
      ),
      validate: [
        ...(!canSkipProfile && isRequired('name')
          ? [required(t1('please_enter_your_full_name'))]
          : []),
      ],
    }),
    birthday: birthday(
      {
        readOnly: false,
        floatingLabelText: addRquiredTick(
          t1('date_month_year_of_birth'),
          !canSkipProfile && isRequired('birthday'),
        ),
        validate: !canSkipProfile && isRequired('birthday') ? undefined : [],
        formatDate: 'DD/MM/YYYY', // kinda hacky, maybe we should have a global config for this
        gmt: '+07:00',
      },
      get(domainInfo, 'conf'),
    ),

    birthplace: birthplace({
      readOnly: false,
      floatingLabelText: addRquiredTick(
        t1('birthplace'),
        !canSkipProfile && isRequired('birthplace'),
      ),
      validate: [
        ...(!canSkipProfile && isRequired('birthplace')
          ? [required(t1('please_enter_birthplace'))]
          : []),
      ],
    }),
    identification_code: {
      type: 'text',
      normalize: (val) => val.replace(/\s+/g, ''),
      floatingLabelText: addRquiredTick('Mã định danh', false),
    },
    mail: mail({
      floatingLabelText: addRquiredTick(
        t1('email'),
        !canSkipProfile && isRequired('mail'),
      ),
      validate: [
        ...(!canSkipProfile && isRequired('mail')
          ? [required(t1('please_enter_email'))]
          : []),
      ],
      readOnly: isReadOnly(readOnlyFields, 'mail'),
      style: {
        fontFamily: 'Roboto',
      },
    }),
    phone: phone({
      floatingLabelText: addRquiredTick(
        t1('phone_number'),
        !canSkipProfile && isRequired('phone'),
      ),
      readOnly: isReadOnly(readOnlyFields, 'phone'),
      validate: [
        ...(!canSkipProfile && isRequired('phone')
          ? [required(t1('please_enter_phone_number'))]
          : []),
      ],
    }),
    sex: sex({
      inline: true,
      validate: [
        ...(!canSkipProfile && isRequired('sex')
          ? [required(t1('please_select_gender'))]
          : []),
      ],
      floatingLabelText: addRquiredTick(
        t1('gender'),
        !canSkipProfile && isRequired('sex'),
      ),
    }),
    identification_card: identification_card({
      fullWidth: true,
      readOnly: isReadOnly(readOnlyFields, 'identification_card'),
      validate: [
        ...(!canSkipProfile && isRequired('identification_card')
          ? [required('Hãy nhập CMND')]
          : []),
        isCmnd('Hãy nhập đúng 9 số với CMND, 12 số với CCCD'),
      ],
      floatingLabelText: addRquiredTick(
        'Số chứng minh thư / căn cước công dân',
        !canSkipProfile && isRequired('identification_card'),
      ),
      floatingLabelFixed: addRquiredTick(
        'Số chứng minh thư / căn cước công dân',
        !canSkipProfile && isRequired('identification_card'),
      ),
    }),
    ethnicity: {
      type: 'radio',
      options: [
        {
          value: 'kinh',
          label: t1('kinh'),
        },
        {
          value: 'dtts',
          label: (
            <span title={t1('ethnic_minority')}>
              {t1('ethnic_minority_short_text')}
            </span>
          ),
        },
      ],
      validate: [
        ...(!canSkipProfile && isRequired('ethnicity')
          ? [required(t1('please_select_ethnicity'))]
          : []),
      ],
      label: addRquiredTick(
        t1('ethnicity'),
        !canSkipProfile && isRequired('ethnicity'),
      ),
      inline: true,
    },
    dia_ban: {
      type: 'radio',
      options: [
        {
          value: 'city',
          label: t1('working_area_city_label'),
        },
        {
          value: 'plain',
          label: t1('working_area_plain_label'),
        },
        {
          value: 'mountain',
          label: t1('working_area_mountain_label'),
        },
      ],
      validate: [
        ...(!canSkipProfile && isRequired('dia_ban')
          ? [required(t1('please_select_working_area'))]
          : []),
      ],
      label: addRquiredTick(
        t1('working_area'),
        !canSkipProfile && isRequired('dia_ban'),
      ),
      inline: true,
    },
    congtac_thuoc_diaban_khokhan: {
      type: 'radio',
      label: addRquiredTick(
        t1('work_in_a_difficult_area'),
        !canSkipProfile && isRequired('congtac_thuoc_diaban_khokhan'),
      ),
      options: yesNoOptions(),
      validate: [
        ...(!canSkipProfile && isRequired('congtac_thuoc_diaban_khokhan')
          ? [required(t1('please_select_answer'))]
          : []),
      ],
      inline: true,
    },
    other_ethnicity: {
      type: 'select',
      options: getDtts(),
      showSearch: true,
      filterOption: (input, option) =>
        unaccentVietnamese(option.props.children)
          .toLowerCase()
          .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0,
      floatingLabelText: addRquiredTick(
        t1('ethnicity_select_label'),
        !canSkipProfile && isRequired('other_ethnicity'),
      ),
      validate: [
        ...(!canSkipProfile && isRequired('other_ethnicity')
          ? [required(t1('please_select_ethnicity'))]
          : []),
      ],
    },
    qualifications: {
      type: 'select',
      floatingLabelText: addRquiredTick(
        t1('highest_education_level_input_label'),
        true,
      ),
      validate: required(t1('please_select_highest_education_level')),
      options: qualificationOptions(),
    },
    teaching_specialization_select: {
      type: 'select',
      floatingLabelText: addRquiredTick(t1('training_major'), true),
      validate: [required(t1('please_enter_training_major'))],
      options: teachingSpecializationOptions(),
    },
    teaching_specialization: {
      type: 'text',
      floatingLabelText: addRquiredTick(
        t1('training_major_text_input_label'),
        true,
      ),
      validate: [required(t1('please_enter_training_major'))],
    },
    org_sub_type: {
      type: 'select',
      showSearch: true,
      optionFilterProp: 'children',
      filterOption: (input, option) =>
        unaccentVietnamese(option.props.children)
          .toLowerCase()
          .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0,
      floatingLabelText: addRquiredTick(t1('organization_type'), true),
      options: allOrgTypes,
      defaultValue:
        values.org_sub_type ||
        get(node, '__expand.user_organizations[0].sub_type'),
      validate: [required(t1('please_select_organization_type'))],
      readOnly: isReadOnly(readOnlyFields, 'org_sub_type'),
      onChange: (event, value) => {
        if (!isHRMS) {
          const grade = getDefaultValueOfGrade({
            org_sub_type: value,
          });
          updateField('grade', grade);
        }
      },
    },
    user_organizations: {
      type: 'select',
      floatingLabelText: addRquiredTick(t1('organization'), true),
      options: 'async',
      populateValue: true,
      allowClear: true,
      format: (v) => get(v, [0]),
      normalize: (v) => (v ? [v] : []),
      showSearch: true,
      optionFilterProp: 'children',
      filterOption: (input, option) =>
        unaccentVietnamese(option.props.children)
          .toLowerCase()
          .indexOf(unaccentVietnamese(input).toLowerCase()) >= 0,
      paramsasync: {
        __url__: organizationApiUrls.get_organizations_for_select_options,
        value: {
          sub_type: values.org_sub_type,
          province_id: get(formValues, 'org_province_id'),
          district_id: get(formValues, 'org_district_id'),
        },
        key: `user_organization_${values.org_sub_type}_${get(
          formValues,
          'org_province_id',
        )}_${get(formValues, 'org_district_id')}`,
      },
      validate: [
        (value, values) => {
          if (values.other_user_organizations) {
            return undefined;
          }
          return required(t1('please_select_organization'))(value);
        },
      ],
      readOnly: isReadOnly(readOnlyFields, 'user_organizations'),
    },
    other_user_organizations: {
      type: 'checkbox',
      label: t1('other_user_organizations_label'),
      onChange: (event, v) => {
        if (v) {
          Store.dispatch(change(formid, 'user_organizations', []));
        }
      },
      className: isReadOnly(readOnlyFields, 'other_user_organizations')
        ? 'checkbox-read-only'
        : '',
      readOnly: isReadOnly(readOnlyFields, 'other_user_organizations'),
    },
    org_province_id: org_province_id({
      floatingLabelText: addRquiredTick(
        t1('province'),
        !canSkipProfile && isRequired('org_province_id'),
      ),
      validate: [
        ...(!canSkipProfile && isRequired('org_province_id')
          ? [
              (value, values) => {
                if (values.other_user_organizations) {
                  return undefined;
                }
                return required(t1('please_select_province'))(value);
              },
            ]
          : []),
      ],
      readOnly: isReadOnly(readOnlyFields, 'org_province_id'),
    }),
    other_user_organizations_name: {
      type: 'text',
      hintText: t1('enter_organization_name'),
      fullWidth: true,
      validate: [
        ...(!canSkipProfile && isRequired('other_user_organizations_name')
          ? [
              (value, values) => {
                if (!values.other_user_organizations) {
                  return undefined;
                }
                return required(t1('please_enter_organization_name'))(value);
              },
            ]
          : []),
      ],
      readOnly: isReadOnly(readOnlyFields, 'other_user_organizations_name'),
    },
    org_district_id: org_district_id(values, {
      floatingLabelText: addRquiredTick(
        t1('district'),
        !canSkipProfile && isRequired('org_district_id'),
      ),
      validate: [
        ...(!canSkipProfile && isRequired('org_district_id')
          ? [
              (value, values) => {
                if (values.other_user_organizations) {
                  return undefined;
                }
                return required(t1('please_select_district'))(value);
              },
            ]
          : []),
      ],
      readOnly: isReadOnly('org_district_id'),
    }),
    giaovien_code: {
      type: 'text',
      floatingLabelText: t1('teacher_code_text_input_label'),
      defaultValue: '',
      errorText: '',
    },
    giaovien_cotcan: {
      type: 'radio',
      label: (
        <span>
          Thuộc nhóm đối tượng cốt cán nào?{' '}
          <Tooltip title="Nếu là GV/CBQLCSGD cốt cán thì chọn 1 trong 2 nhóm (nếu không được chọn là cốt cán thì bỏ qua)">
            <Icon type="question-circle" />
          </Tooltip>
        </span>
      ),
      options: [
        { value: 'highschool', label: 'Giáo viên cốt cán' },
        {
          value: 'official',
          label: 'Cán bộ quản lý cơ sở giáo dục cốt cán',
        },
        {
          value: '',
          label: 'Không là cốt cán',
        },
      ],
      readOnly: defaultReadOnlyField.other,
    },
    teaching_exp_years: {
      type: 'number',
      min: 0,
      max: 40,
      floatingLabelText: addRquiredTick(
        t1('number_of_years_of_teaching_experience2222222222'),
        !canSkipProfile && isRequired('teaching_exp_years'),
      ),
      validate: [
        ...(!canSkipProfile && isRequired('teaching_exp_years')
          ? [required(t1('please_enter_number_of_years_of_experience'))]
          : []),
        inRange(
          0,
          40,
          t1('number_of_years_of_experience_between_%s_and_%s', [0, 40]),
        ),
      ],
      fullWidth: true,
      onKeyDown: (event) => isInputNumericECharacter(event),
    },
    ngay_vao_bien_che: {
      type: DatePicker,
      floatingLabelText: 'Ngày vào biên chế',
      fullWidth: true,
      maxDate: new Date(),
      minDate: values.birthday || null,
    },
    current_position: {
      type: 'radio',
      label: t1('other_job_position'),
      options: [
        { value: 'leader', label: 'Tổ trưởng' },
        { value: 'vice_leader', label: 'Tổ phó chuyên môn' },
        { value: 'other', label: t1('other') },
      ],
      inline: true,
    },
    other_current_position: {
      type: 'text',
      floatingLabelText: addRquiredTick(
        t1('other_job_position'),
        !canSkipProfile && isRequired('other_current_position'),
      ),
      validate: [
        ...(!canSkipProfile && isRequired('other_current_position')
          ? [required(t1('please_enter_other_job_position'))]
          : []),
      ],
    },
    grade: {
      type: 'cascade',
      component: (
        <RadioGrade
          floatingLabelText={addRquiredTick(
            t1('teaching_grade'),
            !canSkipProfile && isRequired('grade'),
          )}
          options={userGradeOptions()}
          validate={[
            ...(!canSkipProfile && isRequired('grade')
              ? [required(t1('please_select_teaching_grade'))]
              : []),
          ]}
          readOnly={!isHRMS && !checkAllowToEditGrade(values, formValues)}
          defaultValue={getDefaultValueOfGrade(formValues, node)}
          userOrganizationId={get(values, 'user_organizations[0]')}
        />
      ),
    },
    training_grades: {
      type: 'multiCheckbox',
      inline: true,
      options: userTrainingGradeOptions(grade),
      floatingLabelText: (
        <span>
          {addRquiredTick(
            t1('teaching_khois'),
            !canSkipProfile && isRequired('training_grades'),
          )}{' '}
          <Tooltip title={t1('teaching_khois_explain_text')}>
            <Icon type="question-circle" />
          </Tooltip>
        </span>
      ),
      validate: [
        ...(!canSkipProfile && isRequired('training_grades')
          ? [
              (value, values) => {
                if (get(userTrainingGradeOptions(values.grade), 'length') > 0) {
                  return required()(value);
                }
                return undefined;
              },
            ]
          : []),
      ],
      classWrapper:
        get(userTrainingGradeOptions(grade), 'length') > 0
          ? undefined
          : 'display-none',
    },
    managing_exp_years: {
      type: 'number',
      min: 0,
      max: 40,
      floatingLabelText: addRquiredTick(
        t1('number_of_years_of_managing_experience'),
        !canSkipProfile && isRequired('managing_exp_years'),
      ),
      validate: [
        ...(!canSkipProfile && isRequired('managing_exp_years')
          ? [required(t1('please_enter_number_of_years_of_experience'))]
          : []),
        inRange(
          0,
          40,
          t1('number_of_years_of_experience_between_%s_and_%s', [0, 40]),
        ),
      ],
      fullWidth: true,
    },
    managing_date: {
      type: DatePicker,
      floatingLabelText: 'Ngày thực hiện quản lý trường',
      fullWidth: true,
      maxDate: new Date(),
    },
    leader_position: {
      type: 'select',
      hiddenWhenOptionEmpty: true,
      floatingLabelText: addRquiredTick(
        t1('current_job_position'),
        !canSkipProfile && isRequired('leader_position'),
      ),
      options: leaderPositionOptions(
        node,
        values.org_sub_type ||
          get(node, '__expand.user_organizations[0].sub_type'),
      ),
      inline: true,
      validate: [
        ...(!canSkipProfile && isRequired('leader_position')
          ? [required(t1('please_select_current_job_position'))]
          : []),
      ],
      readOnly: isReadOnly(readOnlyFields, 'leader_position'),
    },
    sub_leader_position: {
      type: 'select',
      hiddenWhenOptionEmpty: true,
      floatingLabelText: addRquiredTick(
        'Chức vụ kiêm nhiệm',
        !canSkipProfile && isRequired('sub_leader_position'),
      ),
      options: subLeaderPositionOptions(get(values, 'leader_position')),
      inline: true,
      validate: [
        ...(!canSkipProfile && isRequired('sub_leader_position')
          ? [required('Hãy chọn chức vụ kiêm nhiệm')]
          : []),
      ],
      readOnly: isReadOnly(readOnlyFields, 'sub_leader_position'),
    },
    phongban_id: {
      type: 'cascade',
      component: (
        <CategorySelect
          orgTypes={orgTypes}
          ancestor_iids={values.user_organizations}
          firstOption={{
            label: 'Lựa chọn tổ chuyên môn',
            value: '',
          }}
          sub_leader_position={values.sub_leader_position}
          requiredMessage="Hãy chọn tổ chuyên môn"
          formId={formid}
        />
      ),
    },
  };
};

const getEditOrganizationFields = (values, domainInfo) => {
  if (!window.isETEP) {
    return [
      'org_sub_type',
      ...(typeof values.org_sub_type !== 'undefined' &&
      (values.org_province_id ||
        !organizationHasProvince(domainInfo, values.org_sub_type)) &&
      (values.org_district_id ||
        !organizationHasDistrict(domainInfo, values.org_sub_type))
        ? ['user_organizations']
        : []),
      ...(!values.user_organizations ||
      !Array.isArray(values.user_organizations) ||
      !values.user_organizations.length
        ? ['other_user_organizations']
        : []),
      ...(values.other_user_organizations
        ? ['other_user_organizations_name']
        : []),
      ...(organizationHasProvince(domainInfo, values.org_sub_type)
        ? ['org_province_id']
        : []),
      ...(values.org_province_id &&
      organizationHasDistrict(domainInfo, values.org_sub_type) &&
      values.org_sub_type !== 2 //2 là id của sở
        ? ['org_district_id']
        : []),
    ];
  }

  let fields = ['org_sub_type'];

  switch (values.org_sub_type) {
    case taphuanSubType.TAPHUAN_SUBTYPE_BO_GD:
    case taphuanSubType.TAPHUAN_SUBTYPE_LTTU:
    case taphuanSubType.ORG_TYPE_ETEP:
    case taphuanSubType.ORG_TYPE_RGEP:
    case taphuanSubType.ORG_TYPE_RGEP_AND_ETEP:
    case taphuanSubType.ORG_TYPE_VU_GIAO_DUC:
    case taphuanSubType.TAPHUAN_SUBTYPE_SO_GD: {
      fields.push('user_organizations');
      break;
    }
    case taphuanSubType.TAPHUAN_SUBTYPE_PHONG_GD: {
      fields.push('org_province_id');
      if (values.org_province_id) {
        fields.push('user_organizations');
      }
      break;
    }
    default: {
      fields.push('org_province_id');
      if (values.org_province_id) {
        fields = fields.concat(['user_organizations', 'org_district_id']);
      }
    }
  }

  return fields;
};

const ui = (
  step,
  values,
  themeConfig,
  xpath,
  formid,
  props,
  mode,
  domainInfo,
) => {
  const leaderPosition = get(values, 'leader_position');
  const isLoaderPositionChuyenVien = [
    leaderPositions.CHUYEN_VIEN_PHONG,
    leaderPositions.CHUYEN_VIEN_SO,
    leaderPositions.CHUYEN_VIEN_TRUONG,
  ].includes(leaderPosition);

  const isLoaderPositionAdmin = [
    leaderPositions.ADMIN_SSO_TRUONG,
    leaderPositions.ADMIN_SSO_PHONG,
    leaderPositions.ADMIN_SSO_SO,
  ].includes(leaderPosition);

  const allowEditGrade = [
    leaderPositions.LEADER,
    leaderPositions.VICE_LEADER,
    leaderPositions.TEACHER,
    leaderPositions.TO_TRUONG,
  ].includes(leaderPosition);

  const lpOptions = leaderPositionOptions(
    props.node,
    values.org_sub_type ||
      get(props, 'node.__expand.user_organizations[0].sub_type'),
  );

  const showLeaderPosition = Array.isArray(lpOptions) && !!lpOptions.length;

  const expFields = [
    'qualifications',
    ...(!isLoaderPositionChuyenVien && !isLoaderPositionAdmin
      ? ['teaching_specialization_select']
      : []),
    ...(values.teaching_specialization_select === 'other'
      ? ['teaching_specialization']
      : []),
    showLeaderPosition && 'leader_position',
    showLeaderPosition &&
      !!get(props, 'formValues.phongban_id') &&
      get(values, 'leader_position') === leaderPositions.TEACHER &&
      'current_position',
    (get(values, 'leader_position') === leaderPositions.TEACHER ||
      ([leaderPositions.LEADER, leaderPositions.VICE_LEADER].includes(
        get(props, 'formValues.leader_position'),
      ) &&
        get(props, 'formValues.sub_leader_position') ===
          leaderPositions.TO_TRUONG)) &&
      'phongban_id',
    [leaderPositions.LEADER, leaderPositions.VICE_LEADER].includes(
      get(values, 'leader_position'),
    ) && 'sub_leader_position',
    showLeaderPosition &&
      get(values, 'leader_position') === leaderPositions.TEACHER &&
      get(values, 'current_position') === 'other' &&
      'other_current_position',
    ...(!isLoaderPositionAdmin
      ? [
          'ngay_vao_bien_che',
          ...(get(values, 'leader_position') !== leaderPositions.TEACHER
            ? ['managing_date']
            : []),
          'teaching_exp_years',
          ...(get(values, 'leader_position') !== leaderPositions.TEACHER
            ? ['managing_exp_years']
            : []),
        ]
      : []),
    ...(showGrade(values) ? ['grade', 'training_grades'] : []),
  ].filter(Boolean);

  return [
    {
      title: t1('basic_information'),
      id: 'basic',
      fields: [
        // 'giaovien_code',
        'name',
        'birthday',
        'birthplace',
        'sex',
        'ethnicity',
        ...(get(values, 'ethnicity') === 'dtts' ? ['other_ethnicity'] : ''),
        'dia_ban',
        'congtac_thuoc_diaban_khokhan',
      ],
    },
    {
      title: `${t1('basic_info')} 2`,
      id: 'sso',
      fields: ['identification_code', 'mail', 'phone'],
    },
    {
      title: t1('qualifications_and_teaching_experience'),
      id: 'exp',
      fields: expFields,
    },
    {
      title: t1('organization_information'),
      id: 'org',
      fields: getEditOrganizationFields(values, domainInfo),
    },
  ];
};

const validate = (values) => {
  if (values.name && typeof values.name === 'string' && !values.name.trim())
    return {
      name: t1('please_enter_your_name'),
    };
  return undefined;
};

export default ({ title, user }) => ({
  schema,
  ui,
  layout: (step, values, xpath, props) => {
    const { refetchUser, showAlertMessage, reponseUpdate, isEditAccount } =
      props || {};

    return {
      component: FormFreeStyle,
      title,
      freestyle: true,
      optionsProperties: {
        refetchUser,
        reponseUpdate,
        user: get(props, 'node'),
        notShowCardShadow: get(props, 'notShowCardShadow'),
        isEditAccount,
      },
      showAlertMessage,
    };
  },
  validate,
});
