import React from 'react';
import lGet from 'lodash.get';
import DetailOnDialog from 'components/common/detail-on-dialog';
import ViewAssessmentByResult from '../../../components/view-assessment-by-result';
import { ASSESSMENT_STATUS, TCNN_TYPE } from '../../../constant';

const assessmentResult = (assessment, key) => {
  const final = parseInt(
    lGet(assessment, `__expand.temis.${key}.result.final`),
  );

  const approved =
    !isNaN(final) &&
    lGet(assessment, `__expand.temis.${key}.status`) ===
      ASSESSMENT_STATUS.APPROVED;

  return {
    final,
    approved,
    assessorIid: lGet(assessment, `__expand.temis.${key}.peer_iid`),
  };
};

const StatusAssessmentTeacher = ({ item, scholastic }) => {
  const assessmentSelfResult = assessmentResult(item, 'tudanhgia');
  const assessmentFromHT = assessmentResult(item, TCNN_TYPE.GV);
  const assessmentFromPS = assessmentResult(item, TCNN_TYPE.HT);
  const assessmentFromTT = assessmentFromPS.approved
    ? assessmentFromPS
    : assessmentFromHT;

  return (
    <div>
      <div className="justify-content-center status-assessment m-b-15">
        <div
          className={`${
            assessmentSelfResult.approved
              ? 'sent-assessment-icon'
              : 'edit-assessment-icon'
          }  m-l-10 m-r-10`}
        >
          <span
            className={`${
              assessmentSelfResult.approved ? 've-check-circle' : 've-edit-alt'
            } m-r-10`}
          />
          <span>{`${
            assessmentSelfResult.approved
              ? 'Đã gửi đánh giá'
              : 'Chưa gửi đánh giá'
          }`}</span>
        </div>

        <div
          className={`${
            assessmentFromTT.approved
              ? 'sent-assessment-icon'
              : 'edit-assessment-icon'
          }  m-l-10 m-r-10`}
        >
          <span
            className={`${
              assessmentFromTT.approved ? 've-check-circle' : 've-edit-alt'
            } m-r-10`}
          />
          <span>{`${
            assessmentFromTT.approved
              ? 'Cấp trên đã đánh giá'
              : 'Cấp trên chưa đánh giá'
          }`}</span>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <DetailOnDialog
          renderPreview={({ showFull }) => (
            <button
              onClick={showFull}
              className="btn btn-secondary btn-outline m-r-10"
              disabled={!assessmentSelfResult.approved}
            >
              Xem bản tự đánh giá
            </button>
          )}
          renderFull={() => (
            <ViewAssessmentByResult
              item={item}
              scholastic={scholastic}
              assessmentSelfResult={assessmentSelfResult.final}
            />
          )}
          dialogKey="view-my-assessment"
          dialogOptionsProperties={{
            width: '100%',
            autoDetectWindowHeight: false,
            className: 'modal-full-height',
          }}
        />

        <DetailOnDialog
          renderPreview={({ showFull }) => (
            <button
              className="btn btn-secondary btn-outline"
              onClick={showFull}
              disabled={!assessmentFromTT.approved}
            >
              Xem cấp trên đánh giá
            </button>
          )}
          renderFull={() => (
            <ViewAssessmentByResult
              item={item}
              scholastic={scholastic}
              assessmentSelfResult={assessmentFromTT.final}
              assessorIid={assessmentFromTT.assessorIid}
              peerAssess
            />
          )}
          dialogKey="view-peers-assessment"
          dialogOptionsProperties={{
            width: '100%',
            autoDetectWindowHeight: false,
            className: 'modal-full-height',
          }}
        />
      </div>
    </div>
  );
};

export default StatusAssessmentTeacher;
