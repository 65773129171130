import React from 'react';
import lodashGet from 'lodash.get';
import Warning from 'components/common/Warning';
import './style.scss';
import SuccessAlert from 'components/common/SuccessAlert';

class SSOBoxSimple extends React.Component {
  render() {
    const props = this.props;
    const refetchUser = lodashGet(props, 'layoutOptionsProperties.refetchUser');
    const user = lodashGet(props, 'layoutOptionsProperties.user');

    return (
      <div className="m-t-20">
        Đây là tài khoản đồng bộ <Warning inline>tự động</Warning> từ hệ thống
        cơ sở dữ liệu ngành.{' '}
        <Warning inline>Nếu bạn thấy thông tin đơn vị bị sai</Warning>, bấm vào{' '}
        <a href="/faq" target={'_blank'}>
          <SuccessAlert inline>đây để được hỗ trợ</SuccessAlert>
        </a>
      </div>
    );
  }
}

export default SSOBoxSimple;
