import React from 'react';
import { connect } from 'react-redux';

class SearchFormLayout extends React.PureComponent {
  render() {
    const { groups, submitButton } = this.props;
    return (
      <div className="container-fluid elementGroup">
        <div className="row">
          {groups.id.fieldNames.codes && (
            <div className="col-md-6">{groups.id.fieldNames.codes}</div>
          )}
          {groups.id.fieldNames.mails && (
            <div className="col-md-6">{groups.id.fieldNames.mails}</div>
          )}
          {groups.id.fieldNames.names && (
            <div className="col-md-6">{groups.id.fieldNames.names}</div>
          )}
          {groups.id.fieldNames.phones && (
            <div className="col-md-6">{groups.id.fieldNames.phones}</div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">{groups.id.fieldNames.text}</div>
          <div className="col-md-12">
            {groups.id.fieldNames.license_in_active}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 m-t-20 text-center">{submitButton}</div>
        </div>
      </div>
    );
  }
}

export default connect()(SearchFormLayout);
