import React, { Component } from 'react';
import Form from './Form';
import withTemisConfig from 'common/hoc/withTemisConfig';
import { isHieuTruongOrPho } from 'components/admin/user/utils';
import { withRouter } from 'react-router';
import { temisAssessments } from 'components/temis/routes';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import { compose } from 'redux';

class TcnnEdit extends Component {
  requestSuccessful = () => {
    const { history } = this.props;

    history.push(temisAssessments('self'));
  };

  render() {
    const { userRoot, scholastic, isOutOfDeadlineAssessment } = this.props;
    const title = isHieuTruongOrPho(userRoot)
      ? 'ĐÁNH GIÁ HIỆU TRƯỞNG/PHÓ HIỆU TRƯỞNG'
      : 'ĐÁNH GIÁ THEO CHUẨN NGHỀ NGHIỆP GIÁO VIÊN PHỔ THÔNG';

    return (
      <div
        className={`${
          isOutOfDeadlineAssessment ? 'mui-paper-no-box-shadow' : ''
        }`}
      >
        <Form
          userIid={userRoot && userRoot.iid}
          title={title}
          readOnly={isOutOfDeadlineAssessment}
          requestSuccessful={this.requestSuccessful}
          scholastic={scholastic}
          key={`assessment_${scholastic}`}
          userForAssessment={userRoot}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withOutOfDeadlineAssessment,
  withScholastic,
  withTemisConfig,
)(TcnnEdit);
