import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/common/loading';
import { enterMissingData } from 'components/temis/common/routes';

const Temis = Loadable({
  loader: () => import('components/temis'),
  loading: Loading,
});

const EnterMissingData = Loadable({
  loader: () => import('components/temis/enter-missing-data'),
  loading: Loading,
});

export default () => [
  {
    componentId: 'TemisFrontend',
    path: '/temis',
    component: Temis,
  },
  {
    path: enterMissingData(),
    component: EnterMissingData,
    exact: true,
  },
];
