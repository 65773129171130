import React from 'react';
import PreviewUserInDialog from 'components/admin/user/account/common/PreviewInDialog';
import { sexAsText } from 'common/sex';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { t1 } from 'translate';
import CommonAntdTable from 'components/common/antd/table';
import get from 'lodash.get';
import DetailOnDialog from 'components/common/detail-on-dialog';
import { timestampToDateString } from 'common/utils/Date';
import { leaderPositions, leaderPositionToText } from 'configs/constants/user';
import Radio from 'antd/lib/radio';
import DeleteButton from 'components/common/action-button/DeleteBtnWithConfirmDialog';
import apiUrls from 'components/temis/endpoints';
import Button from 'antd/lib/button';
import Account from '../../account';
import AddMembers from './AddMembersToPhongBan';

const ResultMembersInPhongBan = ({
  items = [],
  searchFormId,
  phongban,
  searchTcmFormId,
}) => {
  const [selectedRowKeys, onSelectChange] = React.useState([]);
  const userIidSelected =
    Array.isArray(items) && Array.isArray(selectedRowKeys)
      ? selectedRowKeys.filter(
          (user_iid) => !!items.find(({ iid }) => iid === user_iid),
        )
      : [];
  const defaultColumnWidth = 150;

  const columns = [
    {
      title: t1('general_information'),
      key: 'name',
      width: 250,
      render: (name, item) => (
        <PreviewUserInDialog user={item} showFullDetailButton={false}>
          <div>
            <div>Họ tên: {get(item, 'name')}</div>
            <div>
              {t1('email')}: {get(item, 'mail')}
            </div>
            <div>Điện thoại: {get(item, 'phone')}</div>
          </div>
        </PreviewUserInDialog>
      ),
    },
    {
      title: 'Mã',
      key: 'code',
      width: defaultColumnWidth,
      render: (code, item) => <div>{get(item, 'code')}</div>,
    },
    {
      title: 'Ngày sinh',
      key: 'birthday',
      width: 120,
      render: (birthday, item) => (
        <div>{!!item.birthday && timestampToDateString(item.birthday)}</div>
      ),
    },
    {
      title: t1('gender'),
      key: 'sex',
      width: 90,
      render: (sex, item) => <div>{item && sexAsText(item.sex)}</div>,
    },
    {
      title: 'Vị trí/chức vụ',
      key: 'positions',
      width: defaultColumnWidth,
      render: (item) => {
        const leaderPosition = get(item, 'leader_position');
        const isLeader =
          (leaderPosition === leaderPositions.TEACHER &&
            get(item, 'current_position') === 'leader') ||
          ([leaderPositions.LEADER, leaderPositions.VICE_LEADER].includes(
            leaderPosition,
          ) &&
            get(item, 'sub_leader_position') === leaderPositions.TO_TRUONG);

        return (
          <DeleteButton
            renderComponent={({ onClick }) => {
              return (
                <Radio.Group
                  buttonStyle="solid"
                  onChange={() => {
                    onClick();
                  }}
                  value={
                    isLeader
                      ? leaderPositions.TO_TRUONG
                      : leaderPositions.TEACHER
                  }
                  size="small"
                >
                  <Radio.Button
                    value={leaderPosition}
                    disabled={[
                      leaderPositions.LEADER,
                      leaderPositions.VICE_LEADER,
                    ].includes(leaderPosition)}
                  >
                    {leaderPositionToText(leaderPosition)}
                  </Radio.Button>
                  <Radio.Button value={leaderPositions.TO_TRUONG}>
                    {leaderPositionToText(leaderPositions.TO_TRUONG)}
                  </Radio.Button>
                </Radio.Group>
              );
            }}
            textConfirm={
              isLeader
                ? `Bạn có chắn chắn ${
                    item.name
                  } không phải là tổ trưởng. Bạn có chắc chắn thực hiện điều này?`
                : `Bạn có chắc chắn ${item.name} là tổ trưởng chuyên môn: ${
                    phongban.name
                  }. Bạn có chắc chắn thực hiện điều này?`
            }
            alternativeApi={apiUrls.assign_leader_of_phongban}
            message={{ success: 'Thao tác thành công' }}
            formid={searchFormId}
            params={{
              phongban_id: phongban.iid,
              user_iid: item.iid,
              current_position: isLeader ? null : 'leader',
            }}
          />
        );
      },
    },
    {
      title: 'Kinh nghiệm',
      key: 'experience',
      width: 110,
      render: (item) =>
        item.teaching_exp_years && (
          <span>
            {item.teaching_exp_years} {t1('year')}
          </span>
        ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 100,
      type: TYPE_OF_TABLE_DATA.ACTION,
      render: (action, item) => {
        return (
          <div className="d-flex justify-content-center">
            <PreviewUserInDialog user={item} showFullDetailButton={false}>
              <span className="ve-eye m-r-10" title={t1('view')} />
            </PreviewUserInDialog>

            <DeleteButton
              renderComponent={({ onClick }) => {
                return (
                  <span
                    className="ve-delete m-r-10"
                    title={`Xóa ${item.name} ra khỏi tổ chuyên môn: ${
                      phongban.name
                    }`}
                    onClick={onClick}
                  />
                );
              }}
              textConfirm={`Bạn có chắn chắn xóa giáo viên ${
                item.name
              } ra khỏi tổ chuyên môn`}
              alternativeApi={apiUrls.remove_user_in_phongban}
              formid={[searchFormId, searchTcmFormId]}
              params={{
                phongban_id: phongban.iid,
                user_iids: [item.iid],
              }}
            />
          </div>
        );
      },
    },
  ].filter(Boolean);

  return (
    <div className="account-search-result">
      <CommonAntdTable
        dataSource={items}
        columns={columns}
        pagination={false}
        rowKey="iid"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        className="learn-default"
      />

      <DeleteButton
        title={`Xóa ${
          Array.isArray(userIidSelected) ? userIidSelected.length : 0
        } giáo viên ra khỏi tổ chuyên môn: ${phongban.name}`}
        label={`Xóa ${
          Array.isArray(userIidSelected) ? userIidSelected.length : 0
        } giáo viên được chọn`}
        icon=" "
        textConfirm={`Bạn có chắn chắn xóa ${
          userIidSelected.length
        } giáo viên ra khỏi tổ chuyên môn`}
        alternativeApi={apiUrls.remove_user_in_phongban}
        disabled={!Array.isArray(userIidSelected) || !userIidSelected.length}
        formid={[searchFormId, searchTcmFormId]}
        params={{
          phongban_id: phongban.iid,
          user_iids: userIidSelected,
        }}
      />

      <DetailOnDialog
        renderPreview={({ showFull }) => (
          <Button onClick={showFull} className="btn-primary m-l-15">
            Thêm mới
          </Button>
        )}
        renderFull={({ closeDialog }) => {
          return (
            <Account
              resetForm
              searchFormId="add-members-in-to-phong-ban"
              hiddenFields={{
                leader_position: [
                  leaderPositions.TO_TRUONG,
                  leaderPositions.TEACHER,
                ],
                nin_phongban: phongban.iid,
                temis_account_status: ['activated'],
              }}
              renderResultComponent={(items, props, objects, searchValues) => (
                <AddMembers
                  phongban={phongban}
                  items={items}
                  props={props}
                  objects={objects}
                  searchFormId={searchFormId}
                  searchTcmFormId={searchTcmFormId}
                  searchValues={searchValues}
                  handleAddSuccessfull={closeDialog}
                />
              )}
            />
          );
        }}
        dialogKey="add-members-to-phongban"
        dialogOptionsProperties={{
          title: `Thêm mới giáo viên vào trong tổ: ${phongban.name}`,
          width: '80%',
          handleClose: true,
        }}
      />
    </div>
  );
};

export default ResultMembersInPhongBan;
