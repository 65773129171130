import React from 'react';
import FileUpload from './file-upload';
import BM3PL2CV4530File from 'components/temis/template-files/bieu_mau_03_lay_y_kiem_gv_trong_to.xlsx';

const CoworkerAssessment = () => {
  return (
    <>
      <div className="text-center temis-main-title text-transform m-b-25">
        Đánh giá của đồng nghiệp
      </div>
      <a
        className="d-flex justify-content-end"
        href={BM3PL2CV4530File}
        download
      >
        <button className="btn btn-secondary m-b-10">
          Tải file mẫu BM3/PL2 CV4530
        </button>
      </a>
      <FileUpload />
    </>
  );
};

export default CoworkerAssessment;
