import React from 'react';
import SubTopMenuContext from 'common/context/menu/SubMenuTop';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import Results from './Results';
import topMenuSchema from '../menu/MainstageTopMenu';
import schema from './search-schema';
import withUserInfo from 'common/hoc/withUserInfo';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import endpoints from '../endpoints';

const Layout = ({ userInfo }) => {
  const renderResultComponent = (items, props) => {
    return <Results items={items} {...props} userInfo={userInfo} />;
  };

  const renderNoResultComponent = () => {
    return <SimpleNoResult text="Chưa có mô đun" />;
  };

  return (
    <>
      <SubTopMenuContext
        schema={topMenuSchema()}
        lastBreadcrumbName="Quản lý mô đun"
      />
      <SearchWrapper
        ntype="module"
        formid="module_search"
        renderResultsComponent={renderResultComponent}
        renderNoResultComponent={renderNoResultComponent}
        alternativeApi={endpoints.search_module}
        schema={schema}
        userInfo={userInfo}
        autoSearchWhenStart
      />
    </>
  );
};

export default withUserInfo(Layout);
