import React from 'react';
import { t1 } from 'translate';
import Icon from 'components/common/Icon';
import { PROFILE_GROUP_ROUTER } from '../../constant';

const style = {
  icon: { top: 4, fontSize: 90, marginBottom: 10 },
};
const iconStyle = {
  majors: { top: 4 },
  preview: { top: 4 },
  unlockPath: { top: 3 },
};

const profileMenus = () => {
  return [
    {
      icon: (
        <Icon icon="user" style={{ ...style.icon, ...iconStyle }} antIcon />
      ),
      id: 'update-info',
      href: PROFILE_GROUP_ROUTER.update_info,
      label: t1('basic_info'),
      navLabel: t1('basic_info'),
    },
    {
      icon: (
        <Icon icon="picture" style={{ ...style.icon, ...iconStyle }} antIcon />
      ),
      id: 'update-avatar',
      href: PROFILE_GROUP_ROUTER.update_avatar,
      label: t1('avatar'),
      navLabel: t1('avatar'),
    },
    {
      icon: (
        <Icon icon="lock" style={{ ...style.icon, ...iconStyle }} antIcon />
      ),
      id: 'change-password',
      href: PROFILE_GROUP_ROUTER.change_password,
      label: t1('change_password'),
      navLabel: t1('change_password'),
    },
  ];
};

export default profileMenus;
