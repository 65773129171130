import React from 'react';
import DetailOnDialog from 'components/common/detail-on-dialog';
import TableForm from './TableForm';
import Tooltip from 'antd/lib/tooltip';

const iconStyle = {
  cursor: 'pointer',
};

const Evidence = ({
  tieuChuanIid,
  tieuChiIid,
  searchFormId,
  onChangeEvidence,
  refetchEvidence,
  assessmentEvidence,
  currentEvidenceIids,
  assessmentRubricIid,
}) => {
  const dialogKey = 'view_assessment_evidence';

  const renderPreview = React.useCallback(({ showFull }) => {
    return (
      <Tooltip title="Thêm minh chứng">
        <span
          style={iconStyle}
          className="ve-plus-circle icon-assign-secondary"
          onClick={showFull}
        >
          {' '}
        </span>
      </Tooltip>
    );
  }, []);
  const renderFull = React.useCallback(
    () => {
      return (
        <TableForm
          dialogKey={dialogKey}
          assessmentEvidence={assessmentEvidence}
          tieuChuanIid={tieuChuanIid}
          tieuChiIid={tieuChiIid}
          searchFormId={searchFormId}
          onChangeEvidence={onChangeEvidence}
          refetchEvidence={refetchEvidence}
          currentEvidenceIids={currentEvidenceIids}
          assessmentRubricIid={assessmentRubricIid}
        />
      );
    },
    [
      assessmentEvidence,
      tieuChuanIid,
      tieuChiIid,
      searchFormId,
      onChangeEvidence,
      currentEvidenceIids,
      refetchEvidence,
      assessmentRubricIid,
    ],
  );

  return (
    <DetailOnDialog
      renderPreview={renderPreview}
      renderFull={renderFull}
      dialogKey={dialogKey}
      dialogOptionsProperties={{
        title: 'Thêm tài liệu minh chứng mới',
        width: '80%',
      }}
    />
  );
};

export default Evidence;
