import React from 'react';
import get from 'lodash.get';
import NewTrainingPhase from '../new';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import endpoints from '../../endpoints';

const EditTrainingPhase = ({ node, onTrainingPhaseUpdated }) => {
  if (!node) {
    return <SimpleNoResult text="Không tồn tại" />;
  }

  const fromEtep = get(node, 'from_etep');
  const status = get(node, 'status');
  const deployed = get(status, 'deployed');

  return (
    <div className="white-background">
      <NewTrainingPhase
        node={node}
        mode="edit"
        alternativeApi={endpoints.bdtx_update_training_phase}
        readOnly={fromEtep}
        deployed={deployed}
        onEditSuccessful={onTrainingPhaseUpdated}
      />
    </div>
  );
};

export default EditTrainingPhase;
