import { t1 } from 'translate';
import { input, rte } from 'components/common/forms/schema-fields';
import DatePicker from 'schema-form/elements/date-picker';
import subTypes from 'configs/constants/org-sub-types';
import { simpleOrganization } from 'components/common/elements';
import { slugifier } from 'common/normalizers';
import { isBo, isVGDStaff } from 'components/admin/user/utils';
import Perm from 'common/utils/Perm';
import lodashGet from 'lodash.get';
import { required } from 'common/validators';

const isMultipleSgdsAndPgds = (step) => {
  return step === 'new_batch';
};

const schema = (formid, values, step) => ({
  seller_organization_iid: simpleOrganization(
    {
      floatingLabelText: 'ĐHSP bán học liệu (*)',
    },
    {
      value: {
        sub_type: [
          subTypes.TAPHUAN_SUBTYPE_BO_GD,
          subTypes.TAPHUAN_SUBTYPE_LTTU,
        ],
        pIids: [],
      },
      key: 'seller_organization_iid',
    },
    true,
  ),
  is_deal_for_bgd: {
    type: 'checkbox',
    label: t1('is_deal_for_bgd'),
  },
  only_shown_for_bgd: {
    type: 'checkbox',
    label: t1('only_shown_for_bgd'),
  },
  sgd: simpleOrganization(
    {
      floatingLabelText: 'SGD mua học liệu (*)',
      multiple: isMultipleSgdsAndPgds(step),
    },
    {
      value: {
        sub_type: [subTypes.TAPHUAN_SUBTYPE_SO_GD],
      },
    },
    true,
  ),
  pgd: simpleOrganization(
    {
      floatingLabelText: 'PGD mua học liệu',
      multiple: isMultipleSgdsAndPgds(step),
    },
    {
      value: {
        pIids: isMultipleSgdsAndPgds(step) ? values.sgd : [values.sgd],
        sub_type: [subTypes.TAPHUAN_SUBTYPE_PHONG_GD],
      },
      key: `pgd-of-${values.sgd}`,
    },
    false,
  ),
  name: input('Tên hợp đồng (*)', true),
  code: input('Mã hợp đồng (*)', true, {
    normalize: slugifier,
  }),
  content: rte('Mô tả'),
  materials: {
    type: 'select',
    multiple: true,
    floatingLabelText: t1('learning_materials'),
    options: 'async',
    paramsasync: {
      __url__: '/bdtx/deal/api/list-materials',
      key: `list_materials-${values.seller_organization_iid}`,
      value: {
        organization: values.seller_organization_iid,
        include_of_my_organizations: 1,
      },
    },
    validate: [required()],
  },
  start_date: {
    type: DatePicker,
    floatingLabelText: t1('from_date'),
  },
  end_date: {
    type: DatePicker,
    floatingLabelText: t1('to_date'),
  },
});

const ui = (step, values, themeConfig, xpath, formid, { userInfo = {} }) => {
  const isRoot = Perm.hasPerm('root', null, userInfo);

  let fields = [
    'name',
    'code',
    'content',
    ...(isBo(userInfo) || isVGDStaff(userInfo)
      ? ['seller_organization_iid']
      : []),
    ...(isRoot ? ['is_deal_for_bgd', 'only_shown_for_bgd'] : []),
    ...(!values.is_deal_for_bgd
      ? [
          'sgd',
          ...((isMultipleSgdsAndPgds(step)
          ? lodashGet(values.sgd, 'length')
          : values.sgd)
            ? ['pgd']
            : []),
        ]
      : []),
    'materials',
    'start_date',
    'end_date',
  ];

  return [
    {
      id: 'default',
      fields,
    },
  ];
};

export default { schema, ui };
