import React from 'react';
import Form from './Form';
import RaisedButton from 'components/common/mui/RaisedButton';
import Icon from 'components/common/Icon';
import { t1 } from 'translate';
import DetailOnDialog from 'components/common/detail-on-dialog';
import { submit } from 'redux-form';
import { connect } from 'react-redux';

const ButtonDeleteAccounts = ({
  iconButton,
  searchFormId,
  ids,
  onSelectRowKeysChange,
  hiddenFields = {},
  canSelectAssessmentPhase,
  dispatch,
}) => {
  const formDialogKey = 'button_delete_accounts';

  return (
    <DetailOnDialog
      dialogKey={formDialogKey}
      renderPreview={({ showFull }) => {
        if (iconButton) {
          return <Icon icon="delete" className="action" onClick={showFull} />;
        }

        return (
          <RaisedButton
            primary
            disabled={!Array.isArray(ids) || !ids.length}
            onClick={showFull}
            icon={<Icon icon="delete" style={{ color: 'white' }} />}
            label={t1('delete_%s_account', [
              Array.isArray(ids) ? ids.length : 0,
            ])}
            className="m-t-10 m-r-10"
          />
        );
      }}
      renderFull={({ closeDialog }) => (
        <div>
          <div>{t1('are_you_sure_you_want_to_delete_accounts')}</div>
          <Form
            formid="delete_accounts"
            ids={ids}
            canSelectAssessmentPhase={canSelectAssessmentPhase}
            searchFormId={searchFormId}
            onSelectRowKeysChange={onSelectRowKeysChange}
            hiddenFields={hiddenFields}
            formDialogKey={formDialogKey}
          />
        </div>
      )}
      dialogOptionsProperties={{
        callbacks: {
          onCloseDialog: () => {
            dispatch(submit(searchFormId));
          },
        },
      }}
    />
  );
};

export default connect()(ButtonDeleteAccounts);
