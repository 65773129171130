import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import nodeSagaActions from 'actions/node/saga-creators';
import apiUrls from 'api-endpoints';
import Perm from 'common/utils/Perm';
import ProfileInfo from './info';
import Avatar from './avatar';
import ChangePassword from './change-password';
import Dashboard from './dashboard';
import { getThemeConfig } from 'utils/selectors';
import { loginSuccess } from 'actions/auth';
import { withRouter } from 'react-router';
import TemisProfile from 'components/temis/profile/edit/Temis';
import './stylesheet.scss';

class UserUpdateLayout extends React.Component {
  cssClass = 'front-end-user-update-layout';

  componentDidMount() {
    const { history } = this.props;
    if (Perm.isGuest()) {
      history.push('/');
    }
    window.scrollTo(0, 0);
    this.getUserInfo();
  }

  getUserInfoAction = () => {
    const { dispatch } = this.props;
    const config = {
      url: apiUrls.user_info,
      keyState: 'userInfo',
      executeOnSuccess: (user) => {
        dispatch(
          loginSuccess(user, [
            'id',
            'iid',
            'name',
            'avatar',
            'code',
            'settings',
          ]),
        );
      },
    };

    return nodeSagaActions.getDataRequest(config, {});
  };

  getUserInfo = () => {
    const { dispatch } = this.props;
    dispatch(this.getUserInfoAction());
  };

  render() {
    const { updateType, themeConfig } = this.props;

    let contentDisplay;
    if (updateType === 'avatar') {
      contentDisplay = (
        <div className="user-profile-wrapper">
          <Avatar
            {...this.props}
            actionsToDoOnSuccess={[this.getUserInfoAction()]}
            requestSuccessful={this.getUserInfo}
          />
        </div>
      );
    } else if (updateType === 'change-password') {
      contentDisplay = <ChangePassword {...this.props} />;
    } else if (updateType === 'info') {
      if (window.isETEP) contentDisplay = <TemisProfile notShowCardShadow />;
      else {
        const hiddenFields = {
          school_type: themeConfig && themeConfig.type,
        };
        contentDisplay = (
          <ProfileInfo
            {...this.props}
            hiddenFields={hiddenFields}
            step={updateType === 'setting' ? updateType : null}
            actionsToDoOnSuccess={[this.getUserInfoAction()]}
          />
        );
      }
    } else if (updateType === 'dashboard') {
      contentDisplay = <Dashboard {...this.props} />;
    } else contentDisplay = null;

    if (
      updateType === 'change-password' ||
      updateType === 'info' ||
      updateType === 'avatar'
    ) {
      return (
        <div className="container-fluid">
          <div className="row">
            <div
              className={
                updateType === 'info'
                  ? 'col-12'
                  : 'col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3'
              }
            >
              {contentDisplay}
            </div>
          </div>
        </div>
      );
    } else return contentDisplay;
  }
}

UserUpdateLayout.propTypes = {
  screenSize: PropTypes.shape().isRequired,
  step: PropTypes.string,
  user: PropTypes.shape(),
};

UserUpdateLayout.defaultProps = {
  user: null,
  step: '',
};

const mapStateToProps = (state, props) => {
  const { match } = props;

  const userInfoFromApi = state.dataApiResults && state.dataApiResults.userInfo;

  return {
    screenSize: state.common.screenSize,
    user: userInfoFromApi || {},
    themeConfig: getThemeConfig(state),
  };
};

export default withRouter(connect(mapStateToProps)(UserUpdateLayout));
