import React, { Component } from 'react';
import { compose } from 'redux';
import withTemisConfig from 'common/hoc/withTemisConfig';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import Form from '../../../edit/Form';
import lGet from 'lodash.get';
import './stylesheet.scss';
import DetailOnDialog from 'components/common/detail-on-dialog';
import {
  generateClassByStatusAssessment,
  generateStatusResultAssessment,
} from 'components/temis/utils';
import endpoints from '../../../endpoints';
import { isSGDStaff } from 'components/admin/user/utils';
import taphuanSubTypes from 'configs/constants/org-sub-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import SummaryOpinion from 'components/temis/components/summary-opinion';
import { t1 } from 'translate';
import { ASSESSMENT_STATUS } from 'components/temis/constant';
import { submit } from 'redux-form';
import actions from 'actions/node/creators';
const formId = 'temis-principal-assessment';

class PrincipalAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usePrincipalResult: null,
    };
  }

  setUsePrincipalResult = (currentMilliseconds) => {
    this.setState({
      usePrincipalResult: currentMilliseconds,
    });
  };

  renderFormAssessment = (apiResponseData) => {
    const { scholastic, isOutOfDeadlineAssessment } = this.props;

    const data = apiResponseData[0];
    const rubricToAssessment = lGet(data, 'rubric_iid');
    const userIidToAssessment = lGet(data, 'target.iid');
    const currentStatusOfParentToDoAssessment = lGet(data, 'status');

    if (!rubricToAssessment || !userIidToAssessment) {
      return <SimpleNoResult />;
    }

    const assessmentSelfResult = parseInt(
      lGet(data, 'target.__expand.temis.tudanhgia.result.final'),
    );
    const hasFinalAssessment =
      !isNaN(assessmentSelfResult) &&
      lGet(data, 'target.__expand.temis.tudanhgia.status') ===
        ASSESSMENT_STATUS.APPROVED;

    const selfAssessment = lGet(data, 'target.__expand.temis.tudanhgia');
    const hasSelfAssessment = selfAssessment && hasFinalAssessment;

    const { usePrincipalResult } = this.state;
    const userForAssessment = lGet(data, 'target');

    return (
      <div
        className={`principal-assessment ${
          isOutOfDeadlineAssessment ? 'mui-paper-no-box-shadow' : ''
        } `}
      >
        {rubricToAssessment && (
          <div className=" d-flex justify-content-between align-items-center m-b-15">
            <div className="main-title">
              {' '}
              Đánh giá CNN: {lGet(data, 'target.name')}
            </div>
            <div>
              <DetailOnDialog
                renderPreview={({ showFull }) => (
                  <button
                    type="button"
                    className="text-center btn btn-secondary btn-outline m-r-15"
                    onClick={() => showFull()}
                  >
                    {'Xem bản tự đánh giá'}
                  </button>
                )}
                renderFull={({ closeDialog }) => (
                  <>
                    <div className="assessment-result d-flex">
                      <div className="title">
                        Đánh giá CNN: {lGet(data, 'target.name')}
                      </div>
                      {hasFinalAssessment && (
                        <div className="m-l-35 title">
                          Xếp loại đánh giá:{' '}
                          <span
                            className={` status-text ${generateClassByStatusAssessment(
                              generateStatusResultAssessment(
                                assessmentSelfResult,
                              ) || '',
                            )}`}
                          >
                            {generateStatusResultAssessment(
                              assessmentSelfResult,
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    {hasFinalAssessment ? (
                      <div className="temis-manager">
                        <div className="mui-paper-no-padding mui-paper-no-box-shadow">
                          <Form
                            formId="self-assessment"
                            userIid={userIidToAssessment}
                            requestSuccessful={closeDialog}
                            readOnly
                            dialogKey="selfAssessment"
                            scholastic={scholastic}
                            userForAssessment={userForAssessment}
                          />
                        </div>
                      </div>
                    ) : (
                      <SimpleNoResult text="Chưa gửi bản tự đánh giá" />
                    )}
                  </>
                )}
                dialogKey="selfAssessment"
                dialogOptionsProperties={{
                  width: '100%',
                  autoDetectWindowHeight: false,
                  className: 'modal-full-height',
                }}
              />
              <SummaryOpinion
                buttonClass={'m-r-15'}
                userIid={userIidToAssessment}
              />
              <button
                type="button"
                className={`text-center btn btn-secondary ${
                  usePrincipalResult ? '' : 'btn-outline'
                }`}
                onClick={() => {
                  const currentMilliseconds = new Date().getTime();
                  if (typeof this.setUsePrincipalResult === 'function') {
                    this.setUsePrincipalResult(currentMilliseconds);
                    const { dispatch } = this.props;
                    dispatch(submit(formId));
                  }
                }}
                disabled={!hasSelfAssessment || isOutOfDeadlineAssessment}
              >
                {t1('use_principal_result')}
              </button>
            </div>
          </div>
        )}

        <Form
          formId="principal-assessment"
          userIid={userIidToAssessment}
          peerAssess
          key={`${rubricToAssessment}-${userIidToAssessment}`}
          ignoreUserIidParamToEvidenceAPI
          hideBecomeMaster
          scholastic={scholastic}
          readOnly={isOutOfDeadlineAssessment || !hasSelfAssessment}
          usePrincipalResult={usePrincipalResult}
          isPrincipalNotHasSelfAssessment={!hasSelfAssessment}
          currentStatusOfParentToDoAssessment={
            currentStatusOfParentToDoAssessment
          }
          requestSuccessful={this.requestSuccessful}
        />
      </div>
    );
  };

  renderResult = (apiResponseData = []) => {
    this.setState({
      assessmentData: apiResponseData,
    });
    return null;
  };

  renderNoResult = () => <SimpleNoResult />;

  requestSuccessful = () => {
    const { dispatch, principalAssessmentDialogKey, searchFormId } = this.props;
    dispatch(
      actions.handleOpenDialog(
        { openDialog: false },
        principalAssessmentDialogKey,
      ),
    );
    dispatch(submit(searchFormId));
  };

  render() {
    const isSo = isSGDStaff(this.props.userRoot);
    const subTypesDiscarded = isSo
      ? [taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD]
      : [];
    const { scholastic, target } = this.props;
    const text = lGet(target, 'code') || lGet(target, 'mail');
    const userOrganization = lGet(target, 'user_organizations', []);
    const hiddenFields = {
      items_per_page: 1,
      page: 1,
      scholastic,
      text,
      user_organizations: userOrganization,
    };
    return (
      <>
        <SearchWrapper
          {...this.props}
          key={`${formId}_${scholastic}`}
          formid={formId}
          alternativeApi={endpoints.listOfPeersAssignedToAssess}
          subTypesDiscarded={subTypesDiscarded}
          schema={{}}
          hidePagination
          showResult
          alwaysShowResult
          isSo={isSo}
          hiddenFields={hiddenFields}
          renderResultsComponent={this.renderResult}
          renderNoResultComponent={this.renderNoResult}
          autoSearchWhenStart={true}
          showSearchButton={false}
        />
        {this.renderFormAssessment(this.state.assessmentData || [])}
      </>
    );
  }
}

export default compose(
  connect(),
  withOutOfDeadlineAssessment,
  withScholastic,
  withRouter,
  withTemisConfig,
)(PrincipalAssessment);
