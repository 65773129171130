import { t1 } from 'translate';
import { academicCategories } from './index';
import { required } from 'common/validators';
import { caphoc, tieuChiByCapAndTargetUsers } from 'components/common/elements';
import get from 'lodash.get';
import { userGrades } from 'configs/constants/user';

export const academicAndSkillByCap = (props = {}) => {
  const targetUsers = get(props, 'targetUsers', []);
  const hideAcademic = get(props, 'hideAcademicField');

  return {
    floatingLabelText: `${t1('academic_categories_by_cap')} (*)`,
    type: 'array',
    schema: {
      schema: (formid, values, localStep, xpath) => {
        const cap = get(values, `${xpath}.cap`);

        return {
          cap: caphoc({
            type: 'select',
            floatingLabelText: 'Cấp dạy (*)',
            validate: [required()],
          }),
          academic_categories: academicCategories(formid, {
            label: `${t1('academic_categories')} (*)`,
            multiple: true,
            validate: [required(t1('academic_categories_cannot_be_empty'))],
            apiParams: {
              cap: cap,
              target_users: targetUsers,
              view: 'tree',
              depth: -1,
            },
          }),
          skills_by_target_user: tieuChiByCapAndTargetUsers({
            targetUsers,
            cap,
          }),
        };
      },
      ui: () => [
        {
          id: 'default',
          fields: [
            'cap',
            ...(hideAcademic ? [] : ['academic_categories']),
            'skills_by_target_user',
          ],
        },
      ],
    },
    defaultValue: [
      {
        cap: userGrades.PRIMARY,
      },
    ],
    hiddenRemoveButton: (item, index) => !index,
    ...props,
  };
};
