import React from 'react';
import { t1 } from 'translate';
import { organizations } from 'components/admin/organization/schema/elements';
import get from 'lodash.get';
import taphuanSubTypes from 'configs/constants/org-sub-types';
import ExportData from 'components/common/export-data';

const schema = (formid, values, step, xpath, props, domainInfo) => {
  const enableAssessmentForMamnon = get(
    domainInfo,
    'conf.enable_assessment_for_mamnon',
  );
  return {
    user_organizations: organizations({
      formid,
      multiple: false,
      label: `${t1('organizations')}`,
      defaultValue: props.orgIids,
      subTypesDiscarded: props.isSo
        ? [
            taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD,
            !enableAssessmentForMamnon &&
              taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
          ]
        : [
            !enableAssessmentForMamnon &&
              taphuanSubTypes.TAPHUAN_SUBTYPE_MAM_NON,
          ],
    }),
    text: {
      type: 'text',
      hintText: 'Nhập Tên, Mã hoặc Email',
      floatingLabelText: 'Tìm kiếm GV, CBQL',
      fullWidth: true,
    },
    assessment_status: {
      type: 'multiCheckbox',
      inline: true,
      options: [
        {
          value: 'have_sent',
          label: 'Đã gửi đánh giá',
        },
        {
          value: 'have_not_sent_yet',
          label: 'Chưa gửi đánh giá',
        },
        {
          value: 'have_assessed',
          label: 'Cấp trên đã đánh giá',
        },
        {
          value: 'have_not_assessed_yet',
          label: 'Cấp trên chưa đánh giá',
        },
      ],
    },
    target_user: {
      type: 'multiCheckbox',
      inline: true,
      options: [
        {
          value: 'cbql',
          label: 'CBQL',
        },
        {
          value: 'gv',
          label: 'Giáo viên',
        },
      ],
    },
  };
};

const ui = () => [
  {
    id: 'default',
    fields: ['user_organizations', 'text', 'assessment_status', 'target_user'],
  },
];

const LayoutFreeStyle = ({
  groups,
  submitButton,
  formValues,
  valuesToSubmit,
}) => {
  return (
    <div className="result-assessment">
      <div className="row">
        <div className="col-md-8">
          {get(groups, 'default.fieldNames.user_organizations')}
        </div>
        <div className="col-md-4">{get(groups, 'default.fieldNames.text')}</div>
      </div>
      <div className="d-flex">
        <div className="m-r-50">
          {get(groups, 'default.fieldNames.target_user')}
        </div>
        <div>{get(groups, 'default.fieldNames.assessment_status')}</div>
      </div>
      <div className="row wrap-button">
        <div className="col-md-6 text-right">{submitButton}</div>
        <div className="col-md-6 text-right m-t-7">
          <ExportData
            apiExport="/temis/export/export-result-assessment"
            buttonText="Xuất excel"
            params={Object.assign({}, valuesToSubmit, formValues)}
            fileName="Ket-qua-danh-gia-chung"
            buttonClass="m-l-30"
            fetchingCacheOnly
          />
        </div>
      </div>
    </div>
  );
};

const layout = () => ({
  component: LayoutFreeStyle,
  freestyle: 1,
});

export default {
  schema,
  ui,
  layout,
};
