import get from 'lodash.get';
import {
  caphoc,
  chuong_trinh,
  modules,
  simpleOrganization,
} from 'components/common/elements';
import subTypes from 'configs/constants/org-sub-types';
import { CHUONG_TRINH_03 } from 'components/bdtx/configs';
import { isBo, isVGDStaff } from 'components/admin/user/utils';

const schema = () => {
  return {
    sgd: simpleOrganization(
      {
        floatingLabelText: 'SGD',
        classWrapper: 'col-md-6',
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_SO_GD],
        },
      },
      false,
      {
        value: '',
        label: 'Tất cả đơn vị',
      },
    ),
    pgd: simpleOrganization(
      {
        floatingLabelText: 'PGD',
        classWrapper: 'col-md-6',
      },
      {
        value: {
          sub_type: [subTypes.TAPHUAN_SUBTYPE_PHONG_GD],
        },
      },
      false,
      {
        value: '',
        label: 'Tất cả đơn vị',
      },
    ),
    chuong_trinh: chuong_trinh(
      {
        classWrapper: 'col-md-6',
      },
      {
        excludeChuongTrinhs: [CHUONG_TRINH_03],
        shortName: true,
      },
    ),
    caps: caphoc({
      classWrapper: 'col-md-6',
    }),
  };
};

const ui = (step, values, themeConfig, xpath, formid, { userInfo }) => {
  let fields = ['pgd', 'chuong_trinh', 'caps'];

  if (isBo(userInfo) || isVGDStaff(userInfo)) {
    fields = ['sgd', ...fields];
  }
  return [
    {
      id: 'default',
      fields: fields,
    },
  ];
};

export default { schema, ui };
