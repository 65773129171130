import React from 'react';
import get from 'lodash.get';
import { t1 } from 'translate';
import CommonAntdTable from 'components/common/antd/table';
import { generateClassByStatusAssessment } from '../../utils';
import TooltipAssessment from 'components/temis/components/tooltip-assessment';

const getColumns = ({ assessment_name, score_scale, selfTasks }) => {
  const parts = get(score_scale, 'parts');
  let labelScoreById = null;
  if (Array.isArray(parts) && parts.length) {
    labelScoreById = {};
    parts.forEach(({ id, name }) => {
      labelScoreById[id] = name;
    });
  }

  return [
    {
      title: assessment_name,
      render: ({ isHeader, name, description, depth }) => {
        return {
          children: (
            <p
              style={{
                fontWeight: isHeader ? 'bold' : '',
                marginLeft: 15 * depth,
                marginBottom: 0,
              }}
            >
              <span className="m-r-5">{name}</span>
              {!isHeader && description && (
                <TooltipAssessment content={description} />
              )}
            </p>
          ),
        };
      },
    },
    {
      title: 'Kết quả tự đánh giá',
      width: '200px',
      className: 'text-center',
      render: ({ iid }) => {
        const task = selfTasks.find((selfTask) => selfTask.rubric_iid === iid);
        const answerSelected = get(labelScoreById, get(task, 'answer'));

        return (
          <span
            className={`text-center ${generateClassByStatusAssessment(
              answerSelected,
            )}`}
          >
            {answerSelected}
          </span>
        );
      },
    },
  ].filter(Boolean);
};

const MyResultAssess = ({
  selfAssess,
  dataSource,
  assessment_name,
  score_scale,
}) => {
  if (!Array.isArray(dataSource) || !dataSource.length) {
    return <div>{t1('there_are_no_rubric_to_assess')}</div>;
  }

  const selfTasks = (selfAssess && selfAssess.task) || [];

  return (
    <CommonAntdTable
      className="learn-default temis-self-assessment-table"
      columns={getColumns({
        assessment_name,
        score_scale,
        dataSource,
        selfTasks,
      })}
      dataSource={dataSource}
      pagination={false}
      bordered
      size="middle"
    />
  );
};

export default MyResultAssess;
