import React from 'react';
import { t1 } from 'translate/index';
import { organizations } from 'components/admin/organization/schema/elements/index';
import get from 'lodash.get';
import subTypes from 'configs/constants/org-sub-types';

// in organization component, that using useMemo and dept on disabledBySubType.
// We have to define a variable to keep pointer to object subTypes. It will make dept is not change because same pointer
const disabledBySubType = [
  subTypes.TAPHUAN_SUBTYPE_PHONG_GD,
  subTypes.TAPHUAN_SUBTYPE_BO_GD,
  subTypes.TAPHUAN_SUBTYPE_SO_GD,
];

const schema = (formid, values, step, xpath, props, domainInfo) => ({
  text: {
    type: 'text',
    hintText: 'Nhập Tên, Mã hoặc Email',
    floatingLabelText: 'Tìm kiếm CBQL',
    fullWidth: true,
  },
  user_organizations: organizations({
    formid,
    multiple: false,
    label: `${t1('organizations')}`,
    defaultValue: props.orgIids,
    subTypesDiscarded: props.subTypesDiscarded,
    disabledBySubType: disabledBySubType.concat(
      get(domainInfo, 'conf.enable_assessment_for_mamnon')
        ? []
        : [subTypes.TAPHUAN_SUBTYPE_MAM_NON],
    ),
  }),
});

const ui = () => [
  {
    id: 'default',
    fields: ['user_organizations', 'text'],
  },
];

const LayoutFreeStyle = ({ groups, submitButton }) => (
  <div className="row search-form-assessment">
    <div className="col-md-5">
      {get(groups, 'default.fieldNames.user_organizations')}
    </div>
    <div className="col-md-5">{get(groups, 'default.fieldNames.text')}</div>
    <div className="col-md-2 text-center m-t-5">{submitButton}</div>
  </div>
);

const layout = () => ({
  component: LayoutFreeStyle,
  freestyle: 1,
});

export default {
  schema,
  ui,
  layout,
};
