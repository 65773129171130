import React, { Component } from 'react';
import { t1 } from 'translate';
import get from 'lodash.get';
import { timestampToDateString } from 'common/utils/Date';
import CommonAntdTable from 'components/common/antd/table';
import StatusAssessmentTeacher from './status-assessment';
import { leaderPositionToText } from 'configs/constants/user';

class TableResult extends Component {
  getColumns = () => {
    const { scholastic } = this.props;

    return [
      {
        title: t1('name'),
        width: 180,
        key: 'name',
        render: (item) => get(item, 'name'),
      },
      {
        title: t1('birthday'),
        width: 130,
        key: 'birthday',
        render: (item) => {
          return (
            <div>
              {timestampToDateString(get(item, 'birthday'), {
                showTime: false,
                splitter: '/',
              })}
            </div>
          );
        },
      },
      {
        title: t1('mail'),
        key: 'mail',
        width: 150,
        render: (item) => get(item, 'mail'),
      },
      {
        title: t1('organizations'),
        key: 'organizations',
        render: (item) => (
          <>
            <div> {get(item, 'organization_name')}</div>
            <div>{get(item, '__expand.phongbans[0].name')}</div>
          </>
        ),
      },
      {
        title: 'Chức vụ',
        key: 'position',
        width: 100,
        render: (position, item) => {
          return leaderPositionToText(get(item, 'leader_position'));
        },
      },
      {
        title: 'Trạng thái đánh giá',
        key: 'status-assessment',
        render: (item) => {
          return (
            <StatusAssessmentTeacher item={item} scholastic={scholastic} />
          );
        },
      },
    ];
  };

  render() {
    const { items } = this.props;

    return (
      <div>
        <CommonAntdTable
          columns={this.getColumns()}
          dataSource={Array.isArray(items) ? items : null}
          pagination={false}
          bordered
          size="middle"
          className="learn-default"
        />
      </div>
    );
  }
}

export default TableResult;
