import React, { Fragment } from 'react';
import { t1 } from 'translate/index';
import { organizations } from 'components/admin/organization/schema/elements/index';
import { required } from 'common/validators/index';
import get from 'lodash.get';
import taphuanSubTypes from 'configs/constants/org-sub-types';

const schema = (formid, values, step, xpath, props) => {
  return {
    user_organizations: organizations({
      formid,
      multiple: false,
      label: `${t1('organizations')}`,
      defaultValue: props.orgIids,
      hasSearchDialog: true,
    }),
    text: {
      type: 'text',
      hintText: 'Nhập Tên, Mã hoặc Email',
      floatingLabelText: 'Tìm kiếm CBQL',
      fullWidth: true,
    },
    passed: {
      type: 'radio',
      fullWidth: true,
      options: [
        {
          label: t1('Hiển thị tất cả các giáo viên'),
          value: 'all',
        },
        {
          label: t1('TCNN >= Khá'),
          value: 'rather',
        },
      ],
      defaultValue: 'all',
    },
    passed_rather: {
      type: 'multiCheckbox',
      label: t1('tick_if_send_all'),
      fullWidth: true,
      options: [
        {
          label: 'Năm KN >= 2',
          value: 'teaching_exp_years',
        },
        {
          label: 'Có nguyện vọng trở thành CBQL',
          value: 'have_desire_to_become_master',
        },
      ],
    },
  };
};

const ui = (step, values) => {
  const fields = ['user_organizations', 'text', 'passed'];

  if (values.passed === 'rather') {
    fields.push('passed_rather');
  }

  return [
    {
      id: 'default',
      fields,
    },
  ];
};

const LayoutFreeStyle = ({ groups, submitButton }) => (
  <Fragment>
    <div className="row search-form-assessment">
      <div className="col-md-5">
        {get(groups, 'default.fieldNames.user_organizations')}
      </div>
      <div className="col-md-5">{get(groups, 'default.fieldNames.text')}</div>
      <div className="col-md-2 text-center m-t-5">{submitButton}</div>
    </div>
    <div className="wrap-radio row">
      <div className="col-md-5 m-t-10 m-b-30">
        {get(groups, 'default.fieldNames.passed')}
      </div>
      <div className="col-md-5">
        {get(groups, 'default.fieldNames.passed_rather')}{' '}
      </div>
    </div>
  </Fragment>
);

const layout = () => ({
  component: LayoutFreeStyle,
  freestyle: 1,
});

export default {
  schema,
  ui,
  layout,
};
