import React from 'react';
import NodeNew from 'components/admin/node/new';
import endpoints from 'components/bdtx/training-phase/endpoints';
import schema from 'components/bdtx/training-phase/mainstage/new/schema';
import { withRouter } from 'react-router';
import Modal from 'antd/lib/modal';
import { t1 } from 'translate';
import {
  getUrlByTrainingPhaseIid,
  MAIN_STAGE_VIEW_TYPE,
} from 'components/bdtx/training-phase/mainstage/routes';

const FORM_ID = 'new-training-phase';

const NewTrainingPhase = ({
  node,
  readOnly,
  deployed,
  hiddenFields = {},
  searchFormId,
  mode,
  formid,
  history,
  alternativeApi = endpoints.bdtx_new_training_phase,
  onEditSuccessful,
}) => {
  const gotoTrainingPlan = () => {
    window.location.href = getUrlByTrainingPhaseIid(
      node.iid,
      MAIN_STAGE_VIEW_TYPE.ASSIGN,
    );
  };

  const requestSuccessful = () => {
    if (mode === 'edit') {
      Modal.confirm({
        centered: true,
        title: 'Có sự thay đổi về đợt đào tạo',
        content:
          'Bạn vừa thay đổi đợt đào tạo, điều này sẽ ảnh hưởng đến kế hoạch đào tạo, bạn có muốn thay đổi kế hoạch đào tạo?',
        onOk() {
          gotoTrainingPlan();
        },
        cancelText: t1('cancel'),
        okText: 'Sang màn hình kế hoạch đào tạo',
      });
      if (typeof onEditSuccessful === 'function') {
        onEditSuccessful();
      }
    }
  };

  return (
    <NodeNew
      schema={schema}
      hiddenFields={hiddenFields}
      formid={formid || FORM_ID}
      alternativeApi={alternativeApi}
      searchFormId={searchFormId}
      resetForm={false}
      node={node}
      readOnly={readOnly}
      mode={mode || 'new'}
      requestSuccessful={requestSuccessful}
      deployed={deployed}
    />
  );
};

export default withRouter(NewTrainingPhase);
