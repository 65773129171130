import React from 'react';
import get from 'lodash.get';

const Layout = (props) => {
  const fieldNames = get(props, 'groups.default.fieldNames', {});
  const readOnly = get(props, 'layoutOptionsProperties.readOnly');
  const attachments = get(props, 'layoutOptionsProperties.attachments');

  return (
    <>
      <div>{fieldNames.attachments}</div>
      <div className="m-t-20">{fieldNames.opinions}</div>
      <div className="m-t-20">
        Các ý kiến khác (Ghi rõ):
        <div>
          <div>{fieldNames.good_things}</div>
          <div>{fieldNames.bad_things}</div>
        </div>
      </div>

      {!readOnly && (
        <div className="text-center m-t-25">
          {!attachments.length && (
            <div className="form-validate-error text-center m-b-10">
              Vui lòng tải File đính kèm BM03/PL2 CV4529
            </div>
          )}
          <button className="btn btn-secondary text-center" disabled={readOnly}>
            Lưu
          </button>
        </div>
      )}
    </>
  );
};

export default Layout;
