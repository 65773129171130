import React from 'react';
import debounce from 'lodash.debounce';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/react-quill.min';
import { ImageResize } from './lib/ImageResize.js';
import CustomToolbar from './CustomToolbar';
import './stylesheet.scss';
import Help from '../Help';
import get from 'lodash.get';

Quill.register('modules/imageResize', ImageResize);

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'direction',
  'color',
  'background',
  'font',
  'align',
  'script',
  'super',
  'clean',
];

export default class QuillComponent extends React.Component {
  spanStyle = { color: 'rgba(0, 0, 0, 0.8)' };
  cssClass = 'quill-text-wrapper';

  constructor(props) {
    super(props);
    this.randomId = `richtext-toolbar-${Math.floor(Math.random() * 1000000)}`;

    this.modules = {
      toolbar: {
        // container: toolbarOptions, // `#${this.randomId}`,
        container: `#${this.randomId}`,
        handlers: {
          imageV1: this.handleImageV1,
          image: () => {},
        },
      },
      history: {
        delay: 10000,
        maxStack: 50,
        userOnly: false,
      },
      imageResize: {
        displaySize: true,
      },
    };

    // fix bug quill component performance slow issue
    const { debounceSaveTime = 300 } = this.props;

    this.onChangeDebounced = debounce(this.onChange, debounceSaveTime);

    this.cursorIndex = React.createRef();
  }

  handleImageV1 = () => {
    const quill = this.quill;
    this.props.openMediaManagerDialog();
    this.props.pushQuillJSAction(quill);
  };

  onChange = (content) => {
    if (this.props.onChange) {
      const value = content === '<p><br></p>' ? '' : content;

      this.props.onChange(value);
    }
  };

  onBlur = (range, source, quill) => {
    const { onBlur } = this.props;

    if (onBlur && typeof onBlur === 'function') {
      const value = (quill.getHTML() || '').trim();

      onBlur(value);
    }
  };

  handleImageSelect = (url) => {
    const quill = this.quill.editor;

    quill.insertEmbed(this.cursorIndex.current, 'image', url, 'user');
  };

  render() {
    const {
      value,
      floatingLabelText,
      guide,
      onBlur,
      errorText,
      preserveWhitespace = true,
      defaultValue,
    } = this.props;

    let selectorId = this.props.selectorId ? this.props.selectorId : 'quill';
    let className = this.props.className ? this.props.className : '';

    className += selectorId;
    selectorId = `.${selectorId}`;

    // fix editor khi làm bài tập câu OE gõ bị mất text
    // Nếu mà mode readOnly thì sẽ lấy value và không có onChange
    // Còn mode không phải readOnly thì sẽ lấy defaultValue và có onChange
    const propsModeReadOnlyOrNotReadOnly = {
      ...(this.props.readOnly
        ? {
            value,
          }
        : {}),
      ...(!this.props.readOnly
        ? {
            defaultValue: value || defaultValue,
            onChange: this.onChangeDebounced,
          }
        : {}),
    };

    return (
      <div className={`wrapper-quill ${errorText ? `has-error` : ''}`}>
        <span style={this.spanStyle} className="ant-form-item-label">
          {floatingLabelText} <Help guide={guide} />
        </span>

        <CustomToolbar
          id={this.randomId}
          onImageSelect={this.handleImageSelect}
        />

        <ReactQuill
          ref={(el) => {
            this.quill = el;
          }}
          {...propsModeReadOnlyOrNotReadOnly}
          theme="snow"
          modules={this.modules}
          formats={formats}
          onChangeSelection={(range, source, editor) => {
            const index = get(range, 'index');
            if (index) {
              this.cursorIndex.current = get(range, 'index');
            }
          }}
          bounds={selectorId}
          className={className}
          readOnly={this.props.readOnly}
          onBlur={this.onBlur}
          preserveWhitespace={preserveWhitespace}
        />

        {errorText && <div className="form-validate-error">{errorText}</div>}
      </div>
    );
  }
}
