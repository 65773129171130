import React, { Component } from 'react';
import Widget from 'components/common/Widget';
import TemisTemplatelessEvidence from './templateless-evidence';
import './stylesheets.scss';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';

class TemisEvidence extends Component {
  render() {
    const { scholastic, isOutOfDeadlineAssessment } = this.props;

    return (
      <div className="temis-evidence">
        <Widget>
          <TemisTemplatelessEvidence
            viewOnly={isOutOfDeadlineAssessment}
            scholastic={scholastic}
          />
        </Widget>
      </div>
    );
  }
}

export default withOutOfDeadlineAssessment(withScholastic(TemisEvidence));
