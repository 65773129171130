import React, { Component } from 'react';
import Widget from 'components/common/Widget';
import BDTXReportSearch from './component/Form';
import withUserInfo from 'common/hoc/withUserInfo';
import { TCNN_TYPE } from 'components/temis/constant';
import { generateReportTitle } from 'components/temis/utils';
import { convertStringToElement } from '../utils';

class BDTXTruong extends Component {
  render() {
    const {
      userInfo = {},
      scholastic,
      isResultForCBQL,
      isResultForMN,
      reportTitle,
    } = this.props;

    const { organizations = [] } = userInfo;
    const school = organizations[0];
    const excelExportFileName = generateReportTitle(reportTitle);

    return (
      <div className="report-result">
        <div className="report-result__organization m-b-10">
          <div className="report-result__school">{`Trường: ${!!school &&
            school.name}`}</div>
        </div>
        <div className="report-result__school-export-excel">
          <div className="report-result__tnnn-type">
            {convertStringToElement(reportTitle)}
          </div>
        </div>
        <hr />
        <div>
          <div className="report-result__container">
            <Widget className="title-default">
              <BDTXReportSearch
                tcnn={isResultForCBQL ? TCNN_TYPE.HT : TCNN_TYPE.GV}
                scholastic={scholastic}
                exportProps={{
                  fileName: excelExportFileName,
                }}
                userInfo={userInfo}
                isResultForCBQL={isResultForCBQL}
                isResultForMN={isResultForMN}
              />
            </Widget>
          </div>
        </div>
      </div>
    );
  }
}

export default withUserInfo(BDTXTruong);
