import React from 'react';
import get from 'lodash.get';
import { isAdminTruongOrHieuTruongOrPho } from 'components/admin/user/utils';
import withTemisConfig from 'common/hoc/withTemisConfig';
import { t1 } from 'translate/index';
import { userGradeToText } from 'configs/constants/user';
import { generateClassByStatusAssessment } from '../../../utils';
import CommonAntdTable from 'components/common/antd/table';
import { formatNumber } from 'common/utils/Number';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { TCNN_TYPE } from 'components/temis/constant';

const isTCNNHT = (tcnn) => tcnn === TCNN_TYPE.HT;
const isTCNNGV = (tcnn) => tcnn === TCNN_TYPE.GV;

const getColumns = (scoreScales, tcnn, userRoot, numberOfDataSource = 0) =>
  [
    {
      title: isAdminTruongOrHieuTruongOrPho(userRoot) ? '' : t1('school_level'),
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: ({ school_level, isChildren, name }) => {
        return {
          children: isChildren ? (
            t1(name)
          ) : (
            <span style={{ fontWeight: 600 }}>
              {isAdminTruongOrHieuTruongOrPho(userRoot) &&
              numberOfDataSource === 1
                ? 'Tất cả'
                : userGradeToText(school_level)}
            </span>
          ),
          props: {
            style: { minWidth: 120 },
          },
        };
      },
    },
    !isTCNNHT(tcnn) && {
      title: 'Tổng số giáo viên',
      type: TYPE_OF_TABLE_DATA.NUMBER,
      render: ({ total }) => {
        return {
          children: formatNumber(total) || 0,
        };
      },
    },
    !isTCNNHT(tcnn) && {
      title: 'Tổng số giáo viên đã đánh giá',
      type: TYPE_OF_TABLE_DATA.NUMBER,
      render: ({ total_assessed }) => {
        return {
          children: formatNumber(total_assessed) || 0,
        };
      },
    },
  ]
    .concat(
      Array.isArray(scoreScales) && scoreScales.length > 0
        ? scoreScales.map((scoreScale) => {
            return {
              title: (
                <span
                  className={`${generateClassByStatusAssessment(
                    get(scoreScale, 'name'),
                  )}`}
                >
                  {get(scoreScale, 'name')}
                </span>
              ),
              children: ['amount_of_people', 'percentage'].map(
                (reportType) => ({
                  title: `${t1(`report_type_by_${reportType}`)} (${
                    reportType === 'amount_of_people' ? t1('people') : '%'
                  })`,
                  render: ({ total_assessed, detail }) => {
                    const dataByScaleId = Array.isArray(detail)
                      ? detail.find(
                          ({ id }) => String(id) === String(scoreScale.id),
                        )
                      : {};

                    let text = get(dataByScaleId, 'total') || 0;
                    if (
                      reportType === 'percentage' &&
                      total_assessed > 0 &&
                      text
                    ) {
                      text = parseFloat(
                        ((text / total_assessed) * 100).toFixed(1),
                      );
                    }
                    return {
                      children: total_assessed > 0 ? formatNumber(text) : '',
                      props: {
                        className: 'text-right',
                        width: !isTCNNHT(tcnn) ? '7%' : '10%',
                      },
                    };
                  },
                }),
              ),
            };
          })
        : [],
    )
    .filter(Boolean);

const getPropsToRenderTable = (data, userRoot, tcnn) => {
  if (!Array.isArray(data) || !data.length) {
    return { dataSource: [], expandedRowKeys: [] };
  }

  let indexRowKey = 0;
  const expandedRowKeys = [];
  const dataSource = data
    .map(
      (
        {
          female_in_there,
          dtts_in_there,
          female_dtts_in_there,
          poor_area_in_there,
          leader_in_there,
          vice_leader_in_there,
          ...row
        },
        index,
      ) => {
        const id = `${row.school_level}_${++indexRowKey}`;
        row.id = id;

        if (!row.total && isAdminTruongOrHieuTruongOrPho(userRoot)) {
          return false;
        }

        if (!row.total) {
          return row;
        }

        let children = [];

        const childrenProps = {
          school_level: row.school_level,
          isChildren: true,
        };

        const buildChildren = (dataChildren = {}, id) => {
          const fields = [
            'female_in_there',
            'dtts_in_there',
            'female_dtts_in_there',
            'poor_area_in_there',
          ];
          return fields.map((field) => ({
            ...(!!dataChildren
              ? dataChildren[field]
              : data[index][field] || {}),
            ...childrenProps,
            name: field,
            id: `${id}_${field}`,
          }));
        };

        if (
          (isTCNNHT(tcnn) && !leader_in_there && !vice_leader_in_there) ||
          (isTCNNGV(tcnn) &&
            !female_in_there &&
            !dtts_in_there &&
            !female_dtts_in_there &&
            !poor_area_in_there)
        ) {
          return row;
        } else if (isTCNNHT(tcnn)) {
          children = [
            {
              ...(leader_in_there || {}),
              isChildren: true,
              school_level: row.school_level,
              name: 'leader_in_there',
              id: `${id}_leader_in_there`,
              // children: buildChildren(leader_in_there, id), // TODO: Will show when API for Phong/So ready
            },
            {
              ...(vice_leader_in_there || {}),
              school_level: row.school_level,
              isChildren: true,
              name: 'vice_leader_in_there',
              id: `${id}_vice_leader_in_there`,
              // children: buildChildren(vice_leader_in_there, id), // TODO: Will show when API for Phong/So ready
            },
          ];
        } else if (isTCNNGV(tcnn) && isAdminTruongOrHieuTruongOrPho(userRoot)) {
          children = buildChildren(null, id);
        } else {
          return row;
        }

        expandedRowKeys.push(id);
        row.children = children;
        return row;
      },
    )
    .filter(Boolean);

  return {
    dataSource,
    expandedRowKeys,
  };
};

const TableResult = ({ dataResult, tcnn, userRoot }) => {
  const { dataSource, expandedRowKeys } = getPropsToRenderTable(
    get(dataResult, 'data'),
    userRoot,
    tcnn,
  );

  const scoreScale = get(dataResult, 'score_scale');

  return (
    <CommonAntdTable
      bordered
      rowKey="id"
      size="middle"
      pagination={false}
      // expandIcon={() => <span />}
      // defaultExpandAllRows
      className="white-background learn-default"
      columns={getColumns(scoreScale, tcnn, userRoot, dataSource.length)}
      // onExpand={null}
      // expandedRowKeys={expandedRowKeys}
      dataSource={Array.isArray(dataSource) ? dataSource : []}
      indentSize={20}
    />
  );
};

export default withTemisConfig(TableResult);
