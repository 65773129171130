import React, { Component } from 'react';
import { t3 } from 'translate';
import ChangePasswordUser from 'components/admin/user/system/change-by-field/Form';

class ChangePassword extends Component {
  render() {
    const { user } = this.props;
    if (!user) return null;

    return (
      <div className="change-password">
        <div className="text-center">
          <h1
            className="uppercase change-password-title"
            style={{ marginBottom: 0 }}
          >
            {t3('change_pass')}
          </h1>
        </div>

        <ChangePasswordUser
          formid="edit_user_change_pass"
          ntype="user"
          mode="edit"
          step="change_pass"
          node={user}
          schoolScope
        />
      </div>
    );
  }
}

export default ChangePassword;
