import React from 'react';
import {
  isSo,
  isPhong,
  isBoOrAdminSP,
  isAdminTruongOrHieuTruongOrPho,
  isAmTinh,
} from 'components/admin/user/utils';
import withTemisConfig from 'common/hoc/withTemisConfig';
import get from 'lodash.get';
import BDTXTruong from './BDTXTruong';
import BDTXPhong from './BDTXPhong';
import BDTXSo from './BDTXSo';
import BDTXBo from './BDTXBo';
import { getReportBTitle, reportBTitle } from '../utils';

import TabList from '../../components/tabs';
import withScholastic from 'components/temis/components/scholastic/withScholastic';

const TemisReport = ({ userRoot, scholastic, ...props }) => {
  const isResultForCBQL = get(props, 'route.isResultForCBQL', false);
  const reportTitle = getReportBTitle(isResultForCBQL);

  const tabs = [
    isAdminTruongOrHieuTruongOrPho(userRoot) && {
      name: 'bdtx_truong',
      label: 'BDTX trường',
      component: (
        <BDTXTruong
          scholastic={scholastic}
          isResultForCBQL={isResultForCBQL}
          reportTitle={reportTitle}
        />
      ),
    },
    isPhong(userRoot) && {
      name: 'bdtx_p',
      label: 'BDTX Phòng',
      component: (
        <BDTXPhong
          scholastic={scholastic}
          isResultForCBQL={isResultForCBQL}
          reportTitle={reportTitle}
        />
      ),
    },
    (isSo(userRoot) || isAmTinh(userRoot)) && {
      name: 'bdtx_so',
      label: 'BDTX Sở',
      component: (
        <BDTXSo
          scholastic={scholastic}
          isResultForCBQL={isResultForCBQL}
          reportTitle={reportTitle}
        />
      ),
    },
    isBoOrAdminSP(userRoot) && {
      name: 'bdtx_bo',
      label: 'BDTX Bộ',
      component: (
        <BDTXBo
          scholastic={scholastic}
          isResultForCBQL={isResultForCBQL}
          reportTitle={reportTitle}
        />
      ),
    },
  ].filter(Boolean);

  return <TabList tabList={tabs} key={scholastic} />;
};

export default withScholastic(withTemisConfig(TemisReport));
