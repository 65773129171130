import withTemisConfig from 'common/hoc/withTemisConfig';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import CommonAntdTable from 'components/common/antd/table';
import { TCNN_TYPE } from 'components/temis/constant';
import get from 'lodash.get';
import React from 'react';
import { generateClassByStatusAssessment } from '../../../utils';
import { getNumberFormat } from '../../utils';

const scoreScaleTypes = ['amount_of_people', 'percentage'];

const getTitleColumnForResult = (isResultForCBQL, isSelfAssessment) => {
  let title = '';
  if (isSelfAssessment) {
    title = isResultForCBQL
      ? 'Kết quả tự đánh giá của HT/Phó HT'
      : 'Kết quả tự đánh giá của GV';
  } else {
    title = isResultForCBQL
      ? 'Kết quả xếp loại của thủ trưởng'
      : 'Kết quả xếp loại của cơ sở giáo dục';
  }

  return title;
};

const getScoreScaleTitle = (type) => {
  switch (type) {
    case 'amount_of_people':
      return 'SL (Người)';
    case 'percentage':
      return 'Tỷ lệ (%)';
    default:
      return '';
  }
};

const getTitleColumnForTotal = (isResultForCBQL, isTotalSelfAssessment) => {
  let title = '';
  if (isTotalSelfAssessment) {
    title = isResultForCBQL
      ? 'Tổng số CBQL đã tự đánh giá và xếp loại'
      : 'Tổng số GV đã tự đánh giá và xếp loại';
  } else {
    title = isResultForCBQL ? 'Tổng số CBQL' : 'Tổng số GV';
  }

  return title;
};

const getTotal = (
  { data_report = {} },
  queryKey,
  reportType,
  scoreScaleId,
  skipFormatNumber,
) => {
  const { total_assessed, detail = {} } = data_report;
  const totalAssessed = get(total_assessed, queryKey, 0);
  const data = get(detail, queryKey);

  const dataByScaleId = Array.isArray(data)
    ? data.find(({ id }) => String(id) === String(scoreScaleId))
    : {};

  let total = get(dataByScaleId, 'total') || 0;
  if (reportType === 'percentage' && totalAssessed > 0 && total) {
    total = parseFloat(((total / totalAssessed) * 100).toFixed(1));
  }

  return totalAssessed > 0 ? getNumberFormat(total, skipFormatNumber) : '';
};

const buildScoreScalesChildren = (
  scoreScales = [],
  queryKey,
  skipFormatNumber,
  getColSpanByRow,
) => {
  return scoreScales.map((scoreScale) => {
    const name = get(scoreScale, 'name');

    return {
      title: (
        <span className={`${generateClassByStatusAssessment(name)}`}>
          {name}
        </span>
      ),
      children: scoreScaleTypes.map((reportType) => ({
        title: getScoreScaleTitle(reportType),
        type: TYPE_OF_TABLE_DATA.NUMBER,
        width: 70,
        render: (row) => {
          return {
            children: getTotal(
              row,
              queryKey,
              reportType,
              scoreScale.id,
              skipFormatNumber,
            ),
            props: {
              colSpan: getColSpanByRow(row),
            },
          };
        },
      })),
    };
  });
};

const getColumns = (
  scoreScales,
  tcnn,
  userRoot,
  numberOfDataSource = 0,
  isResultByRubric,
  isResultForCBQL,
  skipFormatNumber,
) => {
  const getColSpanByRow = ({ iid, id }, merge = false) => {
    return iid || id ? 1 : merge ? 7 + 2 * 2 * scoreScales.length : 0;
  };

  return [
    {
      title: 'Đơn vị',
      width: 200,
      type: TYPE_OF_TABLE_DATA.TEXT,
      render: ({ name, iid, id }) => {
        return {
          children: name,
          props: {
            colSpan: getColSpanByRow({ iid, id }, true),
          },
        };
      },
    },
    {
      title: getTitleColumnForTotal(isResultForCBQL),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: 'Tổng (Người)',
          width: 100,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: (row) => {
            const total = get(row, 'data_report.total');

            return {
              children: getNumberFormat(total, skipFormatNumber) || 0,
              type: TYPE_OF_TABLE_DATA.NUMBER,
              width: 80,
              props: {
                className: 'text-right',
                colSpan: getColSpanByRow(row),
              },
            };
          },
        },
        {
          title: '% tăng giảm so với năm trước',
          width: 100,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: (row) => {
            return {
              children: null,
              type: TYPE_OF_TABLE_DATA.NUMBER,
              width: 100,
              props: {
                className: 'text-right',
                colSpan: getColSpanByRow(row),
              },
            };
          },
        },
      ],
    },
    {
      title: 'Tổng số xếp loại của thủ trưởng',
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: 'Tổng (Người)',
          width: 100,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: (row) => {
            const totalGradedOfCsgd = get(
              row,
              'data_report.total_assessed.csgd',
              0,
            );
            return {
              children:
                getNumberFormat(totalGradedOfCsgd, skipFormatNumber) || 0,
              props: {
                className: 'text-right',
                colSpan: getColSpanByRow(row),
              },
            };
          },
        },
        {
          title: 'Tổng (%)',
          width: 80,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: (row) => {
            const totalGradedOfCsgd = get(
              row,
              'data_report.total_assessed.csgd',
              0,
            );
            const total = get(row, 'data_report.total');
            const text =
              total > 0 &&
              parseFloat(((totalGradedOfCsgd / total) * 100).toFixed(1));

            return {
              children:
                total > 0 ? getNumberFormat(text, skipFormatNumber) : '',
              props: {
                className: 'text-right',
                colSpan: getColSpanByRow(row),
              },
            };
          },
        },
      ],
    },
    {
      title: getTitleColumnForTotal(isResultForCBQL, true),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: 'Tổng (Người)',
          width: 100,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: (row) => {
            const totalSelfAssessAndGraded = get(
              row,
              'data_report.total_assessed.tudanhgia',
              0,
            );
            const total = get(row, 'data_report.total');
            return {
              children:
                getNumberFormat(totalSelfAssessAndGraded, skipFormatNumber) ||
                0,
              props: {
                className: 'text-right',
                colSpan: getColSpanByRow(row),
              },
            };
          },
        },
        {
          title: 'Tổng (%)',
          width: 80,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: (row) => {
            const totalSelfAssessAndGraded = get(
              row,
              'data_report.total_assessed.tudanhgia',
              0,
            );
            const total = get(row, 'data_report.total');

            const text =
              total > 0 &&
              parseFloat(((totalSelfAssessAndGraded / total) * 100).toFixed(1));
            return {
              children:
                total > 0 ? getNumberFormat(text, skipFormatNumber) : '',
              props: {
                className: 'text-right',
                colSpan: getColSpanByRow(row),
              },
            };
          },
        },
      ],
    },
    {
      title: getTitleColumnForResult(isResultForCBQL),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: buildScoreScalesChildren(
        scoreScales,
        'csgd',
        skipFormatNumber,
        getColSpanByRow,
      ),
    },
    {
      title: getTitleColumnForResult(isResultForCBQL, true),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: buildScoreScalesChildren(
        scoreScales,
        'tudanhgia',
        skipFormatNumber,
        getColSpanByRow,
      ),
    },
  ];
};

const getPropsToExpandedRowKeys = (dataResult, flatExpandChildren) => {
  if (!Array.isArray(dataResult) || !dataResult.length) {
    return [];
  }

  return dataResult.reduce((result, { id, children }) => {
    result.push(id);

    if (!flatExpandChildren) {
      return result;
    }

    return result.concat(
      getPropsToExpandedRowKeys(children, flatExpandChildren),
    );
  }, []);
};

const formatDataSourceFromDataServerToRenderTable = (
  dataResult,
  flatExpandChildren,
  subTypesOfChildrenDisplay = [],
  level = 0,
) => {
  if (!Array.isArray(dataResult)) {
    return [];
  }

  return dataResult
    .map(({ data_report, sub_type, children, ...organization }) => {
      if (
        level &&
        (!Array.isArray(subTypesOfChildrenDisplay) ||
          !subTypesOfChildrenDisplay.includes(sub_type))
      ) {
        return false;
      }

      const dataReport = Array.isArray(data_report)
        ? data_report.find(({ school_level }) => school_level === 'all_school')
        : {};
      organization.data_report = dataReport || {};

      if (Array.isArray(children) && children.length) {
        const newChildren = formatDataSourceFromDataServerToRenderTable(
          children,
          flatExpandChildren,
          subTypesOfChildrenDisplay,
          level + 1,
        );

        if (Array.isArray(newChildren) && newChildren.length) {
          organization.children = newChildren;
        }
      }

      return organization;
    })
    .filter(Boolean);
};

const TableResultsTree = ({
  dataResult,
  scoreScale,
  tcnn,
  userRoot,
  isResultForCBQL,
  isResultByRubric,
  flatExpandChildren = false,
  subTypesOfChildrenDisplay = [],
  skipFormatNumber = false,
}) => {
  const expandedRowKeys = getPropsToExpandedRowKeys(
    dataResult,
    flatExpandChildren,
  );
  const dataSource = formatDataSourceFromDataServerToRenderTable(
    dataResult,
    flatExpandChildren,
    subTypesOfChildrenDisplay,
  );

  const props = {
    bordered: true,
    rowKey: 'id',
    pagination: false,
    className: 'white-background learn-default',
    dataSource,
    scroll: { x: true },
  };

  if (flatExpandChildren) {
    props.expandedRowKeys = expandedRowKeys;
  }

  return (
    <CommonAntdTable
      {...props}
      columns={getColumns(
        scoreScale,
        tcnn,
        userRoot,
        dataSource.length,
        isResultByRubric,
        isResultForCBQL,
        skipFormatNumber,
      )}
    />
  );
};

export default withTemisConfig(TableResultsTree);
