import React, { Component } from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import TableResult from './TableResult';
import withUserInfo from 'common/hoc/withUserInfo';
import withScholastic from 'components/temis/components/scholastic/withScholastic';
import taphuanSubTypes from 'configs/constants/org-sub-types';
import { leaderPositions } from 'configs/constants/user';
import './style.scss';

const searchFormId = 'temis-result-assessment';

class ResultAssessment extends Component {
  renderResultComponent = (items, { total }) => {
    const { scholastic } = this.props;

    return (
      <TableResult
        items={items}
        totalItems={total}
        searchFormId={searchFormId}
        scholastic={scholastic}
      />
    );
  };

  renderNoResultComponent = () => <SimpleNoResult />;

  render() {
    const { scholastic } = this.props;
    return (
      <SearchWrapper
        key={`${searchFormId}_${scholastic}`}
        formid={searchFormId}
        alternativeApi="/user/api/search"
        schema={schema}
        renderResultsComponent={this.renderResultComponent}
        renderNoResultComponent={this.renderNoResultComponent}
        classPaginationWrapper="pagination-default"
        hiddenFields={{
          scholastic,
          include_sub_organizations: 1,
          block_users_in_organization_inactive: 1,
          nin_sub_types_organizations: [
            taphuanSubTypes.TAPHUAN_SUBTYPE_BO_GD,
            taphuanSubTypes.TAPHUAN_SUBTYPE_SO_GD,
            taphuanSubTypes.TAPHUAN_SUBTYPE_PHONG_GD,
          ],
          _sand_step: 'students',
          _sand_expand: [
            'user_organizations',
            'positions',
            'phongbans',
            'temis',
          ],
        }}
        defaultValues={{
          leader_position: [
            leaderPositions.LEADER,
            leaderPositions.VICE_LEADER,
            leaderPositions.TEACHER,
          ],
        }}
      />
    );
  }
}

export default withScholastic(withUserInfo(ResultAssessment));
