import React, { Component } from 'react';
import Widget from 'components/common/Widget';
import TCNNReportSearch from '../components/SearchForm';
import withUserInfo from 'common/hoc/withUserInfo';
import ExportData from 'components/common/export-data';
import { EXPORT_FILENAME, TCNN_TYPE } from '../../constant';
import ReportProgressSyncerButton from '../syncer';
import { compose } from 'redux';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import lastTimeFinishedSyncing from '../syncer/lastTimeFinishedSyncing';

const TCNNTruong = ({
  userInfo = {},
  scholastic,
  isOutOfDeadlineAssessment,
}) => {
  const [
    lastTimeFinishedSyncingData,
    setLastTimeFinishedSyncingData,
  ] = React.useState(null);

  const { organizations = [] } = userInfo;
  const school = organizations[0];

  const formIdTCNNGV = `temis-report-tcnn_gv-self_assessment-${scholastic}`;
  const formIdCSGD = `temis-report-tcnn_gv-approve_reviews-${scholastic}`;

  return (
    <div className="report-result">
      <div className="report-result__school-export-excel">
        <div className="report-result__school">{`Trường: ${!!school &&
          school.name}`}</div>

        <div className="d-flex">
          <div className="m-t-12 m-r-10">
            {lastTimeFinishedSyncing(lastTimeFinishedSyncingData)}
          </div>
          <div className="report-result__syncer m-r-10">
            <ReportProgressSyncerButton
              formIds={[formIdTCNNGV, formIdCSGD]}
              params={{
                scholastic,
              }}
              disabled={isOutOfDeadlineAssessment}
            />
          </div>
          <div className="report-result__export-excel">
            <ExportData
              apiExport="/temis/export/export-overall-result-assessment-report-in-organization"
              buttonText="Xuất excel BM1/PL3"
              formId="search-score-by-rubric"
              params={{
                scholastic,
              }}
              fileName={EXPORT_FILENAME.principal.BM1_PL3}
            />
          </div>
        </div>
      </div>

      <hr />

      <div>
        <div className="report-result__container">
          <Widget
            title="1. Kết quả tự đánh giá của giáo viên"
            className="title-default"
          >
            <TCNNReportSearch
              tcnn={TCNN_TYPE.GV}
              hideChart
              type="self_assessment"
              scholastic={scholastic}
              formIdDefault={formIdTCNNGV}
              setLastTimeFinishedSyncingData={setLastTimeFinishedSyncingData}
            />
          </Widget>
        </div>
        <div className="report-result__container">
          <Widget
            title="2. Kết quả đánh giá của cơ sở giáo dục phổ thông"
            className="title-default"
          >
            <TCNNReportSearch
              tcnn={TCNN_TYPE.GV}
              hideChart
              type="approve_reviews"
              scholastic={scholastic}
              formIdDefault={formIdCSGD}
            />
          </Widget>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withOutOfDeadlineAssessment,
  withUserInfo,
)(TCNNTruong);
