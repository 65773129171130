import React from 'react';
import withTemisConfig from 'common/hoc/withTemisConfig';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import {
  isAdminTruongOrHieuTruongOrPho,
  isHieuTruongOrPho,
} from 'components/admin/user/utils';
import CommonAntdTable from 'components/common/antd/table';
import { TCNN_TYPE } from 'components/temis/constant';
import { userGradeToText } from 'configs/constants/user';
import get from 'lodash.get';
import { t1 } from 'translate';
import { generateClassByStatusAssessment } from '../../../utils';
import { getNumberFormat } from '../../utils';

const isTCNNHT = (tcnn) => tcnn === TCNN_TYPE.HT;
const isTCNNGV = (tcnn) => tcnn === TCNN_TYPE.GV;

const scoreScaleTypes = ['amount_of_people', 'percentage'];

const getTitleColumnForResult = (isResultForCBQL, isSelfAssessment) => {
  let title = '';
  if (isSelfAssessment) {
    title = isResultForCBQL
      ? 'Kết quả tự đánh giá của HT/Phó HT'
      : 'Kết quả tự đánh giá của GV';
  } else {
    title = isResultForCBQL
      ? 'Kết quả xếp loại của thủ trưởng'
      : 'Kết quả xếp loại của cơ sở giáo dục';
  }

  return title;
};

const getScoreScaleTitle = (type) => {
  switch (type) {
    case 'amount_of_people':
      return 'SL (Người)';
    case 'percentage':
      return 'Tỷ lệ (%)';
    default:
      return '';
  }
};

const getTitleColumnForTotal = (isResultForCBQL, isTotalSelfAssessment) => {
  let title = '';
  if (isTotalSelfAssessment) {
    title = isResultForCBQL
      ? 'Tổng số CBQL đã tự đánh giá và xếp loại'
      : 'Tổng số GV đã tự đánh giá và xếp loại';
  } else {
    title = isResultForCBQL ? 'Tổng số CBQL' : 'Tổng số GV';
  }

  return title;
};

const getTotal = (
  item,
  queryKey,
  reportType,
  scoreScaleId,
  skipFormatNumber,
) => {
  const { total_assessed, detail = {} } = item;
  const totalAssessed = get(total_assessed, queryKey, 0);
  const data = get(detail, queryKey);

  const dataByScaleId = Array.isArray(data)
    ? data.find(({ id }) => String(id) === String(scoreScaleId))
    : {};

  let total = get(dataByScaleId, 'total') || 0;
  if (reportType === 'percentage' && totalAssessed > 0 && total) {
    total = parseFloat(((total / totalAssessed) * 100).toFixed(1));
  }

  return totalAssessed > 0 ? getNumberFormat(total, skipFormatNumber) : '';
};

const buildScoreScalesChildren = (
  scoreScales = [],
  queryKey,
  skipFormatNumber,
) => {
  return scoreScales.map((scoreScale) => {
    const name = get(scoreScale, 'name');

    return {
      title: (
        <span className={`${generateClassByStatusAssessment(name)}`}>
          {name}
        </span>
      ),
      children: scoreScaleTypes.map((reportType) => ({
        title: getScoreScaleTitle(reportType),
        type: TYPE_OF_TABLE_DATA.NUMBER,
        width: 70,
        render: (item) => {
          const { isHeader } = item;
          return {
            children: getTotal(
              item,
              queryKey,
              reportType,
              scoreScale.id,
              skipFormatNumber,
            ),
            props: {
              colSpan: isHeader ? 0 : 1,
            },
          };
        },
      })),
    };
  });
};

const getColumns = (
  scoreScales,
  tcnn,
  userRoot,
  numberOfDataSource = 0,
  isResultByRubric,
  isResultForCBQL,
  skipFormatNumber,
) => {
  return [
    ...(isResultByRubric
      ? [
          {
            title: 'Tiêu chí',
            width: 200,
            render: (item) => {
              const {
                rubric_name,
                school_level,
                school_level_name,
                name,
                isHeader,
              } = item;
              return {
                children: (
                  <span
                    style={{
                      fontWeight: !name ? 'bold' : '',
                    }}
                  >
                    {rubric_name ||
                      name ||
                      school_level_name ||
                      userGradeToText(school_level)}
                  </span>
                ),
                props: {
                  colSpan: isHeader ? 23 : 1,
                },
              };
            },
          },
        ]
      : [
          {
            title: isAdminTruongOrHieuTruongOrPho(userRoot) ? '' : 'Cấp học',
            width: 200,
            type: TYPE_OF_TABLE_DATA.TEXT,
            render: ({ school_level, isChildren, name, isHeader }) => {
              return {
                children: isChildren ? (
                  name
                ) : (
                  <span style={{ fontWeight: 600 }}>
                    {userGradeToText(school_level)}
                  </span>
                ),
                props: {
                  colSpan: isHeader ? 0 : 1,
                },
              };
            },
          },
        ]),
    {
      title: getTitleColumnForTotal(isResultForCBQL),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: 'Tổng (Người)',
          width: 100,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: ({ total, isHeader }) => {
            return {
              children: getNumberFormat(total, skipFormatNumber) || 0,
              type: TYPE_OF_TABLE_DATA.NUMBER,
              width: 80,
              props: {
                colSpan: isHeader ? 0 : 1,
                className: 'text-right',
              },
            };
          },
        },
        {
          title: '% tăng giảm so với năm trước',
          width: 100,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: ({ isHeader }) => {
            return {
              children: null,
              type: TYPE_OF_TABLE_DATA.NUMBER,
              width: 100,
              props: {
                colSpan: isHeader ? 0 : 1,
                className: 'text-right',
              },
            };
          },
        },
      ],
    },
    {
      title: 'Tổng số xếp loại của thủ trưởng',
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: 'Tổng (Người)',
          width: 100,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: ({ total_assessed, isHeader }) => {
            const totalGradedOfCsgd = get(total_assessed, 'csgd', 0);
            return {
              children:
                getNumberFormat(totalGradedOfCsgd, skipFormatNumber) || 0,
              props: {
                colSpan: isHeader ? 0 : 1,
                className: 'text-right',
              },
            };
          },
        },
        {
          title: 'Tổng (%)',
          width: 80,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: ({ total, total_assessed, isHeader }) => {
            const totalGradedOfCsgd = get(total_assessed, 'csgd', 0);
            const text = parseFloat(
              ((totalGradedOfCsgd / total) * 100).toFixed(1),
            );
            return {
              children:
                total > 0 ? getNumberFormat(text, skipFormatNumber) : '',
              props: {
                colSpan: isHeader ? 0 : 1,
                className: 'text-right',
              },
            };
          },
        },
      ],
    },
    {
      title: getTitleColumnForTotal(isResultForCBQL, true),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: [
        {
          title: 'Tổng (Người)',
          width: 100,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: ({ total_assessed, isHeader }) => {
            const totalSelfAssessAndGraded = get(
              total_assessed,
              'tudanhgia',
              0,
            );
            return {
              children:
                getNumberFormat(totalSelfAssessAndGraded, skipFormatNumber) ||
                0,
              props: {
                colSpan: isHeader ? 0 : 1,
                className: 'text-right',
              },
            };
          },
        },
        {
          title: 'Tổng (%)',
          width: 80,
          type: TYPE_OF_TABLE_DATA.TITLE,
          render: ({ total, total_assessed, isHeader }) => {
            const totalSelfAssessAndGraded = get(
              total_assessed,
              'tudanhgia',
              0,
            );
            const text = parseFloat(
              ((totalSelfAssessAndGraded / total) * 100).toFixed(1),
            );
            return {
              children:
                total > 0 ? getNumberFormat(text, skipFormatNumber) : '',
              props: {
                colSpan: isHeader ? 0 : 1,
                className: 'text-right',
              },
            };
          },
        },
      ],
    },
    {
      title: getTitleColumnForResult(isResultForCBQL),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: buildScoreScalesChildren(scoreScales, 'csgd', skipFormatNumber),
    },
    {
      title: getTitleColumnForResult(isResultForCBQL, true),
      type: TYPE_OF_TABLE_DATA.TITLE,
      children: buildScoreScalesChildren(
        scoreScales,
        'tudanhgia',
        skipFormatNumber,
      ),
    },
  ];
};

const generateDataSource = (
  data = [],
  userRoot,
  tcnn,
  prefixId = '',
  flatExpandChildren = false,
  prefixTextDefault = '',
) => {
  let indexRowKey = 0;

  return data.reduce(
    (
      result,
      {
        female_in_there,
        dtts_in_there,
        female_dtts_in_there,
        poor_area_in_there,
        leader_in_there,
        vice_leader_in_there,
        ...row
      },
      index,
    ) => {
      const id = `${prefixId}_${row.school_level}_${++indexRowKey}`;
      row.id = id;
      if (!row.total && isHieuTruongOrPho(userRoot)) {
        return result;
      }

      let prefixText = prefixTextDefault;
      if (row.school_level) {
        row.school_level_name = flatExpandChildren
          ? `${prefixText}${userGradeToText(row.school_level)}`
          : userGradeToText(row.school_level);
        prefixText = `${prefixTextDefault}__`;
      }

      if (!row.total) {
        result.push(row);
        return result;
      }

      let children = [];

      const childrenProps = {
        school_level: row.school_level,
        isChildren: true,
      };

      const buildChildren = (dataChildren = {}, id, prefixText) => {
        const fields = [
          'female_in_there',
          'dtts_in_there',
          'female_dtts_in_there',
          'poor_area_in_there',
        ];
        return fields.map((field) => ({
          ...(!!dataChildren ? dataChildren[field] : data[index][field] || {}),
          ...childrenProps,
          name: flatExpandChildren ? `${prefixText}${t1(field)}` : t1(field),
          id: `${id}_${field}`,
        }));
      };

      if (
        (isTCNNHT(tcnn) && !leader_in_there && !vice_leader_in_there) ||
        (isTCNNGV(tcnn) &&
          !female_in_there &&
          !dtts_in_there &&
          !female_dtts_in_there &&
          !poor_area_in_there)
      ) {
        result.push(row);
        return result;
      } else if (flatExpandChildren && isTCNNHT(tcnn)) {
        children = [
          {
            ...(leader_in_there || {}),
            isChildren: true,
            school_level: row.school_level,
            name: flatExpandChildren
              ? `${prefixText}${t1('leader_in_there')}`
              : t1('leader_in_there'),
            id: `${id}_leader_in_there`,
          },
        ].concat(
          buildChildren(leader_in_there, id, prefixText),
          [
            {
              ...(vice_leader_in_there || {}),
              school_level: row.school_level,
              isChildren: true,
              name: flatExpandChildren
                ? `${prefixText}${t1('vice_leader_in_there')}`
                : t1('vice_leader_in_there'),
              id: `${id}_vice_leader_in_there`,
            },
          ],
          buildChildren(vice_leader_in_there, id, prefixText),
        );
      } else if (isTCNNHT(tcnn)) {
        children = [
          {
            ...(leader_in_there || {}),
            isChildren: true,
            school_level: row.school_level,
            name: flatExpandChildren
              ? `${prefixText}${t1('leader_in_there')}`
              : t1('leader_in_there'),
            id: `${id}_leader_in_there`,
            children: buildChildren(leader_in_there, id, prefixText),
          },
          {
            ...(vice_leader_in_there || {}),
            school_level: row.school_level,
            isChildren: true,
            name: flatExpandChildren
              ? `${prefixText}${t1('vice_leader_in_there')}`
              : t1('vice_leader_in_there'),
            id: `${id}_vice_leader_in_there`,
            children: buildChildren(vice_leader_in_there, id, prefixText),
          },
        ];
      } else if (isTCNNGV(tcnn)) {
        children = buildChildren(null, id, prefixText);
      } else {
        return row;
      }

      if (flatExpandChildren) {
        result.push(row);
        return result.concat(children);
      }

      row.children = children;
      result.push(row);
      return result;
    },
    [],
  );
};

const getPropsToRenderTable = (
  dataTable,
  userRoot,
  tcnn,
  isResultByRubric,
  flatExpandChildren = false,
) => {
  if (!Array.isArray(dataTable) || !dataTable.length) {
    return { dataSource: [], expandedRowKeys: [] };
  }

  let dataSource = [];

  const expandedRowKeys = [];

  if (flatExpandChildren && isResultByRubric) {
    dataSource = dataTable.reduce((result, { children, ...data }) => {
      result.push({
        ...data,
        isHeader: true,
      });

      return result.concat(
        generateDataSource(
          children,
          userRoot,
          tcnn,
          data.id,
          flatExpandChildren,
          '__',
        ),
      );
    }, []);
  } else if (isResultByRubric) {
    dataSource = dataTable
      .map((data) => {
        expandedRowKeys.push(data.id);
        const childrenData = generateDataSource(
          data.children,
          userRoot,
          tcnn,
          data.id,
        );
        return {
          ...data,
          isHeader: true,
          children: childrenData,
        };
      })
      .filter(Boolean);
  } else {
    dataSource = generateDataSource(
      dataTable,
      userRoot,
      tcnn,
      '',
      flatExpandChildren,
      '',
    );
  }

  return {
    dataSource,
    expandedRowKeys,
  };
};

const TableResult = ({
  dataResult,
  tcnn,
  userRoot,
  isResultByRubric,
  isResultForCBQL,
  flatExpandChildren = false,
  skipFormatNumber = false,
}) => {
  const { dataSource, expandedRowKeys } = getPropsToRenderTable(
    get(dataResult, 'data'),
    userRoot,
    tcnn,
    isResultByRubric,
    flatExpandChildren,
  );

  const scoreScale = get(dataResult, 'score_scale', []);

  return (
    <CommonAntdTable
      bordered
      rowKey="id"
      pagination={false}
      className="white-background learn-default"
      columns={getColumns(
        scoreScale,
        tcnn,
        userRoot,
        dataSource.length,
        isResultByRubric,
        isResultForCBQL,
        skipFormatNumber,
      )}
      dataSource={Array.isArray(dataSource) ? dataSource : []}
      scroll={{ x: true }}
      defaultExpandedRowKeys={expandedRowKeys}
    />
  );
};

export default withTemisConfig(TableResult);
