import React, { Component } from 'react';
import Row from 'antd/lib/grid/row';
import Col from 'antd/lib/grid/col';
import Card from 'antd/lib/card';
import './style.scss';
import lodashGet from 'lodash.get';
import styled from 'styled-components';
import Warning from 'components/common/Warning';
import OrganizationsOrPhongBan from 'components/admin/group/common/OrganizationsOrPhongBanInResultTable';
import SuccessAlert from '../../../common/SuccessAlert';
import Errors from './Errors';
import SSOBox from './SSOSimple';
import { t1 } from 'translate';
import { Link } from 'react-router-dom';
import withTemisConfig from 'common/hoc/withTemisConfig';
import { leaderPositions } from 'configs/constants/user';
import { PROFILE_GROUP_ROUTER } from '../../constant';
import WarningUpdateProfile from '../WarningUpdateProfile';
import Alert from 'antd/lib/alert';
import {
  isAdminTruong,
  isAmTinh,
  isLTTUStaff,
} from 'components/admin/user/utils';

const Container = styled(Row)`
  label,
  .ant-form-item-no-colon {
    color: rgba(0, 0, 0, 0.85) !important;
  }

  label.ant-radio-wrapper {
    color: rgba(0, 0, 0, 0.7) !important;
  }
`;

class TemisProfileFormFreeStyle extends Component {
  goToProfilePage = () => {
    window.location.replace(PROFILE_GROUP_ROUTER.update_info);
  };

  render() {
    const {
      groups,
      layout,
      invalid,
      submitSucceeded,
      layoutOptionsProperties,
      formValues = {},
    } = this.props;
    const title = lodashGet(layout, 'title');
    const user = lodashGet(this.props, 'layoutOptionsProperties.user');
    const reponseUpdate = lodashGet(
      this.props,
      'layoutOptionsProperties.reponseUpdate',
    );

    const warningVerifyIdentificationCode =
      [
        leaderPositions.TEACHER,
        leaderPositions.TO_TRUONG,
        leaderPositions.VICE_LEADER,
        leaderPositions.LEADER,
      ].includes(lodashGet(user, 'leader_position')) &&
      lodashGet(reponseUpdate, 'result.identification_code') &&
      !lodashGet(reponseUpdate, 'result.identification_code_encrypted');

    const isHRMS = lodashGet(user, 'hrms');
    const isEditAccount = lodashGet(layoutOptionsProperties, 'isEditAccount');
    const showExpGroup =
      !isAdminTruong(user) && !isLTTUStaff(user) && !isAmTinh(user);

    return (
      <div className="temis-profile-container">
        {layout.showAlertMessage && !submitSucceeded && (
          <div className="text-center m-b-20">
            <Warning>
              {t1(
                'please_provide_complete_personal_information_to_be_able_to_continue_using_the_system',
              )}
            </Warning>
          </div>
        )}
        {title && (
          <div className="text-center">
            <h1 className="temis-profile-title">{title}</h1>
          </div>
        )}

        {!submitSucceeded && (
          <div
            dangerouslySetInnerHTML={{
              __html: t1('fields_marked_with_%s_are_required', [
                '<strong class="text-danger">(*)</strong>',
              ]),
            }}
          />
        )}

        <Container>
          {!submitSucceeded ||
          isEditAccount ||
          !lodashGet(reponseUpdate, 'success') ? (
            <div className="page-wrap page-1">
              <Row type="flex" justify="space-between" gutter={{ md: 10 }}>
                <Col md={24} span={24} className="m-t-20">
                  <Card title={<strong>{t1('general_information')}</strong>}>
                    <Row gutter={24}>
                      <Col>{groups.basic.fieldNames.name}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col className="d-flex">
                        {groups.basic.fieldNames.ethnicity}
                        <div className="temis-profile-other-ethnicity">
                          {groups.basic.fieldNames.other_ethnicity}
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={24}>
                      <Col>{groups.basic.fieldNames.birthday}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col>{groups.basic.fieldNames.birthplace}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col>{groups.basic.fieldNames.sex}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col>{groups.basic.fieldNames.dia_ban}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col>
                        {groups.basic.fieldNames.congtac_thuoc_diaban_khokhan}
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col md={24} span={24} className="m-t-20">
                  <Card title={<strong>{t1('personal_information')}</strong>}>
                    <Row gutter={24} className="m-b-10">
                      {!!user.code && (
                        <Col md={12} span={24}>
                          <div className="m-t-30">
                            {t1('your_login_name')}:
                            <SuccessAlert inline>
                              <b style={{ fontSize: '120%' }}>{user.code}</b>
                            </SuccessAlert>
                          </div>
                        </Col>
                      )}

                      <Col md={12} span={24}>
                        {groups.sso.fieldNames.identification_code}
                      </Col>
                    </Row>

                    <Row gutter={24}>
                      <Col>{groups.sso.fieldNames.identification_card}</Col>
                    </Row>
                    <Row gutter={24}>
                      <Col md={12} span={24}>
                        <div>{groups.sso.fieldNames.mail}</div>
                        {!!isHRMS && (
                          <Warning>
                            {t1(
                              'hrms_account_cannot_edit_email_notification_text',
                            )}
                          </Warning>
                        )}
                      </Col>
                      <Col md={12} span={24}>
                        {groups.sso.fieldNames.phone}
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col md={24} span={24} className="m-t-20">
                  <Card
                    title={<strong>{t1('organization_information')}</strong>}
                  >
                    {isHRMS ? (
                      <Row gutter={24} className="m-t-10">
                        <Col>
                          <div>
                            {t1('in_school_organization')}
                            {lodashGet(
                              user,
                              '__expand.user_organizations.0.name',
                            ) ? (
                              <OrganizationsOrPhongBan
                                item={user}
                                attr={'user_organizations'}
                                showUpUntilOrgSubType="2"
                                showParentsInfo
                                showParentsInfoInline
                                showNameAsInputField
                              />
                            ) : (
                              <Warning>
                                <b>
                                  {t1(
                                    'account_do_not_have_school_organization_info_yet',
                                  )}
                                </b>
                              </Warning>
                            )}
                          </div>
                        </Col>

                        <Col>
                          <SSOBox {...this.props} />
                        </Col>
                      </Row>
                    ) : (
                      <div>
                        {!lodashGet(formValues, 'org_sub_type') && (
                          <Warning>
                            {t1('you_must_select_organization_type_first')}
                          </Warning>
                        )}

                        <Row>{groups.org.fieldNames.org_sub_type}</Row>
                        <Row>{groups.org.fieldNames.org_province_id}</Row>
                        <Row>{groups.org.fieldNames.org_district_id}</Row>
                        <Row>{groups.org.fieldNames.user_organizations}</Row>

                        {!Array.isArray(
                          lodashGet(formValues, 'user_organizations'),
                        ) ||
                        !lodashGet(formValues, 'user_organizations').length ? (
                          <Warning>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t1(
                                  'if_you_cannot_find_your_organization,_call_%s_the_operator_here_%s_for_assistance',
                                  ['<a href="/faq" target="_blank">', '</a>'],
                                ),
                              }}
                            />
                          </Warning>
                        ) : null}
                      </div>
                    )}
                  </Card>
                </Col>

                {!!showExpGroup && (
                  <Col md={24} span={24} className="m-t-20">
                    <Card
                      title={
                        <strong>
                          {t1('qualifications_and_teaching_experience')}
                        </strong>
                      }
                    >
                      {!groups.exp.fieldNames.sub_leader_position ? (
                        <Row>{groups.exp.fieldNames.leader_position}</Row>
                      ) : (
                        <Row gutter={24}>
                          <Col md={12} span={24}>
                            {groups.exp.fieldNames.leader_position}
                          </Col>
                          <Col md={12} span={24}>
                            {groups.exp.fieldNames.sub_leader_position}
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col>{groups.exp.fieldNames.current_position}</Col>
                        {groups.exp.fieldNames.other_current_position && (
                          <Col>
                            {groups.exp.fieldNames.other_current_position}
                          </Col>
                        )}
                      </Row>

                      {!!groups.exp.fieldNames.phongban_id && (
                        <Row>
                          <Col>
                            {groups.exp.fieldNames.phongban_id}
                            <div className="m-b-20">
                              <Alert
                                message={`Nếu không có "Tổ chuyên môn", thầy/cô vui lòng liên hệ Admin trường để thêm "Tổ chuyên môn"`}
                                type="warning"
                                showIcon
                              />
                            </div>
                          </Col>
                        </Row>
                      )}

                      <Row gutter={24}>
                        <Col md={12} span={24}>
                          {groups.exp.fieldNames.qualifications}
                        </Col>
                        <Col md={12} span={24}>
                          {groups.exp.fieldNames.teaching_specialization_select}
                        </Col>
                      </Row>

                      <Row>{groups.exp.fieldNames.teaching_specialization}</Row>

                      <Row gutter={24}>
                        <Col md={12} span={24}>
                          {groups.exp.fieldNames.ngay_vao_bien_che}
                        </Col>
                        <Col md={12} span={24}>
                          {groups.exp.fieldNames.teaching_exp_years}
                        </Col>
                      </Row>

                      <Row gutter={24}>
                        <Col md={12} span={24}>
                          {groups.exp.fieldNames.managing_date}
                        </Col>
                        <Col md={12} span={24}>
                          {groups.exp.fieldNames.managing_exp_years}
                        </Col>
                      </Row>

                      <Row>{groups.exp.fieldNames.grade}</Row>
                      <Row>{groups.exp.fieldNames.training_grades}</Row>
                    </Card>
                  </Col>
                )}
              </Row>
              {invalid && (
                <div className="page-error">
                  <Errors />
                </div>
              )}
              <div className="page-action">
                <button
                  className="btn btn-primary"
                  disabled={invalid && lodashGet(reponseUpdate, 'success')}
                >
                  {t1('done')}
                </button>
              </div>
            </div>
          ) : (
            <>
              {!isEditAccount && (
                <div className="page-wrap page-3">
                  <Card>
                    <div className="submit-success">
                      <div className="submit-success-icon">
                        <span className="ve-check" />
                      </div>
                      <div className="submit-success-description">
                        <span>{t1('etep_profile_form_finish_message')}</span>
                      </div>
                    </div>

                    {!!warningVerifyIdentificationCode && (
                      <WarningUpdateProfile
                        userInfo={lodashGet(reponseUpdate, 'result')}
                      />
                    )}

                    <div className="page-action">
                      <button
                        type="button"
                        className="btn btn-primary btn-outline"
                        onClick={this.goToProfilePage}
                      >
                        {t1('previous')}
                      </button>

                      <Link
                        to="/temis"
                        className={`btn btn-primary ${
                          warningVerifyIdentificationCode ? 'btn-outline' : ''
                        }`}
                      >
                        {t1('back_to_temis_dashboard')}
                      </Link>
                    </div>
                  </Card>
                </div>
              )}
            </>
          )}
        </Container>
      </div>
    );
  }
}

export default withTemisConfig(TemisProfileFormFreeStyle);
