import React, { Component } from 'react';
import withUserInfo from 'common/hoc/withUserInfo';
import PeersAssessment from '../../../report/PeersAssessment';

class PeerAssessmentForTeacher extends Component {
  render() {
    const { userInfo = {}, isPeersAssess } = this.props;
    return <PeersAssessment user={userInfo} isPeersAssess={isPeersAssess} />;
  }
}

export default withUserInfo(PeerAssessmentForTeacher);
