import React from 'react';
import Checkbox from 'antd/lib/checkbox';
import { t1 } from 'translate';
import lodashGet from 'lodash.get';
import {
  types as questionTypes,
  types,
} from 'components/admin/question/schema/question-types';
import {
  demographicSurveyReportCheckboxes,
  tableTitles,
  USER_GROUP_ALL,
} from 'configs/constants/user-demographics';
import OpenEndedQuestionAnswersByQuestionId from '../open-ended-question-answers-by-question-id';
import DisplayHtml from 'components/common/html';
import AntTableWithExport from 'components/common/table/AntTableWithExport';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import defaultSurveyLabels from 'components/admin/survey/mainstage/dashboard/widget/survey-statistics/survey-labels';
import defaultSurveyLabelsTemis from 'components/admin/survey/mainstage/dashboard/widget/survey-statistics-temis/survey-labels';
import { stripHTML } from 'common/utils/string';
import { isEnableTemis } from 'components/temis/utils';

const formatNumberPercent = (percent) => {
  if (percent == 0 || percent == 100) {
    return percent;
  }

  return percent.toFixed(2);
};

const displayPart = (part, total, shouldShowPercentView, displaySeparator) => {
  const percent = total ? (part / total) * 100 : 0;
  return shouldShowPercentView
    ? `${formatNumberPercent(percent)}%`
    : displaySeparator
    ? part.toLocaleString('en')
    : part;
};

const getTotalNumberForAllAnswer = (demographic, item) => {
  let total = lodashGet(item, `total_answer.${demographic}`);

  if (Number.isInteger(total)) {
    return total;
  }

  total = 0;

  const indexGetTotalAnswer = lodashGet(item, 'indexGetTotalAnswer') || [];

  indexGetTotalAnswer.forEach((index) => {
    total += lodashGet(item, `${demographic}.${index}`) || 0;
  });

  return total;
};

const displayNumberForAnswer = (
  demographic,
  item,
  indexAnswer,
  shouldShowPercentView,
  displaySeparator = true,
) => {
  const numberForThisAnswer =
    lodashGet(item, `${demographic}.${indexAnswer}`) || 0;

  const totalNumberForAllAnswer = getTotalNumberForAllAnswer(demographic, item);

  return displayPart(
    numberForThisAnswer,
    totalNumberForAllAnswer,
    shouldShowPercentView,
    displaySeparator,
  );
};

const getColumns = (
  surveyIid,
  paramsToFilter,
  trainingPlan,
  demographicsToShow,
) => {
  const demographics =
    Array.isArray(demographicsToShow) && demographicsToShow.length
      ? demographicsToShow
      : [USER_GROUP_ALL];

  return [
    {
      title: t1('question'),
      key: 'question',
      children: [
        {
          title: t1('question_content'),
          width: 450,
          render: (question) => {
            let children;
            let colSpan = 1;
            let rowSpan = question.rowSpan;

            switch (question.type) {
              case types.TYPE_INTRODUCTION: {
                children = (
                  <h3>
                    <DisplayHtml
                      content={stripHTML(
                        lodashGet(question, 'content_stripped'),
                      )}
                    />
                  </h3>
                );
                colSpan = 2 + demographics.length * 2;
                break;
              }
              case types.TYPE_OPEN_ENDED: {
                children = (
                  <div>
                    <DisplayHtml content={question.content} />
                  </div>
                );
              }

              case questionTypes.TYPE_MC:
              case questionTypes.TYPE_MC_OPEN_ENDED:
              case questionTypes.TYPE_NUMBER: {
                children = (
                  <div>
                    <DisplayHtml
                      content={stripHTML(
                        lodashGet(question, 'content_stripped'),
                      )}
                    />
                  </div>
                );
                break;
              }
              default: {
                children = (
                  <div>
                    <DisplayHtml
                      content={stripHTML(
                        lodashGet(question, 'content_stripped'),
                      )}
                      showLessMore
                      lessWordCount={20}
                    />
                  </div>
                );
                break;
              }
            }

            return {
              children,
              props: {
                colSpan,
                rowSpan,
              },
            };
          },
        },
        {
          title: t1('question_answer'),
          width: 400,
          render: (question) => {
            let children;
            let colSpan = 1;
            let rowSpan = 1;

            switch (question.type) {
              case types.TYPE_INTRODUCTION: {
                children = null;
                colSpan = 0;
                break;
              }
              case types.TYPE_OPEN_ENDED: {
                children = (
                  <div className="text-center">
                    <OpenEndedQuestionAnswersByQuestionId
                      surveyIid={surveyIid}
                      question={question}
                      trainingPlan={trainingPlan}
                      paramsToFilter={paramsToFilter}
                    />
                  </div>
                );

                colSpan = 1 + demographics.length * 2;
                break;
              }
              case questionTypes.TYPE_MC:
              case questionTypes.TYPE_MMC:
              case questionTypes.TYPE_NUMBER:
              case questionTypes.TYPE_TRUE_FALSE:
              case questionTypes.TYPE_MC_OPEN_ENDED: {
                children = question.labelAnswer;
                break;
              }
              default: {
                children = null;
                break;
              }
            }

            return {
              children,
              props: {
                colSpan,
                rowSpan,
              },
            };
          },
        },
      ],
    },
  ].concat(
    demographics.map((demographic) => {
      return {
        title: tableTitles[demographic],
        className: 'text-center',
        children: [
          {
            title: 'Số lượng',
            width: 150,
            type: TYPE_OF_TABLE_DATA.NUMBER,
            render: (item) => {
              if (
                ![
                  questionTypes.TYPE_MC,
                  questionTypes.TYPE_MMC,
                  questionTypes.TYPE_NUMBER,
                  questionTypes.TYPE_TRUE_FALSE,
                  questionTypes.TYPE_MC_OPEN_ENDED,
                ].includes(item.type)
              ) {
                return {
                  children: null,
                  props: {
                    colSpan: 0,
                  },
                };
              }

              return displayNumberForAnswer(
                demographic,
                item,
                item.indexAnswer,
                false,
              );
            },
          },
          {
            title: 'Tỷ lệ',
            width: 150,
            type: TYPE_OF_TABLE_DATA.NUMBER,
            render: (item) => {
              if (
                ![
                  questionTypes.TYPE_MC,
                  questionTypes.TYPE_MMC,
                  questionTypes.TYPE_NUMBER,
                  questionTypes.TYPE_TRUE_FALSE,
                  questionTypes.TYPE_MC_OPEN_ENDED,
                ].includes(item.type)
              ) {
                return {
                  children: null,
                  props: {
                    colSpan: 0,
                  },
                };
              }

              return displayNumberForAnswer(
                demographic,
                item,
                item.indexAnswer,
                true,
              );
            },
          },
        ],
      };
    }),
  );
};

function getDefaultSurveyLabels() {
  const isTemis = isEnableTemis();

  if (isTemis) {
    return defaultSurveyLabelsTemis;
  }

  return defaultSurveyLabels;
}

const formatDataSourceToRender = (items, survey) => {
  const result = [];

  if (!Array.isArray(items) || !items.length) {
    return result;
  }

  const children =
    lodashGet(survey, 'children') || lodashGet(survey, 'metadata');

  items.forEach((item) => {
    let question = item;
    if (Array.isArray(children) && children.length) {
      question = Object.assign(
        question,
        children.find(({ id }) => question.id == id || question.iid == id) ||
          {},
      );
    }

    switch (question.type) {
      case questionTypes.TYPE_MC:
      case questionTypes.TYPE_MMC:
      case questionTypes.TYPE_NUMBER:
      case questionTypes.TYPE_TRUE_FALSE:
      case questionTypes.TYPE_MC_OPEN_ENDED:
        const answers =
          lodashGet(question, 'answers2') || lodashGet(question, 'mc_answers');
        const surveyLabels = Array.isArray(answers)
          ? answers
          : getDefaultSurveyLabels();

        const totalAnswer = surveyLabels.length;
        const indexGetTotalAnswer = [];
        surveyLabels.forEach(({ value }, index) => {
          if (typeof value === 'number') {
            indexGetTotalAnswer.push(value);
          } else {
            indexGetTotalAnswer.push(index);
          }
        });

        surveyLabels.forEach(({ text, value }, index) => {
          result.push({
            ...question,
            labelAnswer:
              stripHTML(text) || t1('option_%snd_of_answer', [index + 1]),
            indexAnswer: typeof value === 'number' ? value : index,
            indexGetTotalAnswer,
            rowSpan: index ? 0 : totalAnswer,
          });
        });

        break;
      default:
        result.push({ ...question, rowSpan: 1 });
        break;
    }
  });

  return result;
};

const ReportAsTable = ({
  items,
  surveyIid,
  trainingPlan,
  paramsToFilter,
  survey,
}) => {
  let [viewDemographics, setViewDemographics] = React.useState([
    USER_GROUP_ALL,
  ]);

  let toggleDemographics = (v) => {
    setViewDemographics(v);
  };

  return (
    <div>
      {Array.isArray(items) && !!items.length && (
        <div className="m-b-20">
          <Checkbox.Group
            options={demographicSurveyReportCheckboxes()}
            defaultValue={[USER_GROUP_ALL]}
            onChange={toggleDemographics}
          />
        </div>
      )}

      <AntTableWithExport
        dataSource={formatDataSourceToRender(items, survey)}
        columns={getColumns(
          surveyIid,
          paramsToFilter,
          trainingPlan,
          viewDemographics,
        )}
        bordered
        pagination={false}
        rowKey="iid"
        childrenColumnName={null}
        size="small"
        scroll={{ x: true }}
      />
    </div>
  );
};

export default ReportAsTable;
