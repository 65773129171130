import React from 'react';
import { t1 } from 'translate';
import schema from './schema/form';
import './style.scss';
import Communication from 'components/common/communication';

const Form = ({
  formId,
  alternativeApi = '/temis/api/communication-to-warning',
  totalWhenSelectAll = 0,
  paramsFilterWhenSelectAll,
  iidSelected = [],
  hiddenFields = {},
  objectTitle,
  disableSendAll,
}) => {
  hiddenFields.params_filter_when_select_all = paramsFilterWhenSelectAll;
  const send_all = hiddenFields.send_all;
  if (
    typeof send_all === 'undefined' &&
    (!Array.isArray(iidSelected) || !iidSelected.length)
  ) {
    hiddenFields.send_all = 1;
  }

  return (
    <Communication
      formid={formId || 'temis-communication'}
      ntype={'communication'}
      hiddenFields={hiddenFields}
      totalWhenSelectAll={totalWhenSelectAll}
      paramsFilterWhenSelectAll={paramsFilterWhenSelectAll}
      objectTitle={objectTitle}
      schema={schema}
      dialogKey="dialog_communication"
      alternativeApi={alternativeApi}
      submitButton={
        <button className="btn btn-secondary send-button" type="submit">
          {t1('send')}
        </button>
      }
      disableSendAll={disableSendAll}
      iidSelected={iidSelected}
    />
  );
};

export default Form;
