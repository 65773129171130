import React from 'react';
import PreviewUserInDialog from 'components/admin/user/account/common/PreviewInDialog';
import { sexAsText } from 'common/sex';
import TYPE_OF_TABLE_DATA from 'common/utils/type-of-table-data';
import { t1 } from 'translate';
import CommonAntdTable from 'components/common/antd/table';
import get from 'lodash.get';
import { timestampToDateString } from 'common/utils/Date';
import { leaderPositions, leaderPositionToText } from 'configs/constants/user';
import { isTeacher } from 'components/admin/user/utils';
import DeleteButton from 'components/common/action-button/DeleteBtnWithConfirmDialog';
import apiUrls from 'components/temis/endpoints';
import Button from 'antd/lib/button';

const AddMembers = ({
  items = [],
  searchFormId,
  phongban,
  searchTcmFormId,
  handleAddSuccessfull,
}) => {
  const [selectedRowKeys, onSelectChange] = React.useState([]);
  const userIidSelected =
    Array.isArray(items) && Array.isArray(selectedRowKeys)
      ? selectedRowKeys.filter(
          (user_iid) => !!items.find(({ iid }) => iid === user_iid),
        )
      : [];
  const defaultColumnWidth = 150;

  const columns = [
    {
      title: t1('general_information'),
      key: 'name',
      width: 250,
      render: (name, item) => (
        <PreviewUserInDialog user={item} showFullDetailButton={false}>
          <div>
            <div>Họ tên: {get(item, 'name')}</div>
            <div>
              {t1('email')}: {get(item, 'mail')}
            </div>
            <div>Điện thoại: {get(item, 'phone')}</div>
          </div>
        </PreviewUserInDialog>
      ),
    },
    {
      title: 'Mã',
      key: 'code',
      width: defaultColumnWidth,
      render: (code, item) => <div>{get(item, 'code')}</div>,
    },
    {
      title: 'Ngày sinh',
      key: 'birthday',
      width: 120,
      render: (birthday, item) => (
        <div>{!!item.birthday && timestampToDateString(item.birthday)}</div>
      ),
    },
    {
      title: t1('gender'),
      key: 'sex',
      width: 90,
      render: (sex, item) => <div>{item && sexAsText(item.sex)}</div>,
    },
    {
      title: 'Tổ chuyên môn',
      width: 100,
      render: (item) => {
        const phongbans = get(item, '__expand.phongbans', []).filter(Boolean);
        if ((!phongbans || !phongbans.length) && isTeacher(item)) {
          return <div className="text-danger">Chưa có tổ chuyên môn</div>;
        }

        return phongbans.map((phongban) => <div>{get(phongban, 'name')}</div>);
      },
    },
    {
      title: 'Vị trí/chức vụ',
      key: 'positions',
      width: defaultColumnWidth,
      render: (item) => {
        let leaderPosition = get(item, 'leader_position');
        if (!leaderPosition) {
          return null;
        }

        if (
          leaderPosition === leaderPositions.TEACHER &&
          get(item, 'current_position') === 'leader'
        ) {
          leaderPosition = leaderPositions.TO_TRUONG;
        }

        return leaderPositionToText(leaderPosition);
      },
    },
    {
      title: 'Kinh nghiệm',
      key: 'experience',
      width: 110,
      render: (item) =>
        item.teaching_exp_years && (
          <span>
            {item.teaching_exp_years} {t1('year')}
          </span>
        ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 100,
      type: TYPE_OF_TABLE_DATA.ACTION,
      render: (action, item) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <PreviewUserInDialog user={item} showFullDetailButton={false}>
              <span
                className="ve-eye m-r-10 d-inline-block m-t-5"
                title={t1('view')}
              />
            </PreviewUserInDialog>

            {get(item, 'phongban_id') !== phongban.iid && (
              <DeleteButton
                renderComponent={({ onClick }) => {
                  return (
                    <span
                      className="ve-plus-circle m-r-10"
                      title={`Thêm ${item.name} vào tổ chuyên môn: ${
                        phongban.name
                      }`}
                      onClick={onClick}
                    />
                  );
                }}
                message={{
                  success: 'Thêm giáo viên vào tổ chuyên môn thành công',
                }}
                onRequestSuccessful={handleAddSuccessfull}
                textConfirm={`Bạn có chắc chắn thêm giáo viên ${
                  item.name
                } vào tổ chuyên môn`}
                alternativeApi={apiUrls.add_user_in_phongban}
                formid={[searchFormId, searchTcmFormId]}
                params={{
                  phongban_id: phongban.iid,
                  user_iids: [item.iid],
                }}
              />
            )}
          </div>
        );
      },
    },
  ].filter(Boolean);

  return (
    <div className="account-search-result">
      <CommonAntdTable
        dataSource={items}
        columns={columns}
        pagination={false}
        rowKey="iid"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            disabled: get(record, 'phongban_id') === phongban.iid,
          }),
        }}
        className="learn-default"
      />

      <DeleteButton
        renderComponent={({ onClick }) => {
          return (
            <Button
              title={`Thêm ${
                Array.isArray(userIidSelected) ? userIidSelected.length : 0
              } giáo viên vào tổ chuyên môn: ${phongban.name}`}
              onClick={onClick}
              disabled={
                !Array.isArray(userIidSelected) || !userIidSelected.length
              }
              className="btn-primary m-t-10"
            >
              {`Thêm ${
                Array.isArray(userIidSelected) ? userIidSelected.length : 0
              } giáo viên được chọn`}
            </Button>
          );
        }}
        message={{ success: 'Thêm giáo viên vào tổ chuyên môn thành công' }}
        onRequestSuccessful={handleAddSuccessfull}
        textConfirm={`Bạn có chắn chắn thêm ${
          Array.isArray(userIidSelected) ? userIidSelected.length : 0
        } giáo viên vào tổ chuyên môn: ${phongban.name}`}
        alternativeApi={apiUrls.add_user_in_phongban}
        disabled={!Array.isArray(userIidSelected) || !userIidSelected.length}
        formid={[searchFormId, searchTcmFormId]}
        params={{
          phongban_id: phongban.iid,
          user_iids: userIidSelected,
        }}
      />
    </div>
  );
};

export default AddMembers;
