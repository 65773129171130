import React from 'react';
import lodashGet from 'lodash.get';
import './stylesheet.scss';

/* this is format tabList need to pass
const tabList = [
  {
    name: "tab1",
    label: 'tab 1',
    component: <h1>This is tab 1</h1>
  }, {
    name: "tab2",
    label: 'tab 2',
    isActive: true,
    component: <h1>This is tab 2</h1>
  },  {
    name: "tab3",
    label: 'tab 3',
    component: <h1>This is tab 3</h1>
  }
]; */

class Tab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabList: props.tabList || [],
    };
  }

  handleClick = (tab) => {
    const { tabList = [], handleChangeTab } = this.props;

    if (typeof handleChangeTab === 'function') {
      handleChangeTab(tab.name);
      return;
    }

    this.setState({
      tabList: tabList.map((data) => ({
        ...data,
        isActive: data.name === tab.name,
      })),
    });
  };

  getTabActive() {
    const { tabList } = this.state;
    const { tabActive } = this.props;
    const currentTabActive = tabList.find(
      (item) => item.name === tabActive || item.isActive,
    );

    return currentTabActive || lodashGet(tabList, '0');
  }

  render() {
    const { tabList } = this.state;

    const tabActiveComponent = this.getTabActive();

    return (
      !!tabList.length && (
        <div className="tab--wrapper mui-paper-no-box-shadow mui-paper-no-padding">
          {tabList.length > 1 && (
            <div className="tab d-flex justify-content-center m-b-30">
              {tabList.map((tab, index) => {
                return (
                  <div
                    key={index}
                    className={`tab-item ${tab.name ===
                      tabActiveComponent.name && 'active'}`}
                    onClick={() => this.handleClick(tab)}
                  >
                    {tab.label}
                  </div>
                );
              })}
            </div>
          )}

          <div className="tab-content">
            {typeof tabActiveComponent.component === 'function'
              ? tabActiveComponent.component()
              : tabActiveComponent.component}
          </div>
        </div>
      )
    );
  }
}
export default Tab;
