import React from 'react';
import get from 'lodash.get';
import apiUrls from '../endpoints';

const schema = (formid, values, step, xpath, { readOnly }) => {
  return {
    target: {
      type: 'text',
      rowsMax: 2,
      rows: 2,
      multiLine: true,
      defaultValue: '',
      errorText: '',
      fullWidth: true,
      classWrapper: 'm-b-15',
      floatingLabelText: 'Mục tiêu:',
      disabled: readOnly,
    },
    content: {
      type: 'multiCheckbox',
      hiddenWhenOptionEmpty: true,
      options: 'async',
      paramsasync: {
        __url__: apiUrls.get_personal_orientation_content,
        value: {
          user_iid: get(values, 'target.iid'),
        },
        transformData: (options) => {
          if (!Array.isArray(options) || !options.length) {
            return [];
          }
          return options.map(({ iid, name }) => ({
            value: iid,
            label: name,
            primaryText: name,
          }));
        },
      },
      classWrapper: 'm-b-15',
      floatingLabelText:
        'Nội dung đăng ký học tập, bồi dưỡng (Các năng lực cần ưu tiên cải thiện):',
      disabled: readOnly,
      maximum: 5,
    },
    content_other: {
      type: 'text',
      classWrapper: 'm-b-15 other-field',
      floatingLabelText: 'Nhập năng lực khác:',
    },
    time_doing: {
      type: 'multiCheckbox',
      hiddenWhenOptionEmpty: true,
      // options: timeDoingOption,
      options: 'async',
      paramsasync: {
        __url__: apiUrls.get_personal_orientation_time_doing,
        value: {
          user_iid: get(values, 'target.iid'),
        },
        transformData: (options) => {
          if (!Array.isArray(options) || !options.length) {
            return [];
          }
          return options.map(({ iid, name }) => ({
            value: iid,
            label: name,
            primaryText: name,
          }));
        },
      },
      classWrapper: 'm-b-15',
      floatingLabelText: 'Thời gian hoàn thành mô đun bồi dưỡng:',
      disabled: readOnly,
    },
    time_doing_other: {
      type: 'text',
      classWrapper: 'm-b-15 other-field',
      floatingLabelText: 'Nhập thời gian thực hiện khác:',
    },
    performance_conditions: {
      type: 'multiCheckbox',
      hiddenWhenOptionEmpty: true,
      // options: performanceConditionsOption,
      options: 'async',
      paramsasync: {
        __url__: apiUrls.get_personal_orientation_performance_conditions,
        value: {
          user_iid: get(values, 'target.iid'),
        },
        transformData: (options) => {
          if (!Array.isArray(options) || !options.length) {
            return [];
          }
          return options.map(({ iid, name }) => ({
            value: iid,
            label: name,
            primaryText: name,
          }));
        },
      },
      classWrapper: 'm-b-15',
      floatingLabelText: 'Điều kiện thực hiện:',
      disabled: readOnly,
    },
    performance_conditions_other: {
      type: 'text',
      classWrapper: 'm-b-15 other-field',
      floatingLabelText: 'Nhập điều kiện thực hiện khác:',
    },
  };
};

const ui = (step, values, themeConfig, xpath) => {
  const timeDoing = get(
    values,
    xpath ? `${xpath}.time_doing` : 'time_doing',
    [],
  );
  const showTimeDoingOther = timeDoing.includes(0);

  const content = get(values, xpath ? `${xpath}.content` : 'content', []);
  const showContentOther = content.includes(0);

  const performanceConditions = get(
    values,
    xpath ? `${xpath}.performance_conditions` : 'performance_conditions',
    [],
  );
  const showPerformanceConditionsOther = performanceConditions.includes(0);

  return [
    {
      id: 'default',
      fields: [
        'target',
        'content',
        ...(showContentOther ? ['content_other'] : []),
        'time_doing',
        ...(showTimeDoingOther ? ['time_doing_other'] : []),
        'performance_conditions',
        ...(showPerformanceConditionsOther
          ? ['performance_conditions_other']
          : []),
      ],
    },
  ];
};

export default {
  schema,
  ui,
};
