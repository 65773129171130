import Layout from './Layout';
import Attachments from 'schema-form/elements/attachments';
import get from 'lodash.get';
import { VIEWER_FILE_EXTENSIONS } from 'components/temis/constant';

const schema = () => ({
  attachments: {
    type: Attachments,
    allowDownload: true,
    fullWidth: true,
    multiple: false,
    limit: 1,
    accept: ['.pdf', '.png', '.jpg', '.jpeg', '.xls', '.xlsx', '.doc', '.docx'],
    noFileManager: true,
    title: 'Đính kèm BM3/PL2 CV4530',
    maxFileSize: 25,
    viewerFileExtensions: VIEWER_FILE_EXTENSIONS,
    justShowFileName: true,
  },
});

const ui = () => [
  {
    id: 'default',
    fields: ['attachments'],
  },
];

const layout = (step, values, xpath, { shouldDisabledSaveButton }) => {
  return {
    component: Layout,
    freestyle: 1,
    optionsProperties: {
      file: get(values, 'attachments[0].link'),
      shouldDisabledSaveButton,
    },
  };
};

export default {
  schema,
  ui,
  layout,
};
