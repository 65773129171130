import React from 'react';
import SearchWrapper from 'components/common/search-wrap-v2/SearchWrapper';
import schema from './schema';
import TableResults from './TableResults';
import apiEndpoints from '../../../endpoints';
import SimpleNoResult from 'components/common/search-wrap/simple-no-result/SimpleNoResult';
import ExportData from 'components/common/export-data';
import { isHieuTruongOrPho, isAdminTruong } from 'components/admin/user/utils';
import ReportProgressSyncerButton from '../../syncer';
import lastTimeFinishedSyncing from '../../syncer/lastTimeFinishedSyncing';
import withOutOfDeadlineAssessment from 'components/temis/components/scholastic/withOutOfDeadlineAssessment';
import { getSubTypesToDisplayFilterData } from 'components/temis/report/utils';
import { connect } from 'react-redux';
import lodashGet from 'lodash.get';
import { createSelector } from 'reselect';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import TableResultsTree from './TableResultsTree';

const RenderResultsComponent = (
  resultData = {},
  {
    tcnn,
    isResultByRubric,
    isResultForCBQL,
    isResultForMN,
    exportProps,
    userInfo,
    isAdminHVQL,
    formid = '',
    isOutOfDeadlineAssessment,
    excelExportFileName,
  },
  objects,
  searchValues = {},
) => {
  const { data, last_time_finished_syncing } = resultData;
  const { organizations = [], scholastic } = searchValues;
  const hasData = Array.isArray(data) && !!data.length;

  return (
    <>
      <div className="m-b-15 text-right">
        <div className="d-flex justify-content-end">
          {hasData && (
            <div className="m-t-12 m-r-10">
              {lastTimeFinishedSyncing(last_time_finished_syncing)}
            </div>
          )}

          <div className="report-result__syncer m-r-10">
            <ReportProgressSyncerButton
              formId={formid}
              params={{
                scholastic,
                organizations,
              }}
              disabled={isOutOfDeadlineAssessment}
            />
          </div>

          {hasData && (
            <div className="report-result__export-excel">
              <ExportData
                buttonClass={'m-b-15'}
                apiExport={
                  apiEndpoints.export_summary_report_assessment_by_tcnn
                }
                buttonText="Xuất excel"
                params={{
                  scholastic,
                  isResultByRubric: isResultByRubric ? 1 : 0,
                  isResultForCBQL: isResultForCBQL ? 1 : 0,
                  isResultForMN: isResultForMN ? 1 : 0,
                }}
                fileName={excelExportFileName}
                tableExport={
                  <TableResults
                    skipFormatNumber={true}
                    tcnn={tcnn}
                    flatExpandChildren
                    dataResult={resultData}
                    isResultByRubric={isResultByRubric}
                    isResultForCBQL={isResultForCBQL}
                  />
                }
                {...exportProps}
              />
            </div>
          )}
        </div>

        {hasData ? (
          <TableResults
            tcnn={tcnn}
            dataResult={resultData}
            isResultByRubric={isResultByRubric}
            isResultForCBQL={isResultForCBQL}
            lastTimeFinishedSyncing={last_time_finished_syncing}
          />
        ) : (
          <SimpleNoResult text="Không có dữ liệu" />
        )}
      </div>
    </>
  );
};

const RenderResultsComponentTree = ({
  resultData = [],
  tcnn,
  isResultByRubric,
  isResultForCBQL,
  isResultForMN,
  exportProps,
  formid = '',
  isOutOfDeadlineAssessment,
  excelExportFileName,
  org_types,
  objects = {},
  searchValues = {},
}) => {
  const [
    subTypesOfChildrenDisplay,
    setSubTypesOfChildrenDisplay,
  ] = React.useState([]);

  const { subTypeData = {}, subTypeOptions = [] } =
    (Array.isArray(resultData) &&
      resultData.length &&
      getSubTypesToDisplayFilterData(resultData)) ||
    {};
  const { organizations = [], scholastic } = searchValues;
  const { score_scale, last_time_finished_syncing } = objects;

  const hasData = Array.isArray(resultData) && !!resultData.length;

  return (
    <>
      {hasData &&
        Array.isArray(subTypeOptions) &&
        !!subTypeOptions.length &&
        Array.isArray(org_types) && (
          <CheckboxGroup
            className="m-b-30"
            options={subTypeOptions.map((subType) => {
              const orgType =
                org_types.find(({ type }) => type == subType) || {};

              return {
                label: orgType.name || subType,
                value: parseInt(subType),
              };
            })}
            value={subTypesOfChildrenDisplay}
            onChange={(values) => {
              let newValue = values;
              values.forEach((value) => {
                newValue = newValue.concat(subTypeData[value]);
              });
              setSubTypesOfChildrenDisplay(
                newValue.filter((val, index, self) => {
                  return self.indexOf(val) === index;
                }),
              );
            }}
          />
        )}

      <div className="m-b-15 text-right">
        <div className="d-flex justify-content-end">
          {hasData && (
            <div className="m-t-12 m-r-10">
              {lastTimeFinishedSyncing(last_time_finished_syncing)}
            </div>
          )}

          <div className="report-result__syncer m-r-10">
            <ReportProgressSyncerButton
              formId={formid}
              params={{
                scholastic,
                organizations,
                resync_all_children: 1,
              }}
              disabled={isOutOfDeadlineAssessment}
            />
          </div>

          {hasData && (
            <div className="report-result__export-excel">
              <ExportData
                buttonClass={'m-b-15'}
                apiExport={
                  apiEndpoints.export_summary_report_assessment_by_tcnn
                }
                buttonText="Xuất excel"
                params={{
                  scholastic,
                  isResultByRubric: isResultByRubric ? 1 : 0,
                  isResultForCBQL: isResultForCBQL ? 1 : 0,
                  isResultForMN: isResultForMN ? 1 : 0,
                }}
                fileName={excelExportFileName}
                updateTableBody={subTypesOfChildrenDisplay}
                tableExport={
                  <TableResultsTree
                    skipFormatNumber={true}
                    tcnn={tcnn}
                    scoreScale={score_scale}
                    flatExpandChildren
                    dataResult={resultData}
                    isResultByRubric={isResultByRubric}
                    isResultForCBQL={isResultForCBQL}
                    isResultForMN={isResultForMN}
                    subTypesOfChildrenDisplay={subTypesOfChildrenDisplay}
                  />
                }
                {...exportProps}
              />
            </div>
          )}
        </div>

        <TableResultsTree
          tcnn={tcnn}
          scoreScale={score_scale}
          dataResult={resultData}
          isResultByRubric={isResultByRubric}
          isResultForCBQL={isResultForCBQL}
          isResultForMN={isResultForMN}
          subTypesOfChildrenDisplay={subTypesOfChildrenDisplay}
        />
      </div>
    </>
  );
};

const RenderTree = (
  resultData = {},
  {
    tcnn,
    isResultByRubric,
    isResultForCBQL,
    isResultForMN,
    exportProps,
    userInfo,
    isAdminHVQL,
    formid = '',
    isOutOfDeadlineAssessment,
    excelExportFileName,
    org_types,
  },
  objects = {},
  searchValues = {},
) => {
  const params = {
    tcnn,
    isResultByRubric,
    isResultForCBQL,
    isResultForMN,
    exportProps,
    userInfo,
    isAdminHVQL,
    formid,
    isOutOfDeadlineAssessment,
    excelExportFileName,
    resultData,
    searchValues,
    objects,
    org_types,
  };
  return <RenderResultsComponentTree {...params} />;
};
const TCNNReportSearch = ({
  tcnn,
  scholastic,
  isResultForMN,
  isResultByRubric,
  isResultForCBQL,
  showSearchForm = true,
  hideSubmitButton = false,
  autoSearchWhenValuesChange = false,
  userInfo,
  isAdminHVQL,
  excelExportFileName,
  org_types,
}) => {
  const [modeView, setMode] = React.useState('normal');
  if (!scholastic) {
    return null;
  }

  const formId = `temis-report-${tcnn}-${scholastic}_${
    isResultByRubric ? 'detail' : 'normal'
  }`;

  const roleToShowSearchForm =
    !isAdminTruong(userInfo) && !isHieuTruongOrPho(userInfo);
  const shouldHideSearchForm = !showSearchForm || !roleToShowSearchForm;
  const schemaForm = shouldHideSearchForm ? undefined : schema();

  return (
    <SearchWrapper
      enableDebounceTime={500}
      key={formId}
      formid={formId}
      tcnn={tcnn}
      schema={schemaForm}
      hiddenFields={{
        tcnn,
        scholastic,
        isResultByRubric: isResultByRubric ? 1 : 0,
        isResultForMN: isResultForMN ? 1 : 0,
      }}
      showResultsWhenSubmitSucceeded
      alternativeApi={
        modeView === 'tree'
          ? apiEndpoints.summary_report_assessment_by_organization
          : apiEndpoints.summary_report_assessment_by_tcnn
      }
      renderResultsComponent={
        modeView === 'tree' ? RenderTree : RenderResultsComponent
      }
      onChange={(value) => {
        setMode(value.view_mode);
      }}
      isResultByRubric={isResultByRubric}
      isResultForCBQL={isResultForCBQL}
      isResultForMN={isResultForMN}
      hideSubmitButton={hideSubmitButton || shouldHideSearchForm}
      showResult
      autoSearchWhenStart={true}
      autoSearchWhenValuesChange={autoSearchWhenValuesChange}
      userInfo={userInfo}
      isAdminHVQL={isAdminHVQL}
      excelExportFileName={excelExportFileName}
      org_types={org_types}
    />
  );
};

const mapStateToProps = createSelector(
  (state) => lodashGet(state, 'domainInfo.school.org_types'),
  (org_types) => ({ org_types }),
);

export default connect(mapStateToProps)(
  withOutOfDeadlineAssessment(TCNNReportSearch),
);
